
import { ClaimReviewModel } from '@alcon-db-models/ClaimReviewModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class ClaimReviewEntityService extends EntityCollectionServiceBase<ClaimReviewModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ClaimReview', serviceElementsFactory);
  }
}

