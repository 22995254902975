



export class DataModel2  {
    creationDate?: Date | null;
    createdByPersonID?: number | null;
    createdByPerson?: string | null;
    modifiedDate?: Date | null;
    modifiedByPersonID?: number | null;
    modifiedByPerson?: string | null;
}

 
     
         
