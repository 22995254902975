import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppSessionSelectors } from './app-session';
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { AccessRole, BusinessRole, StatusCode } from '@alcon-db-models/Enums';
import { BusinessCommon } from '../shared/business-common';

export const selectIsReady: MemoizedSelector<object, boolean> = createSelector(
  AppSessionSelectors.selectIsReady,
  (isReady?: boolean) => (isReady ?? false)
);

export const selectCurrentPersonFeatures: MemoizedSelector<object, FeatureModel[]> = createSelector(
  AppSessionSelectors.selectCurrentPerson,
  AppSessionSelectors.selectFeatureMap,
  (currentPerson: PersonWithRolesModel | null, featureMap: FeatureModel[] | null) => {
    return featureMap && currentPerson ?  BusinessCommon.getPersonFeatures(featureMap, currentPerson) : [];
    // return featureMap
    //   ?.filter(x => x.statusCode == StatusCode.Active)
    //   .map(x =>
    //     ({
    //       feature: x,
    //       accessRolesCommon: x.accessRoles?.filter(y => currentPerson?.accessRoles?.includes(y) ?? false) ?? [],
    //       businessRolesCommon: x.businessRoles?.filter(y => currentPerson?.businessRoles?.includes(y) ?? false) ?? [],
    //       teamIDsCommon: x.teamIDs?.filter(y => currentPerson?.teamIDs?.includes(y) ?? false) ?? []
    //     }))
    //   // must match at least one access role
    //   .filter(x => x.accessRolesCommon?.length)
    //   .map(x => ({...x, doCheckBusinessRoles: x.feature.businessRoles?.length && x.accessRolesCommon?.length == 1 && x.accessRolesCommon[0] == AccessRole.SalesRep }))
    //   // we'll check for matching business roles if feature specifies and the only matching access role is SalesRep
    //   .filter(x => !x.doCheckBusinessRoles || x.businessRolesCommon?.length)
    //   .map(x => ({...x, doCheckAMTeams: x.feature.teamIDs?.length && x.doCheckBusinessRoles && x.businessRolesCommon?.length == 1 && x.businessRolesCommon[0] == BusinessRole.Am }))
    //   // we'll check for matching teams if feature specifies and the only matching business role is AM
    //   .filter(x => !x.doCheckAMTeams || x.teamIDsCommon?.length)
    //   .map(x => x.feature)
    //   ?? [new FeatureModel()];
  }
);






