



export class TerritoryWithBalanceModel  {
    territoryID?: number | null;
    code?: string | null;
    displayName?: string | null;
    businessRole?: string | null;
    primarySalesPersonID?: number | null;
    primarySalesPerson?: string | null;
    primaryBusinessRoleID?: number | null;
    lastTransactionDate?: Date | null;
    balance?: number | null;
    isInCurrentHierarchy?: boolean | null;
}

 
     
         
