



export class ApplicationInfoModel  {
    environmentDisplayName?: string | null;
    dbEnvironmentDisplayName?: string | null;
    doEnvironmentsMatch?: boolean | null;
    alconDepartmentName?: string | null;
    userUploadsWebFolder?: string | null;
    userUploadsStagingFolder?: string | null;
    userUploadsCommitmentsFolder?: string | null;
    userUploadsClaimsFolder?: string | null;
    userUploadsAttachmentsFolder?: string | null;
    userUploadsResourcesFolder?: string | null;
    userUploadsDraftsFolder?: string | null;
    sandboxRestoredBackupDate?: Date | null;
    sandboxRestoreDate?: Date | null;
}

 
     
         
