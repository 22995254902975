import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, first, map, tap, finalize } from 'rxjs/operators';
import { GridResultModel } from './../../../../../../exports/acb-alcon-data/GridResultModel';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Location } from '@angular/common';
import { environment } from './../../environments/environment';
import { ServiceResponse, firstWithTimeout, getErrorMessage, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { AppUxService } from './app-ux.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { JsonUtilities } from '../shared/json-utilities';

export abstract class SearchServiceBase<T> extends BehaviorSubject<GridDataResult> {

  public loading: boolean = false;

  constructor(
      protected http: HttpClient,
      protected router: Router,
      protected store: Store,
      protected localPath: string,
      private appUxService: AppUxService
    ) {
    super((<GridDataResult>{ data: [], total: 0 }));
  }

  public query(searchRequest: T): void {
    this.fetch(searchRequest).pipe(
      first(),
      catchError(err => {
        //Need to show error here.  Inserting error into super's stream will end subscriptions to it
        this.appUxService.openErrorDialog(getErrorMessage(err));
        return of(<GridDataResult>{ data: [], total: 0 });
      })
    ).subscribe(x => {
        super.next(x)
    });
  }

  protected fetch(searchRequest: any): Observable<GridDataResult> {
    this.loading = true;

    return this.search(searchRequest).pipe(
      map(x => (<GridDataResult>{
        data: x.value,
        total: x.total
      })),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  public search(searchRequest: T): Observable<GridResultModel> {
    let params = this.createParams(searchRequest);
    return this.http.get<GridResultModel>(this.createPath(), { params: params }).pipe(firstWithTimeout<GridResultModel>());
  }

  public searchWithErrorHandlingAndLoadingIndicator(searchRequest: T): Observable<GridResultModel> {
    let params = this.createParams(searchRequest);
    return this.http.get<GridResultModel>(this.createPath(), { params: params }).pipe(
      firstWithLoadingOverlayAndErrorHandling<GridResultModel>(),
      tap(x => {
        if (x.hasError) {
          this.appUxService.openErrorDialog(x.errorMessage);
        }
      }),
      map(x => x.response as GridResultModel)
    );
  }

  public searchForExcelExport(searchRequest: T): Observable<ExcelExportData> {
    return this.searchWithErrorHandlingAndLoadingIndicator(searchRequest).pipe(
      map(x => {
        let data = x?.value ?? undefined;
        data = x?.value?.map(y => JsonUtilities.convertDatesAndCopy(y));
        return ({ data: data });
      })
    )
  }

  private createParams(searchRequest: T) {
    let params: { [param: string]: string | string[]; } = {};
    Object.keys(searchRequest).filter(x => (searchRequest as any)[x] != null).forEach(x => params[x] = (searchRequest as any)[x]);
    return params;
  }

  private createPath() {
    return Location.joinWithSlash(environment.baseApiUrl, this.localPath);
  }

  // public search(searchRequest: T): Observable<GridResultModel> {
  //   // Clean request...
  //   //Object.keys(searchRequest).forEach((key) => ((searchRequest as any)[key] == null) && delete (searchRequest as any)[key]);
  //   let params: { [param: string]: string | string[]; } = {};
  //   Object.keys(searchRequest).filter(x => (searchRequest as any)[x] != null).forEach(x => params[x] = (searchRequest as any)[x])
  //   return this.http.get<GridResultModel>(Location.joinWithSlash(environment.baseApiUrl, this.localPath), { params: params })
  //     .pipe(catchError((e) => throwError("application/identity failed")))
  // }

}
