import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClaimWithDetailsService } from '@services/claim-with-details.service';
import { CommitmentWithDetailsService } from '@services/commitment-with-details.service';
import { of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';

export type claimPrintStatus = 'loading' | 'ready' | 'failed';

@Component({
  selector: 'acb-alcon-print-claim-page',
  templateUrl: './print-claim-page.component.html',
  styleUrls: ['./print-claim-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintClaimPageComponent implements OnInit, OnDestroy {

  public claimStatus: claimPrintStatus = 'loading';
  public commitmentStatus: claimPrintStatus = 'loading';
  public claim$: Subject<ClaimWithDetailsModel> = new Subject<ClaimWithDetailsModel>();
  public commitment$: Subject<CommitmentWithDetailsModel> = new Subject<CommitmentWithDetailsModel>();

  private _claimID?: number;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private claimWithDetailsService: ClaimWithDetailsService,
    private commitmentWithDetailsService: CommitmentWithDetailsService,
    private changeDetectorRef: ChangeDetectorRef
    ) {

    route.params.pipe(first()).subscribe(params => {
      this._claimID = params['id'];
    });

    this.claim$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      commitmentWithDetailsService.getByKey(x.commitmentID)
      .pipe(first(), catchError(() => { this.commitmentStatus = 'failed'; return of(undefined); }))
      .subscribe(x => { if (x?.commitmentID) { this.commitment$.next(x); this.commitmentStatus = 'ready'; changeDetectorRef.detectChanges(); } else { this.commitmentStatus = 'failed'; } });
    });

    if (!this._claimID) {
      this.claimStatus = 'failed';
    } else {
      claimWithDetailsService.getByKey(this._claimID)
        .pipe(first(), catchError(() => { this.claimStatus = 'failed'; return of(undefined); }))
        .subscribe(x => { if (x?.claimID) { this.claim$.next(x); this.claimStatus = 'ready'; changeDetectorRef.detectChanges(); } else { this.claimStatus = 'failed'; } });
    }
  }

  ngOnInit(): void {
  }

  public onPrint() {
    window.print();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
