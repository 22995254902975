
import { Injectable } from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { selectCurrentPerson, selectImpersonationStatus } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

// This will not work as expected.  Grid, data and/or directive is reused

@Injectable({
  providedIn: 'root'
})
export class PageReuseStrategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {};
  private _was_impersonating: boolean = false;

  public constructor(private rxStore: Store) {

    // this.rxStore.select(selectImpersonationStatus).subscribe((x) => {
    //   if (this._was_impersonating != x.isImpersonating)
    //     this.handlers = {};
    //   this._was_impersonating = x.isImpersonating;
    // })

  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;//(route.component as any)['name'] === 'ManageCommitmentsPageComponent';
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[this.getKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[this.getKey(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) {
      return null;
    }
    return this.handlers[this.getKey(route)];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return curr.routeConfig === future.routeConfig;
  }

  private getKey(route: ActivatedRouteSnapshot) {
    let key: string = "";
    let personID: number | null = 0;
    this.rxStore.select(selectCurrentPerson).pipe(first()).subscribe(x => personID = x?.personID ?? null);
    if (personID) {
      key = personID?.toString() + ':' + (route.component ? (route.component as any)['name'] : (route?.firstChild?.component as any)['name']);
    }
    return key;
  }

}
