import { CustomerSearchWithDefaultRequestModel } from '@alcon-db-models/CustomerSearchWithDefaultRequestModel';
import { Directive } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { AppUxService } from '@services/app-ux.service';
import { CustomerSelectSearchService } from '@services/customer-select-search-service';
import { SelectSearchBinding, SelectSearchBindingBaseDirective } from './select-search-binding.base.directive';

@Directive({
  selector: '[customerSelectSearchBinding]'
})
export class CustomerSelectSearchBindingDirective extends SelectSearchBindingBaseDirective implements SelectSearchBinding {

  constructor(customerSelectSearchService: CustomerSelectSearchService, grid: GridComponent, appUxService: AppUxService) {
    super(customerSelectSearchService, grid, appUxService);
  }

  public getSearchRequest(doIgnorePagination: boolean = false): CustomerSearchWithDefaultRequestModel {

    this.beforeBind.emit(null);

    this.grid.loading = true;

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };


    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      customerCodeOrName: getValue('customerCodeOrName'),
      prependCustomerID: getValue('prependCustomerID'),
    });
  }
}

