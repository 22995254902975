import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Component, EventEmitter, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, take, takeUntil } from 'rxjs/operators';
import { ViewMode, WindowMode } from '../components.module';
import { CustomerWithDetailsService } from '@services/customer-with-details.service';
import { CustomerWithDetailsModel } from '@alcon-db-models/CustomerWithDetailsModel';
import { DisplayError } from '../display-errors/display-errors.component';
import { CustomerType, PayType, VisibilityType } from '@alcon-db-models/Enums';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { PayTypeForDropdownService } from '@services/paytype-for-dropdown.service';
import { PayTypeForDropdownModel } from '@alcon-db-models/PayTypeForDropdownModel';
import { CustomerPayTypeModel } from '@alcon-db-models/CustomerPayTypeModel';
import { CustomerInfoService } from '@services/customer-info.service';
import { RemoveEvent } from '@progress/kendo-angular-grid';
import { CustomerEmailModel } from '@alcon-db-models/CustomerEmailModel';
import { AppUxService } from '@services/app-ux.service';
import { CustomerViewMode } from 'src/app/shared/static';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from 'src/app/shared/acb-stream';

@Component({
  selector: 'acb-alcon-edit-customer',
  template: `
    <kendo-dialog-titlebar class="acb-select-payee-titlebar" (close)="onClose()">
      {{ viewMode == 'edit' ? 'Edit' : 'New' }}
    </kendo-dialog-titlebar>
    <form [formGroup]="customerForm" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [ngClass]="sectionClass" [doShowLegend]="false">
        <div class="acb-inner-panel" fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start top">

          <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
            <kendo-label [for]="displayName" text="Customer Name"></kendo-label>
            <input formControlName="displayName" kendoTextBox #displayName autocomplete="off"/>
            <kendo-formhint>Customer's name</kendo-formhint>
            <kendo-formerror>Error: name required</kendo-formerror>
          </kendo-formfield>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
              <kendo-label [for]="locationLine1" text="Address 1"></kendo-label>
              <input formControlName="locationLine1" kendoTextBox #locationLine1 autocomplete="off"/>
              <kendo-formhint>Address Line 1</kendo-formhint>
              <kendo-formerror>Error: line required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <ng-container>
          <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
              <kendo-label [for]="locationLine2" text="Address 2"></kendo-label>
              <input formControlName="locationLine2" kendoTextBox #locationLine2 autocomplete="off"/>
              <kendo-formhint>Address Line 2</kendo-formhint>
              <kendo-formerror></kendo-formerror>
            </kendo-formfield>
          </ng-container>



          <!-- <ng-container *ngIf="customerViewMode != 'payee'">
            <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
              <kendo-label [for]="visibilityTypeID" text="Status"></kendo-label>
              <kendo-combobox
              [data]="this.visibilityTypes$ | async"
              [textField]="'displayName'"
              [valueField]="'id'"
              [formControlName]="'visibilityTypeID'"
              [valuePrimitive]="true"
              #visibilityTypeID
              autocomplete="off"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror></kendo-formerror>
            </kendo-formfield>
          </ng-container> -->

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto">
              <kendo-label [for]="city" text="City"></kendo-label>
              <input formControlName="city" kendoTextBox #city autocomplete="off"/>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: city required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
              <kendo-label [for]="stateProvinceCodeID" text="State / Province"></kendo-label>
              <kendo-combobox
              [data]="this.stateProvinceCodes$ | async"
              [textField]="'displayName'"
              [valueField]="'id'"
              [filterable]="true"
              [formControlName]="'stateProvinceCodeID'"
              (filterChange)="onStateDropdownFilter($event)"
              [valuePrimitive]="true"
              #stateProvinceCodeID
              autocomplete="off"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: state required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 8em" fxFlex.lt-sm="auto">
              <kendo-label [for]="postalCodeValue" text="Postal Code"></kendo-label>
              <input formControlName="postalCodeValue" kendoTextBox #postalCodeValue autocomplete="off"/>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: Postal Code required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <div class="acb-flex-break"></div>

          <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
            <kendo-label [for]="countryCodeID" text="Country"></kendo-label>
            <kendo-dropdownlist
              [data]="countryCodes$ | async"
              [textField]="'displayName'"
              [valueField]="'id'"
              [formControlName]="'countryCodeID'"
              [valuePrimitive]="true"
              #countryCodeID
              >
            </kendo-dropdownlist>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
              <kendo-label [for]="taxCode" text="Federal Tax ID"></kendo-label>
              <input formControlName="taxCode" kendoTextBox #taxCode autocomplete="off"/>
              <kendo-formhint>Customer's tax ID</kendo-formhint>
              <kendo-formerror></kendo-formerror>
            </kendo-formfield>
          </ng-container>


          <ng-container *ngIf="canAdministrateCustomers">

            <ng-container>
              <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
                <kendo-label [for]="batchPayTypeID" text="Batch Pay Type"></kendo-label>
                <kendo-combobox
                [data]="this.payTypes$ | async"
                [textField]="'displayName'"
                [valueField]="'payTypeID'"
                [formControlName]="'batchPayTypeID'"
                [valuePrimitive]="true"
                #batchPayTypeID
                autocomplete="off"
                >
                </kendo-combobox>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror></kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <ng-container>
              <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
                <kendo-label [for]="canBeClaimed" class="acb-form-label-and-checkbox" text="Can Be Claimed"><input formControlName="canBeClaimed" type="checkbox" kendoCheckBox #canBeClaimed /></kendo-label>
              </kendo-formfield>
            </ng-container>

            <div class="acb-flex-break"></div>

            <div fxLayout="row">
              <div fxFlex fxLayout="column" *ngIf="restrictions && restrictions.length" class="acb-grid-wrapper">
                <label class='k-label'>Restrictions</label>
                <kendo-grid
                  class="acb-display-grid"
                  [data]= "restrictions"
                  [pageable]="false"
                  [sortable]="false"
                  [reorderable]="false"
                  [selectable]="false"
                  [resizable]="true"
                  >
                  <kendo-grid-column title="Pay Type" field="payType">
                  </kendo-grid-column>
                  <kendo-grid-column title="Allowed" field="doExclude" [width]="80">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span [ngClass]="{ 'acb-danger': dataItem.doExclude, 'acb-good': !dataItem.doExclude  }">{{ dataItem.doExclude ? 'No' : 'Yes' }}</span>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
                <ng-container *ngIf="isACHEnrolled">
                  <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="end end">
                    <kendo-label [for]="isACHDisabled" class="acb-form-label-and-checkbox" text="Disable ACH"><input formControlName="isACHDisabled" type="checkbox" kendoCheckBox #isACHDisabled /></kendo-label>
                  </kendo-formfield>
                </ng-container>
              </div>
              <div fxFlex="60%" fxLayout="column" class="acb-grid-wrapper" *ngIf="CustomerWithDetails?.emailAddresses as emailAddresses">
                <label class='k-label'>Email</label>
                <kendo-grid
                  class="acb-display-grid"
                  [data]= "emailAddresses"
                  [pageable]="false"
                  [sortable]="false"
                  [reorderable]="false"
                  [selectable]="false"
                  [resizable]="true"
                  (remove)="onEmailRowRemove($event)"
                  >
                  <kendo-grid-command-column
                    [width]="40"
                    [reorderable]="false"
                    [includeInChooser]="false"
                    [resizable]="false"
                    >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <button kendoGridEditCommand class="acb-in-grid-edit-button"  [icon]="'edit'" look="clear" (click)="onEditEmail($event, dataItem)"></button>
                    </ng-template>
                  </kendo-grid-command-column>
                  <kendo-grid-column title="Address" field="emailAddress" >
                  </kendo-grid-column>
                  <kendo-grid-column title="Opt In" field="allowBitField" [width]="70">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span [ngClass]="{ 'acb-danger': !allowBit(dataItem), 'acb-good': allowBit(dataItem)  }">{{ allowBit(dataItem) ? 'Yes' : 'No' }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-command-column [width]="40">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button kendoGridRemoveCommand [look]="'clear'" [icon]="'delete'"></button>
                    </ng-template>
                  </kendo-grid-command-column>
                </kendo-grid>
                <div class="acb-add-product-button-wrapper" fxLayout="row" fxLayoutAlign="end end">
                  <button kendoButton (click)="onAddEmail()"><span class="k-icon k-i-add"></span> Add Email</button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" ><span class="k-icon k-i-checkmark"></span>{{ viewMode == 'edit' ? 'Update' : 'Save' }}</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-customer.component.scss'],
  providers: [
    LocalizationService,
    {provide: L10N_PREFIX, useValue: ''},
    { provide: LOCALE_ID, useValue: "en-US" },
  ]
})
export class EditCustomerComponent implements OnDestroy, OnInit {

  private fb: UntypedFormBuilder = new UntypedFormBuilder();

  @Input() public viewMode:ViewMode = "edit";
  @Input() public customerViewMode:CustomerViewMode = 'thirdparty';

  private _customerID?:number | null | undefined;
  @Input() set customerID(value:number | null | undefined) {
    if (this._customerID = value) {
      this.CustomerWithDetails$ = this.customerWithDetailsService.getByKey(value);
      this.CustomerWithDetails$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.CustomerWithDetails = JsonUtilities.convertDatesAndCopy(x);
        this.customerForm.patchValue({...x});
      });
    }
    else {
      this.customerForm.reset({});
      this.CustomerWithDetails = undefined;
    }
  }

  private _title?: string;
  @Input() public set title(value: string) { this._title = value; };
  public get title(): string {
    if (this._title) {
      return this._title;
    } else {
      const customerTypeLabel: string = this.customerViewMode == "thirdparty" ? "Third Party" : "Customer";
      switch(this.viewMode) {
        case "edit":
          return "Edit " + customerTypeLabel
        default:
          return "New " + customerTypeLabel
      }
    }
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<CustomerWithDetailsModel> = new EventEmitter();

  public staticTypes$: Observable<StaticTypeModel[]>;
  public stateProvinceCodes$: Observable<StaticTypeModel[]>;
  public countryCodes$: Observable<StaticTypeModel[]>;
  public visibilityTypes$: Observable<StaticTypeModel[]>;
  public payTypesSource$: BehaviorSubject<PayTypeForDropdownModel[]> = new BehaviorSubject([] as PayTypeForDropdownModel[]);
  public payTypes$: Subject<PayTypeForDropdownModel[]> = new Subject();

  public restrictions: CustomerPayTypeModel[] = [];

  public CustomerWithDetails$: Observable<CustomerWithDetailsModel> = new Observable;
  public CustomerWithDetails?: CustomerWithDetailsModel;
  public loading$: Observable<boolean> = this.customerWithDetailsService.loading$;
  public destroy$: Subject<void> = new Subject<void>();
  public displayErrors$:  BehaviorSubject<DisplayError[]> = new BehaviorSubject<DisplayError[]>([]);

  public canAdministrateCustomers: boolean = false;
  public emailAddresses: CustomerEmailModel[] = [];

  public get sectionClass(): string {
    switch (this.customerViewMode) {
      case "payee":
        return 'acb-section-02';
      default:
        return 'acb-section-01';
    }
  }

  public get isACHEnrolled() {
    return Boolean(this.CustomerWithDetails?.isACHEnrolled);
  }

  // If mask contains 1 then email opts out and we reverse value to get "allow"
  public allowBit(email:CustomerEmailModel) {
    return !Boolean((email.allowBitField ?? 0) & 1);
  }

  public customerForm = this.fb.group({
    customerID: new UntypedFormControl(),
    code: new UntypedFormControl(),
    displayName: new UntypedFormControl(null, [Validators.required]),
    lensRating: new UntypedFormControl(),
    lensCareRating: new UntypedFormControl(),
    orderBlock: new UntypedFormControl(),
    sfCustomerAccount: new UntypedFormControl(),
    locationLine1: new UntypedFormControl(null, [Validators.required]),
    locationLine2: new UntypedFormControl(),
    city: new UntypedFormControl(null, [Validators.required]),
    stateProvinceCodeID: new UntypedFormControl(null, [Validators.required]),
    postalCodeValue: new UntypedFormControl(null, [Validators.required]),
    countryCodeID: new UntypedFormControl(null, [Validators.required]),
    taxCode: new UntypedFormControl(),
    visibilityTypeID: new UntypedFormControl(VisibilityType.Visible,[Validators.required]),
    canBeClaimed: new UntypedFormControl(true,[Validators.required]),
    isACHDisabled: new UntypedFormControl(),
    isACHEnrolled: new UntypedFormControl(),
    batchPayTypeID: new UntypedFormControl(),
    customerTypeID: new UntypedFormControl(this.customerViewMode == 'customer' ? CustomerType.Hierarchy : CustomerType.AlternativePayee),
  });

  constructor(
    private store: Store,
    private customerWithDetailsService: CustomerWithDetailsService,
    //private customerInfoService: CustomerInfoService,
    private payTypeForDropdownService: PayTypeForDropdownService,
    private appUxService: AppUxService
    ) {

    this.staticTypes$ = this.store.select(selectStaticTypes);
    this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
    this.countryCodes$ = this.staticTypes$.pipe(map(x =>  x.filter(y => y.tableName == 'CountryCode')));
    this.visibilityTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'VisibilityType')));
    this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'PayType')));
    this.payTypesSource$.pipe(takeUntil(this.destroy$)).subscribe(x => this.updatePayTypes(x));

    this.payTypeForDropdownService.getAll().pipe(first()).subscribe(y => this.payTypesSource$.next(y));

    store.select(selectCurrentPersonFeatures).pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.canAdministrateCustomers = x.some((x:FeatureModel) => x.code == 'can_administrate_customers');
    });

    this.customerForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let payTypes = this.updatePayTypes() ?? [];
      if (x.payTypeID && !payTypes.some(y => y.payTypeID === x.payTypeID)) {
        this.customerForm.patchValue({ payTypeID: undefined }, { emitEvent: false });
      }
      this.updatePayTypeRestrictions();
    });
  }

  public validateForm(): boolean {
    this.customerForm.markAllAsTouched();
    return this.customerForm.valid;
  }

  public getErrors(): string[] {

    const errors: string[] = [];
    const de: DisplayError[] = [];
    function addError(s: string) {
      errors.push(s);
      de.push({ message: s });
    }

    //TODO: Refactor, merge with kendo-formerror.  String resource?

    if (this.customerForm.controls.displayName.errors)
      addError("Customer Name is required");

    if (this.customerForm.controls.locationLine1.errors)
      addError("Address 1 is required");

    if (this.customerForm.controls.city.errors)
      addError("City is required");

    if (this.customerForm.controls.stateProvinceCodeID.errors)
      addError("State is required");

    if (this.customerForm.controls.postalCodeValue.errors)
      addError("Zipcode is required");

    this.displayErrors$.next(de.length ? [{ children: de, groupTitle: 'Required Fields', message: '' }] : [])
    return errors;
  }

  private updatePayTypes(payTypesSource?: PayTypeForDropdownModel[]) {
    const payTypes = payTypesSource ?? this.payTypesSource$.value ?? [];
    const nextPayTypes = this.CustomerWithDetails?.isACHEnrolled && !this.customerForm.value.isACHDisabled ?
      payTypes.filter(x => x.payTypeID != PayType.Check) :
      payTypes.filter(x => x.payTypeID != PayType.Ach);
    this.payTypes$.next(nextPayTypes);
    return nextPayTypes;
  }

  private updatePayTypeRestrictions() {
    let restrictionsToAssign = [...(this.CustomerWithDetails?.payTypes?.map(x => ({...x})) ?? [])];
    if (restrictionsToAssign.length) {
      let res = restrictionsToAssign.find(x => x.payTypeID == PayType.Ach);
      if (res) res.doExclude = this.customerForm.value.isACHEnrolled ? Boolean(this.customerForm.value.isACHDisabled) : true;
      res = restrictionsToAssign.find(x => x.payTypeID == PayType.Check);
      if (res) res.doExclude = this.customerForm.value.isACHEnrolled ? !Boolean(this.customerForm.value.isACHDisabled) : false;
    }
    this.restrictions = restrictionsToAssign.sort((a,b) => ((a.payType ?? "") > (b.payType ?? "")) ? 1 : (((b.payType ?? "") > (a.payType ?? "")) ? -1 : 0));
  }

  ngOnInit(): void {
    if (this.viewMode == "new") {
      this.CustomerWithDetails = { emailAddresses: [] };
    }
  }

  public onStateDropdownFilter(value: string) {
    if (!value) {
      this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
      return;
    }
    this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y =>
      y.tableName == 'StateProvinceCode' && y.code!.toLowerCase().startsWith(value.toLowerCase())
      )));
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onSave() {

    if (!this.validateForm()) {
      this.getErrors();
      return;
    }

    const customer = {
      ...this.CustomerWithDetails,
      ...this.customerForm.value
    }

    this.customerWithDetailsService.upsert(customer).pipe(
      firstWithLoadingOverlayAndErrorHandling<CustomerWithDetailsModel>()
    ).subscribe((x:ServiceResponse<CustomerWithDetailsModel>) => {
      if (x.hasError) {
        this.appUxService.openErrorDialog(x.errorMessage);
      } else {
        this.saved.emit(x.response ?? undefined);
      }
    });

    // this.customerWithDetailsService.upsert(customer).pipe(first()).subscribe(y => {
    //   this.saved.emit(y);
    // })

  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onEmailRowRemove(event: RemoveEvent) {
    const email = (event.dataItem as CustomerEmailModel);
    if (!email || !this.CustomerWithDetails?.emailAddresses?.length) return;
    this.CustomerWithDetails.emailAddresses = this.CustomerWithDetails.emailAddresses.filter(x => {
      return email.customerEmailID ?
        x.customerEmailID != email.customerEmailID :
        x.customerEmailID || x.emailAddress != email.emailAddress;
    })
  }

  public onEditEmail = ((event: any, dataItem: any ) =>  {
    const dialogSpec = this.appUxService.openEditCustomerEmail(dataItem);
    if (!dialogSpec?.component || !dialogSpec.dialogRef)
      return;

    dialogSpec.component.save.pipe(first()).subscribe(x => {
      dialogSpec.dialogRef.close();
      (dataItem as CustomerEmailModel).emailAddress = x.emailAddress;
      (dataItem as CustomerEmailModel).allowBitField = x.optIn ? 0 : 1;
    });

    dialogSpec.component.cancel.pipe(first()).subscribe(x => {
      dialogSpec.dialogRef.close();
    });
  }).bind(this);

  public onAddEmail = (() =>  {

    const dialogSpec = this.appUxService.openAddCustomerEmail();
    if (!dialogSpec?.component || !dialogSpec.dialogRef)
      return;

    dialogSpec.component.save.pipe(first()).subscribe(x => {
      dialogSpec.dialogRef.close();
      if (x && this.CustomerWithDetails) {
        (this.CustomerWithDetails.emailAddresses = this.CustomerWithDetails.emailAddresses ?? []).push({ emailAddress: x.emailAddress, allowBitField: x.optIn ? 0 : 1 });
      }
    });

    dialogSpec.component.cancel.pipe(first()).subscribe(x => {
      dialogSpec.dialogRef.close();
    });
  }).bind(this);


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


