import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WidgetParameter, WidgetParameterSpec } from '../filter-chooser/filter-chooser.component';

@Component({
  selector: 'acb-alcon-filter-chooser-field[form][parameter][parameterSpec]',
  template: `
    <div [formGroup]="form" *ngIf="form && parameterSpec && parameterSpec.code">
      <label [attr.for]="parameterSpec.code">{{ parameterSpec.label }}</label>
      <div [ngSwitch]="parameterSpec.type">
        <kendo-dropdownlist [formControlName]="parameterSpec.code" [data]="parameterSpec.choices" *ngSwitchCase="'choice'" [popupSettings]="{ popupClass: 'acb-widget-tool-dropdown-popup' }">
        </kendo-dropdownlist>
        <input [formControlName]="parameterSpec.code" type="checkbox" kendoCheckBox *ngSwitchCase="'bit'"/>
      </div>
    </div>
  `,
  styleUrls: ['./filter-chooser-field.component.scss']
})
export class FilterChooserFieldComponent implements OnInit {

  @Input() form?: UntypedFormGroup;

  @Input() parameter: WidgetParameter = new WidgetParameter();
  @Input() parameterSpec: WidgetParameterSpec = new WidgetParameterSpec();

  constructor() { }

  ngOnInit(): void {
  }

}
