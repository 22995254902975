import { ClientStorageService, ClientStorageType } from '@data-services/client-storage.service';
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { ThemeModel } from '@alcon-db-models/ThemeModel';
import { AppConfigService } from '@data-services/app-config.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { AccessRoleModel } from '@alcon-db-models/AccessRoleModel';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { AuditCodeWithGroupModel } from '@alcon-db-models/AuditCodeWithGroupModel';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { BusinessRoleModel } from '@alcon-db-models/BusinessRoleModel';
import { ReportModel } from '@alcon-db-models/ReportModel';
import { ResourceTypeModel } from '@alcon-db-models/ResourceTypeModel';
import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';
import { BannerNotificationTypeModel } from '@alcon-db-models/BannerNotificationTypeModel';
import { TeamModel } from '@alcon-db-models/TeamModel';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { updateIsReady } from './app-session.actions';

export const appSessionFeatureKey = 'AppSession';
export const appSessionLocalStorageKey = appSessionFeatureKey;

export interface AppSessionState {
  isReady: boolean,
  memoryOnlyRefreshToken: string | null;
  accessPerson: PersonWithRolesModel | null;
  currentPerson: PersonWithRolesModel | null;
  doKeepMeLoggedIn: boolean | null;
  isWaitingForPasswordReset: boolean | null;
  // Isn't this a bad idea?
  lastError: any;
  featureMap: FeatureModel[];
  reports: ReportModel[];
  themes: ThemeModel[];
  staticTypes: StaticTypeModel[],
  statusCodesWithGroups: StatusCodeWithGroupsModel[],
  auditCodesWithGroups: AuditCodeWithGroupModel[],
  productsForDropdown: ProductForDropdownModel[],
  resourceTypes: ResourceTypeModel[],
  accessRoles: AccessRoleModel[],
  businessRoles: BusinessRoleModel[],
  businessRules: any,
  environmentRules: any,
  bannerNotificationTypes: BannerNotificationTypeModel[],
  applicationInfo: ApplicationInfoModel,
  externalLinks: any,
  transferDepositTypes?: StaticTypeModel[],
  pullbackDepositTypes?: StaticTypeModel[],  
  teams: TeamModel[]
}

export const initialState: AppSessionState = {
  isReady: false,
  memoryOnlyRefreshToken: null,
  accessPerson: null,
  currentPerson: null,
  doKeepMeLoggedIn: false,
  isWaitingForPasswordReset: false,
  lastError: null,
  featureMap: [],
  reports: [],
  themes: [],
  staticTypes: [],
  statusCodesWithGroups: [],
  auditCodesWithGroups: [],
  productsForDropdown: [],
  resourceTypes: [],
  accessRoles: [],
  businessRoles: [],
  businessRules: {},
  environmentRules: {},
  bannerNotificationTypes: [],
  applicationInfo: {},
  externalLinks: {},
  transferDepositTypes: [],
  pullbackDepositTypes: [],  
  teams: []
};

export function appIntialStateFactory(clientStorageService: ClientStorageService, appConfigService: AppConfigService) {
  return from(appConfigService.loadInitialConfiguration()).pipe(map (configValues => {
    let localValues = clientStorageService.getItem(appSessionLocalStorageKey);
    localValues.isReady = false;
    let state = {
      ...initialState,
      ...localValues,
      ...configValues, 
      ...{ isReady: true }
    }
    //clientStorageService.setItem(appSessionLocalStorageKey, state, ClientStorageType.sessionOnly);

    return state;
  }))
}

export function appIntialIntialStateFactory(clientStorageService: ClientStorageService) {
  let localValues = clientStorageService.getItem(appSessionLocalStorageKey);
  let state = {
    ...initialState,
    ...localValues
  }
  return  { [appSessionFeatureKey]: state};
}
  
