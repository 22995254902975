import { Component, OnInit } from '@angular/core';
import { CommitmentSearchModel } from '@alcon-db-models/CommitmentSearchModel';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { from, Observable, Subject } from 'rxjs';
import { PageExpansionService } from '@services/page-expansion.service';
import { Store } from '@ngrx/store';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { SearchFilterService } from '@services/search-filter.service';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';

@Component({
  selector: 'acb-alcon-search-base',
  template: '',
  styles: [ ]
})
export abstract class SearchBaseComponent implements OnInit {

  public get searchForm() { return this.searchFilterService.formGroup; }
  public set searchForm(value:any) { this.searchFilterService.formGroup = value }

  public destroy$: Subject<void> = new Subject<void>();
  public staticTypes$: Observable<StaticTypeModel[]>;
  public statusCodes$: Observable<StaticTypeModel[]>;
  public statusCodesWithGroups$: Observable<StatusCodeWithGroupsModel[]>;
  public payTypes$: Observable<StaticTypeModel[]>;
  public fundingDocumentTypes$: Observable<StaticTypeModel[]>;
  public fundsForDropdownSource$: Observable<FundForDropdownModel[]>;
  public fundsForDropdownByYear$: Observable<FundForDropdownModel[]>;
  public fundsForDropdown$: Observable<FundForDropdownModel[]>;
  public fundsYears$: Observable<number[]>;
  public searchResults$: Observable<CommitmentSearchModel> = from([]);
  public territoriesForDropdownSource$: Observable<TerritoryForDropdownModel[]>;
  public territoriesForDropdown$: Observable<TerritoryForDropdownModel[]>;
  public activityForDropdownModel$: Observable<ActivityForDropdownModel[]>;
  public expenseTypes$: Observable<StaticTypeModel[]>;


  public pageExpanded$ = this.pageExpansionService.pageExpanded$;
  public expandToggleIcon$ = this.pageExpansionService.pageExpanded$.pipe(takeUntil(this.destroy$), startWith(false), map(x => x ? 'fullscreen-exit' : 'fullscreen'));
  public expandToggleIcon: 'fullscreen-exit' | 'fullscreen' = 'fullscreen';

  constructor(
    protected store: Store,
    protected pageExpansionService: PageExpansionService,
    protected searchFilterService: SearchFilterService
  ) {
    this.staticTypes$ = searchFilterService.staticTypes$;
    this.statusCodesWithGroups$ = searchFilterService.statusCodesWithGroups$;
    this.statusCodes$ = searchFilterService.statusCodes$;
    this.payTypes$ = searchFilterService.payTypes$;
    this.fundingDocumentTypes$ = searchFilterService.fundingDocumentTypes$;
    this.fundsForDropdownSource$ = searchFilterService.fundsForDropdownSource$;
    this.fundsYears$ = searchFilterService.fundsYears$;
    this.fundsForDropdownByYear$ = searchFilterService.fundsForDropdownByYear$;
    this.fundsForDropdown$ = searchFilterService.fundsForDropdown$;
    this.territoriesForDropdownSource$ = searchFilterService.territoriesForDropdownSource$;
    this.territoriesForDropdown$ = searchFilterService.territoriesForDropdown$;
    this.activityForDropdownModel$ = searchFilterService.activitiesForDropdown$;
    this.expenseTypes$ = searchFilterService.expenseTypes$;

    this.expandToggleIcon$.subscribe(x => this.expandToggleIcon = x);
  }

  ngOnInit(): void {
  }

  public onFundDropdownFilter(value: string) {
    if (!value) {
      this.fundsForDropdown$ = this.fundsForDropdownByYear$;
      return;
    }
    this.fundsForDropdown$ = this.fundsForDropdownByYear$.pipe(
      map(x => x
        .filter(y => y.displayName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onTerritoryDropdownFilter(value: string) {
    if (!value) {
      this.territoriesForDropdown$ = this.territoriesForDropdownSource$;
      return;
    }
    this.territoriesForDropdown$ = this.territoriesForDropdownSource$.pipe(
      map(x => x
        .filter(y => y.territorySuggestedName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onSearch() {
  }

  public onToggleExpand() {
    this.pageExpansionService.togglePageExpanded();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


