import { Utilities } from './utilities';

export class JsonUtilities {

  private static _isoDateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  private static _dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;

  public static checkIsoDateString(value: any): boolean {
    return typeof value === 'string' ? this._isoDateFormat.test(value) : false;
  }

  // WARN: getItem...FromStorage fuctions designed for infrequent use

  private static getItemRawStringFromStorageInternal(key: string): string {
    return sessionStorage.getItem(key) ?? localStorage.getItem(key) ?? 'null';
  }

  public static getItemFromStorage(key: string): any {
    return JSON.parse(this.getItemRawStringFromStorageInternal(key));
  }

  public static getItemWithParsedDateFromStorage(key: string): any {
    return JSON.parse(this.getItemRawStringFromStorageInternal(key), (k, v) =>
      this.checkIsoDateString(v) ? new Date(v) : v
    );
  }

  public static parseItemWithDates(json: string): any {
    return JSON.parse(json, (k, v) =>
      this.checkIsoDateString(v) ? new Date(v) : v
    );
  }

  public static setItemToStorage(
    key: string,
    value: any,
    isPersistent: boolean
  ) {
    [sessionStorage, localStorage].map((x) => x.removeItem(key));
    (isPersistent ? localStorage : sessionStorage).setItem(
      key,
      JSON.stringify(value)
    );
  }

  public static convertDatesAndCopy(object: any): any {
    if (!object) return object;
    object = Utilities.deepCopyObject(object);// Object.assign({}, object, JSON.parse(JSON.stringify(object)));
    this.convertDatesInternal(object);
    return object;
  }

  public static convertDates(object: any) {
    this.convertDatesInternal(object);
  }

  public static updateZeroTimes(object: any) {
    for (const key of Object.keys(object)) {
      const value = object[key];
      if (value instanceof Date) {
        this.updateUTCValueToReflectZeroTime(value);
      }
    }
  }

  public static promoteLocalDateToUTC(object: any) {
    this.promoteLocalDateToUTCInternal(object);
  }

  private static promoteLocalDateToUTCInternal(object: any, i: number = 0) {
    if (i++ > 1000) throw new Error("promoteLocalDateToUTC recursion limit exceeded")
    if ( !object || !(object instanceof Object)) {
      return;
    }
    if (object instanceof Array) {
      for (const item of object) {
        this.promoteLocalDateToUTCInternal(item, i);
      }
    } else {
      for (const key of Object.keys(object)) {
        const value = object[key];
        if (value instanceof Date) {
          //TODO: Create and assign new date instead? Use ticks?
          const year = value.getFullYear();
          const month = value.getMonth();
          const day = value.getDate();
          const h = value.getHours();
          const m = value.getMinutes();
          const s = value.getSeconds();
          const ms = value.getMilliseconds();
          value.setUTCFullYear(year, month, day);
          value.setUTCHours(h,m,s,ms);
        } else {
          this.promoteLocalDateToUTCInternal(value, i);
        }
      }
    }
  }

  public static updateUTCValueToReflectZeroTime(date: Date | undefined | null) {
    if (date?.getHours() === 0 && date?.getMinutes() === 0 && date?.getSeconds() === 0 && date?.getMilliseconds() === 0) {
      date.setUTCHours(0,0,0,0);
    }
    return date;
  }

  private static convertDatesInternal(object: any, i: number = 0) {
    if (i++ > 100) throw new Error("convertDates recursion limit exceeded")
    if ( !object || !(object instanceof Object)) {
      return;
    }
    if (object instanceof Array) {
      for (const item of object) {
        this.convertDatesInternal(item, i);
      }
    }
    for (const key of Object.keys(object)) {
      const value = object[key];
      if (typeof value === 'string' && (this._dateRegex.test(value) || this._isoDateFormat.test(value))) {
        object[key] = new Date(value);
      } else {
        this.convertDatesInternal(value, i);
      }
    }
  }

  //TODO: Refactor.  This is a copy of convertDatesInternal
  // private static convertDatesToShortDateStringInternal(object: any, i: number = 0) {
  //   const dtf = new Intl.DateTimeFormat('en-US');
  //   if (i++ > 100) throw new Error("convertDates recursion limit exceeded")
  //   if ( !object || !(object instanceof Object)) {
  //     return;
  //   }
  //   if (object instanceof Array) {
  //     for (const item of object) {
  //       this.convertDatesToShortDateStringInternal(item, i);
  //     }
  //   }
  //   for (const key of Object.keys(object)) {
  //     const value = object[key];
  //     if (typeof value === 'string' && (this._dateRegex.test(value) || this._isoDateFormat.test(value))) {
  //       object[key] = dtf.format(new Date(value));
  //     } else {
  //       this.convertDatesToShortDateStringInternal(value, i);
  //     }
  //   }
  // }


}
