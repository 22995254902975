

import { LocationModel } from './LocationModel';//2
import { DataModel2 } from './DataModel2';//3

export class CommitmentOrganizationsModel extends DataModel2 {
    code?: string | null;
    commitmentID?: number | null;
    commitmentCode?: string | null;
    customerID?: number | null;
    customerCode?: string | null;
    customer?: string | null;
    customerLocation?: LocationModel | null;
    billToCustomerID?: number | null;
    billToCustomerCode?: string | null;
    billTo?: string | null;
    billToLocation?: LocationModel | null;
    payeeCustomerID?: number | null;
    payeeCustomerCode?: string | null;
    payee?: string | null;
    payeeLocation?: LocationModel | null;
    territoryID?: number | null;
    territoryCode?: string | null;
    territory?: string | null;
    territorySuggestedName?: string | null;
    territoryPersonID?: number | null;
    territoryPerson?: string | null;
    fundingTerritoryID?: number | null;
    fundingTerritoryCode?: string | null;
    fundingTerritory?: string | null;
    fundingTerritorySuggestedName?: string | null;
    fundingTerritoryPersonID?: number | null;
    fundingTerritoryPerson?: string | null;
    fundID?: number | null;
    fundCode?: string | null;
    fund?: string | null;
    fundYear?: number | null;
    fundAllowsInternalDeduction?: boolean | null;
    fundRequiresPop?: boolean | null;
    fundIsPostAudit?: boolean | null;
    fundIsVenueAware?: boolean | null;
    fundDoesUseCommitmentActivities?: boolean | null;
    fundCommitmentActivitiesRequired?: boolean | null;
}

 
     
         
