import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class ClaimWithDetailsService extends EntityCollectionServiceBase<ClaimWithDetailsModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ClaimWithDetails', serviceElementsFactory);
  }
}

