<acb-alcon-search-filters
  [title]="'Search'"
  [formGroup]="searchForm"
  (formReset)="onSearch()"
  (filterChosen)="onSearch()"
  (filtersExpandChanged)="onFiltersExpandChanged($event)"
  #searchFilters
  [fxHide]="this.pageExpanded$ | async"
  [doShowEpansionAlert]="doShowEpansionAlert"
>
  <p featureText>
      <strong>Claim Search</strong>
      Your most recent claims will automatically populate below.
      <br>
      <br>
      To expand the search option for other claims, click on the right corner box to allow you to search for additional fields.
      <br>
      <br>
      To see additional field options on the grid below, click on the Column Lines to the left to add or remove columns.
  </p>
  <span featureKeyHeader>
    Action Key
  </span>
  <div featureKey *ngIf="searchFlavor === 'manage'">
    <div class='acb-action-button-legend' fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap=".5em" fxLayoutGap.xs="2em">
      <div class="acb-grid-edit" fxFlex="7em" *ngIf="canEditOwnClaims || canEditClaims">
        <span class="k-icon k-i-edit"></span> &nbsp;=&nbsp; Edit
      </div>
      <div class="acb-grid-copy" fxFlex="7em" *ngIf="canCloneClaims">
        <span class="k-icon k-i-copy"></span> &nbsp;=&nbsp; Clone
      </div>
      <div class="acb-grid-view" fxFlex="7em">
        <span class="k-icon k-i-eye"></span> &nbsp;=&nbsp; View
      </div>
    </div>
  </div>

  <ng-container filterSectionBody>

    <kendo-formfield *appFieldExtentions fxFlex [fxHide]="searchFilters.isExpanded">
      <kendo-label [for]="name" text="ID or Name"></kendo-label>
      <input formControlName="name" kendoTextBox #name autocomplete="off" />
      <kendo-formhint fxFlex>Claim, Commitment, Customer, Territory, or Fund </kendo-formhint>
    </kendo-formfield>

    <ng-container>
      <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto" [fxHide]="searchFilters.isExpanded" [fxHide.lt-sm]="true">
        <kendo-label [for]="statusCode" text="Status Code"></kendo-label>
        <kendo-combobox
          #statusCode
          formControlName="_statusCode"
          [data]="claimStatusCodes$ | async"
          [textField]="'displayName'"
          [valueField]="'statusCodeID'"
          [suggest]="true"
          >
        </kendo-combobox>
        <kendo-formhint>Claim status</kendo-formhint>
      </kendo-formfield>
    </ng-container>


    <!-- =================================================================================================== -->

    <kendo-formfield *appFieldExtentions fxFlex="1 0 calc(14% - 2em)" fxFlex.md="0 0 calc(20% - 2em)" fxFlex.sm="0 0 calc(20% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="claimIDs" text="Claim IDs"></kendo-label>
      <kendo-textbox formControlName="claimIDs" #claimIDs autocomplete="off" multipleIntegerTextbox></kendo-textbox>
      <kendo-formhint>One or more, ex: 10, 20, 30</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="1 0 calc(14% - 2em)" fxFlex.md="0 0 calc(20% - 2em)" fxFlex.sm="0 0 calc(20% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="commitmentIDs" text="Commitment IDs"></kendo-label>
      <kendo-textbox formControlName="commitmentIDs" #commitmentIDs autocomplete="off" multipleIntegerTextbox></kendo-textbox>
      <kendo-formhint>One or more, ex: 10, 20, 30</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(20% - 2em)" fxFlex.md="0 0 calc(30% - 2em)" fxFlex.sm="0 0 calc(30% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="customerCode" text="Customer Number"></kendo-label>
      <input formControlName="customerCode" kendoTextBox #customerCode autocomplete="off" />
      <kendo-formhint>Customer's code</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(20% - 2em)" fxFlex.md="0 0 calc(30% - 2em)" fxFlex.sm="0 0 calc(30% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="customerName" text="Customer Name"></kendo-label>
      <input formControlName="customerName" kendoTextBox #customerName autocomplete="off" />
      <kendo-formhint>Typical name</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(20% - 2em)" fxFlex.md="0 0 calc(30% - 2em)" fxFlex.sm="0 0 calc(30% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="payeeName" text="Payee Name"></kendo-label>
      <input formControlName="payeeName" kendoTextBox #payeeName autocomplete="off" />
      <kendo-formhint>Typical name</kendo-formhint>
    </kendo-formfield>

    <!-- =================================================================================================== -->

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="startDate" text="Start Date"></kendo-label>
      <kendo-datepicker formControlName="startDate" #startDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
      <kendo-formhint>Activity start date</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="endDate" text="End Date"></kendo-label>
      <kendo-datepicker formControlName="endDate" #endDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
      <kendo-formhint>Activity end date</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="minAmount" text="Min Amount"></kendo-label>
      <kendo-numerictextbox [spinners]="false" [decimals]="2" [format]="'c2'" [min]="0" #minAmount [formControlName]="'minAmount'"></kendo-numerictextbox>
      <kendo-formhint>Minimum commited amount</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="maxAmount" text="Max Amount"></kendo-label>
      <kendo-numerictextbox [spinners]="false" [decimals]="2" [format]="'c2'" #maxAmount [formControlName]="'maxAmount'"></kendo-numerictextbox>
      <kendo-formhint>Maximum commited amount</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="payType" text="Pay Type"></kendo-label>
      <kendo-dropdownlist
        [data]="payTypes$ | async"
        [textField]="'displayName'"
        [valueField]="'id'"
        [formControlName]="'payTypeID'"
        [valuePrimitive]="true"
        #payType
        >
      </kendo-dropdownlist>
      <kendo-formhint>Form of payment</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.md="0 0 calc(30% - 2em)" fxFlex.sm="0 0 calc(30% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="invoiceNumber" text="Invoice"></kendo-label>
      <input formControlName="invoiceNumber" kendoTextBox #invoiceNumber autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.md="0 0 calc(30% - 2em)" fxFlex.sm="0 0 calc(30% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="deduction" text="Deduction"></kendo-label>
      <input formControlName="deduction" kendoTextBox #deduction autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <!-- =================================================================================================== -->
    <ng-container *ngIf="this.fundsYears$ | async as fundsYearsData">
      <kendo-formfield
        *appFieldExtentions
        fxFlex="0 0 calc(13% - 2em)"
        fxFlex.md="0 0 calc(16% - 2em)"
        fxFlex.sm="0 0 calc(25% - 2em)"
        fxFlex.xs="grow"
        [fxHide]="!searchFilters.isExpanded"
        >
        <kendo-label [for]="fundYears" text="Fund Years"></kendo-label>
        <kendo-multiselect

          formControlName="fundYears"
          [data]="fundsYearsData"
          [valuePrimitive]="true"
          #fundYears
          [autoClose]="false"
          [ngClass.lt-lg]="'acb-multiselect-small'"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="this.fundsForDropdown$ | async as fundsForDropdownData">
      <kendo-formfield *appFieldExtentions fxFlex="1 0 calc(22% - 2em)" fxFlex.md="1 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(75% - 2em)" fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="funds" text="Funds"></kendo-label>
        <kendo-multiselect
          formControlName="funds"
          [data]="fundsForDropdownData"
          [textField]="'displayName'"
          [valueField]="'fundID'"
          #funds
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="this.activityForDropdownModel$ | async as activityForDropdownData">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(22% - 2em)" fxFlex.md="0 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(75% - 2em)" fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="activities" text="Activities"></kendo-label>
        <kendo-multiselect
          formControlName="activities"
          [data]="activityForDropdownData"
          [textField]="'displayName'"
          [valueField]="'activityID'"
          #activities
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="this.territoriesForDropdown$ | async as territoriesForDropdownData">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(21% - 2em)" fxFlex.md="0 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(69% - 2em)"  fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="territories" text="Territories"></kendo-label>
        <kendo-multiselect
          formControlName="territories"
          [data]="territoriesForDropdownData"
          [textField]="'code'"
          [valueField]="'territoryID'"
          #territories
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="(this.claimStatusCodes$ | async) as claimStatusCodesData">
      <kendo-formfield
        *appFieldExtentions
        fxFlex="0 0 calc(16% - 2em)" fxFlex.md="0 0 calc(16% - 2em)"
        fxFlex.sm="0 0 calc(31% - 2em)"
        fxFlex.xs="grow"
        [fxHide]="!searchFilters.isExpanded"
        >
        <kendo-label [for]="statusCodes" text="Status"></kendo-label>
        <kendo-multiselect
          formControlName="statusCodes"
          [data]="claimStatusCodesData"
          [textField]="'displayName'"
          [valueField]="'statusCodeID'"
          #statusCodes
          [autoClose]="false"
          [ngClass.lt-lg]="'acb-multiselect-small'"

          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions [fxHide]="!searchFilters.isExpanded" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap=".75em" fxLayoutAlign="start center" class="acb-checkbox-row">
          <input type="checkbox" class="k-checkbox" #doExcludeCanceledAndDenied formControlName="doExcludeCanceledAndDenied" [checked]="true" >
          <kendo-label [for]="doExcludeCanceledAndDenied" text="Exclude Canceled and Denied?"></kendo-label>
        </div>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

    </ng-container>

    <ng-container *ngIf="searchFlavor == 'review'">

      <div fxHide.sm class="acb-flex-break"></div>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="minReceivedDate" text="Min Received"></kendo-label>
        <kendo-datepicker formControlName="minReceivedDate" #minReceivedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="maxReceivedDate" text="Max Received"></kendo-label>
        <kendo-datepicker formControlName="maxReceivedDate" #maxReceivedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="minProcessedDate" text="Min Processed"></kendo-label>
        <kendo-datepicker formControlName="minProcessedDate" #minProcessedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="maxProcessedDate" text="Max Processed"></kendo-label>
        <kendo-datepicker formControlName="maxProcessedDate" #maxProcessedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="minReleasedDate" text="Min Released"></kendo-label>
        <kendo-datepicker formControlName="minReleasedDate" #minReleasedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="maxReleasedDate" text="Max Released"></kendo-label>
        <kendo-datepicker formControlName="maxReleasedDate" #maxReleasedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="minClearedDate" text="Min Cleared"></kendo-label>
        <kendo-datepicker formControlName="minClearedDate" #minClearedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="maxClearedDate" text="Max Cleared"></kendo-label>
        <kendo-datepicker formControlName="maxClearedDate" #maxClearedDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <div fxHide.sm class="acb-flex-break"></div>

      <!-- <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canCopy" text="Copyable"></kendo-label>
        <kendo-dropdownlist
          #canCopy
          formControlName="canCopy"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    -->

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canReverse" text="Reversible"></kendo-label>
        <kendo-dropdownlist
          #canReverse
          formControlName="canReverse"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canVoid" text="Voidable"></kendo-label>
        <kendo-dropdownlist
          #canVoid
          formControlName="canVoid"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canCancel" text="Cancelable"></kendo-label>
        <kendo-dropdownlist
          #canCancel
          formControlName="canCancel"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canEdit" text="Editable"></kendo-label>
        <kendo-dropdownlist
          #canEdit
          formControlName="canEdit"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canAudit" text="Auditable"></kendo-label>
        <kendo-dropdownlist
          #canAudit
          formControlName="canAudit"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canCheck" text="Checkable"></kendo-label>
        <kendo-dropdownlist
          #canCheck
          formControlName="canCheck"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="canPend" text="Pendable"></kendo-label>
        <kendo-dropdownlist
          #canPend
          formControlName="canPend"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

    </ng-container>

  </ng-container>
</acb-alcon-search-filters>

<kendo-grid
  #resultsGrid
  claimSearchBindingDirective
  [pageable]="{
    buttonCount: 4,
    pageSizes: true
  }"
  [sortable]="true"
  [reorderable]="true"
  [pageSize]="10"
  [selectable] = "
    searchFlavor === 'review' ? {
      enabled: true,
      mode: 'multiple'
    } : false"
  kendoGridSelectBy="claimID"
  [(selectedKeys)] = "selectedKeys"
  (selectedKeysChange)="onResultsGridSelectedKeysChange($event)"
  [resizable]="true"
  class="acb-results-grid"
  >
  <ng-template kendoGridToolbarTemplate>
    <div fxLayout="row" fxLayoutAlign=" center" >
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
        <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'" fxFlexOffset="1em" >Reset Grid</button>
      </div>
      <kendo-toolbar
        *ngIf="searchFlavor === 'review'"
        [overflow]="true"
        fxFlexAlign="left"
        fxFlex="0 0 16em"
        fxFlex.xs="0 0 4em"
        [ngStyle]="{'margin':'0'}"
        >
        <kendo-toolbar-button
          fxFlexAlign="left"
          *ngIf="searchFlavor === 'review'"
          [text]="'Update Rec. Date'"
          [icon]="'calendar'"
          (click)="onUpdateReceivedDate()"
          [className]="'acb-primary'"
          ></kendo-toolbar-button>
      </kendo-toolbar>
      <div fxFlex='8 1 auto' fxFlex.xs></div>



      <kendo-toolbar
        *ngIf="searchFlavor === 'manage' || searchFlavor === 'review'"
        [overflow]="true"
        fxFlex="10 1 52em"
        fxFlex.xs="0 0 4em"
        [fxHide]="!hasSelected"
        [ngStyle]="{'margin':'0'}"
        >
        <kendo-toolbar-button [text]="'Clear (' + this.selectedKeys.length.toString() + ')'" [icon]="'apply-format'" (click)="onClearButtonClick()" [className]="'acb-toolbar-button-clear'" ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Audit (' + auditableSelectedKeys.length + ')'"
          [icon]="'aggregate-fields'"
          (click)="onAuditClaim()"
          [className]="'acb-teal'"
          [disabled]="!auditableSelectedKeys.length"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Check (' + checkableSelectedKeys.length + ')'"
          [icon]="'aggregate-fields'"
          (click)="onCheckClaim()"
          [className]="'acb-good'"
          [disabled]="!checkableSelectedKeys.length"
          ></kendo-toolbar-button>

        <!-- <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Pend (' + pendableSelectedKeys?.length + ')'"
          [icon]="'clock'"
          (click)="onPendClaim()"
          [className]="'acb-warning'"
          [disabled]="true || !pendableSelectedKeys?.length"
          ></kendo-toolbar-button>
          -->

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Void (' + voidableSelectedKeys.length + ')'"
          [icon]="'close-outline'"
          (click)="onVoidClaim()"
          [className]="'acb-danger'"
          [disabled]="!voidableSelectedKeys.length"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Cancel (' + cancellableSelectedKeys.length + ')'"
          [icon]="'cancel-outline'"
          (click)="onCancelClaim()"
          [className]="'acb-danger'"
          [disabled]="!cancellableSelectedKeys.length"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Reset (' + resetableSelectedKeys.length + ')'"
          [icon]="'reset'"
          (click)="onResetClaim()"
          [className]="'acb-primary'"
          [disabled]="!resetableSelectedKeys.length"
          ></kendo-toolbar-button>


      </kendo-toolbar>
      <div fxFlex></div>
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export<span [fxHide.xs]="true">&nbsp;to Excel</span></button>
        <button kendoButton type="button" [icon]="expandToggleIcon" class="k-button" (click)="onToggleExpand()" fxFlexOffset="1em" ></button>
      </div>
    </div>
  </ng-template>

  <kendo-grid-command-column
    [width]="30"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [locked]="true"
    *ngIf="searchFlavor === 'review'"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox />
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-command-column
    [width]="30"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [locked]="true"
    *ngIf="searchFlavor === 'review' || searchFlavor === 'manage'"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-edit"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button
        kendoGridEditCommand
        [style.visibility]="getVisibility(dataItem?.canEdit)"
        class="acb-in-grid-edit-button"
        [icon]="dataItem?.isEditRestrictedToAttachments ? 'attachment' : 'edit'"
        look="clear"
        (click)="onEditClaim($event, dataItem)"
        >
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-command-column
    [width]="30"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [locked]="true"
    *ngIf="(searchFlavor === 'manage' || searchFlavor == 'review') && canReverseClaim"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-rewind-sm"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoButton [style.visibility]="getVisibility(dataItem?.canReverse)" class="acb-in-grid-reverse-button" [icon]="'rewind-sm'" look="clear" (click)="onReverseClaim($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-command-column
    [width]="30"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [locked]="true"
    *ngIf="searchFlavor === 'search' || searchFlavor === 'manage'"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-print"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoButton class="acb-in-grid-print-button" [icon]="'print'" look="clear" (click)="onPrintClaim($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>


  <kendo-grid-column title="Claim ID" field="claimID" [width]="100" class="acb-grid-column-integer acb-grid-cell-with-view" [reorderable]="false" [includeInChooser]="false" [resizable]="false" [locked]= "isGridIdentifierAndActionColumnsLocked">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button (click)="onViewClaim($event, dataItem)">
        {{dataItem.claimID}}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Comm ID" field="commitmentID" [width]="100" class="acb-grid-column-integer acb-grid-cell-with-view">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button (click)="onViewCommitment($event, dataItem)">
        {{dataItem.commitmentID}}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Status" field="displayStatus" [width]="90">
  </kendo-grid-column>
  <kendo-grid-column title="ACB Status" field="status" [width]="100" *ngIf="searchFlavor === 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Cust #" field="customerCode" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column title="Customer" field="customer" [width]="200">
  </kendo-grid-column>
  <kendo-grid-column title="Payee" field="payee" [width]="200" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Commited" field="commitmentAmount" format="{0:c}" class="acb-grid-column-currency" [width]="110">
  </kendo-grid-column>
  <kendo-grid-column title="Claimed" field="claimedAmount" format="{0:c}" class="acb-grid-column-currency" [width]="110">
  </kendo-grid-column>
  <kendo-grid-column title="Paid" field="paidAmount" format="{0:c}" class="acb-grid-column-currency" [width]="110">
  </kendo-grid-column>
  <kendo-grid-column title="Activity" field="activity" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="Pay Type" field="payType" [width]="110" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Deduction" field="deduction" [width]="140">
  </kendo-grid-column>
  <kendo-grid-column title="Invoice" field="invoice" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="Doc #" field="documentNumber" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column title="Fund" field="fund" [width]="240">
  </kendo-grid-column>
  <kendo-grid-column title="Start" field="startDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="End" field="endDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.endDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Received" field="receivedDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.receivedDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Processed" field="processedDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.processedDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Released" field="releasedDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.releasedDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Cleared" field="clearedDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.clearedDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Submitted By" field="submittedBy" [width]="200">
  </kendo-grid-column>

  <ng-container *ngIf="isEventAndVenueAware">
    <kendo-grid-column title="Event" field="event" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="Venue" field="venue" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="Venue City" field="venueCity" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="V. State" field="venueStateProvinceCode" [width]="90" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="V. ENA" field="estimatedAttendeeCount" [width]="90" [hidden]="true">
    </kendo-grid-column>
  </ng-container>

  <kendo-grid-column title="Ben. Rec." field="wereBenefitsReceived" [width]="90" class="acb-user-grid-yesno-column" [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': !dataItem.wereBenefitsReceived}">{{dataItem.wereBenefitsReceived ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Final Approver" field="commitmentFinalApprover" [width]="200" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Reissue Of" field="reissueOfClaimID" [width]="100" [hidden]="true" class="acb-grid-column-integer">
  </kendo-grid-column>
  <kendo-grid-column title="Reissued By" field="reissuedByPerson" [width]="200" [hidden]="true">
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes' [pageSizes]="[5,10,20,40]" ></kendo-pager-page-sizes>
  </ng-template>
  <kendo-grid-excel fileName="Claims.xlsx" [fetchData]="allData">
  </kendo-grid-excel>
</kendo-grid>
