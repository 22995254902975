import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectImpersonationStatus } from '@app-store/app-session/app-session.selectors';
import { first, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
    protected router: ActivatedRoute
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.router.data.pipe(first(), switchMap(data => {

      if (data.allowAnonymous) return next.handle(request);

      return this.store.select(selectImpersonationStatus).pipe(take(1), switchMap(x => {
        const personID = x.currentPerson.personID;
        const impersonatorPersonID = x.isImpersonating ? x.accessPerson.personID : undefined;

        //TODO: Move 'ACB-Context-Person' and 'ACB-Context-Impersonator' to config or resource?
        if (personID) {
          request = request.clone({ headers: request.headers.set('ACB-Context-Person', personID.toString()) });
          // Might be better to derive impersonator by inspecting token and comparing to ACB-Context-Person.  Lets do that for now...
          // if (impersonatorPersonID) {
          //   request = request.clone({ headers: request.headers.set('ACB-Context-Impersonator', impersonatorPersonID.toString()) });
          // }
        }
        return next.handle(request);
      }));
    }));
  }
}
