import { ReportRequestModel } from '@alcon-db-models/ReportRequestModel';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { selectCurrentPerson } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ReportResultsService } from '@services/report-results.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { JsonUtilities } from '../shared/json-utilities';

@Directive({
  selector: '[reportBindingDirective]'
})
export class ReportBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {

  @Input('reportID') reportID?: number;

  private _serviceSubscription?: Subscription;
  private _personID?: number;

  constructor(
    private reportResultsService: ReportResultsService,
    grid: GridComponent,
    store: Store
    ) {
    super(grid);
    store.select(selectCurrentPerson).pipe(first()).subscribe(x => this._personID = x?.personID ?? undefined);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._serviceSubscription = this.reportResultsService.subscribe((result) => {
      this.grid.data = JsonUtilities.convertDatesAndCopy(result);
      this.grid.loading = false;
      this.notifyDataChange();
    });
  }

  public ngOnDestroy(): void {
    if (this._serviceSubscription) {
        this._serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.reportResultsService.query(this.getSearchRequest());
  }

  public reset(): void {
    this.grid.data = [];
  }

  public getSearchRequest(doIgnorePagination: boolean = false): ReportRequestModel {

    const params: { [name: string]: number } = {};
    (this.state.filter?.filters as FilterDescriptor[])?.forEach(x => { params[String(x.field)] = x.value });

    return ({
      reportID: this.reportID,
      personID: this._personID,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : undefined,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.dir != 'desc' ?? true : undefined,
      parameters: params
    });
  }
}

