import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { combineLatest, from, Observable } from 'rxjs';
import { AuthenticationService } from '@data-services/authentication.service'
import { Store } from '@ngrx/store';
import { selectAccessTokenInfo } from '@app-store/app-persisted-settings/app-persisted-settings.selectors';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { selectDoKeepMeLoggedIn, selectMemoryOnlyRefreshToken } from '@app-store/app-session/app-session.selectors';
import { TokenInfoModel } from '@alcon-db-models/TokenInfoModel';
import { tokenRefreshSuccess } from '@app-store/app-session/app-session.actions';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from '../shared/utilities';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private static readonly _apiCallsToIgnoreByUrl = ['authenticate/login', 'application/configuration', 'ApplyCommitmentReviewToken', 'application/logerror', 'authenticate/password-reset-verification', 'authenticate/reset-password'];

  constructor(private store: Store, private authenticationService: AuthenticationService, protected router: ActivatedRoute ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // TODO: Clean this up
    // Hack: checking url for strings smells bad
    if (JwtInterceptor._apiCallsToIgnoreByUrl.some(x => Utilities.normalizeUrl([environment.baseApiUrl,x]).toLowerCase() == request.url.toLowerCase()))
      return next.handle(request);

    const selectMemoryOnlyRefreshToken$ = this.store.select(selectMemoryOnlyRefreshToken).pipe(startWith(null));
    const selectDoKeepMeLoggedIn$ = this.store.select(selectDoKeepMeLoggedIn).pipe(startWith(null));
    const selectAccessTokenInfo$ = this.store.select(selectAccessTokenInfo).pipe(startWith(null));

    return combineLatest([selectAccessTokenInfo$, selectMemoryOnlyRefreshToken$, selectDoKeepMeLoggedIn$]).pipe(
      first(),
      switchMap(([tokenInfo, memoryOnlyRefreshToken, doKeepMeLoggedIn]) => {
        if (request.url.toLowerCase().indexOf("spa-refresh") === -1 && tokenInfo && !this.authenticationService.checkToken(tokenInfo)) {
          return from(this.authenticationService.refreshToken({ doPersistAuthorization: doKeepMeLoggedIn, refreshToken: memoryOnlyRefreshToken })).pipe(
            map(x => {
              if (!x?.tokenInfoModel) {
                throw new Error("JwtInterceptor -> Refresh failed");
              }
              this.store.dispatch(tokenRefreshSuccess({ payload: x  }));
              return x.tokenInfoModel;
            }),
            switchMap(x => next.handle(this.updateRequest(x, request)))
          );
        }
        else
        {
          return next.handle(this.updateRequest(tokenInfo, request))
        }
      })
    );


  }

  private updateRequest(tokenInfo: TokenInfoModel, request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.has('Authorization') && request.url.includes(environment.baseApiUrl) && tokenInfo?.token) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${tokenInfo.token}` }});
    }
    return request
  }
}
