import { CommitmentOrganizationsModel } from '@alcon-db-models/CommitmentOrganizationsModel';
import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { CountryCode, StateProvinceCode } from '@alcon-db-models/Enums';
import { LocationModel } from '@alcon-db-models/LocationModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { AppWindowService } from '@services/app-window.service';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil, first, debounce } from 'rxjs/operators';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { ViewMode } from '../components.module';

export type PayeeType = 'shipTo' | 'billTo' | 'alt';

@Component({
  selector: 'acb-alcon-select-payee',
  template: `
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [sectionTitle]="'Payee'" class="acb-section-02" [doShowLegend]="doShowLegend">

        <div fxLayout="row wrap" class="acb-payee-choices">
          <div
            class="acb-payee-choice"
            [ngClass]="{ 'acb-selected': form.value.payeeType == 'shipTo' }"
            (click)="form.patchValue({ payeeType: 'shipTo' })"
            fxLayout="column"
            fxFlex="1 0 24em"
            >
            <div class="acb-payee-title">
              Ship To
            </div>
            <div class="acb-payee-choice-body" fxLayout="row" fxLayoutAlign="start center">
              <input type="radio" #shipTo [value]="'shipTo'" kendoRadioButton [formControlName]="'payeeType'" required/>
              <acb-alcon-select-payee-address
                [spec]="{
                  code: commitmentOrganizations?.customerCode,
                  name: commitmentOrganizations?.customer,
                  location: commitmentOrganizations?.customerLocation
                }"
                fxFlex
                >
              </acb-alcon-select-payee-address>
            </div>
          </div>
          <div
            *ngIf="doShowBillToChoice"
            class="acb-payee-choice"
            [ngClass]="{ 'acb-selected': form.value.payeeType == 'billTo' }"
            (click)="form.patchValue({ payeeType: 'billTo' })"
            fxLayout="column"
            fxFlex="1 0 24em"
            >
            <div class="acb-payee-title">
              Bill To
            </div>
            <div class="acb-payee-choice-body" fxLayout="row" fxLayoutAlign="start center">
              <input type="radio" #billTo [value]="'billTo'" kendoRadioButton [formControlName]="'payeeType'" required/>
              <acb-alcon-select-payee-address
                [spec]="{ code: commitmentOrganizations?.billToCustomerCode, name: commitmentOrganizations?.billTo, location: commitmentOrganizations?.billToLocation }"
                fxFlex
                >
              </acb-alcon-select-payee-address>
            </div>
          </div>

          <div
            class="acb-payee-choice"
            [ngClass]="{ 'acb-selected': form.value.payeeType == 'alt' }"
            (click)="form.patchValue({ payeeType: 'alt' })"
            fxLayout="column"
            fxFlex="1 0 24em"
            >
            <div class="acb-payee-title">
              Other
            </div>
            <div class="acb-payee-choice-body" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex>
              <input type="radio" #alt [value]="'alt'" kendoRadioButton [formControlName]="'payeeType'" required/>
              <acb-alcon-select-payee-address
                *ngIf="doShowAltPayeeAddress"
                [spec]="{ code: payee?.customerCode, name: payee?.customer, location: {
                  locationLine1: payee?.locationLine1,
                  locationLine2: payee?.locationLine2,
                  city: payee?.city,
                  stateProvinceCode: getStateProvinceCode(payee),
                  postalCodeValue: payee?.postalCodeValue,
                  countryCode: getCountryCode(payee)
                  }
                }"
                fxFlex="1 0 20em"
                >
              </acb-alcon-select-payee-address>
              <ng-container *ngIf="form.value.payeeType == 'alt' else altBanner">
                <button
                  *ngIf="doShowAltPayeeAddress else addPayee"
                  kendobutton
                  class="acb-edit-button k-button"
                  (click)="onSelectPayeeCustomer()"
                  >
                  <span class="k-icon k-i-edit"></span> Edit
                </button>
                <ng-template #addPayee>
                  <button (click)="onSelectPayeeCustomer()" kendobutton class="acb-edit-button k-button">Select Alt Payee</button>
                </ng-template>
              </ng-container>
              <ng-template #altBanner>
                <div class="acb-alt-payee-placeholder-banner" fxFlex fxLayout fxLayoutAlign="center center">
                  <div>
                  <span class="k-icon k-i-arrow-chevron-right"></span>&nbsp;Select Alt Payee&nbsp;<span class="k-icon k-i-arrow-chevron-left"></span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

        </div>

      </acb-alcon-section-with-legend>
    </form>
  `,
  styleUrls: ['./select-payee.component.scss']
})
export class SelectPayeeComponent implements OnInit, OnDestroy {

  @Output() payeeSelected: EventEmitter<CustomerSearchWithDefaultModel> = new EventEmitter();

  @Input() doShowLegend: boolean = true;
  @Input() viewMode: ViewMode = 'new';

  private _commitmentOrganizations?: CommitmentOrganizationsModel;
  @Input() public set commitmentOrganizations(value:CommitmentOrganizationsModel | undefined) {

    this._lastPayeeType = undefined;
    this._commitmentOrganizations = value;
    const currentPayeeID = this._selectedPayee?.customerID ?? value?.payeeCustomerID;
    let choice: PayeeType | undefined = undefined;

    if (currentPayeeID && value) {
      choice = 'alt';
      if (currentPayeeID == value.billToCustomerID)
        choice = 'billTo';
      if (currentPayeeID == value.customerID)
        choice = 'shipTo';
    }

    this.form.patchValue({ payeeType: choice });
    this.form.updateValueAndValidity({ emitEvent: false });
    this.changeDetectorRef.detectChanges();
  }
  public get commitmentOrganizations():CommitmentOrganizationsModel | undefined {
    return this._commitmentOrganizations;
  }

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public stateProvinceCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
  public countryCodes$: Observable<StaticTypeModel[]> =  this.staticTypes$.pipe(map(x =>  x.filter(y => y.tableName == 'CountryCode')));

  private _payee?: CustomerSearchWithDefaultModel;
  @Input() public set payee(value:CustomerSearchWithDefaultModel | undefined) {

    if ((!this._payee?.customerID && !value?.customerID) || (this._payee?.customerID && this._payee?.customerID == value?.customerID)) return;

    this._payee = value;
    this._selectedPayee = this._payee ?? null;
    this.doPayeeSelection(this._payee);

    let choice: PayeeType | undefined = undefined;
    if (value?.customerID) {
      choice = 'alt';
      if (value.customerID == this._commitmentOrganizations?.billToCustomerID)
        choice = 'billTo';
      if (value.customerID == this._commitmentOrganizations?.customerID)
        choice = 'shipTo';

    }
    if (this.form.value.payeeType != choice) {
      this.form.patchValue({ payeeType: choice });
      this.form.updateValueAndValidity({ emitEvent: false });

    }
    this.changeDetectorRef.detectChanges();
  }
  public get payee():CustomerSearchWithDefaultModel | undefined {
    return this._payee;
  }

  private _selectedPayee:CustomerSearchWithDefaultModel | null = null;

  public destroy$: Subject<void> = new Subject<void>();

  public getState(state: string | null | undefined): StateProvinceCode | null {
    return state ? state as any as StateProvinceCode : null;
  }

  public form: UntypedFormGroup =  new UntypedFormGroup({
    payeeType: new UntypedFormControl(),
  });

  private _lastPayeeType: any;
  constructor(
    private appWindowService: AppWindowService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    @Optional() wizardFeatureService?: WizardFeatureService
  ) {

    let states: StaticTypeModel[] = [];
    let countries: StaticTypeModel[] = [];
    this.stateProvinceCodes$.pipe(first()).subscribe(x => { states = x; });
    this.countryCodes$.pipe(first()).subscribe(x => { countries = x; });

    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(10)).subscribe(x => {

      let stateInfo: StaticTypeModel | null | undefined = null as StaticTypeModel | null;
      let countryInfo: StaticTypeModel | null | undefined = null as StaticTypeModel | null;

      const getInfo = (location?: LocationModel | null) => {
        stateInfo = states.find(y => y.id == location?.stateProvinceCode);
        countryInfo = countries.find(y => y.id == location?.countryCode);
      }

      if (this._lastPayeeType && this._lastPayeeType == x.payeeType) return;

      this._lastPayeeType = x.payeeType
      switch (x.payeeType) {
        case 'shipTo':
          getInfo(this.commitmentOrganizations?.customerLocation);
          this._selectedPayee = {
            customerID: this.commitmentOrganizations?.customerID,
            customerCode: this.commitmentOrganizations?.customerCode,
            customer: this.commitmentOrganizations?.customer,
            ...this.commitmentOrganizations?.customerLocation,
            stateProvinceCodeID: stateInfo?.id,
            stateProvinceCode: stateInfo?.code,
            stateProvince: stateInfo?.displayName,
            countryCodeID: countryInfo?.id,
            countryCode: countryInfo?.code,
            country: countryInfo?.displayName
          };
          this.doPayeeSelection(this._selectedPayee);
          break;
        case 'billTo':
          getInfo(this.commitmentOrganizations?.billToLocation);
          this._selectedPayee = {
            customerID: this.commitmentOrganizations?.billToCustomerID,
            customerCode: this.commitmentOrganizations?.billToCustomerCode,
            customer: this.commitmentOrganizations?.billTo,
            ...this.commitmentOrganizations?.billToLocation,
            stateProvinceCodeID: stateInfo?.id,
            stateProvinceCode: stateInfo?.code,
            stateProvince: stateInfo?.displayName,
            countryCodeID: countryInfo?.id,
            countryCode: countryInfo?.code,
            country: countryInfo?.displayName
          };
          this.doPayeeSelection(this._selectedPayee);
          break;
        case 'alt':
          if (this.commitmentOrganizations?.payeeCustomerID && !this._selectedPayee) {
            getInfo(this.commitmentOrganizations?.payeeLocation);
            this._selectedPayee = {
              customerID: this.commitmentOrganizations?.payeeCustomerID,
              customerCode: this.commitmentOrganizations?.payeeCustomerCode,
              customer: this.commitmentOrganizations?.payee,
              ...this.commitmentOrganizations?.payeeLocation,
              stateProvinceCodeID: stateInfo?.id,
              stateProvinceCode: stateInfo?.code,
              stateProvince: stateInfo?.displayName,
              countryCodeID: countryInfo?.id,
              countryCode: countryInfo?.code,
              country: countryInfo?.displayName
            };
            this.doPayeeSelection(this._selectedPayee);
          }
          // TODO: Fix.  This is a hack and cause of the alt payee selection redudant popup and flakiness.  Checking doShowAltPayeeAddress twice to work around
          else if (!this.doShowAltPayeeAddress) window.setTimeout(() => { if (!this.doShowAltPayeeAddress) this.onSelectPayeeCustomer() }, 10);
          break;
      }
    })
  }

  private _lastPayeeSelected:number | undefined | null;
  private doPayeeSelection(selectedPayee?:CustomerSearchWithDefaultModel) {
    if (this._lastPayeeSelected != selectedPayee?.customerID) this.payeeSelected.emit(selectedPayee);
    this._lastPayeeSelected = selectedPayee?.customerID;
  }


  //HACK: model types are mixed up
  public getStateProvinceCode(model?:CustomerSearchWithDefaultModel | null): StateProvinceCode {
    return (model?.stateProvinceCodeID ?? model?.stateProvinceCode) as StateProvinceCode;
  }

  //HACK: model types are mixed up
  public getCountryCode(model?:CustomerSearchWithDefaultModel | null): CountryCode {
    return (model?.countryCodeID ?? model?.countryCode) as CountryCode;
  }


  ngOnInit(): void {
  }

  public get doShowBillToChoice():boolean {
    return Boolean(this.commitmentOrganizations?.billToCustomerID) &&
      this.commitmentOrganizations?.billToCustomerID != this.commitmentOrganizations?.customerID;
  }

  public get doShowAltPayeeAddress():boolean {
    return Boolean(this.payee?.customerID) &&
      this.payee?.customerID != this.commitmentOrganizations?.customerID &&
      this.payee?.customerID != this.commitmentOrganizations?.billToCustomerID;
  }

  public getSelectedItem():CustomerSearchWithDefaultModel | null {
    return this._selectedPayee;
  }

  public onSelectPayeeCustomer = (() =>  {
    const dialogSpec = this.appWindowService.openSelectPayeeCustomer({ customerID: this.commitmentOrganizations?.customerID, payeeCustomerID:this.payee?.customerID });
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => {
      if (this.form.value.payeeType == 'alt') {
        if (this._selectedPayee?.customerID == this.commitmentOrganizations?.customerID)
          this.form.patchValue({ payeeType: 'shipTo'});
        else if (this._selectedPayee?.customerID == this.commitmentOrganizations?.billToCustomerID)
          this.form.patchValue({ payeeType: 'billTo'});
        else
          this.form.value.payeeType = undefined
        this.form.updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
      }
      dialogSpec?.dialogRef.close();
    });
    dialogSpec?.component?.select?.pipe(take(1), debounceTime(100)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x) {
        this.doPayeeSelection(this._selectedPayee = x);
        this.changeDetectorRef.detectChanges();
      }
    });
  }).bind(this);

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
