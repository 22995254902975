
<ng-template #widgetToolPopupTemplate>
  <div #widgetToolPopup  [ngStyle]="{'min-width.px':hostElementRef.nativeElement.offsetWidth, 'height.px': hostElementRef.nativeElement.offsetHeight }" fxLayout="column">
    <div class="acb-widget-tool-popup-header" fxLayout="row">
      <button kendoButton [icon]="'arrow-left'" class="acb-widget-tool-popup-leave-button" #widgetToolLeave (click)="toggleWidgetToolPopup()"></button>
      <div class="acb-widget-tool-popup-title">
        {{ getWidgetName() }}
      </div>
    </div>
    <div class="acb-widget-tool-popup-inner-wrapper" fxLayout="row" fxflex>
      <div fxLayout="column">
        <kendo-buttongroup [selection]="'single'" fxLayout="column" #widgetToolSelection>
          <button class="acb-widget-tool-button-filters" kendoButton [toggleable]="true" [icon]="'filter'" (selectedChange)="onWidgetToolSelectedChange($event, 'filter')" [selected]="activeToolType == 'filter'" *ngIf="this.additionalParametersSpec"></button>
          <button class="acb-widget-tool-button-columns" kendoButton [toggleable]="true" [icon]="'columns'" (selectedChange)="onWidgetToolSelectedChange($event, 'columns')" [selected]="activeToolType == 'columns'" *ngIf="this.dashboardItem?.presentationType == 'grid'"></button>
          <!-- <button class="acb-widget-tool-button-options" kendoButton [toggleable]="true" [icon]="'gears'" (selectedChange)="onWidgetToolSelectedChange($event, 'options')" [selected]="activeToolType == 'options'"></button> -->
        </kendo-buttongroup>
      </div>
      <div fxFlex>
        <div class="acb-widget-tool-popup-body" [ngSwitch]="activeToolType">
          <div class="acb-widget-tool-popup-page acb-filters-page" *ngSwitchCase="'filter'">
            <div class="acb-widget-tool-page-title">Filter Parameters</div>
            <acb-alcon-filter-chooser [additionalParameters]="additionalParameters" [additionalParametersSpec]="additionalParametersSpec">
            </acb-alcon-filter-chooser>
          </div>
          <div class="acb-widget-tool-popup-page acb-columns-page" *ngSwitchCase="'columns'">
            <div class="acb-widget-tool-page-title">Grid Columns</div>
            <acb-alcon-column-chooser [columns]="columns">
            </acb-alcon-column-chooser>
          </div>
          <div class="acb-widget-tool-popup-page acb-options-page" *ngSwitchCase="'options'">
            <div class="acb-widget-tool-page-title">Options</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div 
  #widgetInnerWrapper 
  #widgetToolPopupAnchor
  [ngClass]="{ 
    'acb-selected':isSelected, 
    'acb-widget-inner-wrapper':true, 
    'acb-widget-locked': isLocked
  }" 
  fxLayout="column" 
  >
  <div class="acb-widget-header" fxLayout="row" >
    <button
      *ngIf="false && this.dashboardItem?.presentationType == 'grid' && !isLocked"
      [fxHide]="isLocked"
      #widgetToolPopupButton
      kendoButton
      class="k-button acb-widget-tool-popup-button"
      [icon]="'more-vertical'"
      [look]="'flat'"
      (click)="toggleWidgetToolPopup($any(widgetToolPopupAnchor), widgetToolPopupTemplate)"
      ></button>
    <div class="acb-widget-title">
      {{ getWidgetName() }}
    </div>
    <div fxFlex></div>
    <button class="acb-widget-close-button" kendoButton [look]="'flat'" [icon]="'close'" *ngIf="!isToolPopupShowing && !isLocked && canDelete" (click)="onCloseWidget()"></button>
  </div>
  <div class="acb-widget-body" [ngClass]="{'acb-widget-messages':this.dashboardItem?.presentationType=='messagecenter'}" [ngSwitch]="this.dashboardItem?.presentationType" fxFlex>
      <div *ngSwitchCase="'messagecenter'" class="acb-dashboard-messages">
        <div *ngFor='let message of messageReportResults$ | async' class="acb-dashboard-message">
          <div class="acb-dashboard-message-title">{{ message.title }}</div>
          <div class="acb-dashboard-message-body" [innerHtml]="message.body"></div>
        </div> 
      </div>
      <kendo-chart 
        #chart 
        *ngSwitchCase="'chart'" 
        (render)="onChartRender($event)" 
        [renderAs]="'svg'" 
        [transitions]="false" 
        [class]="isChartReady ? 'acb-dashboard-chart-is-ready' : 'acb-dashboard-chart-not-ready'" 
        [resizeRateLimit]="10"
        (seriesClick)="onSeriesClick($event)"
        >
        <kendo-chart-area background="transparent">
        </kendo-chart-area>
        <kendo-chart-tooltip [ngSwitch]="this.dashboardItem?.dashboardItemType">
          <ng-template *ngSwitchCase="'donutchart'"
            kendoChartSeriesTooltipTemplate
            let-value="value"
            let-dataItem="dataItem"
            >
            <div gdAreas="header header | amount-label amount | percent-label percent" gdRows="auto auto auto" gdGap="4px" style="margin: 6px 8px 8px 8px;">
              <div gdArea="header" style="text-align: center; font-weight: bold; font-size: larger;">{{ dataItem.category }}</div>
              <div gdArea="amount-label" style="text-align: right; padding-right: 4px;">Amount:</div>
              <div gdArea="amount">{{ value | currency }}</div>
              <div gdArea="percent-label" style="text-align: right; padding-right: 4px;">Percent of Total:</div>
              <div gdArea="percent">{{ dataItem.supplemental | percent }}</div>
            </div>
          </ng-template>
          <ng-template *ngSwitchDefault
            kendoChartSeriesTooltipTemplate
            let-value="value"
            let-dataItem="dataItem"
            >
            <div gdArea="amount">{{ value | currency }}</div>          
          </ng-template>
        </kendo-chart-tooltip>
        <kendo-chart-axis-defaults [visible]="doShowAxis" >
          <kendo-chart-axis-defaults-labels format="c0">
          </kendo-chart-axis-defaults-labels>
        </kendo-chart-axis-defaults>
        <ng-container [ngSwitch]="this.dashboardItem?.dashboardItemType">
          <kendo-chart-legend
            *ngSwitchCase="'donutchart'"
            [visible]= "doShowRightLegend"
            position="right"
            [offsetX]="-20"
            [spacing]="4"
            [markers]="{
              type: 'circle',
              width: 8,
              height: 8
            }">
            <kendo-chart-legend-item [visual]="pieLegendVisual" [cursor]="'pointer'"></kendo-chart-legend-item>
          </kendo-chart-legend>
          <kendo-chart-legend
            *ngSwitchDefault
            [visible]= "doShowTopLegend"
            position="top"
            [spacing]="12"
            [markers]="{
              type: 'square',
              width: 8,
              height: 8
            }">
            <kendo-chart-legend-item [visual]="chartLegendVisual" [cursor]="'pointer'"></kendo-chart-legend-item>
          </kendo-chart-legend>
        </ng-container>
        <kendo-chart-series >
          <kendo-chart-series-item *ngFor='let item of series$ | async' [type]='item.type' [data]='item.data' [name]='item.name' [field]="'data'" [categoryField]="'category'">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
      <kendo-grid *ngSwitchCase="'grid'"
        #grid
        [data]="gridData$ | async"
        [resizable]="true"
        [autoSize]="false"
        [rowClass]="getRowClass"
        >
        <ng-container *ngFor='let col of columns'>
          

            <kendo-grid-column
              [field]="col.field"
              [title]="col.title"
              [width]="col.width"
              [format]="col.format"
              [hidden]="col.hidden"
              [class]="(col.drillThroughSpec ? 'acb-grid-cell-with-view' : '') + (col.class ? col.class : '') + (rowGroup == col.field ? ' acb-dashboard-grid-group-column' : '')"
            >
              <ng-container *ngIf="rowGroup == col.field; else normalCell">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div [ngClass]="{ 'acb-dashboard-grid-group-row-overlay': dataItem.isGroupRow }">
                    {{ dataItem[col.field] }}
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #normalCell kendoGridCellTemplate let-dataItem>
                <button *ngIf="col.drillThroughSpec" (click)="onColumnValueClicked(col,dataItem)">
                  {{ dataItem[col.field] }}
                </button>
                <div *ngIf="!col.drillThroughSpec">
                  
                  {{ col.format ? intlService.format(col.format, dataItem[col.field]) : dataItem[col.field] }}
                </div>
              </ng-template>

            </kendo-grid-column>
        </ng-container>
      </kendo-grid>
    <!-- </ng-container>   -->
  </div>
</div>
<div *ngIf="isLoading" class="k-i-loading acb-loading" ></div>
