import { GridStackEngine } from "gridstack/dist/gridstack-engine";
import { GridStackMoveOpts, GridStackNode, GridStackPosition } from "gridstack/dist/types";

export class AcbGridStackEngine extends GridStackEngine  {
    constructor(opts = {}) {
        super(opts);
    }

    protected directionCollideCoverage(node: GridStackNode, o: GridStackMoveOpts, collides: GridStackNode[]): GridStackNode {
        if (!o.rect || !(node as any)._rect)
        return {};
        let r0 = (node as any)._rect; // where started
        let r = { ...o.rect }; // where we are
        // update dragged rect to show where it's coming from (above or below, etc...)
        if (r.y! > r0.y) {
            r.h! += r.y! - r0.y;
            r.y = r0.y;
        }
        else {
            r.h! += r0.y - r.y!;
        }
        if (r.x! > r0.x) {
            r.w! += r.x! - r0.x;
            r.x = r0.x;
        }
        else {
            r.w! += r0.x - r.x!;
        }
        let collide:GridStackNode;
        collides.forEach(n => {
            if (n.locked || !(n as any)._rect)
                return;
            let r2 = (n as any)._rect; // overlapping target
            let yOver = Number.MAX_VALUE, xOver = Number.MAX_VALUE, overMax = 0.5; // need >50%
            // depending on which side we started from, compute the overlap % of coverage
            // (ex: from above/below we only compute the max horizontal line coverage)
            if (r0.y < r2.y) { // from above
                yOver = ((r.y! + r.h!) - r2.y) / r2.h;
            }
            else if (r0.y + r0.h > r2.y + r2.h) { // from below
                yOver = ((r2.y + r2.h) - r.y!) / r2.h;
            }
            if (r0.x < r2.x) { // from the left
                xOver = ((r.x! + r.w!) - r2.x) / r2.w;
            }
            else if (r0.x + r0.w > r2.x + r2.w) { // from the right
                xOver = ((r2.x + r2.w) - r.x!) / r2.w;
            }
            let over = Math.min(xOver, yOver);
            if (over > overMax) {
                overMax = over;
                collide = n;
            }
        });
        o.collide = collide!; // save it so we don't have to find it again
        return collide!;
    }

    protected directionCollideCoverage2(node: GridStackNode, o: GridStackMoveOpts, collides: GridStackNode[]): GridStackNode {
        if (!o.rect || !(node as any)._rect)
            return {};
        let r0 = (node as any)._rect; // where started
        let r:GridStackPosition = { ...o.rect }; // where we are
        // update dragged rect to show where it's coming from (above or below, etc...)
        if ((r.y ?? 0) > r0.y) {
            r.h = (r.y ?? 0) - r0.y + (r.h ?? 0);        
            r.y = r0.y;
        }
        else {
            r.h = r0.y - (r.y ?? 0) + (r.h ?? 0);
        }
        if ((r.x ?? 0) > r0.x) {
            r.w = (r.x ?? 0) - r0.x + (r.w ?? 0);
            r.x = r0.x;
        }
        else {
            r.w = r0.x - (r.x ?? 0) + (r.w ?? 0); 
        }
        let collide:GridStackNode = {};
        collides.forEach((n:GridStackNode) => {
            if (n.locked || !(n as any)._rect)
                return;
            let r2:GridStackPosition = (n as any)._rect; // overlapping target
            let yOver = Number.MAX_VALUE, xOver = Number.MAX_VALUE, overMax = 0.5; // need >50%
            // depending on which side we started from, compute the overlap % of coverage
            // (ex: from above/below we only compute the max horizontal line coverage)
            if (r0.y < (r2.y ?? 0)) { // from above
                yOver = r2.h ? (((r.y ?? 0) + (r.h ?? 0)) - (r2.y ?? 0)) / r2.h : 0;
            }
            else if (r0.y + r0.h > (r2.y ?? 0) + (r2.h ?? 0)) { // from below
                yOver = r2.h ? (((r2.y ?? 0) + (r2.h ?? 0)) - (r.y ?? 0)) / r2.h : 0;
            }
            if (r0.x < (r2.x ?? 0)) { // from the left
                xOver = r2.w ? (((r.x ?? 0) + (r.w ?? 0)) - (r2.x ?? 0)) / r2.w : 0;
            }
            else if (r0.x + r0.w > (r2.x ?? 0) + (r2.w ?? 0)) { // from the right
                xOver = r2.w ? (((r2.x ?? 0) + (r2.w ?? 0)) - (r.x ?? 0)) / r2.w : 0;
            }
            let over = Math.min(xOver, yOver);
            if (over > overMax) {
                overMax = over;
                collide = n;
            }
        });
        o.collide = collide; // save it so we don't have to find it again
        return collide;
    }

}