import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { StatusCode } from '@alcon-db-models/Enums';
import { CommitmentSubjectBaseService } from '@services/commitment-subject-base.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';

@Component({
  selector: 'acb-alcon-view-commitment-details',
  template: `
    <div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">

      <div class="acb-details-layout"  gdColumns="repeat(auto-fill, minmax(8em, max-content))">
        <div class="acb-view-field-commitment-id">
          <div>
            <div class="acb-value">{{ commitment?.commitmentID }}</div>
          </div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Status"></kendo-label>
          <div style="font-weight:bold" [ngStyle]="{'color': getStatusColor(commitment) }" >{{ commitment?.status }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Amount"></kendo-label>
          <div style="font-weight:bold">{{ commitment?.amount | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Start Date"></kendo-label>
          <div>{{ commitment?.startDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="End Date"></kendo-label>
          <div>{{ commitment?.endDate | date: 'M/d/yyyy' }}</div>
        </div>

        <ng-container *ngIf="commitment?.fundIsVenueAware">
          <div class="acb-view-field">
            <kendo-label text="Event"></kendo-label>
            <div>{{ commitment?.event }}</div>
          </div>
          <div class="acb-view-field">
            <kendo-label text="Est. Attendees"></kendo-label>
            <div>{{ commitment?.estimatedAttendeeCount }}</div>
          </div>
          <div class="acb-view-field">
            <kendo-label text="Venue"></kendo-label>
            <div>{{ commitment?.venue }}</div>
          </div>
          <div class="acb-view-field">
            <kendo-label text="Venue City"></kendo-label>
            <div>{{ commitment?.venueCity }}</div>
          </div>
          <div class="acb-view-field">
            <kendo-label text="Venue State"></kendo-label>
            <div>{{ commitment?.venueStateProvinceCode }}</div>
          </div>
        </ng-container>


        <div class="acb-view-field acb-span-2" *ngIf="doesUseCommitmentActivities">
          <kendo-label text="Activity"></kendo-label>
          <div>{{ commitment?.activity }}</div>
        </div>
        <div class="acb-view-field acb-span-2" *ngIf="!doShowLessForReview">
          <kendo-label text="Territory"></kendo-label>
          <div>{{ commitment?.territorySuggestedName }}</div>
        </div>
        <div class="acb-view-field acb-span-2" *ngIf="!doShowLessForReview && commitment?.fundingTerritoryID && commitment?.territoryID != commitment?.fundingTerritoryID">
          <kendo-label text="Funded By"></kendo-label>
          <div>{{ commitment?.fundingTerritorySuggestedName }}</div>
        </div>
        <div class="acb-view-field" *ngIf="!doShowLessForReview">
          <kendo-label text="Created By"></kendo-label>
          <div>{{ commitment?.createdByPerson }}</div>
        </div>
      </div>

      <div class="acb-view-comment">
        <kendo-label text="Comments"></kendo-label>
        <ng-container *ngIf="commitment?.comments?.length">
          <div>{{ commitment!.comments![commitment!.comments!.length - 1].commentBody }}</div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./view-commitment-details.component.scss']
})
export class ViewCommitmentDetailsComponent implements OnInit, OnDestroy {

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public cellWidth: number = 200;

  //@Input() public commitment?: CommitmentWithDetailsModel | null;

  private _commitment: CommitmentWithDetailsModel | null | undefined;
  @Input() public set commitment(value: CommitmentWithDetailsModel | null | undefined) {
    this._commitment = value;
    if (this.areCommitmentActivityRulesDeterminedByFund) {
      this.doesUseCommitmentActivities = value?.fundDoesUseCommitmentActivities ?? false;
      this.commitmentActivitiesRequired = (value?.fundDoesUseCommitmentActivities ?? false) && (value?.fundCommitmentActivitiesRequired ?? false);
    }
  };
  public get commitment() { return this._commitment; }

  @Input() public doShowLessForReview: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    store: Store,
    @Optional() private commitmentSubjectBaseService?: CommitmentSubjectBaseService
  ) {

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })

    if (commitmentSubjectBaseService) {
      commitmentSubjectBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.commitment = x
      });
    }
  }

  getStatusColor(commitment?: CommitmentWithDetailsModel | null) {
    switch (commitment?.statusCodeID as StatusCode) {
      case StatusCode.Approved:
        return '#00AE44';
      case StatusCode.Denied:
        return '#9B1A2F';
      case StatusCode.Requested:
        return '#0063b7';
      case StatusCode.Closed:
        return '#FF6B12';
      case StatusCode.Canceled:
        return '#9B1A2F';
      default:
        return '#484844';
    }
  }

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
