import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponent, SelectionEvent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { BehaviorSubject } from 'rxjs';
import { SelectSearchBindingBaseDirective } from '../directives/select-search-binding.base.directive';
import { WindowMode } from './components.module';

@Component({template:''})
export abstract class SelectComponentBase<T> {

  @Input() windowMode: WindowMode = 'dialog';

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<T | null> = new EventEmitter();
  @Output() selectedChange: EventEmitter<T | null> = new EventEmitter();

  protected abstract formFieldsToClearOnSelect:string[] | null;
  public abstract searchForm: UntypedFormGroup;
  public abstract getSelectedItem():T | null;
  protected abstract doSelectionChange(fund: T | null): void;

  private _resultsGrid?: GridComponent;
  @ViewChild('resultsGrid') protected set resultsGrid(grid: GridComponent | undefined) {
    if(this._resultsGrid = grid) {
    }
  };

  private _bindingDirective?: SelectSearchBindingBaseDirective;
  protected set bindingDirective(directive: SelectSearchBindingBaseDirective | undefined) {
    this._bindingDirective = directive;
  }
  protected get bindingDirective(): SelectSearchBindingBaseDirective | undefined {
    return this._bindingDirective;
  }

  protected get resultsGrid() { return this._resultsGrid; }

  public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedKeys: number[] = [];
  public windowHeigth: number = window.innerHeight;

  constructor(
    @Optional() protected wizardFeatureService?: WizardFeatureService,
    @Optional() protected changeDetectorRef?: ChangeDetectorRef
    ) {
    wizardFeatureService?.clear.subscribe((x: boolean) => {
      if (x) {
        this.selectedKeys.length = 0;
        this.doSelectionChange(null);
      }
    })
  }

  public validate(): boolean {
    return false;
  }

  public updateFilter(): void {
    if (!this.bindingDirective || !this.searchForm)
      return;
    let filters:FilterDescriptor[] = [];
    Object.keys(this.searchForm.controls).forEach((fieldName: string) => {
      const field = this.searchForm.get(fieldName);
      if (field) {
        let val = field.value;
        if (val && typeof val === 'string' && fieldName != 'fundYears') {
          val = val ? '%' + val.replace('[','[[').replace(']',']]').replace('%','[%]').replace('_','[_]') + '%' : undefined;
        }
        filters.push({ field: fieldName, operator: 'eq', value: val })
      }
    });
    this.bindingDirective.filter = { filters: filters, logic: 'and' };
  }

  public ngOnInit(): void {
  }

  public onSearch() {
    if (this.resultsGrid && this.bindingDirective) {
      this.bindingDirective.skip = 0;
      this.rebind();
    }
  }

  public rebind() {
    this.selectedKeys.length = 0;
    this.bindingDirective?.rebind();
    this.changeDetectorRef?.detectChanges();
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onSelect(item:T | null | undefined) {
    if (this.formFieldsToClearOnSelect?.length) {
      this.formFieldsToClearOnSelect.forEach(x => this.searchForm.get(x)?.setValue(null));
    }
    this.select.emit(item);
    this.changeDetectorRef?.detectChanges();
  }

  public onSelectionChange(event?:SelectionEvent) {
    this.doSelectionChange(event?.selectedRows?.length ? event.selectedRows[0]?.dataItem : null);
    this.changeDetectorRef?.detectChanges();
  }
}
