
import { NgModule} from '@angular/core';
import { FieldExtentionsDirective } from './../../directives/field-extentions.directive'
import { CommitmentSearchBindingDirective } from './../../directives/commitment-search-binding.directive';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel'

import { CustomerSelectSearchBindingDirective } from './../../directives/customer-select-search-binding.directive';
import { FundSelectSearchBindingDirective } from '../../directives/fund-select-search-binding.directive';
import { TerritorySelectSearchBindingDirective } from '../../directives/territory-select-search-binding.directive';
import { PayeeSelectSearchBindingDirective } from '../../directives/payee-select-search-binding.directive';
import { ClaimSearchBindingDirective } from '../../directives/claim-search-binding.directive';
import { CommitmentSelectSearchBindingDirective } from '../../directives/commitment-select-search-binding.directive';
import { TransactionHistorySearchBindingDirective } from '../../directives/transaction-history-search-binding.directive';
import { ManageUserSearchBindingDirective } from '../../directives/manage-user-search-binding.directive';
import { ThirdPartySearchBindingDirective } from '../../directives/third-party-search-binding.directive';
import { CustomerSearchBindingDirective } from '../../directives/customer-search-binding.directive';
import { ClaimFundingDispatchSearchBindingDirective } from '../../directives/claim-funding-dispatch-search-binding.directive';

import { MultipleIntegerTextboxDirective } from '../../directives/multiple-integer-textbox.directive';
import { ReportBindingDirective } from '../../directives/report-binding.directive';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { FundAllocatedModel } from '@alcon-db-models/FundAllocatedModel';
import { TerritoryWithBalanceModel } from '@alcon-db-models/TerritoryWithBalanceModel';
import { TransactionType } from '@alcon-db-models/Enums';
import { AuditCodeWithGroupModel } from '@alcon-db-models/AuditCodeWithGroupModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { DraftModel } from '@alcon-db-models/DraftModel';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { State } from '@progress/kendo-data-query';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { CommitmentApprovalRuleSearchBindingDirective } from 'src/app/directives/commitment-approval-rule-search-binding.directive';
import { FundSearchBindingDirective } from 'src/app/directives/fund-search-binding.directive';
import { AuditInstructionSearchBindingDirective } from 'src/app/directives/audit-instruction-search-binding.directive';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { GridUserPreferenceDirective } from 'src/app/directives/grid-user-preference.directive';
import { SandboxFileUploadSearchBindingDirective } from 'src/app/directives/sandbox-file-upload-search-binding.directive';
import { LcpComparisonSearchBindingDirective } from 'src/app/directives/lcp-comparison-search-binding.directive';
import { DashboardForDropdownModel } from '@alcon-db-models/DashboardForDropdownModel';
import { DashboardItemForDashboardModel } from '@alcon-db-models/DashboardItemForDashboardModel';


@NgModule({
  declarations: [
    FieldExtentionsDirective,
    CommitmentSearchBindingDirective,
    CustomerSelectSearchBindingDirective,
    FundSelectSearchBindingDirective,
    TerritorySelectSearchBindingDirective,
    PayeeSelectSearchBindingDirective,
    ClaimSearchBindingDirective,
    CommitmentSelectSearchBindingDirective,
    TransactionHistorySearchBindingDirective,
    ManageUserSearchBindingDirective,
    ThirdPartySearchBindingDirective,
    CustomerSearchBindingDirective,
    ClaimFundingDispatchSearchBindingDirective,
    MultipleIntegerTextboxDirective,
    ReportBindingDirective,
    CommitmentApprovalRuleSearchBindingDirective,
    FundSearchBindingDirective,
    AuditInstructionSearchBindingDirective,
    GridUserPreferenceDirective,
    SandboxFileUploadSearchBindingDirective,
    LcpComparisonSearchBindingDirective
  ],
  exports: [
    FieldExtentionsDirective,
    CommitmentSearchBindingDirective,
    CustomerSelectSearchBindingDirective,
    FundSelectSearchBindingDirective,
    TerritorySelectSearchBindingDirective,
    PayeeSelectSearchBindingDirective,
    ClaimSearchBindingDirective,
    CommitmentSelectSearchBindingDirective,
    TransactionHistorySearchBindingDirective,
    ManageUserSearchBindingDirective,
    ThirdPartySearchBindingDirective,
    CustomerSearchBindingDirective,
    ClaimFundingDispatchSearchBindingDirective,
    MultipleIntegerTextboxDirective,
    ReportBindingDirective,
    CommitmentApprovalRuleSearchBindingDirective,
    FundSearchBindingDirective,
    AuditInstructionSearchBindingDirective,
    GridUserPreferenceDirective,
    SandboxFileUploadSearchBindingDirective,
    LcpComparisonSearchBindingDirective
  ]
})
// TODO:  Core?  Shared?  Share?  Whatever we end up calling it we should centralize our common imports here
export class CoreModule {
}

// TODO:  Create library?  Dunno, we shouldn't continue to hang these models off the end of core...
export class TerritoryForDropdownWithCompositeNameModel extends TerritoryForDropdownModel  {
  constructor(model?: TerritoryForDropdownModel) {
    super();
    if (model)
      Object.assign(this, model);
  }
  get compositeName():string {
    return (this.code ?? '') + ' - ' + (this.primarySalesPerson ?? '');
  }
}

export class AuditCodeWithCompositeNameModel extends AuditCodeWithGroupModel  {
  constructor(model?: AuditCodeWithGroupModel) {
    super();
    if (model)
      Object.assign(this, model);
  }
  get compositeName():string {
    return (this.code ?? '') + ' - ' + (this.displayName ?? '');
  }
}

export class BatchStep {
  constructor(params?:BatchStep) {
    if (params)
      Object.assign(this,params);
  }
  public transactionAmount?: number | null = null;
  public transactionType?: TransactionType | null = null;
  public depositType?: StaticTypeModel | null = null;
  public fund?: FundAllocatedModel | null = null;
  public fromTerritory?: TerritoryWithBalanceModel | null = null;
  public toTerritory?: TerritoryWithBalanceModel | null = null;
  public comment?: string | null = null;
  public isOrphan: boolean = false;
  public _internalID?: number;
}

export class FundAdjustment {
  constructor(
    public fundID: number,
    public adjustmentAmount: number
  ) {}
}

export class CommitmentWithDraftModel extends CommitmentWithDetailsModel {
  constructor(public draft: DraftModel) {
    super();
    const commitment = JsonUtilities.parseItemWithDates(draft.draftBody ?? '')?.commitment as CommitmentWithDetailsModel;
    if (commitment) Object.assign(this, commitment);
  }

  public get draftID() { return this.draft.draftID; }
  public get ownedBy() { return this.draft.ownedBy; }
}

export class ClaimWithDraftModel extends ClaimWithDetailsModel {
  constructor(public draft: DraftModel) {
    super();
    const claim = JsonUtilities.parseItemWithDates(draft.draftBody ?? '')?.claim as ClaimWithDetailsModel;
    if (claim) Object.assign(this, claim);
  }

  public get draftID() { return this.draft.draftID; }
  public get ownedBy() { return this.draft.ownedBy; }
}

export interface GridSettings {
  columnsConfig: ColumnSettings[];
  state: State;
}

export interface ColumnSettings {
  field: string;
  width?: number;
  hidden?: boolean;
  orderIndex?: number;
}

export interface DashboardSettings {
  currentDashboardID: number | null | undefined,
  dashboards: DashboardSetting[] | null | undefined,   
}

export interface DashboardSetting  {
  dashboardID?: number;
  filterValues?: any;
  dashboardItems: DashboardItemSetting[];
}

export interface DashboardItemSetting  {
  dashboardDashboardItemID?: number;
  dashboardItemID?: number;
  additionalParameters?: string | null;
  x?: number | null;
  y?: number | null;
  rows?: number | null;
  cols?: number | null;
}

export class FeatureWithChildren extends FeatureModel {
  canEdit: boolean = true;
  children: FeatureWithChildren[] = [];
}

