import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FundForDropdownService } from '@services/fund-for-dropdown.service';
import { TerritoryForDropdownService } from '@services/territory-for-dropdown.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { DashboardItemSetting, DashboardSetting, DashboardSettings } from '../core/core.module';

import { DashboardForDropdownService } from '@services/dashboard-for-dropdown.service';
import { DashboardItemForDashboardService } from '@services/dashboard-item-for-dashboard.service';
import { DashboardItemForDropdownService } from '@services/dashboard-item-for-dropdown.service';
import { DashboardForDropdownModel } from '@alcon-db-models/DashboardForDropdownModel';
import { DashboardItemForDashboardModel } from '@alcon-db-models/DashboardItemForDashboardModel';

import {  NgResizeObserver,  ngResizeObserverProviders} from "ng-resize-observer";
import { DashboardWidgetComponent, DashboardWidgetOptions } from '../dashboard-widget/dashboard-widget.component';
import { DashboardItemType, DashboardType, StatusCode } from '@alcon-db-models/Enums';

import { GridStackNode, GridStackWidget } from 'gridstack';
import { GridstackComponent, nodesCB, NgGridStackOptions } from 'gridstack/dist/angular';
import { AcbGridStackEngine} from './AcbGridStackEngine';
import { FilterSpecFieldType } from "@alcon-db-models/FilterSpecFieldType";
import { UserPreferencesService } from "@services/user-preferences.service";
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns";
import { JsonUtilities } from "src/app/shared/json-utilities";
import { selectDashboardPreferences } from "@app-store/user-preferences/user-preferences.selectors";
import { Actions } from "@ngrx/effects";
import { DashboardItemForDropdownModel } from "@alcon-db-models/DashboardItemForDropdownModel";
import { DashboardService } from "@services/dashboard.service";
import { AppWindowService } from '@services/app-window.service';
import { DashboardUpsertRequestModel } from '@alcon-db-models/DashboardUpsertRequestModel';
import { DashboardDashboardItemModel } from '@alcon-db-models/DashboardDashboardItemModel';
import { AppUxService } from '@services/app-ux.service';
import { selectImpersonationStatus } from '@app-store/app-session/app-session.selectors';

class DashboardForDropdownExtModel extends DashboardForDropdownModel
{
  public isDirty: boolean = false
  constructor(dashboard:DashboardForDropdownModel) { super(); Object.assign(this, dashboard); }
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

@Component({
  selector: 'acb-alcon-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [...ngResizeObserverProviders],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnDestroy {

  @ViewChild('dashboardList') dashboardList?: DropDownListComponent;
  @ViewChild('masterDashboard') masterDashboard?: GridstackComponent;
  @ViewChildren('widget') widgets?:QueryList<DashboardWidgetComponent>;

  @Output() widgetSelecting: EventEmitter<DashboardWidgetComponent> = new EventEmitter();

  // private fields
  private _previousFilterFormValue: any | undefined;
  private _dashboardsForDropdown: DashboardForDropdownExtModel[] = [];
  private _previousFormValue?: any;
  private _dashboardSettings?: DashboardSettings;
  private _currentDashboardItems: DashboardItemForDashboardModel[] = [];
  private _fundYears:number[] = [];
  
  // observables fields
  public destroy$: Subject<void> = new Subject<void>();
  public fundsForDropdownSource$: Observable<FundForDropdownModel[]>;
  public fundsForDropdown$: Observable<FundForDropdownModel[]>;
  public fundsYears$: Observable<number[]>;
  public territoriesForDropdown$: Observable<TerritoryForDropdownModel[]>;
  public dashboardsForDropdown$: Subject<DashboardForDropdownExtModel[]> = new Subject();
  public dashboardItemsForDashboard$: Subject<DashboardItemForDashboardModel[]> = new Subject();
  public dashboardItemsForDropdown$: Observable<DashboardItemForDropdownModel[]>;
  public dashboardWidgetOptions$: BehaviorSubject<DashboardWidgetOptions> = new BehaviorSubject<DashboardWidgetOptions>(new DashboardWidgetOptions);
  public fundsForWidget$: BehaviorSubject<FundForDropdownModel[]> = new BehaviorSubject<FundForDropdownModel[]>([]);
  public territoriesForWidget$: BehaviorSubject<TerritoryForDropdownModel[]> = new BehaviorSubject<FundForDropdownModel[]>([]);

  // fields
  public userDashboardType = DashboardType.User;
  public dashboardFilterUIMode: 'view' | 'edit' = 'view';
  public dashboardAddWidgetData:any = [];
  public isImpersonating: boolean = false;

  public dashboardHamburgerData = [
    {
      actionName: "New",
      icon: 'plus-circle',
      disabled: false,
      mustHaveDashboard: false,
      mustHaveSavePermission: false,
      click: (dataItem: any): void => {
        this.onNewDashboard();
      }
    },
    {
      actionName: "Clone",
      icon: 'copy',
      disabled: !this.currentDashboard,
      mustHaveDashboard: true,
      mustHaveSavePermission: false,
      click: (dataItem: any): void => {
        this.onCloneDashboard();
      }
    },
    {
      actionName: "Edit",
      icon: 'edit',
      disabled: !this.currentDashboard,
      mustHaveDashboard: true,
      mustHaveSavePermission: true,
      click: (dataItem: any): void => {
        this.onEditDashboard();
      }
    },
    {
      actionName: "Save",
      icon: 'save',
      disabled: !this.currentDashboard || !this.currentDashboard.isDirty,
      mustHaveDashboard: true,
      mustHaveSavePermission: true,
      mustBeDirty: true,
      click: (): void => {
        this.onSaveDashboard();
      }
    },
    {
      actionName: "Delete",
      icon: 'delete',
      disabled: !this.currentDashboard,
      mustHaveDashboard: true,
      mustHaveSavePermission: true,
      click: (dataItem: any): void => {
        this.onDeleteDashboard();
      }
    }
  ];
  
  public masterDashboardGridOptions: NgGridStackOptions = {
    float: false,
    margin: 5,
    auto: true,
    animate: false,
    draggable: { pause: 10 },
    resizable: { handles: 'all' },
    staticGrid: false,
    engineClass: AcbGridStackEngine
  }

  public filterForm = new UntypedFormGroup({
    fundYears: new UntypedFormControl(),
    funds: new UntypedFormControl(),
    territories: new UntypedFormControl(),
  });

  // properties
  public get isLocked() { return false; }

  private _currentDashboard: DashboardForDropdownExtModel | undefined | null;
  public set currentDashboard(value) {
    this._currentDashboard = value;
    this.syncDashboardMenuDisabled();
  };
  public get currentDashboard() { return this._currentDashboard };

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  constructor(
    fundForDropdownService: FundForDropdownService,
    territoryForDropdownService: TerritoryForDropdownService,
    dashboardForDropdownService: DashboardForDropdownService,
    private store: Store,
    private dashboardItemForDashboardService: DashboardItemForDashboardService,
    private dashboardItemForDropdownService: DashboardItemForDropdownService,
    private userPreferencesService: UserPreferencesService,
    private dashboardService: DashboardService,
    private appWindowService: AppWindowService,
    private appUxService: AppUxService,
    //actions$: Actions,
    //resize$: NgResizeObserver,
    //changeDetectorRef: ChangeDetectorRef,
  ) {

    GridstackComponent.addComponentToSelectorType([DashboardWidgetComponent]);

    combineLatest([
      this.store.select(selectDashboardPreferences).pipe(first()),
      this.store.select(selectImpersonationStatus).pipe(first()),
    ]).pipe(first()).subscribe(x => {
      this.isImpersonating = x[1].isImpersonating;
      this._dashboardSettings = !this.isImpersonating && x[0] ? JsonUtilities.convertDatesAndCopy(x[0]) : undefined;
    });

    this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.onFilterFormValueChanges(x);
    })

    this.fundsForDropdownSource$ = fundForDropdownService.getWithQuery({ statusCodeIDs: `${StatusCode.Active}` });
    this.fundsYears$ = this.fundsForDropdownSource$.pipe(map(x => x.map(y => y.year as number).filter((value, index, self) => value && self.indexOf(value) === index)));
    this.fundsYears$.pipe(takeUntil(this.destroy$)).subscribe(x => this._fundYears = x);
    this.fundsForDropdown$ = this.fundsForDropdownSource$;
    this.territoriesForDropdown$ = territoryForDropdownService.getAll().pipe(first());
    this.dashboardItemsForDropdown$ = this.dashboardItemForDropdownService.getAll();

    this.dashboardItemsForDropdown$.pipe(first()).subscribe(x => {
      const getIconAndClass = (itemType:DashboardItemType|null|undefined) => {
        switch(itemType) {
          case DashboardItemType.ActionCenter: return { icon: "cursor", class: "acb-dashboard-dropdown-info" };
          case DashboardItemType.AreaChart: return { icon: "chart-area-stacked100", class: "acb-dashboard-dropdown-chart" };
          case DashboardItemType.AreaChartStacked: return { icon: "chart-area-stacked", class: "acb-dashboard-dropdown-chart" }; 
          case DashboardItemType.BarGraph: return { icon: "chart-bar-clustered", class: "acb-dashboard-dropdown-chart" };  
          case DashboardItemType.ColumnChart: return { icon: "chart-column-clustered", class: "acb-dashboard-dropdown-chart" };   
          case DashboardItemType.ColumnChartStacked: return { icon: "chart-column-stacked", class: "acb-dashboard-dropdown-chart" };    
          case DashboardItemType.DonutChart: return { icon: "chart-doughnut", class: "acb-dashboard-dropdown-chart" };     
          case DashboardItemType.LineGraph: return { icon: "chart-line", class: "acb-dashboard-dropdown-chart" };     
          case DashboardItemType.LineGraphStacked: return { icon: "chart-line-stacked", class: "acb-dashboard-dropdown-chart" };     
          case DashboardItemType.PieChart: return { icon: "chart-pie", class: "acb-dashboard-dropdown-chart" };     
          case DashboardItemType.MessageCenter: return { icon: "info", class: "acb-dashboard-dropdown-info" };
          case DashboardItemType.DataGrid: return { icon: "table", class: "acb-dashboard-dropdown-grid" };;
          default: return "";
        }
      };
      this.dashboardAddWidgetData = x.map(x => {
        return {
          ...getIconAndClass(x.dashboardItemTypeID),
          actionName: x.displayName,
          mustHaveDashboard: true,
          mustHaveSavePermission: true,
          item: x,
          click: this.onAddWidget
        };
      })
    });

    this.dashboardsForDropdown$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._dashboardsForDropdown = x;// x?.map(y => JsonUtilities.convertDatesAndCopy(y) as DashboardForDropdownModel) ?? [];
      this.currentDashboard = this.currentDashboard ? x.find(y => y.dashboardID == this.currentDashboard!.dashboardID) : undefined;
    });

    combineLatest([
      this.store.select(selectDashboardPreferences).pipe(first()), 
      this.dashboardsForDropdown$, 
      this.fundsForDropdownSource$,
      this.dashboardItemsForDropdown$, 
      this.territoriesForDropdown$]).pipe(first()).subscribe((x) => {


        const dashboardSettings = x[0];
        const dashboardsForDropdown = x[1];
        if (!dashboardsForDropdown?.length) return;
        this.updateDashboardsName(dashboardSettings ?? undefined,dashboardsForDropdown);
        // Restore dashboard selection from user prefs or set to first in dashboard dropdown
        const current = dashboardsForDropdown.find(y => y.dashboardID == dashboardSettings?.currentDashboardID) ?? dashboardsForDropdown[0];

        console.debug("combineLatest",current,this.dashboardList);

        if (this.dashboardList) {
          this.dashboardList.value = current;
          this.onDashboardValueChanged(current, false, false);
        }
      });

    dashboardForDropdownService.getAll().pipe(first()).subscribe(x => {
      this.dashboardsForDropdown$.next(x.map(y => new DashboardForDropdownExtModel(y)));
    })

    this._previousFormValue = this.filterForm.value;
  }

  // --------------------------------------------------------------------------
  // Private Methods
  // --------------------------------------------------------------------------

  private setCurrentDashboard(value?: DashboardForDropdownExtModel, doClearFilters:boolean = true) {
    if (doClearFilters) this.clearFilers();

    console.debug('setCurrentDashboard',value)


    if (value?.dashboardID) {
      this.currentDashboard = value;
      this.updateDashboardWidgetOptions();
      const params = new HttpParams( { fromObject:  { 'dashboardID': value.dashboardID.toString() } });
      this.masterDashboard?.grid?.batchUpdate(true);
      combineLatest([
        this.store.select(selectDashboardPreferences).pipe(first()), 
        this.dashboardItemForDashboardService.getWithQuery(params.toString()).pipe(first(x => !!x)),
        this.dashboardService.findByID(value.dashboardID)
      ]).pipe(first()).subscribe(([settings,models,dashboard]) => {

        console.debug('setCurrentDashboard',settings,models,dashboard)

        if (this.isImpersonating) settings = null;

        const setting = settings?.dashboards?.find(y => y.dashboardID == value.dashboardID);
        models = setting ? 
          models.filter(x => setting?.dashboardItems.some(y => y.dashboardDashboardItemID == x.dashboardDashboardItemID)) :
          models.filter(x => x.statusCodeID == StatusCode.Active);


          console.debug('setCurrentDashboard models',models, setting)


        if (!setting && dashboard?.filterValues) {
          try {
            let v = JSON.parse(dashboard?.filterValues) ?? {};
            v = { 
              fundYears: v.fundYears, 
              funds: (v.fundIDs as number[] ?? []).map(x => this.getFundByID(x)) ?? [],
              territories: (v.territoryIDs as number[] ?? []).map(x => this.getTerritoryByID(x)) ?? []
            }
            this.filterForm.patchValue(v);
            this.updateDashboardWidgetOptions();
          } catch(err) { }
        }          


        this._currentDashboardItems = models;
        this.masterDashboard?.grid?.load(models.map(y => 
          {
            const item = y;
            const settingItem = setting?.dashboardItems?.find(z => z.dashboardDashboardItemID == y.dashboardDashboardItemID);
            const widget = {
              id: item.dashboardDashboardItemID?.toString(),          
              maxH: item.maxItemRows ?? undefined,
              minH: item.minItemRows ?? undefined,
              maxW: item.maxItemCols ?? undefined,
              minW: item.minItemCols ?? undefined,
              h: settingItem?.rows ?? item.rows ?? undefined,
              w: settingItem?.cols ?? item.cols ?? undefined,
              x: settingItem?.x ?? item.x ?? undefined,
              y: settingItem?.y ?? item.y ?? undefined,
              selector: "acb-alcon-dashboard-widget",
              item: y,
              noResize: this.isImpersonating,
              noMove: this.isImpersonating,
              locked: this.isImpersonating 
            };
            return widget;
          }),true);
        this.masterDashboard?.grid?.batchUpdate(false);

      });
    } else {
      this._currentDashboard = undefined;
      this.masterDashboard?.grid?.removeAll(true);
    }
  }

  private syncDashboardMenuDisabled() {
    this.dashboardHamburgerData.forEach(x =>
      x.disabled = (x.mustHaveDashboard && !this._currentDashboard) || 
        (x.mustHaveSavePermission && this._currentDashboard?.dashboardType != DashboardType.User) ||
        ((x.mustBeDirty ?? false) && !(this._currentDashboard?.isDirty ?? false))
    );
  }

  private resetCurrentDashboardSettings() {
    if (!this.currentDashboard) return;
    let settings = this._dashboardSettings;
    if (!settings) return;
    if (settings.dashboards?.length) {
      settings.dashboards = settings.dashboards.filter(x => x.dashboardID != this.currentDashboard?.dashboardID);
    }
    this.setDashboardSettings(settings);
    this.updateDashboardsName(settings);
  }

  private upsertNewDashboard(dialogTitle: string, nameValue?: string, dashboard?: DashboardUpsertRequestModel) {
    const requestModel:DashboardUpsertRequestModel = {...dashboard};
    this.appWindowService.openRequestName(dialogTitle, undefined, undefined, nameValue, null, 400, 230).pipe(first()).subscribe(result => {
      if (!result?.name) return;
      requestModel.displayName = result?.name;
      this.dashboardService.upsert(requestModel).then(x => {
        if (!x.dashboardID) return;
        const dashboard = {
          dashboardID: x.dashboardID,
          displayName: x.displayName,
          code: x.code,
          dashboardType: x.dashboardTypeID,
          filterSpec: x.filterSpec,
          isDirty: false
        };
        const dashboards:DashboardForDropdownExtModel[] = [
          ...this._dashboardsForDropdown,
          dashboard
        ];
        this.dashboardsForDropdown$.next(dashboards);
        if (this.dashboardList) {
          this.dashboardList.value = dashboard;
          this.onDashboardValueChanged(dashboard, true, false);
        }
      });
    });
  }

  private alignFunds() {
    const selectedYears = (this.filterForm.value?.fundYears ?? []) as number[];
    if (!selectedYears.length) return;
    const selectedFunds = (this.filterForm.value?.funds ?? []) as FundForDropdownModel[];
    if (!selectedFunds.length) return;
    const filteredFunds = ((this.filterForm.value?.funds ?? []) as FundForDropdownModel[]).filter(x => selectedYears.some(y => y == x.year))
    if (JSON.stringify(filteredFunds) == JSON.stringify(selectedFunds)) return;
    this.filterForm.patchValue({
      funds: (this.filterForm.value?.funds as FundForDropdownModel[]).filter(x => selectedYears.some(y => y == x.year))
    }, { onlySelf: false, emitEvent: true });
  }

  private getTerritoryByID(territoryID: number) {
    let territory:TerritoryForDropdownModel | null = null;
    this.territoriesForDropdown$.pipe(first()).subscribe(x => territory = x.find(y => y.territoryID == territoryID) ?? null);
    return territory;
  }

  private getFundByID(fundID: number) {
    let fund:FundForDropdownModel | null = null;
    this.fundsForDropdownSource$.pipe(first()).subscribe(x => fund = x.find(y => y.fundID == fundID) ?? null);
    return fund;
  }

  private clearFilers() {
    this.filterForm.reset();
    this.filterForm.patchValue({ fundYears: [], funds: [], territories: [] });
  }

  private updateUserPreferences() {
    this.updateUserPreferencesCurrentDashboardID();
    this.updateUserPreferencesWidgetLayout();
  }

  private updateUserPreferencesWidgetLayout() {
    const nodes: GridStackNode[] | undefined = this.masterDashboard?.grid?.engine?.nodes;
    const currentDashboardID = this._currentDashboard?.dashboardID;
    if (!currentDashboardID) return;
    let settings: DashboardSettings = {
      ...this._dashboardSettings!,
    }
    settings.dashboards ??= [];
    const setting = this.getDashboardSettingFromCurrentNodesAndFilters();
    if (!setting) return;

    const idx = settings.dashboards.findIndex(x => x.dashboardID == setting.dashboardID);
    if (idx >= 0) {
      settings.dashboards[idx] = setting;
    } else {
      settings.dashboards.push(setting);
    }

    this.setDashboardSettings(settings);
  }

  private getShortenedName(names:string[]|undefined|null) {
    if (!names?.length) return;
    return { label: names.sort()[0], info: ((names.length > 1) ? ` (+${names.length - 1} more)` : "") };//    names.sort()[0] + ((names.length > 1) ? ` (+${names.length - 1} more)` : "")
  }

  private updateCurrentDashboard() {
    if (!this._currentDashboard) return;
    this.updateDashboardWidgetOptions();
  }

  private updateUserPreferencesCurrentDashboardID(dashboardID?: number) {
    
    let settings: DashboardSettings = {
      ...this._dashboardSettings! ?? {},
      currentDashboardID: dashboardID ?? this._currentDashboard?.dashboardID,
    }
    this.setDashboardSettings(settings);
  }

  private getDashboardSettingFromCurrentNodesAndFilters():DashboardSetting|null {

    if (!this._currentDashboard?.dashboardID) return null;

    const setting = this._dashboardSettings?.dashboards?.find(x => x.dashboardID == this._currentDashboard?.dashboardID) ?? { 
      dashboardID: this._currentDashboard.dashboardID,
    } as DashboardSetting;
    
    const nodes: GridStackNode[] | undefined = this.masterDashboard?.grid?.engine?.nodes;
    const dashboardItems: DashboardItemSetting[] = nodes?.length ? nodes.map((x) => {
      const currentDashboardItem = this._currentDashboardItems.find(y => y.dashboardDashboardItemID == x.id);
      return {
        dashboardDashboardItemID: x.id,
        dashboardItemID: currentDashboardItem?.dashboardItemID,
        additionalParameters: null,
        x: x.x,
        y: x.y,
        rows: x.h,
        cols: x.w,      
      } as DashboardItemSetting
    }) : [];

    setting.dashboardItems = dashboardItems;
    setting.filterValues = {
      fundYears: this.getAreFundYearsAllowed() ? [...(this.filterForm?.get('fundYears')?.value as number[] ?? [])] : [],
      fundIDs: this.getAreFundsAllowed() ? (this.filterForm?.get('funds')?.value as FundForDropdownModel[] ?? []).filter(x => x.fundID).map(x => x.fundID as number) : [],
      territoryIDs: this.getAreTerritoriesAllowed() ? (this.filterForm?.get('territories')?.value as TerritoryForDropdownModel[] ?? []).filter(x => x.territoryID).map(x => x.territoryID as number) : [],
    }

    return setting;
  }

  private updateDashboardWidgetOptions() {

    const formFundYears = this.filterForm?.get('fundYears')?.value;
    let fundYears = this.getAreFundsAllowed() && formFundYears?.length ? formFundYears : this._fundYears;

    const options:DashboardWidgetOptions = {
      funds: this.getAreFundsAllowed() ? this.filterForm?.get('funds')?.value ?? [] : [],
      fundYears: fundYears,
      territories: this.getAreTerritoriesAllowed() ? this.filterForm.get('territories')?.value ?? [] : [],
      // We'll always return true, but will not allow saving, just like widget layout changes
      canDelete: !this.isImpersonating//this.getCanDelete()
    };
    this.dashboardWidgetOptions$.next({...this.dashboardWidgetOptions$.value, ...options });
  }

  private setDashboardSettings(settings: DashboardSettings) {
    this._dashboardSettings = JsonUtilities.convertDatesAndCopy(settings);
    this.userPreferencesService.setDashboardSettings(settings);
    this.updateDashboardsName(settings);
  }

  private updateDashboardsName(settings?: DashboardSettings, dashboards?: DashboardForDropdownExtModel[]) {

    settings ??= this._dashboardSettings; 

    if (!settings) return;
    dashboards ??= this._dashboardsForDropdown;
    dashboards = dashboards?.map(y => JsonUtilities.convertDatesAndCopy(y) as DashboardForDropdownExtModel) ?? [];
    if (dashboards?.length) {
      dashboards.forEach(y => {
        y.isDirty = settings!.dashboards?.some(z => z.dashboardID == y.dashboardID) ?? false;
      });
      setTimeout(() => {
        if (dashboards) {
          this.dashboardsForDropdown$.next(dashboards);
        };
        this.syncDashboardMenuDisabled();
      }, 100);
    }
  }

  private fundsYearsValueChanged() {
    const years: number[] = (this.filterForm?.get('fundYears')?.value as []);
    this.fundsForDropdown$ = years?.length ?
      this.fundsForDropdownSource$.pipe(map(x => x.filter(x =>years?.some(y => y == x.year)))) :
      this.fundsForDropdownSource$;
    this.alignFunds();
  }

  private fundsValueChanged() {
    const funds: FundForDropdownModel[] = (this.filterForm?.get('funds')?.value as []) ?? [];
    let params = new HttpParams();
    if (funds.length) {
      params = new HttpParams( { fromObject:  { 'fundIDs': (funds?.length ? funds.map(x => x.fundID?.toString()).join(',') : '') } });
    }
  }

  private territoriesValueChanged() {
  }  

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------

  public isItemSelected(formControlName: string, value: string): boolean {
    return (this.filterForm.get(formControlName)?.value as [])?.some(x => x == value) ?? false;
  }

  public getFormYearList() {
    let names:string[] = this.filterForm?.value?.fundYears;// ?? [];   
    return (names && names?.length) ? names.sort().join(', ') : null;
  }

  public getFormFundNameList() {
    let names:string[] = this.filterForm.value?.funds?.map((x:FundForDropdownModel) => x?.displayName);
    return this.getShortenedName(names);
  }

  public getFormTerritoryNameList() {
    let names:string[] = this.filterForm.value?.territories?.map((x:TerritoryForDropdownModel) => x?.territorySuggestedName);
    return this.getShortenedName(names);
  }

  public getAreFundYearsAllowed() {
    return this._currentDashboard?.filterSpec?.fields?.some(x => x.fieldType == FilterSpecFieldType.FundYears) ?? false;
  }

  public getAreFundsAllowed() {
    return this._currentDashboard?.filterSpec?.fields?.some(x => x.fieldType == FilterSpecFieldType.FundIDs) ?? false;
  }

  public getAreTerritoriesAllowed() {
    return this._currentDashboard?.filterSpec?.fields?.some(x => x.fieldType == FilterSpecFieldType.TerritoryIDs) ?? false;
  }

  public getCanDelete() {
    return this._currentDashboard?.dashboardType == DashboardType.User ?? false;
  }


  public getHasDashboard() {
    return !!this._currentDashboard;
  }

  // --------------------------------------------------------------------------
  // Event Handling
  // --------------------------------------------------------------------------

  onEditFiltersClick() {
    this.dashboardFilterUIMode = 'edit';
    this._previousFilterFormValue = this.filterForm.value;
  }

  onEditFiltersSaveClick() {
    if (this.dashboardFilterUIMode == 'view') return;
    this.dashboardFilterUIMode = 'view';
    this._previousFilterFormValue = undefined;
    this.updateCurrentDashboard();
    this.updateUserPreferences();
  }

  onEditFiltersCancelClick() {
    if (this.dashboardFilterUIMode == 'view') return;
    this.dashboardFilterUIMode = 'view';
    if (this._previousFilterFormValue) this.filterForm.patchValue(this._previousFilterFormValue);
    this.updateUserPreferences();
  }
 
  onEditFiltersClearClick() {
    this.clearFilers();
    this.onEditFiltersSaveClick();
    this.dashboardFilterUIMode = 'view';
  }

  public onDashboardValueChanged(value?: DashboardForDropdownExtModel, doUpdatePrefs: boolean = true, doClearFilters: boolean = false) {  
    
    this.clearFilers();
    let settings = JsonUtilities.convertDatesAndCopy(this._dashboardSettings) as DashboardSettings;

    if (value?.dashboardID && settings?.currentDashboardID && settings?.dashboards) {
      const setting = settings.dashboards.find(y => y.dashboardID == value.dashboardID);
      const filters = {
        fundYears: setting?.filterValues?.fundYears as number[] ?? [],
        funds: (setting?.filterValues?.fundIDs as number[] ?? []).map(x => this.getFundByID(x)) ?? [],
        territories: (setting?.filterValues?.territoryIDs as number[] ?? []).map(x => this.getTerritoryByID(x)) ?? [],
      };
      this.filterForm.patchValue(filters);
    }
    
    this.setCurrentDashboard(value,false);
    if (doUpdatePrefs) this.updateUserPreferencesCurrentDashboardID(value?.dashboardID ?? undefined);
  }


  public onFilterFormValueChanges(formValue: any) {
    if (JSON.stringify(formValue?.fundYears ?? []) != JSON.stringify(this._previousFormValue?.fundYears ?? [])) {
      this.fundsYearsValueChanged();
    } else if (JSON.stringify(formValue?.funds ?? {}) != JSON.stringify(this._previousFormValue?.funds ?? {}))  {
      this.fundsValueChanged();
    } else if (JSON.stringify(formValue?.territories ?? {}) != JSON.stringify(this._previousFormValue?.territories ?? {}))  {
      this.territoriesValueChanged();
    } else {
      return;
    }
    this._previousFormValue = formValue;
  }

  public onNewDashboard() {
    this.upsertNewDashboard('New Dashboard');
  }

  public onCloneDashboard() {
    if (!this._currentDashboard?.dashboardID) return;
    const nameValue = this._currentDashboard.displayName + " copy";
    const settings = this.getDashboardSettingFromCurrentNodesAndFilters();
    const dashboardRequest:DashboardUpsertRequestModel = {
      fromDashboardID: this._currentDashboard.dashboardID,
      displayName: this._currentDashboard.displayName + " copy",
      filterSpec: JSON.stringify(this._currentDashboard.filterSpec),
      dashboardTypeID: DashboardType.User,
      dashboardDashboardItems: this._currentDashboardItems
        .filter(x => settings?.dashboardItems.some(y => y.dashboardDashboardItemID == x.dashboardDashboardItemID)) 
        .map(x => {
          const di = settings?.dashboardItems.find(y => y.dashboardDashboardItemID == x.dashboardDashboardItemID);
          return {
            dashboardItemID: x.dashboardItemID,
            columnsSpec: x.columnsSpec,
            additionalParameters: x.additionalParameters,
            initialPlacementOrder: x.initialPlacementOrder,
            cols: di?.cols ?? x.cols,
            rows: di?.rows ?? x.rows,
            x: di?.x ?? x.x,
            y: di?.y ?? x.y
          };
        }),
      filterValues: JSON.stringify(settings?.filterValues)
    };
    this.upsertNewDashboard('New Dashboard', nameValue, dashboardRequest);
  }

  public onDeleteDashboard() {
    if (!this._currentDashboard?.dashboardID || !this._dashboardsForDropdown?.length) return;
    this.appUxService.openConfirmation(
      "Delete Dashboard?",
      `Are you sure you want to delete dashboard "${this._currentDashboard?.displayName}"?`,
      undefined,
      190,
      'alcon-background-orange',
      'acb-section-09').then((confirm) => {

        if (!confirm) return;

        this.dashboardService.delete(this._currentDashboard!.dashboardID!).then(x => {
          if (x) {
            this._dashboardsForDropdown.splice(this._dashboardsForDropdown.findIndex(y => y.dashboardID == this._currentDashboard!.dashboardID!), 1);
            this.dashboardsForDropdown$.next(this._dashboardsForDropdown);
            const dashboard = this._dashboardsForDropdown.length ? this._dashboardsForDropdown[0] : null;
            if (this.dashboardList) {
              this.dashboardList.value = dashboard;
              this.onDashboardValueChanged(dashboard ?? undefined, true, false);
            }
          }
        });

    });
  }


  public onSaveDashboard() {
    
    const settings = this.getDashboardSettingFromCurrentNodesAndFilters();

    if (settings?.filterValues && this._currentDashboard?.dashboardID) {
      this.dashboardService.updateFilterValues(this._currentDashboard.dashboardID, JSON.stringify(settings.filterValues));
    }

    const items = this.masterDashboard?.grid?.engine?.nodes?.filter(x => (x as any).item?.dashboardItemID).map(x => { 
      const _sourceItem =  (x as any).item as DashboardItemForDashboardModel;
      return ({
        ..._sourceItem,
        ...{
          x: x.x,
          y: x.y,
          rows: x.h,
          cols: x.w,
          statusCodeID: StatusCode.Active
        }
      }) as DashboardItemForDashboardModel;
    }) ?? [];

    const request =  { dashboardID: this._currentDashboard!.dashboardID, items: items };
    this.dashboardItemForDashboardService.save(request).pipe(first()).subscribe(x => {
      if (x) {
        this.resetCurrentDashboardSettings();
      }
    });
  }

  public onEditDashboard() {
    if (!this._currentDashboard?.dashboardID) return;

    const nameValue = this._currentDashboard.displayName;
    const dashboardID = this._currentDashboard.dashboardID; 
    
    this.appWindowService.openRequestName('Edit Dashboard', undefined, undefined, nameValue, null, 400, 230).pipe(first()).subscribe(result => {
      if (!result?.name) return;
      this.dashboardService.edit(dashboardID, result.name).then(x => {
        if (!x?.dashboardID) return;
        // const dashboard = {
        //   dashboardID: x.dashboardID,
        //   displayName: x.displayName,
        //   code: x.code,
        //   dashboardType: x.dashboardTypeID,
        //   filterSpec: x.filterSpec,
        //   isDirty: false
        // };
        const dashboard = this._dashboardsForDropdown.find(x => x.dashboardID == dashboardID);
        if (!dashboard) return;
        dashboard.displayName = x.displayName;

        // this.dashboardsForDropdown$.next(dashboards);
        // if (this.dashboardList) {
        //   this.dashboardList.value = dashboard;
        //   this.onDashboardValueChanged(dashboard, true, false);
        // }
      });
    });
  }

  public onResetClick() {
    this.filterForm.reset();
    this.resetCurrentDashboardSettings();
    if (this.currentDashboard) {
      this.masterDashboard?.grid?.removeAll(true);
      setTimeout(() => this.setCurrentDashboard(this.currentDashboard!,false), 100);
    }
  }

  public onMasterDashboardGridChange(data: nodesCB) {
    this.updateUserPreferencesWidgetLayout();
  }

  public onAddWidget = ((command:any) => {

    const dashboardItemID = command?.item?.dashboardItemID as number|undefined|null;
    if (!dashboardItemID || !this._currentDashboard?.dashboardID) return;

    const model: DashboardItemForDashboardModel = {
      dashboardID: this._currentDashboard.dashboardID,
      dashboardItemID: dashboardItemID,
      statusCodeID: StatusCode.Pending
    }

    this.dashboardItemForDashboardService.add(model).pipe(first()).subscribe(item => {
 
      let items = this._currentDashboardItems.map(x => JsonUtilities.convertDatesAndCopy(x));
      items.push(item);
      this.dashboardItemsForDashboard$.next(items);

      const itemsToLoad = [
        ...this.masterDashboard?.grid?.engine?.nodes ?? [],
        {
          id: item.dashboardDashboardItemID?.toString(),          
          maxH: item.maxItemRows ?? undefined,
          minH: item.minItemRows ?? undefined,
          maxW: item.maxItemCols ?? undefined,
          minW: item.minItemCols ?? undefined,
          h: item.rows ?? undefined,
          w: item.cols ?? undefined,
          x: item.x ?? undefined,
          y: item.y ?? undefined,
          selector: "acb-alcon-dashboard-widget",
          item: JsonUtilities.convertDatesAndCopy(item)
        }];

      this.masterDashboard?.grid?.load(itemsToLoad);
      this.updateUserPreferences();
    });

  }).bind(this);

  public removeWidgetByID = ((dashboardDashboardItemID:number) => {
    const itemsToLoad = this.masterDashboard?.grid?.engine?.nodes?.filter(x => x.id != dashboardDashboardItemID?.toString()) ?? [];
    this.masterDashboard?.grid?.load(itemsToLoad);
    // primarily to trigger *change
    this.updateUserPreferences();
  }).bind(this);

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
