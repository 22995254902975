import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import * as AppPersistedSettingsActions from './app-persisted-settings.actions';
import { Store } from '@ngrx/store';
import { AppPersistedSettingsState, appPersistedSettingsLocalStorageKey } from './app-persisted-settings.state';
import { selectAppPersistedSettingsState } from './app-persisted-settings.selectors';
import { ClientStorageService } from '@data-services/client-storage.service';
import { Router } from '@angular/router';
import { noopAction } from '@app-store/root.actions';

@Injectable()
export class AppPersistedSettingsEffects {

  updatePersistedSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AppPersistedSettingsActions.updateAccessTokenInfo,
        AppPersistedSettingsActions.updatePersistedSettings
        ),
      withLatestFrom(this.store.select(selectAppPersistedSettingsState)),
      map(([action,state]) => {
        return AppPersistedSettingsActions.persistedSettingsUpdated({ data: state });
        })
      )
    }
  );

  persistedSettingsUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType( AppPersistedSettingsActions.persistedSettingsUpdated ),
      map((action) => {
        this.clientStorageService.updateLocalStorageListener(ClientStorageService.createScopeKey(appPersistedSettingsLocalStorageKey), this.onLocalStorageListenerCallBack, false);
        return noopAction()
        })
      )
    }
  );

  private onLocalStorageListenerCallBack = ((key: string | null, newValue: string | null, oldValue: string | null): void => {
    try {
      if (key === ClientStorageService.createScopeKey(appPersistedSettingsLocalStorageKey)) {
        const oldState = oldValue ? JSON.parse(oldValue) as AppPersistedSettingsState : null;
        const newState = newValue ? JSON.parse(newValue) as AppPersistedSettingsState : null;
        if (oldState?.accessTokenInfo?.userID && oldState.accessTokenInfo.userID != newState?.accessTokenInfo?.userID) {
          this.router.navigate(['./logout']);
        }
      }
    } catch(e) {
    }
  }).bind(this);

  constructor(
    private actions$: Actions,
    private store: Store<AppPersistedSettingsState>,
    private clientStorageService: ClientStorageService,
    private router: Router
    ) {}
}
