
import { FeatureType } from './Enums';//1
import { FeatureMandateType } from './Enums';//1
import { StatusCode } from './Enums';//1
import { VisibilityType } from './Enums';//1
import { AccessRole } from './Enums';//1
import { BusinessRole } from './Enums';//1


export class FeatureModel  {
    featureID?: number | null;
    featureMapID?: number | null;
    code?: string | null;
    displayName?: string | null;
    alternativeName?: string | null;
    displayDescription?: string | null;
    internalNote?: string | null;
    uri?: string | null;
    data?: string | null;
    sortOrder?: number | null;
    delegateFeatureID?: number | null;
    parentFeatureID?: number | null;
    reportID?: number | null;
    resourceID?: number | null;
    resource?: string | null;
    resourceInternalFileName?: string | null;
    resourceIsStaged?: boolean | null;
    featureType?: FeatureType | null;
    featureMandateType?: FeatureMandateType | null;
    statusCode?: StatusCode | null;
    visibilityType?: VisibilityType | null;
    accessRoles?: AccessRole[] | null;
    businessRoles?: BusinessRole[] | null;
    teamIDs?: number[] | null;
}

 
     
         
