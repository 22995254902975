import { TransactionType } from '@alcon-db-models/Enums';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { BatchStep, FundAdjustment } from '../core/core.module';
import { BatchAllocationStepComponent } from './batch-allocation-step.component';
import { BatchSubjectWithAdjustment } from './territory-with-balance-formfield.component';

@Component({
  selector: 'acb-alcon-batch-allocation-step-dialog',
  template: `
    <kendo-dialog-titlebar class="alcon-titlebar-green" (close)="onCancel()">
      Batch Step {{ batchStep.isOrphan ? '(Orphaned Territory Pullback)' : '' }}
    </kendo-dialog-titlebar>
    <acb-alcon-batch-allocation-step
      #batchAllocationStep
      [calcBatchSubjects]="calcBatchSubjects"
      [batchStep]="batchStep"
      [fundAdjustments]="fundAdjustments"
      (close)="onClose()"
      (cancel)="onCancel()"
      >
    </acb-alcon-batch-allocation-step>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" type='button'><span class="k-icon k-i-cancel"></span>Cancel</button>
        <button type="button" kendoButton [primary]="true" [disabled]="!batchAllocationStep?.form?.valid" (click)="onSave()" ><span class="k-icon k-i-checkmark"></span>Add</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./batch-allocation-step-dialog.component.scss']
})
export class BatchAllocationStepDialogComponent implements OnInit {

  @ViewChild("batchAllocationStep") batchAllocationStep?: BatchAllocationStepComponent;

  @Input() batchStep: BatchStep = new BatchStep();
  @Input() fundAdjustments: FundAdjustment[] = [];
  @Input() calcBatchSubjects?: {(selectedStep: BatchStep, fromSubject:BatchSubjectWithAdjustment | undefined | null, toSubject:BatchSubjectWithAdjustment | undefined | null): void;};

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<BatchStep> = new EventEmitter();

  @HostListener('window:keyup.enter', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.batchAllocationStep?.form?.valid)
      this.onSave();
  }

  constructor() { }

  ngOnInit(): void {
  }

  public onClose() {
    this.close.emit();
  }

  public onCancel() {
    this.cancel.emit();
  }

  public onSave() {
    if (!this.batchAllocationStep?.form.valid)
      return;
    if (this.batchAllocationStep.batchStep.transactionType != TransactionType.Transfer && this.batchAllocationStep.batchStep.transactionType != TransactionType.Pullback) {
      this.batchAllocationStep.batchStep.fromTerritory = undefined;
      this.batchAllocationStep.batchStep.toTerritory = undefined;
    }
    this.save.emit({...this.batchAllocationStep.batchStep, ...{ fund: this.batchAllocationStep.fund }});
  }
}
