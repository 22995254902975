
 
 
export enum AccessRole {  
    Readonly = 1,  
    Broker = 2,  
    SalesRep = 3,  
    SalesManager = 4,  
    Vp = 5,  
    President = 6,  
    ClientAdmin = 7,  
    Psg1 = 8,  
    Psg2 = 9,  
    Psg3 = 10,  
    AcbAdmin = 11,  
    ClientRoles = 12,  
    HierarchyRoles = 13,  
    AcbRoles = 14,  
    AdminRoles = 15,  
    AllRoles = 16,  
    UnrestrictedClientRoles = 17,  
    Corporate = 18,  
    SuperAdmin = 255  
}  
 
export enum AccessRoleType {  
    Role = 1,  
    Container = 2  
}  
 
export enum AccountType {  
    Bucket = 1,  
    Fund = 2,  
    Customer = 3,  
    Territory = 4,  
    Commitment = 5,  
    Claim = 6  
}  
 
export enum ApprovalResultType {  
    Preliminary = 1,  
    Final = 2  
}  
 
export enum ApprovalRuleType {  
    Preapprove = 1,  
    PostApprove = 2,  
    Override = 3,  
    Supplemental = 4  
}  
 
export enum AttachmentRole {  
    Pop = 1,  
    Invoice = 2,  
    General = 3,  
    Other = 4  
}  
 
export enum AuditCodeGroup {  
    ParticipationRequest = 1,  
    ApprovalAuthorization = 2,  
    MissingNewspaperDocumentatio = 3,  
    MissingBroadcastDoc = 4,  
    MissingPrintDocumentation = 5,  
    MissingOtherDocumentation = 6,  
    RateDiscrepancy = 7,  
    LateClaimSubmission = 8,  
    InvalidMediaActivity = 9,  
    InvalidAdCost = 10,  
    SizeDiscrepancy = 11,  
    PerformanceError = 12,  
    DuplicateClaim = 13,  
    InvalidProduct = 14,  
    CommitmentRejection = 15,  
    Informational = 16,  
    MathError = 17,  
    Deduction = 18,  
    Exception = 19,  
    Warning = 20,  
    CommitmentCanceled = 21,  
    VoidClaim = 22,  
    Approve = 200,  
    Deny = 205,  
    ApprovePartialPay = 210,  
    ApproveReversal = 215  
}  
 
export enum AuditResultType {  
    Preliminary = 1,  
    Final = 2,  
    ReadyToExport = 3  
}  
 
export enum BusinessRole {  
    Am = 1,  
    Rsd = 2,  
    Asd = 3,  
    Undefined = 4,  
    Vp = 5,  
    President = 6,  
    Admin = 7  
}  
 
export enum ClaimClaimRelationshipType {  
    Reversal = 1  
}  
 
export enum ClaimSetRole {  
    Clone = 1,  
    Batch = 2,  
    Transaction = 3  
}  
 
export enum CommentType {  
    Description = 1,  
    Approval = 2,  
    Denial = 3,  
    Pop = 4,  
    Other = 5  
}  
 
export enum CountryCode {  
    Ca = 1,  
    Us = 2  
}  
 
export enum CustomerType {  
    Hierarchy = 1,  
    AlternativePayee = 2,  
    National = 3  
}  
 
export enum DashboardItemType {  
    BarGraph = 1,  
    ColumnChart = 2,  
    ColumnChartStacked = 3,  
    AreaChart = 4,  
    AreaChartStacked = 5,  
    LineGraph = 6,  
    LineGraphStacked = 7,  
    PieChart = 8,  
    DonutChart = 9,  
    DataGrid = 10,  
    ActionCenter = 11,  
    MessageCenter = 12,  
    ActivityStatement = 13  
}  
 
export enum DashboardType {  
    System = 1,  
    User = 2  
}  
 
export enum DepositType {  
    Original = 1,  
    Other = 2,  
    Incremental = 3,  
    BusinessGiveBack = 4  
}  
 
export enum DollarType {  
    UserClaimed = 1,  
    ApprovedAmount = 2,  
    AuditedValue = 3  
}  
 
export enum DraftType {  
    Commitment = 1,  
    Claim = 2  
}  
 
export enum EmailType {  
    Primary = 1,  
    Other = 2,  
    Payee = 3  
}  
 
export enum EventType {  
    Open = 1,  
    Change = 2,  
    Review = 3,  
    Update = 4,  
    Close = 5  
}  
 
export enum ExceptionType {  
    InvalidTerritory = 1,  
    InvalidCustomer = 2,  
    InvalidFund = 3,  
    CustomerTerritoryMismatch = 4  
}  
 
export enum ExpenseType {  
    Gtn = 1,  
    Tfc = 2  
}  
 
export enum FeatureMandateType {  
    System = 1,  
    User = 2,  
    External = 3  
}  
 
export enum FeatureType {  
    Route = 1,  
    Container = 2,  
    Resource = 3,  
    System = 4  
}  
 
export enum FundGroupType {  
    Private = 1,  
    National = 2,  
    Regional = 3,  
    Incentive = 4  
}  
 
export enum FundingDocumentType {  
    Check = 1,  
    CreditMemo = 2,  
    Deduction = 3,  
    AuditNotice = 4,  
    Ach = 5,  
    Internal = 6  
}  
 
export enum LedgerType {  
    BudgetAdjustment = 1,  
    CommitmentTransfer = 2,  
    ClaimPayment = 3  
}  
 
export enum LocationType {  
    Primary = 1,  
    Other = 2  
}  
 
export enum PayType {  
    Check = 1,  
    CreditMemo = 2,  
    Internal = 3,  
    Ach = 4,  
    Deduction = 5  
}  
 
export enum PermissionType {  
    Grant = 1,  
    Deny = 2  
}  
 
export enum PresentationType {  
    Chart = 1,  
    Grid = 2,  
    SlowGrid = 3,  
    ActionCenter = 4,  
    MessageCenter = 5,  
    ActivityStatement = 6  
}  
 
export enum ProductType {  
    Clc = 1,  
    Deoh = 2  
}  
 
export enum ReferenceNumberType {  
    CopyApprovalNumber = 1,  
    ControlNumber = 2,  
    ExternalId = 3,  
    RelatedClaim = 4,  
    InvoiceNumber = 5  
}  
 
export enum ResourceType {  
    Bmp = 1,  
    Csv = 2,  
    Doc = 3,  
    Docx = 4,  
    Gif = 5,  
    Html = 6,  
    Jpg = 7,  
    Png = 8,  
    Pdf = 9,  
    Rtf = 10,  
    Svg = 11,  
    Tif = 12,  
    Txt = 13,  
    Xls = 14,  
    Xlsx = 15,  
    Xml = 16,  
    Zip = 17  
}  
 
export enum SizeType {  
    Narrow = 1,  
    Wide = 2,  
    ExtraWide = 3,  
    ExtraWideWithDynamicHeight = 4,  
    Tiny = 5,  
    ExtraWideAndTall = 6  
}  
 
export enum SourceType {  
    Commitment = 1,  
    Claim = 2,  
    Audit = 3  
}  
 
export enum SpecialClaimType {  
    Reversal = 1  
}  
 
export enum SqlErrorMessageType {  
    InvalidParameter = 1,  
    NotFound = 2,  
    BusinessRuleViloation = 101,  
    InsufficientFunds = 102,  
    IdentityRequired = 201,  
    NotAuthorized = 202  
}  
 
export enum StateProvinceCode {  
    Alaska = 1,  
    Alabama = 2,  
    Arkansas = 3,  
    Arizona = 4,  
    California = 5,  
    Colorado = 6,  
    Connecticut = 7,  
    DistrictOfColumbia = 8,  
    Delaware = 9,  
    Florida = 10,  
    Georgia = 11,  
    Hawaii = 12,  
    Iowa = 13,  
    Idaho = 14,  
    Illinois = 15,  
    Indiana = 16,  
    Kansas = 17,  
    Kentucky = 18,  
    Louisiana = 19,  
    Massachusetts = 20,  
    Maryland = 21,  
    Maine = 22,  
    Michigan = 23,  
    Minnesota = 24,  
    Missouri = 25,  
    Mississippi = 26,  
    Montana = 27,  
    NorthCarolina = 28,  
    NorthDakota = 29,  
    Nebraska = 30,  
    NewHampshire = 31,  
    NewJersey = 32,  
    NewMexico = 33,  
    Nevada = 34,  
    NewYork = 35,  
    Ohio = 36,  
    Oklahoma = 37,  
    Oregon = 38,  
    Pennsylvania = 39,  
    PuertoRico = 40,  
    RhodeIsland = 41,  
    SouthCarolina = 42,  
    SouthDakota = 43,  
    Tennessee = 44,  
    Texas = 45,  
    Utah = 46,  
    Virginia = 47,  
    Vermont = 48,  
    Washington = 49,  
    Wisconsin = 50,  
    WestVirginia = 51,  
    Wyoming = 52,  
    Alberta = 53,  
    BritishColumbia = 54,  
    Manitoba = 55,  
    NewBrunswick = 56,  
    NewfoundlandAndLabrador = 57,  
    NovaScotia = 58,  
    Ontario = 59,  
    PrinceEdwardIsland = 61,  
    Quebec = 62,  
    Saskatchewan = 63,  
    NorthwestTerritories = 64,  
    Nunavut = 65,  
    Yukon = 66  
}  
 
export enum StatusCode {  
    Approved = 1,  
    Denied = 2,  
    ClaimPayment = 3,  
    Active = 4,  
    Inactive = 5,  
    Processing = 6,  
    Processed = 7,  
    Requested = 8,  
    Canceled = 9,  
    Expired = 10,  
    Inprocess = 11,  
    Void = 12,  
    Paid = 13,  
    Partialpaid = 14,  
    Pending = 15,  
    Submitted = 16,  
    Checked = 17,  
    Audited = 18,  
    Closed = 19,  
    Draft = 20,  
    SupApproval = 21,  
    PrintedByUser = 22,  
    Preliminary = 23,  
    ArchiveOnly = 24,  
    Failed = 25,  
    PartialApproved = 25  
}  
 
export enum StatusCodeGroup {  
    Claim = 1,  
    Commitment = 2,  
    General = 3,  
    Review = 4,  
    Active = 5  
}  
 
export enum TemplateType {  
    NewUserEmail = 1,  
    ResetPasswordEmail = 2,  
    AchNotification = 3,  
    CommitmentApproved = 4,  
    CommitmentApprovalNotification = 5,  
    CommitmentDenied = 6,  
    CommitmentCanceled = 7,  
    PasswordResetVerification = 8,  
    ActionableCommitmentApprovalNotification = 9,  
    ActionableCommitmentApprovalNotificationLine = 10,  
    SingleActionableCommitmentApprovalNotification = 11,  
    ClaimsDenied = 12,  
    ClaimsDeniedLine = 13,  
    ClaimsPending = 14,  
    ClaimsPendingLine = 15  
}  
 
export enum TransactionType {  
    Deposit = 1,  
    Reversal = 2,  
    Transfer = 3,  
    Pullback = 4,  
    CommitmentTransfer = 5,  
    CommitmentPullback = 6,  
    ClaimPayment = 7,  
    ClaimReversal = 8  
}  
 
export enum ValidationRuleType {  
    NumberOfDaysFromMonthEndActivityStartProhibited = 1  
}  
 
export enum VisibilityType {  
    Visible = 1,  
    Hidden = 2,  
    Conditional = 3  
}  
     
         
