import { HierarchyCompareModel } from '@alcon-db-models/HierarchyCompareModel';
import { Component, Input, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-treelist';
import { Observable } from 'rxjs';

@Component({
  selector: 'acb-alcon-hierarchy-compare',
  templateUrl: './hierarchy-compare.component.html',
  styleUrls: ['./hierarchy-compare.component.scss']
})
export class HierarchyCompareComponent implements OnInit {

  @Input() hierarchyCompares?: HierarchyCompareModel[] | null;
  @Input() compareGroupTitle1: string = "1";
  @Input() compareGroupTitle2: string = "2"
  @Input() showFlat: boolean = false;

  constructor() { }

  public getRowClass(context: RowClassArgs) {

    let className = "acb-compare-row";
    let hc: HierarchyCompareModel = context.dataItem as HierarchyCompareModel;
    if (hc) {

      if (!hc.isTerritory && !hc.isTerritory2) {
        className += " acb-compare-team-row"
      } else {
        if (!hc.territoryCode && hc.hiD2)
          className += " acb-compare-new-row"
        else if (hc.hid && !hc.hiD2)
          className += " acb-compare-abandoned-row"
        else if (hc.hid != hc.hiD2)
          className += " acb-compare-mismatch-ids-row"
        else if (hc.salesRepPersonID != hc.salesRepPersonID2 || hc.territory != hc.territory2)
          className += " acb-compare-change-row"
      }
    }
    return className;
  }

  public getName(hc: HierarchyCompareModel) {
    if (hc.isTerritory || hc.isTerritory2) {
      return hc.name == hc.name2 ? hc.name : (hc.name ?? "?") + " ⇨ " + (hc.name2 ?? "?");
    }
    return hc.name ?? hc.name2;
  }

  ngOnInit(): void {
  }

  // public allData(): Observable<ExcelExportData> {



  //   if(this.bindingDirective) {
  //     let request = this.bindingDirective.getSearchRequest(true) ?? {};
  //     request.isReview = this.searchFlavor === 'review';
  //     return this.commitmentSearchService.search(this.bindingDirective.getSearchRequest(true) ?? null)
  //       .pipe(
  //         first(),
  //         map(x => {
  //           let data = x?.value ?? undefined;
  //           data = x?.value?.map(y => JsonUtilities.convertDatesAndCopy(y));
  //           return ({ data: data });
  //         }));
  //   }
  //   return of({});
  // }

}
