import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acb-alcon-view-error-result',
  template: `
    <kendo-dialog-titlebar (close)="onClose()">
      {{title}}
    </kendo-dialog-titlebar>
    <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-07">
      <p *ngFor='let msg of getMessages()' >
      {{msg}}
      </p>
    </acb-alcon-section-with-legend>
    <kendo-dialog-actions>
      <button kendoButton [primary]="true" (click)="onClose()" >OK</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./view-error-result.component.scss']
})
export class ViewErrorResultComponent implements OnInit {

  @Input() message?: string | string[] | null = "Unknown Error";
  @Input() title?: string | null = "Error";
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onClose() {
    this.close.emit(null);
  }

  public getMessages() {
    return Array.from(this.message ?? []);
  }

}
