import { createAction } from '@ngrx/store';

export enum ActionTypes {
  rootStoreIntilized = '[Root Event] rootStoreIntilized',
  NoOp = '[Root Event] No Op'
}

export const rootStoreIntilized = createAction(ActionTypes.rootStoreIntilized);

export const noopAction = createAction(ActionTypes.NoOp);
