import { PersonWithRolesModel } from "@alcon-db-models/PersonWithRolesModel";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { selectAccessPerson, selectEnvironmentRules } from "@app-store/app-session/app-session.selectors";
import { Store } from "@ngrx/store";
import { ApplicationService } from "@services/application.service";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  private static _lastErrorTimestamp: number = 0;

  constructor(private injector: Injector) { }

  handleError(error: any) {

    let isTestMode: boolean = false;
    let doShowUnexpectedErrors: boolean = false;
    try {

      let accessPerson:PersonWithRolesModel | null = <PersonWithRolesModel><unknown>null;

      try {
        const store = this.injector.get(Store);
        store.select(selectAccessPerson).pipe(first()).subscribe(x => accessPerson = x);
        store.select(selectEnvironmentRules).pipe(first()).subscribe(x => {
          isTestMode = x.IsTestMode ?? false;
          doShowUnexpectedErrors = x.DoShowUnexpectedErrors ?? false;
        });
        if (isTestMode) console.debug("GlobalErrorHandler: ", error)
      } catch(err) {}

      const errorToLog =
      {
        applicationUserID: accessPerson?.applicationUserID,
        source: 'SPA',
        context: (error?.name ? error.name + " : " : "") + (error?.requestData?.url ?? error?.url ?? this.injector.get(Router).url),
        message: error?.Error ?? error?.message ?? error?.description ?? ((typeof error === 'string') ? error : 'script error'),
        info1: window.navigator.userAgent,
        info2: error?.stack ?? JSON.stringify(error)
      }

      this.injector.get(ApplicationService).logError(errorToLog).then(x => {
        if (!doShowUnexpectedErrors) return;
        let now = new Date().getTime();
        if (!GlobalErrorHandler._lastErrorTimestamp || (now - GlobalErrorHandler._lastErrorTimestamp) > 1000) {
          GlobalErrorHandler._lastErrorTimestamp = now;
          window.alert(`Unexpected Error (${x})`);
          now = new Date().getTime();
        }
        GlobalErrorHandler._lastErrorTimestamp = now;
      });

    } catch(err) {
      if (isTestMode) console.debug("GlobalErrorHandler: Exception in GlobalErrorHandler", err)
    }
  }
}
