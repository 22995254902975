import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'acb-alcon-dashboard-multiselect-dropdown',
  template: `
    <kendo-multiselect
      *ngIf="formControl"
      [ngClass]="{ 'acb-slim-dropdown-only-multiselect': true, 'acb-selected': this.selection.length }"
      [data]="data"
      [kendoMultiSelectSummaryTag]="0"
      [valuePrimitive]="valuePrimitive"
      [clearButton]="false"
      [autoClose]="true"
      [placeholder]="placeholder"
      [textField]= "$any(textField)"
      [valueField]= "$any(valueField)"
      [formControl]="formControl"
      [popupSettings]= "{ width: 'auto' }"
      fillMode="none"
      (valueChange)="this.valueChanged.emit(null)"
    >
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <input type="checkbox" class="k-checkbox" [checked]="isItemSelected(dataItem)">
        <label class="k-checkbox-label">{{ valuePrimitive ? dataItem : textField ? dataItem[textField] : '' }}</label>
      </ng-template>
      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
        <span class="k-icon k-i-arrow-s"></span>
        <span class="acb-select-group-value">{{ valuePrimitive ? dataItems[0] : textField ? dataItems[0][textField] : '' }}</span>
        <em class="acb-select-group-count" *ngIf="dataItems.length > 1"> (+{{ dataItems.length - 1 }} more)</em>
      </ng-template>
    </kendo-multiselect>
  `,
  styleUrls: ['./dashboard-multiselect-dropdown.component.scss']
})
export class DashboardMultiselectDropdownComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() textField?: string = undefined;
  @Input() valueField?: string = undefined;
  @Input() valuePrimitive: boolean = false;
  @Input() placeholder: string = "All...";

  @Input() set controlName(value: string) {
    this.formControl = this.formGroupDirective.form.get(value) as UntypedFormControl
  }

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  formControl?: UntypedFormControl;
  get selection() { return (this.formControl?.value as []) ?? [] };

  constructor(
    private formGroupDirective: FormGroupDirective
  ) {
  }

  ngOnInit(): void {
  }

  public isItemSelected(dataItem: any): boolean {
    return this.selection.some(x => x == dataItem);
  }
}
