
<div class="acb-dashboard-toolbar">
    <div class="acb-dashboard-chooser-section">
        <kendo-dropdownlist
            #dashboardList
            class="acb-dashboard-list"
            fillMode="flat"
            [data]="dashboardsForDropdown$ | async"
            [textField]="'displayName'"
            [valueField]="'dashboardID'"            
            fxFlex="12em"
            (valueChange)="onDashboardValueChanged($event)"
            >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div class="acb-dashboard-list-item">
                    <span class="acb-dashboard-list-name" style="margin: 0 2px 0 0">{{ dataItem?.displayName }}</span><kendo-icon name="lock" style="font-size: 11px; opacity:.5; margin: 0 0 0 1px; vertical-align: top;" *ngIf="dataItem?.dashboardType != userDashboardType"></kendo-icon><span class="acb-dashboard-list-star" style="font-size: 12px; opacity:.5; margin: 0 0 0 1px;  vertical-align: top;" *ngIf="dataItem?.isDirty">*</span>
                    <!-- <span class="acb-dashboard-list-name">{{ dataItem?.displayName }}</span><span class="acb-dashboard-list-star" *ngIf="dataItem?.isDirty">*</span> -->
                </div>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <div class="acb-dashboard-list-value" [ngClass]="{ 'acb-dashboard-list-value-locked': dataItem?.dashboardType != userDashboardType}" >
                    <span class="acb-dashboard-list-name">{{ dataItem?.displayName }}</span><kendo-icon name="lock" *ngIf="dataItem?.dashboardType != userDashboardType"></kendo-icon><span class="acb-dashboard-list-star" *ngIf="dataItem?.isDirty">*</span>
                </div>
            </ng-template>            
        </kendo-dropdownlist>
        <!-- <kendo-button [icon]="isLocked ? 'lock' : 'unlock'" fillMode="flat" (click)="onLockClick()" *ngIf="currentDashboard"></kendo-button> -->
        <kendo-dropdownbutton icon="menu" fillMode="flat" [data]="dashboardHamburgerData"  textField="actionName" [disabled]="isImpersonating" >
        </kendo-dropdownbutton>
        <kendo-dropdownbutton icon="table-insert" fillMode="flat" [data]="dashboardAddWidgetData"  textField="actionName" [disabled]="isImpersonating || currentDashboard?.dashboardType != userDashboardType" class="acb-dashboard-add-widget-button">
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                <div [class]="'acb-dashboard-dropdown-item ' + dataItem.class">
                    <kendo-icon [name]="dataItem.icon"></kendo-icon>
                    <span>{{ dataItem.actionName }}</span>
                </div>
              </ng-template>            
        </kendo-dropdownbutton>
        <kendo-button [icon]="'reset'" fillMode="flat" (click)="onResetClick()" *ngIf="currentDashboard" [disabled]="isImpersonating || !currentDashboard.isDirty"></kendo-button>
    </div>
    <div class="acb-dashboard-filters" [fxHide]="!getHasDashboard()">
        <div class="acb-dashboard-filter-summaries" *ngIf="dashboardFilterUIMode == 'view'" (click)="onEditFiltersClick()">
            <div class="acb-dashboard-filter-summary" *ngIf="getAreFundYearsAllowed() && getFormYearList() as names">
                <div class="acb-dashboard-filter-label">Years:</div>
                <div class="acb-dashboard-filter-value">{{ names }}</div>
            </div>
            <div class="acb-dashboard-filter-summary" *ngIf="getAreFundsAllowed() && getFormFundNameList() as names">
                <div class="acb-dashboard-filter-label">Funds:</div>
                <div class="acb-dashboard-filter-value">{{ names.label }} <span class="acb-name-sub-info">{{ names.info }}</span></div>
            </div>
            <div class="acb-dashboard-filter-summary" *ngIf="getAreTerritoriesAllowed() && getFormTerritoryNameList() as names">
                <div class="acb-dashboard-filter-label">Territories:</div>
                <div class="acb-dashboard-filter-value">{{ names.label }} <span class="acb-name-sub-info">{{ names.info }}</span></div>
            </div>
        </div>
        <form [formGroup]="filterForm" class="acb-dashboard-filter-controls" [ngClass]="{'acb-hidden': dashboardFilterUIMode != 'edit'}" >
            <ng-container *ngIf="(this.fundsYears$ | async) as fundsYearsData">
                <acb-alcon-dashboard-multiselect-dropdown
                    *ngIf="getAreFundYearsAllowed()"
                    [data] = "fundsYearsData"
                    [controlName] = "'fundYears'"
                    [placeholder]="'Year...'"
                    [valuePrimitive]="true"
                    fxFlex="12em"
                >
                </acb-alcon-dashboard-multiselect-dropdown>
            </ng-container>
            <ng-container *ngIf="(this.fundsForDropdown$ | async) as fundsForDropdownData">
                <acb-alcon-dashboard-multiselect-dropdown
                    *ngIf="getAreFundsAllowed()"
                    [data] = "fundsForDropdownData"
                    [controlName] = "'funds'"
                    [textField]="'displayName'"
                    [valueField]="'fundID'"
                    [placeholder]="'Fund...'"
                    fxFlex="18em"
                >
                </acb-alcon-dashboard-multiselect-dropdown>
            </ng-container>
            <ng-container *ngIf="(this.territoriesForDropdown$ | async) as territoriesForDropdownData">                
                <acb-alcon-dashboard-multiselect-dropdown
                    *ngIf="getAreTerritoriesAllowed()"
                    [data] = "territoriesForDropdownData"
                    [controlName] = "'territories'"
                    [textField]="'territorySuggestedName'"
                    [valueField]="'territoryID'"
                    [placeholder]="'Territory...'"
                    fxFlex="18em"
                >
                </acb-alcon-dashboard-multiselect-dropdown>
            </ng-container>
        </form>
        <kendo-button *ngIf="dashboardFilterUIMode == 'view'" icon="filter" fillMode="flat" (click)="onEditFiltersClick()"  [disabled]="isImpersonating || isLocked"></kendo-button>
        <kendo-buttongroup *ngIf="dashboardFilterUIMode == 'edit'">
            <kendo-button class="acb-dashboard-filter-commit-button" icon="checkmark" fillMode="flat" (click)="onEditFiltersSaveClick()"></kendo-button>
            <kendo-button class="acb-dashboard-filter-close-button" icon="cancel" fillMode="flat" (click)="onEditFiltersCancelClick()"></kendo-button>
            <!-- <kendo-button icon="filter-clear" fillMode="flat" (click)="onEditFiltersClearClick()"></kendo-button> -->
        </kendo-buttongroup>
    </div>

</div>

<gridstack #masterDashboard class="acb-master-dashboard" [options]="masterDashboardGridOptions" (changeCB)="onMasterDashboardGridChange($event)">
</gridstack>
