import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'acb-alcon-edit-claim-details-doctor',
  template: `
    <ng-container *ngIf="form; let form" [formGroup]="form">
      <div  fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
        <div class="acb-doctor-name" fxFlex="8em">
          <kendo-label text="Name"></kendo-label>
          <kendo-textbox formControlName="doctor">
          </kendo-textbox>
        </div>
        <div class="acb-doctor-code" fxFlex="8em">
          <kendo-label text="APIN"></kendo-label>
          <kendo-textbox formControlName="doctorCode">
          </kendo-textbox>
        </div>
        <div style="padding-top: 1.25em;">
          <button kendoButton [look]="'clear'" [icon]="'delete'" (click)="onDeleteClicked()" [disabled]="form.disabled">
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./edit-claim-details-doctor.component.scss']
})
export class EditClaimDetailsDoctorComponent implements OnInit {

  @Input() form?: UntypedFormGroup;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public onDeleteClicked() {
    this.delete.emit(this.form);
  }

}
