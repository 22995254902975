import { EventEmitter, Injectable, Output } from "@angular/core";


@Injectable()
export class WizardFeatureService {
  constructor() {
  }

  @Output() clear: EventEmitter<boolean> = new EventEmitter();

  public clearSelection() {
    this.clear.emit(true);
  }
}

