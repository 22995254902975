



export class RefreshRequestModel  {
    refreshToken?: string | null;
    doPersistAuthorization?: boolean | null;
}

 
     
         
