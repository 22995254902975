<div *ngIf="featuresForMenu$ | async as featuresForMenuData">
    <kendo-menu #mainMenu
      class="acb-main-menu"
      [kendoMenuFlatBinding]="featuresForMenuData"
      idField="featureID"
      parentIdField = "parentFeatureID"
      textField="menuText"
      (select)="onMenuItemSelect($event)"
    >
      <ng-template kendoMenuItemTemplate let-item="item">
        <span [class]="getMenuItemClass(item)">
          <span class="acb-menu-item-icon"></span> {{ item.text }}
        </span>
      </ng-template>
    </kendo-menu>

    <button kendoButton 
      fillMode="clear" 
      class="acb-hamburger-menu-button"
      [toggleable]="true"
      (selectedChange)="onHamburgerMenuButtonSelectedChange($event)"
      #hamburgerMenuButton
      >
      <kendo-icon name="menu">
      </kendo-icon>
    </button>

    <div #hamburgerMenu class="acb-hamburger-menu" [ngClass]="{'acb-visible':doShowHumburgerMenu}" >
      <ul *ngFor="let feature of getTopLevel(featuresForMenuData)" class="acb-hamburger-menu-item acb-hamburger-menu-top-item">
        <ng-container *ngTemplateOutlet="menuItemListTemplate; context:{ features: [feature] }">
        </ng-container>
      </ul>
    </div>

    <ng-template #menuItemListTemplate let-features="features">
      <li *ngFor="let feature of features"  [ngClass]="{'acb-hamburger-menu-folder': feature.featureType == featureType.Container }">
        <span class="acb-hamburger-menu-item-link" *ngIf="feature.featureType == featureType.Route || feature.featureType == featureType.Resource; else nonLinkTemplate" (click)="navigateFeature(feature)">
            {{feature.displayName}}
        </span>
        <ng-template #nonLinkTemplate>
          <span>
              {{feature.displayName}}
          </span>
        </ng-template>
        <ul class="acb-hamburger-menu-item">
          <ng-container *ngTemplateOutlet="menuItemListTemplate; context:{ features: getChildren(feature.featureID, featuresForMenuData) }">
          </ng-container>
        </ul>
      </li>
    </ng-template>

<!-- 


    <div class="acb-hamburger-menu" [ngClass]="{'acb-visible':doShowHumburgerMenu}" >
        <ul class="acb-hamburger-menu-outer">
            <ng-container *ngTemplateOutlet="menuItemListTemplate; context:{ features: getTopLevel(featuresForMenuData) }">
            </ng-container>
        </ul>
    </div>

    <ng-template #menuItemListTemplate let-features="features">
        <li *ngFor="let feature of features" 
            [ngClass]="{'acb-hamburger-menu-top': !feature.parentFeatureID, 'acb-hamburger-menu-folder': feature.featureType == featureType.Container }"
            >
            <button *ngIf="feature.featureType == featureType.Route || feature.featureType == featureType.Resource; else nonLinkTemplate" (click)="navigateFeature(feature)">
                {{feature.displayName}}
            </button>
            <ng-template #nonLinkTemplate>
                <span>
                    {{feature.displayName}}
                </span>
            </ng-template>
            
            <ul class="acb-hamburger-menu-item">
                <ng-container *ngTemplateOutlet="menuItemListTemplate; context:{ features: getChildren(feature.featureID, featuresForMenuData) }">
                </ng-container>
            </ul>
        </li>
    </ng-template> -->

  </div>