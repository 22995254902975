import { ClaimDetailStatusWithLedgerModel } from '@alcon-db-models/ClaimDetailStatusWithLedgerModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-view-claim-review',
  templateUrl: './view-claim-review.component.html',
  styleUrls: ['./view-claim-review.component.scss']
})
export class ViewClaimReviewComponent implements OnInit {

  @Input() public claim?: ClaimWithDetailsModel | null;


  constructor() { }

  //TODO: Refactor
  public getLastHistoryAuditCodes() {
    return this.claim?.history?.length ? this.claim.history[this.claim.history.length - 1].auditCodes : null;
  }

  //TODO: Refactor
  public getLastHistoryComment() {
    return this.claim?.history?.length ? this.claim.history[this.claim.history.length - 1].eventComment : null;
  }


  ngOnInit(): void {
  }

}
