import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppSessionModule } from './app-session';
import { AppPersistedSettingsModule } from './app-persisted-settings';
import { UserPreferencesModule } from './user-preferences';

@NgModule({
  imports: [
    CommonModule,
    AppSessionModule,
    AppPersistedSettingsModule,
    UserPreferencesModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  declarations: []
})
export class RootModule {}
