import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'acb-alcon-value-with-percent',
  template: `
  <div
    *ngIf="form"
    [formGroup]="form"
    class="acb-amount-percent-wrapper"
    fxLayout="row"
    fxLayoutGap=".5em"
    >
    <div class="acb-amount" fxFlex="calc(60% - .5em)">
      <kendo-numerictextbox
        [decimals]="valueDecimals"
        [format]="valueNumericFormat"
        [min]="minValue!"
        [max]="maxValue!"
        [value]="0"
        [spinners]="false"
        [formControlName]="valueFormControlName"
        [autoCorrect]="valueAutoCorrect"
        [step]="100"
        (valueChange)="onAmountValueChange($event)"
        >
      </kendo-numerictextbox>
    </div>
    <div class="acb-percent" fxFlex="40%">
      <kendo-numerictextbox
        [decimals]="percentDecimals"
        [format]="percentFormat"
        [step]="1"
        [min]="0"
        [max]="100"
        [value]="0"
        [spinners]="false"
        [formControlName]="percentFormControlName"
        [autoCorrect]="percentAutoCorrect"
        (valueChange)="onPercentValueChange($event)"
        >
      </kendo-numerictextbox>
    </div>
  </div>
  `,
  styleUrls: ['./value-with-percent.component.scss']
})
export class ValueWithPercentComponent implements OnInit {

  @Input() maxValue?: number;
  @Input() minValue?: number = 0;
  @Input() form?:UntypedFormGroup;
  @Input() valueFormControlName = 'amount';
  @Input() percentFormControlName = 'percent';
  @Input() contextAmount = 0;
  @Input() valueNumericFormat = "c";
  @Input() percentFormat = "##0.0'%'";
  @Input() valueDecimals = 2;
  @Input() percentDecimals = 3;
  @Input() valueAutoCorrect = true;
  @Input() percentAutoCorrect = true;

  constructor() { }

  public onPercentValueChange(percent:number) {
    if (!this?.form?.controls) return;
    const controlValue = percent ? Math.floor((this.contextAmount * percent) + Number.EPSILON) / 100 : 0;
    this.form.controls[this.valueFormControlName].setValue(controlValue);
  }

  public onAmountValueChange(amount:number) {
    if (!this?.form?.controls) return;
    const controlValue = amount && this.contextAmount ? Math.round(((amount / this.contextAmount) + Number.EPSILON) * 100) : 0;
    this.form.controls[this.percentFormControlName].setValue(controlValue);
  }

  ngOnInit(): void {
  }
}
