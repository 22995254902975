import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CommitmentEditService } from '@services/commitment-edit.service';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditCommitmentDetailsComponent } from './edit-commitment-details.component';

@Component({
  selector: 'acb-alcon-edit-commitment-details-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-edit-commitment-details-titlebar" (close)="onClose()">
      Edit Details
    </kendo-dialog-titlebar>
    <ng-container #editCommitmentDetailsContainer></ng-container>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onApply()" [disabled]="!isFormValid" ><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-commitment-details-dialog.component.scss'],
})
export class EditCommitmentDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('editCommitmentDetailsContainer', { read:ViewContainerRef }) editCommitmentDetailsContainer?: ViewContainerRef;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() apply: EventEmitter<any> = new EventEmitter();

  public destroy$: Subject<void> = new Subject<void>();

  private _valid: boolean = true;
  public get isFormValid():boolean {
    return this._valid;
  }

  private _commitmentEditService?: CommitmentEditService;
  public set commitmentEditService(value:CommitmentEditService) {
    this._commitmentEditService = value;
    this._commitmentEditService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._valid = x.details;
    })
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetectionRef : ChangeDetectorRef
    ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Need to pass service to edit details component, provider context is lost going through window service...
    // TODO: Clean up by moving to feature NGRX?
    if (this.editCommitmentDetailsContainer && this._commitmentEditService) {
      const factory = this.resolver.resolveComponentFactory(EditCommitmentDetailsComponent);

      const injector: Injector = Injector.create({ providers: [
        {
          provide: CommitmentEditService, useValue: this._commitmentEditService
        },
        {
          provide: CommitmentFormBaseService, useExisting: CommitmentEditService
        }
      ]});
      injector.get(CommitmentEditService);

      const ref: ComponentRef<EditCommitmentDetailsComponent> =  this.editCommitmentDetailsContainer.createComponent(factory, undefined, injector);
      ref.instance.doShowLegend = false;

      this.changeDetectionRef.detectChanges();
    }
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApply() {
    if (this.isFormValid)
      this.apply.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
