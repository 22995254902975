import { Component, Input, Output, EventEmitter, ViewEncapsulation, HostBinding } from '@angular/core';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'acb-alcon-column-chooser',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [ 'column-chooser.component.scss'],
    template: `
      <div class="acb-column-chooser-inner-wrapper" fxLayout="row wrap">
        <div  class="acb-column-chooser-column" fxLayout="row" fxFlex="50%" *ngFor='let col of columns'>
          <input #showHideColumn type="checkbox" kendoCheckBox [ngModel]="!col.hidden" (ngModelChange)="col.hidden = !$event">
          <kendo-label [for]="showHideColumn">{{ col.title || col.field}}</kendo-label>
        </div>
      </div>
    `
})
export class ColumnChooserComponent {
    @Input() public columns: ColumnSettings[] = [];
    @Output() public changes: EventEmitter<State> = new EventEmitter<State>();

    @HostBinding("class.host")
    public get hostClass() {
      return true;
    }

    public toggle(column: ColumnSettings): boolean {
        column.hidden = !column.hidden;
        return false;
    }
}

export interface ColumnSettings {
  field: string;
  title?: string;
  type?: string;
  hidden?: boolean;
}
