import { SearchServiceBase } from './search-service-base'
import { Injectable } from '@angular/core';
import { CommitmentApprovalRuleSearchRequestModel } from '@alcon-db-models/CommitmentApprovalRuleSearchRequestModel';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUxService } from './app-ux.service';

@Injectable({
  providedIn: 'root'
})
export class CommitmentApprovalRuleSearchService extends SearchServiceBase<CommitmentApprovalRuleSearchRequestModel> {
  constructor(protected http: HttpClient, protected router: Router, protected store: Store, appUxService: AppUxService) {
    super(http, router, store, 'CommitmentApprovalRuleSearch', appUxService);
  }
}
