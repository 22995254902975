import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { CustomerSelectSearchBindingDirective } from 'src/app/directives/customer-select-search-binding.directive';
import { SelectComponentBase } from '../select.component.base';

@Component({
  selector: 'acb-alcon-select-customer',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" (close)="onClose()" *ngIf="this.windowMode == 'dialog'">
      Select Customer
    </kendo-dialog-titlebar>
    <div fxLayout="column" style="height: 100%;">
      <form [formGroup]="searchForm" style="width: 100%;" class="k-form">
        <acb-alcon-section-with-legend [doShowLegend]="windowMode == 'page'" class="acb-section-01" [sectionTitle]="'Select Customer'">
        <p featureText>
      </p>

          <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start start">
            <kendo-formfield fxFlex >
              <kendo-label [for]="customerCodeOrName" text="Number or Name"></kendo-label>
              <input formControlName="customerCodeOrName" kendoTextBox #customerCodeOrName autocomplete="off" />
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
            <div fxFlex='0 0 6em' style="text-align: right; padding: 36px 0 0 0;" class="acb-search-button-wrapper">
              <button type="submit" kendoButton (click)="onSearch()" style="width:100%">Search</button>
            </div>
          </div>
        </acb-alcon-section-with-legend>
      </form>

      <kendo-grid
        customerSelectSearchBinding
        [doInitResults]= "true"
        [selectable] = "{
          enabled: true,
          mode: 'single'
        }"
        [pageSize]="windowMode == 'dialog' ? windowHeigth > 800 ? 10 : 5 : 5"
        [pageable]="{
          buttonCount: 3,
          pageSizes: true,
          responsive: false,
          info: false
        }"
        [sortable]="true"
        [resizable]="true"
        (beforeBind)="updateFilter()"
        kendoGridSelectBy="customerID"
        [(selectedKeys)] = "selectedKeys"
        (selectionChange)="onSelectionChange($event)"
        #resultsGrid
        fxFlex
        [ngClass]="{
          'acb-grid-has-selection': !!getSelectedItem(),
          'acb-grid-window-mode-page': windowMode == 'page',
          'acb-grid-window-mode-dialog': windowMode == 'dialog',
          'acb-grid-page-size-5': resultsGrid.pageSize == 5,
          'acb-grid-page-size-10': resultsGrid.pageSize == 10,
          'acb-grid-page-size-15': resultsGrid.pageSize == 15
        }"
        >
        <kendo-grid-command-column [width]="32" [resizable]=false>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Number" field="customerCode" [width]="110">
        </kendo-grid-column>
        <kendo-grid-column title="Name" field="customer" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column title="Address 1" field="locationLine1" [width]="180" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Address 2" field="locationLine2" [width]="120" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="City" field="city" [width]="120" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="State" field="stateProvinceCode" [width]="70" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Zip" field="postalCodeValue" [width]="80" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-messages
          [pagerItemsPerPage]="'per page'"
          [pagerItems]="'funds'"
          [pagerOf]="'of'"
          [pagerPage]="''"
          >
        </kendo-grid-messages>
        <ng-template #pagerTemplate kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage" let-total="total" let-pageSize="pageSize">
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[5,10,15]" fxHide.xs="true"></kendo-pager-page-sizes>
            <div fxFlex></div>
            <div style="font-size: smaller">{{total}} customers</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
    <kendo-dialog-actions *ngIf="this.windowMode == 'dialog'">
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!isSelected" (click)="onSelect(getSelectedItem())" ><span class="k-icon k-i-checkmark"></span>Select</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent extends SelectComponentBase<CustomerSearchWithDefaultModel> implements OnInit {

  @ViewChild(CustomerSelectSearchBindingDirective) public set customerSelectSearchBinding(value:CustomerSelectSearchBindingDirective) {
    this.bindingDirective = value;
    this.bindingDirective.rebind();
  }

  get isSelected():boolean {
    return Boolean(this._selectedCustomer?.customerID);
  }

  private _customerID?: number | null;
  private _bindingDirectiveDataChangeSubscription?:Subscription
  @Input() public set customerID(value: number | null | undefined) {

    this._customerID = value;

    this._bindingDirectiveDataChangeSubscription?.unsubscribe();
    if (!value) return;
    this.selectedKeys[0] = value;
    this.searchForm.patchValue({ prependCustomerID: value });
    const data: CustomerSearchWithDefaultModel[] = (this.resultsGrid?.data as GridDataResult)?.data ?? [];
    let customer = data.find(x => x.customerID == value) ?? null;
    // make selection now if customer is naturally on first page
    if (customer) {
      this.doSelectionChange(customer);
    // else wait until data page is refreshed with prepend customer
    } else {
      this._bindingDirectiveDataChangeSubscription = this.bindingDirective?.dataChange.pipe(
        map((x:GridDataResult) => x?.data?.find((y:any) => y?.customerID == value) as CustomerSearchWithDefaultModel ?? null),
        filter(x => Boolean(x)),
        first()
      ).subscribe(x => this.doSelectionChange(x));
      super.onSearch();
    }
  };

  protected formFieldsToClearOnSelect:string[] = ['prependCustomerID'];
  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    customerCodeOrName: new UntypedFormControl(),
    prependCustomerID: new UntypedFormControl()
  });

  private _selectedCustomer:CustomerSearchWithDefaultModel | null = null;

  constructor(@Optional() wizardFeatureService?: WizardFeatureService) {
    super(wizardFeatureService);
  }

  ngOnInit(): void {
  }

  public getSelectedItem():CustomerSearchWithDefaultModel | null {
    return this._selectedCustomer;
  }

  protected doSelectionChange(customer: CustomerSearchWithDefaultModel | null) {
    if (customer?.customerID) this.selectedKeys[0] = customer?.customerID;
    this._selectedCustomer = customer;
    this.searchForm.patchValue({ prependCustomerID: this._selectedCustomer?.customerID ?? null });
    this.selectedChange.emit(this._selectedCustomer);
  }
}
