import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '@data-services/authentication.service';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs/operators';
import { AuthenticationGuard } from './authentication.guard';
import { AccessGuard } from './access.guard';

@Injectable({ providedIn: 'root' })
export class MasterGuard implements CanActivate {

  private _authenticationGuard: AuthenticationGuard;
  private _accessGuard: AccessGuard;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
  ) {
    this._authenticationGuard = new AuthenticationGuard(this.router, this.authenticationService, this.store);
    this._accessGuard = new AccessGuard(this.router, this.authenticationService, this.store);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var guards = (this._authenticationGuard.canActivate(next, state) as Observable<boolean>).pipe(first(), switchMap(x =>
      x ? (this._accessGuard.canActivate(next, state) as Observable<boolean>) : of(Boolean(false))
    ));

    return guards;
  }
}
