



export class TerritoryForDropdownModel  {
    territoryID?: number | null;
    code?: string | null;
    displayName?: string | null;
    territorySuggestedName?: string | null;
    primarySalesPersonID?: number | null;
    primarySalesPerson?: string | null;
    primaryBusinessRoleID?: number | null;
}

 
     
         
