import { ClaimDetailStatusWithLedgerModel } from '@alcon-db-models/ClaimDetailStatusWithLedgerModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { StatusCode } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Component, Input, OnInit } from '@angular/core';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-view-claim-review-history',
  template: `
    <label class='acb-alcon-display-item-label'>Status Tracking</label>
    <kendo-grid
      *ngIf="gridView$ | async as gridViewData"
      #statusTrackingGrid
      class="acb-display-grid"
      [data]= "gridViewData"
      [pageable]="false"
      [sortable]="false"
      [reorderable]="false"
      [selectable]="false"
      [resizable]="true"
      >
      <kendo-grid-column title="Date" field="eventDate" [width]="150" [style]="{'text-align': 'center'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.eventDate | date: 'M/d/yyyy H:mm:ss a' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Person" field="person" [autoSize]="true">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ !dataItem.status && dataItem.territory ? dataItem.person + ' ('+dataItem.territory+')' : dataItem.person }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Status" field="status" [width]="100">
      </kendo-grid-column>
      <kendo-grid-column title="Transaction $" field="transactionAmount" [width]="120" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.transactionAmount | currency }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Audit Code" field="auditCodes" [autoSize]="true">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [innerHTML]="dataItem.auditCodes"></div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Comment" field="eventComment" [autoSize]="true">
      </kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-Claim-review-history.component.scss']
})
export class ViewClaimReviewHistoryComponent implements OnInit {

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public statusCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StatusCode')));
  public auditResultTypes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'AuditResultType')));

  private _claim: ClaimWithDetailsModel | null | undefined = {};
  @Input() public set claim(value: ClaimWithDetailsModel | null | undefined) {
    this._claim = value;
    const history = value?.history?.map(x => {
      const y:any = {
        type: this.getType(x),
        status: this.getStatusCode(x),
        person: this.getPerson(x)
      };
      Object.assign(y, x);
      return y;
    }) ?? []
    this.history = history;
    this.loadData()
  };
  public get claim(): ClaimWithDetailsModel | null | undefined {
    return this._claim;
  }
  public history: any[] = [];
  public gridView$: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>({data:[], total: 0});
  public sort: SortDescriptor[] = [{
    field: 'ClaimDetailStatusID',
    dir: 'asc'
  }];

  private getStatusCode(model: ClaimDetailStatusWithLedgerModel): string | null {
    let statusCode: string | null = null;
    let statusCodeID: number | null = model?.statusCode ?? null;
    if (statusCodeID == StatusCode.Paid) statusCodeID = StatusCode.Approved;
    if (statusCodeID == StatusCode.Partialpaid) statusCodeID = StatusCode.PartialApproved;
    if (statusCodeID) {
      this.statusCodes$.pipe(first(), map(x => x.find(y => y.id == statusCodeID))).subscribe(x => statusCode = x?.displayName ?? null);
    }
    return statusCode;
  }

  private getType(model: ClaimDetailStatusWithLedgerModel): string | null {
    let auditResultTypesID: number | null = model?.auditResultType ?? null;
    let auditResultType: string | null = null;
    this.auditResultTypes$.pipe(first(), map(x => x.find(y => y.id == auditResultTypesID))).subscribe(x => auditResultType = x?.displayName ?? null);
    return (model.statusFlavor ?? '') + (auditResultType ? '(' + auditResultType + ')' : '');
  }

  private getPerson(model: ClaimDetailStatusWithLedgerModel): string | null {
    const person: string = model.eventPerson ?? "?"
    return (model.eventImpersonator ? model.eventImpersonator + ' (for ' + person + ')' : person);
  }

  constructor(private store: Store,) {
  }

  private loadData() {
    this.gridView$.next({
      data: orderBy(this.history, this.sort),
      total: this.history.length
    });
  }

  ngOnInit(): void {
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
}
