import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthenticationService } from '@data-services/authentication.service';
import { first, filter, timeout, tap, catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAccessPerson, selectFeatureMap } from '@app-store/app-session/app-session.selectors';
import { StatusCode } from '@alcon-db-models/Enums';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';

@Injectable({ providedIn: 'root' })
export class AccessGuard implements CanActivate {

  constructor (
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
    ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let doAllow = of(true);

    // Only restrict access to features with a featureCode
    const featureCode = next?.data?.featureMapCode;

    if (featureCode) {

      const selectAccessPerson$ = this.store.select(selectAccessPerson).pipe(filter(x => Boolean(x)));
      const selectCurrentPersonFeatures$ = this.store.select(selectCurrentPersonFeatures).pipe(filter(x => Boolean(x?.length)));
      const selectFeatureMap$ = this.store.select(selectFeatureMap).pipe(filter(x => Boolean(x?.length)));

      const url = ((next as any)?._routerState.url as string);
      let doPasswordChange: boolean = false;

      doAllow = combineLatest([selectAccessPerson$, selectFeatureMap$, selectCurrentPersonFeatures$]).pipe(
        timeout(2000),
        first(),
        tap(([accessPerson]) => {
          if (!url?.includes('change-password')) {
            if (accessPerson?.mustUpdatePassword) {
              doPasswordChange = true;
              throw new Error('User must change password');
            }
          }
        }),
        switchMap(([accessPerson, featureMap, currentPersonFeatures]) => {
          const feature = featureMap.find(x => x.code == featureCode);
          const personFeature = currentPersonFeatures.find(x => x.code == featureCode);
          // is feature not present or globally inactive?
          if (!feature?.statusCode || feature.statusCode != StatusCode.Active)
            return of(false);
          // is inactive for user or not present for user?
          if (!personFeature?.statusCode || feature.statusCode != StatusCode.Active)
            return of(false);
          // all good?  Lets make sure...
          return this.authenticationService.checkFeatureAccess(featureCode);
        }),
        catchError((x) => {
          return of(false)
        }),
        tap(x => {
          if (!x) {
            // HACK: url?.includes('home') is to prevent a possible redirect loop.  There must be a better way.
            const redirectUrl = doPasswordChange ? 'profile/change-password' : url?.includes('home') ? '/logout' : '/home';
            this.router.navigateByUrl(redirectUrl);
          }
        })
      );
    }
    return doAllow;
  }
}


