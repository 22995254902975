import { AccessRole, BusinessRole, StatusCode } from "@alcon-db-models/Enums";
import { FeatureModel } from "@alcon-db-models/FeatureModel";
import { PersonWithRolesModel } from "@alcon-db-models/PersonWithRolesModel";
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { JsonUtilities } from "./json-utilities";

export class BusinessCommon {

  public static getPersonFeatures(features: FeatureModel[], person: PersonWithRolesModel) {
    return features
    ?.filter(x => x.statusCode == StatusCode.Active)
    .map(x =>
      ({
        feature: x,
        accessRolesCommon: x.accessRoles?.filter(y => person?.accessRoles?.includes(y) ?? false) ?? [],
        businessRolesCommon: x.businessRoles?.filter(y => person?.businessRoles?.includes(y) ?? false) ?? [],
        teamIDsCommon: x.teamIDs?.filter(y => person?.teamIDs?.includes(y) ?? false) ?? []
      }))
    // must match at least one access role
    .filter(x => x.accessRolesCommon?.length)
    .map(x => ({...x, doCheckBusinessRoles: x.feature.businessRoles?.length && x.accessRolesCommon?.length == 1 && x.accessRolesCommon[0] == AccessRole.SalesRep }))
    // we'll check for matching business roles if feature specifies and the only matching access role is SalesRep
    .filter(x => !x.doCheckBusinessRoles || x.businessRolesCommon?.length)
    .map(x => ({...x, doCheckAMTeams: x.feature.teamIDs?.length && x.doCheckBusinessRoles && x.businessRolesCommon?.length == 1 && x.businessRolesCommon[0] == BusinessRole.Am }))
    // we'll check for matching teams if feature specifies and the only matching business role is AM
    .filter(x => !x.doCheckAMTeams || x.teamIDsCommon?.length)
    .map(x => JsonUtilities.convertDatesAndCopy(x.feature))
    ?? [new FeatureModel()];
  }

}
