import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TokenResetRequestModel } from "@alcon-db-models/TokenResetRequestModel"
import { RefreshRequestModel } from "@alcon-db-models/RefreshRequestModel"
import { AccessRequestResultModel } from '@alcon-db-models/AccessRequestResultModel';
import { ResetPasswordRequestModel } from '@alcon-db-models/ResetPasswordRequestModel';
import { ChangePasswordRequestModel } from '@alcon-db-models/ChangePasswordRequestModel';
import { Store } from '@ngrx/store';
import {AccessControlBaseService} from './access-controlled-base.service';
import { Router } from '@angular/router';
import { TokenInfoModel } from '@alcon-db-models/TokenInfoModel';
import { tokenRefreshSuccess } from '@app-store/app-session/app-session.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends AccessControlBaseService {

  public redirectUrl?: string;

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public navigateToRedirect() {
    return Boolean(this.redirectUrl) ?
      this.router.navigateByUrl(this.redirectUrl ?? '.').then((x) => { if (x) this.redirectUrl = undefined; return x;}) :
      Promise.resolve(false);
  }

  // This might throw HTTP error
  public login(username: string, password: string, doPersistAuthorization: boolean): Promise<AccessRequestResultModel> {
    return this.post<AccessRequestResultModel>('authenticate/login', { username, password, doPersistAuthorization }).pipe(
        map((accessRequest: any) => {
          // We should always have a user, 401 Unauthorized returned by API if credentials are invalid.
          if (!accessRequest?.tokenInfoModel?.token) {
            // ...but, just in case.
            throw(new Error('Invaild credentials'));
          }
          return accessRequest;
        })
      ).toPromise();
  }

  // This might throw HTTP error
  public logout():void {
    this.get('/authenticate/logout');
  }

  // This might throw HTTP error
  public refreshToken(refreshRequest: RefreshRequestModel | null = new RefreshRequestModel()): Promise<AccessRequestResultModel> {
    return refreshRequest ? this.post<any>(
        '/authenticate/spa-refresh',
        refreshRequest
      )
      .toPromise()
      : Promise.resolve(false);
  }

  public checkToken(tokenInfoModel?: TokenInfoModel | null): boolean {
    return (tokenInfoModel?.expiration || false) && new Date(tokenInfoModel.expiration) >= new Date();
  }

  public passwordResetVerification(tokenResetRequest: TokenResetRequestModel): Promise<boolean|undefined> {
    return this.post<boolean>('authenticate/password-reset-verification', { emailAddress: tokenResetRequest?.emailAddress }).toPromise();
  }

  public resetPassword(resetPasswordRequest: ResetPasswordRequestModel): Promise<boolean|undefined> {
    return this.post<boolean>('authenticate/reset-password', resetPasswordRequest).toPromise();
  }

  public changePassword(changePasswordRequest: ChangePasswordRequestModel): Promise<{ success: boolean, errors: { code: string, description: string}[]}|undefined> {
    return this.post<{ success: boolean, errors: { code: string, description: string}[]}>('authenticate/change-password', changePasswordRequest).toPromise();
  }


  public checkFeatureAccess(featureCode: string): Observable<boolean> {
    return this.get<boolean>('application/check-feature-access', { 'featureCode' : featureCode  });
  }

  public refreshTokenWith(doKeepMeLoggedIn: boolean, memoryOnlyRefreshToken?: string | null) {
    return from(this.refreshToken({ doPersistAuthorization: doKeepMeLoggedIn, refreshToken: memoryOnlyRefreshToken })).pipe(
      switchMap(x => {
        if (!x?.tokenInfoModel) {
          throw new Error("auth -> Refresh failed");
        }
        this.store.dispatch(tokenRefreshSuccess({ payload: x  }));
        return of(true);
      }));
  }

}
