<div class="acb-header-main-header">
  <a [routerLink]="['/home']" class="acb-header-logo">
    <img src="../assets/images/logo-white.svg" type="image/svg+xml" alt="Alcon" width="120" height="50" />
  </a>
</div>

<div *ngIf="errorMode == 'error'" class="acb-feature-wrapper" fxLayout="column" fxLayoutAlign="center center" [ngClass.lt-md]="'acb-small'">
  <div class="acb-inner acb-inner-error" fxLayout="column" fxLayoutAlign="start start">
    <!-- <span class="acb-feature-text">Review <span *ngIf="commitmentsAffected.length > 1">Batch</span> Failed</span> -->
    <span class="acb-feature-text">Review Batch Failed</span>
    <div class="acb-feature-text-detail">
      <!-- <div class="acb-commitment-list">Commitment{{ commitmentsAffected.length > 1 ? 's' : ''}}: {{ commitmentListDisplay }}</div> -->
      <ul>
        <li *ngFor="let errorMessage of errorMessages;">
          {{errorMessage}}
        </li>
      </ul>
    </div>
    <div fxLayout="row wrap" class="acb-botton-row" >
      <button kendoButton [routerLink]="['/']" class="k-button acb-goto-dashboard" fxFlexOffset="2em" fxFlex="0 0 calc(40% - 4em)">Go To Dashboard</button>
      <button kendoButton [routerLink]="['/commitments/manage']" class="k-button acb-goto-manage" fxFlex="0 0 calc(45% - 2em)">Manage Commitments</button>
      <div fxFlex="0 0 calc(40% - 2em)">&nbsp;</div>
      <button kendoButton [routerLink]="['/commitments/review']" class="k-button acb-goto-view-queue" fxFlex="0 0 calc(45% - 2em)">Review Approval Queue</button>
    </div>
    <div fxFlex="0 1 5vh"></div>
  </div>
</div>


<div *ngIf="errorMode == 'success'" class="acb-feature-wrapper" fxLayout="column" fxLayoutAlign="center center" [ngClass.lt-md]="'acb-small'">
  <div class="acb-inner" [ngClass]="{'acb-inner-approve': reviewMode == 'approve', 'acb-inner-deny': reviewMode == 'deny'}" fxLayout="column" fxLayoutAlign="start start">
    <span class="acb-feature-text">{{commitmentsAffected.length > 1 ? commitmentsAffected.length : '' }} Commitment{{ commitmentsAffected.length > 1 ? 's' : '' }} {{desiredStatus}}!</span>
    <div class="acb-feature-text-detail">
      <div class="acb-commitment-list">Commitment{{ commitmentsAffected.length > 1 ? 's' : ''}}: {{ commitmentListDisplay }}</div>
    </div>
    <div fxLayout="row wrap" class="acb-botton-row" >
      <button kendoButton [routerLink]="['/']" class="k-button acb-goto-dashboard" fxFlexOffset="2em" fxFlex="0 0 calc(40% - 4em)">Go To Dashboard</button>
      <button kendoButton [routerLink]="['/commitments/manage']" class="k-button acb-goto-manage" fxFlex="0 0 calc(45% - 2em)">Manage Commitments</button>
      <div fxFlex="0 0 calc(40% - 2em)">&nbsp;</div>
      <button kendoButton [routerLink]="['/commitments/review']" class="k-button acb-goto-view-queue" fxFlex="0 0 calc(45% - 2em)">Review Approval Queue</button>
    </div>
    <div fxFlex="0 1 5vh"></div>
  </div>
<div>




<!-- <div *ngIf="hasError == null">
  NULL
</div>
<div *ngIf="hasError === true">
  {{ errorMessage }}
</div>
<div *ngIf="hasError === false">
  Success!
</div> -->
