import { StatusCode } from '@alcon-db-models/Enums';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplyCommitmentReviewTokenService } from '@services/apply-commitment-review-token.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AnonymousCommitmentErrorMode, AnonymousCommitmentReviewMode } from 'src/app/components/components.module';

@Component({
  selector: 'acb-alcon-anonymous-commitment-review-page',
  templateUrl: './anonymous-commitment-review-page.component.html',
  styleUrls: ['./anonymous-commitment-review-page.component.scss']
})
export class AnonymousCommitmentReviewPageComponent implements OnInit, OnDestroy {

  // public hasError?: boolean;
  public errorMessages: string[] = [];
  public destroy$: Subject<void> = new Subject<void>();
  public commitmentsAffected: number[] = [];
  public desiredStatus: string = 'Unknown';
  public commitmentListDisplay?: string;
  public reviewMode:AnonymousCommitmentReviewMode = 'unknown';
  public errorMode:AnonymousCommitmentErrorMode = 'unknown';

  private _token?:string;

  constructor(
    activatedroute: ActivatedRoute,
    protected applyCommitmentReviewTokenService: ApplyCommitmentReviewTokenService,
    protected changeDetectorRef: ChangeDetectorRef) {
      activatedroute.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.reviewMode = data.reviewMode;
      })
      activatedroute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this._token = x['t'];
        window.history.replaceState({}, '', `${location.protocol}//${location.host}${location.pathname}`);
      });
    }

  ngOnInit(): void {
    this.errorMode = 'unknown';
    this.desiredStatus = this.reviewMode == 'approve' || this.reviewMode == 'approve-all' ? "Approved" : this.reviewMode == 'deny' ? "Denied" : "";
    const desiredStatusCodeID = this.reviewMode == 'approve' || this.reviewMode == 'approve-all' ? StatusCode.Approved : this.reviewMode == 'deny' ? StatusCode.Denied : null;

    this.applyCommitmentReviewTokenService.applyToken({
      desiredCommitmentStatusCodeID: desiredStatusCodeID,
      tokenOrSet: this._token,
      internalNote: navigator.userAgent
    }).pipe(first()).subscribe(x => {
      if (x.response?.wasSuccessful === false) {
        this.errorMode = 'error';
        this.errorMessages = x.response.message ? [x.response.message] : [];
      } else if (x.hasError) {
        this.errorMode = 'error';
        this.errorMessages = x.errorMessage;
      } else {
        this.commitmentsAffected = x.response?.commitmentsAffected ?? [];
        this.errorMode = 'success';
        this.errorMessages = [];
        this.commitmentListDisplay = this.commitmentsAffected.map(x => "#" + x).join(', ');
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
