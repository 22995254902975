import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { FundGroupForDropdownModel } from '@alcon-db-models/FundGroupForDropdownModel';
import { FundGroupInfoModel } from '@alcon-db-models/FundGroupInfoModel';
import { FundSearchModel } from '@alcon-db-models/FundSearchModel';
import { FundWithDetailsModel } from '@alcon-db-models/FundWithDetailsModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { ActivityForDropdownService } from '@services/activity-for-dropdown.service';
import { AppUxService } from '@services/app-ux.service';
import { FundGroupForDropdownService } from '@services/fund-group-for-dropdown.service';
import { FundSearchService } from '@services/fund-search.service';
import { FundWithDetailsService } from '@services/fund-with-details.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from 'src/app/shared/acb-stream';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { ViewMode } from '../components.module';
import { WindowNotification } from '../window-notifications/window-notifications.component';
import { VisibilityType } from '@alcon-db-models/Enums';
import { FundNotification } from '@alcon-db-models/FundNotification';

@Component({
  selector: 'acb-alcon-edit-fund',
  templateUrl: './edit-fund.component.html',
  styleUrls: ['./edit-fund.component.scss']
})
export class EditFundComponent implements OnInit, OnDestroy {

  @Input() public viewMode:ViewMode = "edit";

  public activitiesForDropdown$ = new ReplaySubject<ActivityForDropdownModel[]>(1);
  public fundGroupsForDropdown$ = new ReplaySubject<FundGroupForDropdownModel[]>(1);
  public primaryFundGroupsForDropdown$ = new ReplaySubject<FundGroupForDropdownModel[]>(1);
  public expenseTypes$ = this.store.select(selectStaticTypes).pipe(filter(x => Boolean(x?.length)), first(), map(x => x.filter(y => y.tableName == 'ExpenseType')));
  public accessRoles$ = this.store.select(selectStaticTypes).pipe(filter(x => Boolean(x?.length)), first(), map(x => x.filter(y => y.tableName == 'AccessRole')));
  public fundNotificationTypes$ = this.store.select(selectStaticTypes).pipe(filter(x => Boolean(x?.length)), first(), map(x => x.filter(y => y.tableName == 'FundNotificationType' && y.visibilityTypeID == VisibilityType.Visible)));

  private _fundID?:number | null | undefined;
  @Input() set fundID(value:number | null | undefined) {
    if (this._fundID = value) {
      this.fundWithDetailsService.getByKey(value).pipe(first()).subscribe(x => {
        if (x.fundID) {
          this.fundWithDetails$.next(x);
        }
      });
    } else {
      this.fundForm.reset({});
      this.fundWithDetails = undefined;
    }
  }

  @Input() set cloneFundID(value:number) {
    if (value) {
      this.fundWithDetailsService.getByKey(value).pipe(first()).subscribe(x => {
        if (x.fundID) {
          let f: FundWithDetailsModel = JsonUtilities.convertDatesAndCopy(x);
          f.fundID = null;
          f.accountID = null;
          f.code = null;
          f.internalNote = null;
          f.statusCodeID = null;
          f.visibilityTypeID = null;
          this.fundWithDetails$.next(f);
        }
      });
    } else {
      this.fundForm.reset({});
      this.fundWithDetails = undefined;
    }
  }


  public notifications:WindowNotification[] = [];
  public fundWithDetails$: ReplaySubject<FundWithDetailsModel> = new ReplaySubject<FundWithDetailsModel>(1);
  public fundWithDetails: FundWithDetailsModel | undefined = undefined;
  public destroy$: Subject<void> = new Subject<void>();

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<FundSearchModel> = new EventEmitter();

  public fundForm = new UntypedFormGroup({
    fundID: new UntypedFormControl(null),
    displayName: new UntypedFormControl(null, [Validators.required]),
    startDate: new UntypedFormControl(null, [Validators.required]),
    endDate: new UntypedFormControl(null, [Validators.required]),
    isRollback: new UntypedFormControl(false),
    isPostAudit: new UntypedFormControl(false),
    isPopRequired: new UntypedFormControl(false),
    allowsOnlyLocalTransferAllocation: new UntypedFormControl(false),
    allowsInternalDeduction: new UntypedFormControl(false),
    expenseTypeID: new UntypedFormControl(null, [Validators.required]),
    isVenueAware: new UntypedFormControl(false),
    doesUseCommitmentActivities: new UntypedFormControl(false),
    commitmentActivitiesRequired: new UntypedFormControl(false),
    activities: new UntypedFormControl(null),
		fundGroupIDs: new UntypedFormControl(null, [Validators.required]),
    primaryFundGroupID: new UntypedFormControl(null, [Validators.required]),
    accessRoles: new UntypedFormControl(null),
    fundNotifications: new UntypedFormControl(null)
  });

  constructor(
    private store: Store,
    private fundWithDetailsService: FundWithDetailsService,
    private appUxService: AppUxService,
    private changeDetectorRef: ChangeDetectorRef,
    activityForDropdownService: ActivityForDropdownService,
    fundGroupForDropdownService: FundGroupForDropdownService
    ) {
    this.fundWithDetails$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let f: any = this.fundWithDetails = x ? JsonUtilities.convertDatesAndCopy(x) : undefined;
      if (f) {
        f.fundGroupIDs = f.fundGroups?.map((y:FundGroupInfoModel) => y.fundGroupID) ?? [];
        f.primaryFundGroupID = f.fundGroups?.find((y:FundGroupInfoModel) => y.isPrimary)?.fundGroupID ?? [];
      }
      this.fundForm.patchValue({...f});
    });

    activityForDropdownService.getAll().pipe(first()).subscribe(x => this.activitiesForDropdown$.next(x));
    fundGroupForDropdownService.getAll().pipe(first()).subscribe(x => this.fundGroupsForDropdown$.next(x));

    (this.fundForm.controls.fundGroupIDs as UntypedFormControl)?.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      withLatestFrom(this.fundGroupsForDropdown$)).subscribe(([groupIDs, fundGroupForDropdown]) => {
        // TODO: There must be a better way to not include lookup misses
        const groups = (groupIDs as number[])?.map(x => fundGroupForDropdown.find(y => y.fundGroupID == x)).filter(x => typeof x != 'undefined') as FundGroupInfoModel[] ?? [];
        if (!groups.some(x => x.fundGroupID == this.fundForm.controls.primaryFundGroupID?.value)) this.fundForm.controls.primaryFundGroupID.setValue(null);
        this.primaryFundGroupsForDropdown$.next(groups);
      }
    );

  }

  public validateForm(): boolean {
    this.fundForm.markAllAsTouched();
    return this.fundForm.valid;
  }

  public updateErrors() {
    this.notifications.length = 0;
    // if (this.fundForm.errors?.noScope) {
    //   this.notifications.push({ type:'error', isVisible:true, message: 'Must select at least one Fund or Territory'});
    // }
  }

  ngOnInit(): void {
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onSave() {

    if (!this.validateForm()) {
      this.updateErrors();
      return;
    }

    const fundGroupIDs = (this.fundForm.controls.fundGroupIDs?.value ?? []) as number[];
    const primaryFundGroupID = (this.fundForm.controls.primaryFundGroupID?.value ?? []) as number;

    let fundGroups: FundGroupInfoModel[] = [];
    this.fundGroupsForDropdown$.pipe(first()).subscribe(x => fundGroups = x.filter(y => fundGroupIDs.some(z => z == y.fundGroupID)) as FundGroupInfoModel[]);
    const fund: FundWithDetailsModel = {
      ...this.fundWithDetails, 
      ...this.fundForm.value, 
      fundGroups: fundGroups.map(x => ({fundGroupID: x.fundGroupID, isPrimary: primaryFundGroupID == x.fundGroupID }) ) 
    };

    //fund.fundNotifications = fund.fundNotifications?.map(x => { return { fundNotificationTypeID: x as number} as FundNotification });

    this.fundWithDetailsService.upsert(fund).pipe(
        firstWithLoadingOverlayAndErrorHandling<FundWithDetailsModel>()
      ).subscribe((x:ServiceResponse<FundWithDetailsModel>) => {
        if (x.hasError) {
          this.appUxService.openErrorDialog(x.errorMessage);
        } else {
          this.saved.emit(x.response ?? undefined);
        }
      });

  }

  public onCancel() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
