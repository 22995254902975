import { AccessRequestResultModel } from '@alcon-db-models/AccessRequestResultModel';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return from(this.authenticationService.refreshToken()).pipe(first(),
        catchError(e => of(null)),
        map((x: AccessRequestResultModel | null) => {
          if (x?.accessPerson) {
            if (!this.authenticationService.navigateToRedirect()) this.router.navigate(["."]);
            return false;
          }
          return true;
        }));
  }
}
