


import { CommitmentOrganizationsModel } from './CommitmentOrganizationsModel';//3

export class ClaimInfoModel extends CommitmentOrganizationsModel {
    claimID?: number | null;
    statusCodeID?: number | null;
    status?: string | null;
    displayStatusCodeID?: number | null;
    displayStatus?: string | null;
    commitmentAmount?: number | null;
    commitmentAvailableAmount?: number | null;
    claimedAmount?: number | null;
    paidAmount?: number | null;
    activityID?: number | null;
    activity?: string | null;
    activityDescription?: string | null;
    deduction?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
    documentNumber?: string | null;
    isLastClaim?: boolean | null;
    invoice?: string | null;
    payTypeID?: number | null;
    payType?: string | null;
    receivedDate?: Date | null;
    processedDate?: Date | null;
    releasedDate?: Date | null;
    clearedDate?: Date | null;
    numberOfAds?: number | null;
    copyApproval?: string | null;
    event?: string | null;
    venue?: string | null;
    venueCity?: string | null;
    venueStateProvinceCodeID?: number | null;
    venueStateProvinceCode?: string | null;
    estimatedAttendeeCount?: number | null;
    wereBenefitsReceived?: boolean | null;
    commitmentFinalApprover?: string | null;
    commitmentFinalApproverPersonID?: number | null;
    canView?: boolean | null;
    canCopy?: boolean | null;
    canReverse?: boolean | null;
    canVoid?: boolean | null;
    canCancel?: boolean | null;
    canEdit?: boolean | null;
    isEditRestrictedToAttachments?: boolean | null;
    canAudit?: boolean | null;
    canCheck?: boolean | null;
    canPend?: boolean | null;
    canReset?: boolean | null;
    notificationRecipientEmailAddress?: string | null;
    specialClaimTypeID?: number | null;
}

 
     
         
