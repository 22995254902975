import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acb-alcon-select-payee-customer-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" (close)="payeeCustomer.onClose()">
      Select Payee
    </kendo-dialog-titlebar>
    <acb-alcon-select-payee-customer
      #payeeCustomer
      [payeeSpec]="payeeSpec"
      (cancel)="cancel.emit($event)"
      (select)="select.emit($event)"
      (selectedChange)="onSelectedChanged($event)"
      >
    </acb-alcon-select-payee-customer>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="payeeCustomer.onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!hasSelection" (click)="payeeCustomer.onSelect(payeeCustomer.getSelectedItem())" ><span class="k-icon k-i-checkmark"></span>Select</button>
    </kendo-dialog-actions>
  `,
  styles: [':host { width: 100%; }']
})
export class SelectPayeeCustomerDialogComponent implements OnInit {

  constructor() { }

  @Input() public payeeSpec?: { customerID?: number | null | undefined, payeeCustomerID?: number | null | undefined } | null;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<CustomerSearchWithDefaultModel | null> = new EventEmitter();
  @Output() selectedChange: EventEmitter<CustomerSearchWithDefaultModel | null> = new EventEmitter();

  public hasSelection: boolean = false;

  ngOnInit(): void {
  }

  public onSelectedChanged(event: any) {
    this.selectedChange.emit(event);
    this.hasSelection = Boolean(event?.customerID);
  }
}
