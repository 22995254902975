import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { CommitmentReviewRequestModel } from '@alcon-db-models/CommitmentReviewRequestModel'
import { CommitmentReviewResultModel } from '@alcon-db-models/CommitmentReviewResultModel'
import { Observable } from 'rxjs';
import { ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class CommitmentReviewService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  // public postReview(models: CommitmentReviewRequestModel[] ): Promise<CommitmentReviewResultModel[]> {
  //   return this.post<CommitmentReviewResultModel[]>('CommitmentReview', models).toPromise();
  // }



  public postReview(models: CommitmentReviewRequestModel[] ): Observable<ServiceResponse<CommitmentReviewRequestModel[]>> {
    return this.post<CommitmentReviewResultModel[]>('CommitmentReview', models).pipe(
      firstWithLoadingOverlayAndErrorHandling<CommitmentReviewRequestModel[]>()
    );
  }
}
