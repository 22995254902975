<div fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="!(loading | async)">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start end" fxLayoutAlign.lt-md class="acb-details-wrapper" [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'">
    <acb-alcon-view-claim-details
      [claim]="claim"
      [doShowLessForReview]="viewMode == 'review'"
      (viewCommitment)="viewCommitment.emit($event)"
      (viewClaim)="viewClaim.emit($event)"
      fxFlex
      >
    </acb-alcon-view-claim-details>
    <div class="acb-edit-button-wrapper">
      <button
        kendoButton
        class="acb-edit-button"
        [ngClass] = "{ 'acb-error': !this.detailsValid }"
        (click)="onEditDetails()"
        [disabled]="!canEditDetails"
        >
      <span class="k-icon k-i-edit"></span> Edit</button>
    </div>
  </div>

  <!-- <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start end"
    fxLayoutAlign.lt-md
    class="acb-review-wrapper"
    [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
    *ngIf="canEditAfterAudit && wasAudited"
    >
    <acb-alcon-view-claim-review [claim]="claim">
    </acb-alcon-view-claim-review>
    <div class="acb-edit-button-wrapper">
      <button
        kendoButton
        class="acb-edit-button"
        (click)="onEditReview()"
        >
      <span class="k-icon k-i-edit"></span> Edit</button>
    </div>
  </div> -->

  <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">

    <div
      class="acb-commitment acb-view-mode"
      fxLayout="row"
      fxFlex="1 0 16em"
      fxFlex.lt-sm="grow"
      [ngClass]="{ 'acb-expanded': commitmentDisplay.isExpanded }"
      [ngClass.gt-xs]="{ 'acb-expanded': commitmentDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
      >
      <div class="acb-details-layout-section acb-section2" fxFlex fxLayout="column">
        <acb-alcon-display-info-card
          #commitmentDisplay
          [label]="'Commitment'"
          [headerValue]="claim?.commitmentID ? claim?.commitmentID?.toString() : ''"
          [nameAndValues] = "[
            ['ID', claim?.commitmentID ? claim?.commitmentID?.toString() : '', true],
            ['Customer', claim?.customer, false],
            ['Fund', claim?.fund, false]
          ]"
          [isExpandedOnly]="viewMode == 'review'"
          fxFlex
          ></acb-alcon-display-info-card>
        <div class="acb-edit-button-wrapper" [hidden]="!commitmentDisplay.isExpanded" *ngIf="!claim?.isEditRestrictedToAttachments">
          <button kendoButton class="acb-edit-button" (click)="onEditCommitment()"><span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </div>
    <div
      class="acb-payee acb-view-mode"
      fxLayout="row"
      fxFlex="1 0 16em"
      fxFlex.lt-sm="grow"
      [ngClass]="{ 'acb-expanded': payeeDisplay.isExpanded }"
      [ngClass.gt-xs]="{ 'acb-expanded': payeeDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
      >
      <div class="acb-details-layout-section acb-section3" fxFlex fxLayout="column">
        <acb-alcon-display-address
          #payeeDisplay
          [label]="'Payee'"
          [code]="claim?.payeeCustomerCode"
          [name]="claim?.payee"
          [location]="claim?.payeeLocation"
          [isExpandedOnly]="viewMode == 'review'"
          fxFlex
          ></acb-alcon-display-address>
        <div class="acb-edit-button-wrapper" [hidden]="!payeeDisplay.isExpanded" *ngIf="!claim?.isEditRestrictedToAttachments">
          <button kendoButton class="acb-edit-button" (click)="onEditPayee()"><span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" [ngClass.gt-xs]="'acb-intra-form-margin-correction'" fxFlex="1 1 100%">
    <acb-alcon-section-with-legend
      #productsSection
      [isCollapsible]="false"
      [sectionTitle]="'Products'"
      class="acb-section-11 acb-products-section"
      fxFlex="calc(50% - 1em)"
      [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
      >
      <div fxLayout="column" style="height: 100%;">
        <acb-alcon-view-claim-products [claim]="claim" [viewMode]="'edit'" fxFlex>
        </acb-alcon-view-claim-products>
        <div class="acb-edit-button-wrapper" *ngIf="!claim?.isEditRestrictedToAttachments">
          <button
            kendoButton
            class="acb-edit-button"
            [ngClass] = "{ 'acb-error': !productsValid }"
            (click)="onEditProducts()"
            >
          <span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </acb-alcon-section-with-legend>
    <acb-alcon-section-with-legend
      #attachmentsSection
      [isCollapsible]="false"
      [sectionTitle]="'Attachments'"
      class="acb-section-06 acb-attachments-section"
      fxFlex="calc(50% - 1em)"
      [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
      >
      <div fxLayout="column" style="height: 100%;">
        <acb-alcon-view-claim-attachments [viewMode]="'view-edit'" [claim]="claim" [doShowFieldLabel]="false" >
        </acb-alcon-view-claim-attachments>
        <div class="acb-edit-button-wrapper">
          <button kendoButton class="acb-edit-button" (click)="onEditAttachments()"><span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </acb-alcon-section-with-legend>
  </div>

  <div class="acb-outer-section">
    <acb-alcon-view-claim-review-history [claim]="claim"></acb-alcon-view-claim-review-history>
  </div>

</div>
