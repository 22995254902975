import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of, from, combineLatest } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, mergeMap, first, tap, timeout, exhaustMap, delay } from 'rxjs/operators';
import { AuthenticationService } from '@data-services/authentication.service';
import { ClientStorageService } from '@data-services/client-storage.service';
//import * as AppActions from './app-session.actions';
import { AppSessionState, appIntialStateFactory  } from './app-session.state';
import { selectAccessPerson, selectAppSessionState, selectCurrentPerson } from "./app-session.selectors";
import { ApplicationService } from '@data-services/application.service';
import * as AppSessionActions from './app-session.actions'
import { AppConfigService } from '@data-services/app-config.service';
import { PersonWithRolesService } from '@data-services/person-with-access-roles.service'
import { TypedAction } from '@ngrx/store/src/models';
import { Router } from '@angular/router';
import { debug } from 'console';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { selectIsReady } from '@app-store/root.selectors';

@Injectable()
export class AppSessionEffects {

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      tap(() => { this.store.dispatch(AppSessionActions.updateIsReady({payload:false})); }),
      map(() => {
        return appIntialStateFactory(this.clientStorageService, this.appConfigService).pipe(map(x => {          
          return AppSessionActions.updateAppSession({ payload: x })
        }))
      }),
      mergeMap(x => x),
      catchError(() => of(<TypedAction<AppSessionActions.ActionTypes.UpdateAppSession>>{}))
    );
  });

  onReinitAppSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.reinitAppSession),
      map(() => {
        return appIntialStateFactory(this.clientStorageService, this.appConfigService).pipe(map(x => {
          return AppSessionActions.updateAppSession({ payload: x })
        }))
      }),
      mergeMap(x => x)
    );
  });


  onUpdateAppSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.updateAppSession),
      switchMap((action) => {
        const result: any[] = [
          AppSessionActions.appSessionUpdated({ payload: action.payload}),
          AppSessionActions.themesUpdated({ payload: action.payload.themes}),
          AppSessionActions.updateFeatureMap({ payload: action.payload.featureMap}),
        ];
        if (action.payload.accessPerson) {
          result.push(AppSessionActions.accessPersonUpdated({ payload: action.payload.accessPerson ?? {}}));
        }

        return result;
       })
    );
 });

  onLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.logInUser),
      map(x => x.payload),
      switchMap(payload => this.authenticationService.login(payload.username, payload.password, payload.doPersistAuthorization)
        .then(data => ([
            //AppActions.userLoggedOut(),
            AppSessionActions.updateDoKeepMeLoggedIn({ payload: payload.doPersistAuthorization }),
            AppSessionActions.userLoginSuccessForRoot({ payload: data }),
            AppSessionActions.userLoginSuccess({ payload: data })
          ])
        )
        .catch((error) => ([AppSessionActions.userLoginFailure(error)]))
      ),
      switchMap((x) => x)
    );
  });

  onUserLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.userLoginSuccess),
      //tap(() => { this.store.dispatch(AppSessionActions.reinitAppSession()); }),
      switchMap((action) =>
        this.applicationService.getLoggedInIdentity()
          .then(x => {
            return [
              AppSessionActions.updateAccessPerson({ payload: x ?? {} }),
              AppSessionActions.userLoginSuccessComplete({ payload: action.payload }),
            ]
          }).catch(e => {
            return [AppSessionActions.userLoginFailure({ payload: "Error retrieving identity"})];
          })
      ),
      switchMap((x) => {
        return x;
      })
    );
  });

  onTokenRefreshSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.tokenRefreshSuccess),
      //tap(() => { this.store.dispatch(AppSessionActions.reinitAppSession()); }),
      switchMap(x => {
        if (x?.payload?.accessPerson) {
          return of(AppSessionActions.updateAccessPerson({ payload: x.payload.accessPerson }));
        } else {
          return this.applicationService.getLoggedInIdentity()
            .then(x => AppSessionActions.updateAccessPerson({ payload: x ?? {} }))
            .catch(e => {
              return AppSessionActions.userLoginFailure({ payload: "Error retrieving identity"});
            })
        }
      })

    );
   });

   onUpdateAccessPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.updateAccessPerson),
      switchMap(action => {
        const person = action.payload;
        return combineLatest([this.store.select(selectCurrentPerson), this.store.select(selectAccessPerson)]).pipe(
          first(),
          map(([currentPerson, accessPerson]) => {
            currentPerson = person?.applicationUserID && accessPerson?.applicationUserID == person?.applicationUserID ? currentPerson ?? person : person;
            return [
              AppSessionActions.accessPersonUpdated({ payload: person ?? {}}),
              AppSessionActions.updateCurrentPerson({ payload: currentPerson ?? {}}),
              AppSessionActions.appSessionUpdated({ payload: { accessPerson: person, currentPerson: currentPerson }})
            ];
          })
        )
      }),
      switchMap((x) => {
        return x;
      }));
  });

  onUpdateCurrentPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.updateCurrentPerson),
      map(action => { 
        return AppSessionActions.appSessionUpdated({ payload: { currentPerson: action.payload }}); 
      })
    );
  })

  onImpersonatePerson$ = createEffect(() => {
    //return of(AppSessionActions.noopAction());
    return this.actions$.pipe(      
      ofType(AppSessionActions.impersonatePerson),
      switchMap((action) => {
        return this.PersonWithRolesService.getByKey(action.payload).pipe(
          timeout(2000),
          first(),
          map((x) => { 
            x = JsonUtilities.convertDatesAndCopy(x);
            return AppSessionActions.updateCurrentPerson({ payload: x }); 
          }),
          catchError((error) => { 
            return of(AppSessionActions.userLoginFailure({ payload: "Error retrieving identity"}));
          })
        )
      })
    )

      // switchMap((action) =>
      //   this.PersonWithRolesService.getByKey(action.payload)
      //     .toPromise()
      //     .then(x => AppActions.updateCurrentPerson({ payload: x }))
      //     .catch(e => {
      //       // ???????????
      //       return AppActions.userLoginFailure({ payload: "Error retrieving identity"});
      //     })
      //  )
    //);
 });

 onRevertImpersonation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.revertImpersonation),
      withLatestFrom(this.store.select(selectAccessPerson)),
      map(([, accessPerson]) => AppSessionActions.updateCurrentPerson({ payload: accessPerson ?? {} }))
    );
  });


  onLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
      // TODO: Review this.  It is the "reactive way" but smells bad.  Could unfuzzy it a little by using route data.  Is that enough?
      filter((action) => action.payload.routerState.url.includes('logout')),
      map(() => AppSessionActions.userLoggedOut())
    );
  });

  // //TODO: error handling
  // onBeginPasswordReset$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AppSessionActions.beginPasswordReset),
  //     switchMap(action => {
  //       if (!action?.payload.emailAddress) {
  //         return of(AppSessionActions.noopAction());
  //       }
  //       // TODO:  Now that we're not getting the token back (HTTP only cookie) this can be cleaned up...
  //       return from(this.authenticationService.passwordResetVerification(action.payload)
  //         .then(success => {
  //           if (success)
  //             return AppSessionActions.passwordResetBegun({ payload: action.payload });
  //           else
  //             throw new Error('Something bad happened');
  //         })
  //         .catch(e => { throw e })
  //       )
  //     })
  //   );
  // });

  // onEndPasswordReset$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AppSessionActions.endPasswordReset),
  //     switchMap(action => {
  //       if (!action?.payload?.newPassword || !action?.payload?.emailAddress || !action?.payload?.verificationCode) {
  //         throw new Error('Invalid request');
  //       }
  //       return from(this.authenticationService.resetPassword(action.payload)
  //         .then(wasSuccessful => {
  //           return AppActions.passwordResetComplete({ payload: wasSuccessful });
  //         })
  //         .catch(e => {
  //           throw e;
  //         })
  //       )
  //     })
  //   );
  // });


  updateAppSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AppSessionActions.updateMemoryOnlyRefreshToken,
        AppSessionActions.updateDoKeepMeLoggedIn
        ),
      withLatestFrom(this.store.select(selectAppSessionState)),
      map(([action,state]) => {
        return AppSessionActions.appSessionUpdated({ payload: { ...state }});
        })
      )
    }
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private applicationService: ApplicationService,
    private clientStorageService: ClientStorageService,
    private PersonWithRolesService: PersonWithRolesService,
    private appConfigService: AppConfigService,
    private store: Store<AppSessionState>,
    private router: Router,
  ) {}
}
