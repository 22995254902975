import { BehaviorSubject } from "rxjs";

export class AppWindowServiceBase {

  public static doShowModalOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static doShowLoadingOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // --------------------------------------------------------------------------
  public showModalOverlay() {
    AppWindowServiceBase.doShowModalOverlay$.next(true);
  }

  // --------------------------------------------------------------------------
  public static showModalOverlay() {
    AppWindowServiceBase.doShowModalOverlay$.next(true);
  }

  // --------------------------------------------------------------------------
  public hideModalOverlay() {
    AppWindowServiceBase.doShowModalOverlay$.next(false);
  }

  // --------------------------------------------------------------------------
  public static hideModalOverlay() {
    AppWindowServiceBase.doShowModalOverlay$.next(false);
  }

  // --------------------------------------------------------------------------
  public toggleModalOverlay() {
    AppWindowServiceBase.doShowModalOverlay$.next(!AppWindowServiceBase.doShowModalOverlay$.value);
  }

  // --------------------------------------------------------------------------
  public showLoadingOverlay() {
    AppWindowServiceBase.doShowLoadingOverlay$.next(true);
  }

  public static showLoadingOverlay() {
    AppWindowServiceBase.doShowLoadingOverlay$.next(true);
  }

  // --------------------------------------------------------------------------
  public hideLoadingOverlay() {
    AppWindowServiceBase.doShowLoadingOverlay$.next(false);
  }

  public static hideLoadingOverlay() {
    AppWindowServiceBase.doShowLoadingOverlay$.next(false);
  }

  // --------------------------------------------------------------------------
  public toggleLoadingOverlay() {
    AppWindowServiceBase.doShowLoadingOverlay$.next(!AppWindowServiceBase.doShowLoadingOverlay$.value);
  }
}
