import { Action, createAction, props } from '@ngrx/store';
import { AccessRequestResultModel } from '@alcon-db-models/AccessRequestResultModel'
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel'
import { TokenResetRequestModel } from '@alcon-db-models/TokenResetRequestModel';
import { ResetPasswordRequestModel } from '@alcon-db-models/ResetPasswordRequestModel';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { ThemeModel } from '@alcon-db-models/ThemeModel';

export enum ActionTypes {
  UpdateIsReady = '[App Command2] Update Is Ready',
  LoginSuccess = '[Auth Event2] Log In Success',
  LoginSuccessComplete = '[Auth Event2] Log In Success, Identity Updated',
  LoginFailure = '[Auth API2] Log In Failure',
  LogInUser = '[Auth Command2] Log In User',
  UserLoggedOut = '[Auth Event2] User Logged Out',
  TokenRefreshSuccess = '[Auth Event2] Token Refresh Success',
  UpdatePersistedSettings = '[App Command2] Update Persisted Settings',
  PersistedSettingsUpdated = '[App Event2] Persisted Settings Were Updated',
  UserRemovedFromStorage = '[Auth Event2] User Removed From Storage',
  UpdateUserName = '[App Command] Update User Name',
  UpdateAccessPerson = '[App Command2] Update Access Person',
  UpdateDoKeepMeLoggedIn = '[App Command2] Update Do Keep Me Logged In',
  UpdateMemoryOnlyRefreshToken = '[App Session Command] Update : Memory Only Refresh Token',
  UserLoginSuccessForRoot = '[App Session Event] User Login Success Ready For Root',
  AppSessionUpdated = '[App Session Event] App Session Updated',
  AccessPersonUpdated = '[App Session Event] Access Person Updated',
  UpdateCurrentPerson = '[App Session Command] Update Current Person',
  ImpersonatePerson = '[App Session Command] Impersonate Person',
  RevertImpersonation = '[App Session Command] Revert Impersonation',
  UpdateFeatureMap = '[App Session Command] Update Feature Map',
  UpdateThemes = '[App Session Command] Update Themes',
  ThemesUpdated = '[App Session Event] Themes Updated',
  UpdateAppSession = '[App Session Command] Update App Session',
  ReinitAppSession = '[App Session Command] Reinitilize App Session',
  NoOp = '[Auth Event2] No Op'
}

export const updateIsReady = createAction(ActionTypes.UpdateIsReady, props<{ payload: boolean }>());

export const reinitAppSession = createAction(ActionTypes.ReinitAppSession);

export const updateAppSession = createAction(ActionTypes.UpdateAppSession, props<{ payload: any }>());

export const appSessionUpdated = createAction(ActionTypes.AppSessionUpdated, props<{ payload: any }>());

export const logInUser = createAction(ActionTypes.LogInUser, props<{ payload: { username: string; password: string; doPersistAuthorization: boolean }}>());

export const userLoggedOut = createAction(ActionTypes.UserLoggedOut);

export const userLoginSuccess = createAction(ActionTypes.LoginSuccess, props<{ payload: AccessRequestResultModel; }>());

export const userLoginSuccessForRoot = createAction(ActionTypes.UserLoginSuccessForRoot, props<{ payload: AccessRequestResultModel; }>());

export const userLoginSuccessComplete = createAction(ActionTypes.LoginSuccessComplete, props<{ payload: AccessRequestResultModel; }>());

export const userLoginFailure = createAction(ActionTypes.LoginFailure, props<{ payload: any; }>());

export const tokenRefreshSuccess = createAction(ActionTypes.TokenRefreshSuccess, props<{ payload: AccessRequestResultModel; }>());

export const updateDoKeepMeLoggedIn = createAction(ActionTypes.UpdateDoKeepMeLoggedIn, props<{ payload: boolean }>());

export const updateAccessPerson = createAction(ActionTypes.UpdateAccessPerson, props<{ payload: PersonWithRolesModel }>());

export const accessPersonUpdated = createAction(ActionTypes.AccessPersonUpdated, props<{ payload: PersonWithRolesModel }>());

export const updateCurrentPerson = createAction(ActionTypes.UpdateCurrentPerson, props<{ payload: PersonWithRolesModel }>());

export const impersonatePerson = createAction(ActionTypes.ImpersonatePerson, props<{ payload: number }>());

export const revertImpersonation = createAction(ActionTypes.RevertImpersonation);

export const updateMemoryOnlyRefreshToken = createAction(ActionTypes.UpdateMemoryOnlyRefreshToken, props<{ payload: string }>());

export const updateFeatureMap  = createAction(ActionTypes.UpdateFeatureMap, props<{ payload: FeatureModel[] }>());

export const updateThemes  = createAction(ActionTypes.UpdateThemes, props<{ payload: ThemeModel[] }>());

export const themesUpdated  = createAction(ActionTypes.ThemesUpdated, props<{ payload: ThemeModel[] }>());

export const noopAction = createAction(ActionTypes.NoOp);

export interface AppAction extends Action {
  payload: any;
}

