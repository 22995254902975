import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { ColumnComponent, DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subject, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ColumnSettings, GridSettings } from '../components/core/core.module';
import { JsonUtilities } from '../shared/json-utilities';

@Directive()
export abstract class UserPreferenceEnabledDataBindingDirective extends DataBindingDirective implements OnInit, OnDestroy, AfterViewInit {

  private destroy$: Subject<void> = new Subject<void>();
  private templateGridSettings: GridSettings = {  columnsConfig: [], state: {} };
  protected abstract preferenceKey: string;

  constructor(
    grid: GridComponent,
    private userPreferencesService: UserPreferencesService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
    super(grid);
  }

  public ngOnInit(): void {

    this.templateGridSettings.state = JsonUtilities.convertDatesAndCopy(this.state);
    const settings = this.userPreferencesService.getGridSettings(this.preferenceKey);
    if (settings?.state) {
      this.state = {...this.state, ...settings.state};
    }

    this.grid.dataStateChange.pipe(takeUntil(this.destroy$)).subscribe(x => this.onDataStateChange(x));

    this.grid.columnReorder.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.onColumnSettingsChange();
    })

    this.grid.columnResize.pipe(takeUntil(this.destroy$)).subscribe(x => {
      x.forEach(y => {
        const column = y.column as ColumnComponent;
        this.onColumnSettingsChange({ field: column.field, width: y.newWidth });
      })
    })

    this.grid.columnVisibilityChange.pipe(takeUntil(this.destroy$)).subscribe(x => {
      x.columns.forEach(y => {
        const column = y as ColumnComponent;
        this.onColumnSettingsChange({ field: column.field, hidden: column.hidden });
      })
    })

    super.ngOnInit();
  }

  ngAfterViewInit(): void {

    this.templateGridSettings.columnsConfig = [];
    this.grid.columns.map(x => x as ColumnComponent).forEach(x => {
      this.templateGridSettings.columnsConfig.push( { field: x.field, hidden: x.hidden, width: x.width, orderIndex: x.leafIndex });
    });

    const settings = this.userPreferencesService.getGridSettings(this.preferenceKey);
    if (settings) {
      settings.columnsConfig.forEach(x => {
        const column = this.grid.columns.map(y => y as ColumnComponent).find(y => y.field && y.field === x.field);
        if (column) {
          column.hidden = x.hidden == null ? column.hidden : x.hidden;
          column.width = x.width == null ? column.width : x.width;
          column.orderIndex = x.orderIndex == null ? (undefined as unknown) as number : x.orderIndex;
        }
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  private onDataStateChange(state: State): void {
    const settings = this.userPreferencesService.getGridSettings(this.preferenceKey) ?? {  columnsConfig: [], state: {} };
    if (settings) {
      settings.state = state;
      this.userPreferencesService.setGridSettings(this.preferenceKey, settings);
    }
  }

  public resetGridPreferences() {

    this.userPreferencesService.setGridSettings(this.preferenceKey, {  columnsConfig: [], state: {} });

    this.applyState({
      skip: 0,
      sort: [],
      filter: this.state.filter,
      group: [],
      ...this.templateGridSettings.state
    });

    this.data = [];
    this.notifyDataChange();

    // delay, wait for data to clear so autofit does not vary column width based on row values
    timer(100).pipe(first()).subscribe(() => {
      // using auto fit to force the internal grid to resize
      this.grid.autoFitColumns();
      // restoring intial values
      this.templateGridSettings.columnsConfig.forEach((x,i) => {
          const column = this.grid.columns.toArray()[i];
          if (column) {
            column.hidden = x.hidden as boolean;
            column.width = x.width as number;
            column.orderIndex = x.orderIndex as number;
          }
        });
      // reload data
      this.rebind();
    });
  }

  private updateColumn(columnSettings?: ColumnSettings, gridSettings?: GridSettings): void {
    if (!columnSettings?.field || !gridSettings) return;
    let col = gridSettings.columnsConfig.find(x => x.field == columnSettings.field);
    if (!col) {
      col = { field: columnSettings.field  };
      gridSettings.columnsConfig.push(col);
    }
    Object.assign(col, columnSettings);
  }

  private _to_setGridSettings?:number;
  private onColumnSettingsChange(columnSettings?: ColumnSettings): void {
    let gridSettings = this.userPreferencesService.getGridSettings(this.preferenceKey)  ?? {  columnsConfig: [], state: {} };
    this.updateColumn(columnSettings, gridSettings);
    this.userPreferencesService.setGridSettings(this.preferenceKey, gridSettings);
    // We need to delay retrieving index until after event
    window.clearTimeout(this._to_setGridSettings);
    this._to_setGridSettings = window.setTimeout(() => {
        // We'll get this again to make sure we're working with a copy of the RxJs object
        gridSettings = this.userPreferencesService.getGridSettings(this.preferenceKey)  ?? {  columnsConfig: [], state: {} };
        // Use leafIndex, orderIdex is relative, the natural order in the template conflicts with a saved orderIndex
        this.grid.columns.map(x => x as ColumnComponent).filter(x => x.field).forEach(x => {
          this.updateColumn({ field: x.field, orderIndex: x.leafIndex  }, gridSettings);
        });
        this.userPreferencesService.setGridSettings(this.preferenceKey, gridSettings);
      }, 10);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }

}
