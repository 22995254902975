import { Injectable } from '@angular/core';
import { DashboardReportModel } from '@alcon-db-models/DashboardReportModel';
import { DashboardReportRequestModel } from '@alcon-db-models/DashboardReportRequestModel';
import { DashboardMessageModel } from '@alcon-db-models/DashboardMessageModel';
import { Observable } from 'rxjs';
import { AccessControlBaseService } from './access-controlled-base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class DashboardReportService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public getMessageReport(): Observable<DashboardMessageModel[]> {
    return this.post<DashboardMessageModel[]>('DashboardMessageReport', {});
  }

  public getChartReport(dashboardReportRequest: DashboardReportRequestModel): Observable<DashboardReportModel[]> {
    return this.post<DashboardReportModel[]>('DashboardChartReport',dashboardReportRequest);
  }

  public getGridReport(dashboardReportRequest: DashboardReportRequestModel): Observable<any[]> {
    return this.post<any[]>('DashboardGridReport',dashboardReportRequest);
  }


}
