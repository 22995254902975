import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-skeleton-layout',
  templateUrl: './skeleton-layout.component.html',
  styleUrls: ['./skeleton-layout.component.scss']
})
export class SkeletonLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
