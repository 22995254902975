



export class CommitmentDetailPhaseModel  {
    commitmentID?: number | null;
    commitmentDetailID?: number | null;
    commitmentDetailPhaseID?: number | null;
    yearIncrement?: number | null;
    year?: number | null;
    amounts?: number[] | null;
    createdBy?: string | null;
    createdByPersonID?: number | null;
    modifiedBy?: string | null;
    modifiedByPersonID?: number | null;
}

 
     
         
