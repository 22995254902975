import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpdateDraftStatusRequestModel } from '@alcon-db-models/UpdateDraftStatusRequestModel';
import { Store } from '@ngrx/store';
import {AccessControlBaseService} from './access-controlled-base.service';
import { Router } from '@angular/router';
import { DashboardUpsertRequestModel } from '@alcon-db-models/DashboardUpsertRequestModel';
import { DraftRequestModel } from '@alcon-db-models/DraftRequestModel';
import { ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { DashboardModel } from '@alcon-db-models/DashboardModel';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public findByID(dashboardID:number): Promise<DashboardModel|null|undefined> {
    return this.get<DashboardModel>('/Dashboard',{ dashboardID }).toPromise();
  }

  public edit(dashboardID:number, displayName:string): Promise<DashboardModel|null|undefined> {
    return this.post<DashboardModel>('/Dashboard/edit',{ dashboardID, displayName }).toPromise();
  }
  
  public updateFilterValues(dashboardID:number, filterValues:string): Promise<boolean|null|undefined> {
    return this.post<boolean>('/Dashboard/UpdateFilterValues',{ dashboardID, filterValues }).toPromise();
  }

  public upsert(model: DashboardUpsertRequestModel): Promise<DashboardModel> {
    return this.post<any>('/Dashboard',model).toPromise();
  }

  public delete(dashboardID:number): Promise<boolean> {
    return this.post<any>('/Dashboard/delete',dashboardID).toPromise();
  }
}
