import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UserFormBaseService } from './user-form-base.service';
import { UserInfoService } from './user-info.service';

@Injectable()
export class UserEditService extends UserFormBaseService {

  constructor(
    store: Store,
    userInfoService: UserInfoService
  ) {
    super(store, userInfoService);
    this.user$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this.updateForm(x)
    });
  }
}
