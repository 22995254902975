import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ClaimDuplicateModel } from '@alcon-db-models/ClaimDuplicateModel'

@Injectable({
  providedIn: 'root'
})
export class ClaimDuplicateService extends EntityCollectionServiceBase<ClaimDuplicateModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ClaimDuplicate', serviceElementsFactory);
  }
}
