import { CommitmentSearchRequestModel } from '@alcon-db-models/CommitmentSearchRequestModel';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { CommitmentSearchService } from '@services/commitment-search-service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { SearchFlavor } from '../shared/static';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[commitmentSearchBindingDirective]'
})
export class CommitmentSearchBindingDirective extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  private serviceSubscription?: Subscription;

  @Input() preferenceKey: string = "CommitmentSearch";
  @Input() searchFlavor: SearchFlavor = 'search';

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public commitmentSearchService: CommitmentSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.commitmentSearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.commitmentSearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.commitmentSearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): CommitmentSearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      name: getValue('name'),
      commitmentIDs: getValue('commitmentIDs'),
      payeeName: getValue('payeeName'),
      statusCodeIDs: getValue('statusCodeIDs'),
      territoryIDs: getValue('territoryIDs'),
      customerCode: getValue('customerCode'),
      customerName: getValue('customerName'),
      startDate: getValue('startDate'),
      endDate: getValue('endDate'),
      fundYears: getValue('fundYears'),
      fundIDs: getValue('fundIDs'),
      activityIDs: getValue('activityIDs'),
      minAmount: getValue('minAmount'),
      maxAmount: getValue('maxAmount'),
      createdByPersonID: getValue('createdByPersonID'),
      impersonatedByPersonID: getValue('impersonatedByPersonID'),
      excludeCanceledAndDenied: getValue('excludeCanceledAndDenied'),
      isReview: this.searchFlavor === 'review',
      isCloseQueue: this.searchFlavor === 'close',
    });
  }



}
