import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

// TODO: this was orginally created to handle token auth headers.  Rethink need now that we're using an interceptor
export abstract class AccessControlBaseService {

  //TODO: Fix this, it is an odd way to pass services.  Smells bad.  Derived classes should pass using super().
  protected abstract http: HttpClient
  protected abstract router: Router;
  protected abstract store: Store;

  public post<T>(localUrl: string, body: any): Observable<T> {
    return this.http.post<T>(Location.joinWithSlash(environment.baseApiUrl, localUrl), body);
  }

  public get<T>(localUrl: string, params?: any): Observable<T> {
    return this.http.get<T>(Location.joinWithSlash(environment.baseApiUrl, localUrl), { params:params });
  }

  public put<T>(localUrl: string, body: any): Observable<T> {
    return this.http.put<T>(Location.joinWithSlash(environment.baseApiUrl, localUrl), body);
  }

}
