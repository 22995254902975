import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-section-with-legend',
  template: `
    <div class="acb-section-inner" fxLayout="column">
      <legend *ngIf="doShowLegend" (click)="isCollapsible ? toggleCollapse() : undefined;" [ngStyle]="{'cursor': isCollapsible ? 'pointer' : 'initial' }" >
        <ng-container *ngTemplateOutlet="legendTemplate || (isCollapsible ? collapsibleLegendTemplate : defaultLegendTemplate)"></ng-container>
      </legend>
      <div class="acb-section-body" [fxHide]="isCollapsed">
        <ng-content></ng-content>
      </div>
    </div>
    <ng-template #defaultLegendTemplate>
      <h3>{{ sectionTitle }}</h3>
    </ng-template>
    <ng-template #collapsibleLegendTemplate>
      <div fxLayout="row" fxLayoutAlign="start center">
        <h3 style="margin-right:1em;">{{ sectionTitle }}</h3>
        <ng-content select="'[legendContent]'"></ng-content>
        <div fxFlex></div>
        <button class="acb-collapse-button" kendoButton [icon]="isCollapsed ? 'arrow-chevron-down' : 'arrow-chevron-up'" [look]="'clear'" fxFlexAlign="stetch" (click)="toggleCollapse(); $event.stopPropagation();"></button>
      </div>
    </ng-template>
  `,
  styleUrls: ['./section-with-legend.component.scss']
})
export class SectionWithLegendComponent implements OnInit, OnDestroy {

  @Input() sectionTitle?: string = "";
  @Input() legendTemplate?: TemplateRef<any>;
  @Input() doShowLegend: boolean = true;

  private _subjectUpdatedSubscription?: Subscription;
  @Input() set subjectUpdated(value:Subject<boolean>) {
    if (this._subjectUpdatedSubscription) this._subjectUpdatedSubscription.unsubscribe();
    this._subjectUpdatedSubscription = value.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x && !this.isCollapsed) {
        this.toggleCollapse();
      }
    });
  };

  @Output() isCollapsedChanged = new EventEmitter<boolean>();

  public destroy$: Subject<void> = new Subject<void>();

  private _isCollapsible: boolean = false;
  @Input() set isCollapsible(value: boolean) {
    this.isCollapsed = this._isCollapsible = value;
  }
  get isCollapsible() { return this._isCollapsible }

  private _isCollapsed: boolean = false;
  @HostBinding('class.acb-collapsed') set isCollapsed(value:boolean) { this.isCollapsedChanged.emit(this._isCollapsed = value); }
  get isCollapsed() { return this._isCollapsed; }

  constructor() {
  }

  ngOnInit(): void {
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
