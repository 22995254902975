import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { CustomerForDropdownModel } from '@alcon-db-models/CustomerForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class CustomerForDropdownService extends EntityCollectionServiceBase<CustomerForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomerForDropdown', serviceElementsFactory);
  }
}
