import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { EditWithProgressBaseComponent } from '../edit-with-progress.base.component';

class CommitmentSpec {
  constructor(
    public commitmentID: number | undefined,
    public startDate: Date | undefined,
    public endDate: Date | undefined,
    public amount: number = 0
  ) {}
}

@Component({
  selector: 'acb-alcon-edit-commitment-phasing',
  template: `
    <form
      *ngIf="form"
      [formGroup]="form"
      style="width: 100%; height: 100%;"
      class="k-form"
      [ngClass]="{
        'acb-phase-setup-complete': commitmentSpec.amount === assignedAmount,
        'acb-phase-cost-exceeded': (commitmentSpec.amount - assignedAmount) < 0,
        'acb-phase-applied-zero': !assignedAmount
      }">
      <acb-alcon-section-with-legend [sectionTitle]="'Phasing'" class="acb-section-10" [doShowLegend] = "doShowLegend">
        <div *ngIf="commitmentSpec.startDate; else mustSelectStartDate"
          [fxLayout]="'column'">
          <div fxLayout="row" fxLayoutGap="1em" style="margin-bottom:1em; height: 5em;">
            <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label">
              <kendo-label fxLayout="row" fxLayoutAlign="start end" class="acb-progress-top-label"><span>Setup Progress</span></kendo-label>
              <div fxFlex class="acb-phase-progress">
                <ng-container *ngIf="commitmentSpec.amount; else noAmount">
                  <kendo-progressbar
                    #progressBar
                    [min]="0"
                    [max]="commitmentSpec.amount"
                    [value]="assignedAmount"
                    [label]="{ format: getProgressBarFormat, position: 'center' }"
                    emptyCssClass="acb-phase-progressbar-empty"
                    progressCssClass="acb-phase-progressbar-progress"
                    >
                  </kendo-progressbar>
                </ng-container>
                <ng-template #noAmount>
                  <div fxLayout fxLayoutAlign="center center" class="acb-no-progress">
                    <kendo-label>
                      Assign Commiment Cost to view phase progress
                    </kendo-label>
                  </div>
                </ng-template>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label acb-reset-button-wrapper">
              <kendo-label>&nbsp;</kendo-label>
              <button fxFlex kendoButton [icon]="'reset'" (click)="onReset()" [disabled]="!assignedAmount">
              </button>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" style="padding-top: .5em; margin-bottom:.5em;">
            <div gdColumns="repeat(auto-fit, minmax(12em, 1fr))" fxFlex="6 0 auto" class="acb-progress-summary">
              <div class="acb-applied-row" fxLayout="row">
                <span fxFlex="0 0 6em" style="text-align:right">
                  Applied:
                </span>
                <span fxFlex>
                  {{ assignedAmount | currency }}
                </span>
              </div>
              <div class="acb-remaining-row" fxLayout="row">
                <span fxFlex="0 0 6em" style="text-align:right">
                  Remaining:
                </span>
                <span fxFlex>
                  {{ getRemaining() | currency }}
                </span>
              </div>
            </div>
            <div
              fxFlex="1 0 auto"
              fxLayout="row wrap"
              class="acb-phase-quarters"
              fxLayoutAlign="center center"
              [fxHide]="!getAreValidDates()"
              >
              <div *ngFor="let qv of this.quarterAmounts; let i = index;" [ngClass]="{ 'acb-acb-phase-active-quarter': getIsActiveQuarter(i + 1) }" fxLayout="row">
                <div>{{ qv | currency  }}</div><div>Q{{i+1}}</div>
              </div>
            </div>
          </div>
          <acb-alcon-phasing-page *ngFor="let p of $any(phasingFormArray?.controls); let i=index;" [form]="p!" [groupName]="i" [activityMonths]="getActivityMonths(p)">
          </acb-alcon-phasing-page>
        </div>
        <ng-template #mustSelectStartDate>
          <div class="acb-must-select-startdate">
            <span class="k-icon k-i-arrow-left"></span> Select Start Date to apply phasing
          </div>
        </ng-template>
      </acb-alcon-section-with-legend>
    </form>
  `,
  styleUrls: ['./edit-commitment-phasing.component.scss']
})
export class EditCommitmentPhasingComponent extends EditWithProgressBaseComponent implements OnInit, OnDestroy {

  @Input() doShowLegend: boolean = true;

  public commitmentSpec: CommitmentSpec = new CommitmentSpec(undefined,undefined,undefined);
  public get amount(): number {
    return this.commitmentSpec.amount;
  }
  public set amount(value:number) {
    this.commitmentSpec.amount = value;
  }

  public phasingFormArray?: UntypedFormArray;
  public phaseYearDisplayed?: number;
  public form: UntypedFormGroup;

  public quarterAmounts: number[] = new Array(4).fill(0);

  public getAreValidDates() {
    return this.commitmentSpec.startDate?.getTime &&
    this.commitmentSpec.endDate?.getTime &&
    this.commitmentSpec.startDate.getFullYear() == this.commitmentSpec.endDate.getFullYear();
  }

  public getActivityMonths(pageForm: UntypedFormGroup) {
    let months = (new Array(12)).fill(false);
    if (pageForm.controls.yearIncrement?.value >= 0 && this.getAreValidDates()) {
      const yearIncrement = pageForm.controls.yearIncrement.value as number;
      const startTime = moment(this.commitmentSpec.startDate).startOf('month').valueOf();
      const endTime = moment(this.commitmentSpec.endDate).endOf('month').valueOf();
      const year = this.commitmentSpec.startDate!.getFullYear() + yearIncrement;
      const getTime = (m:number) => ((new Date(year, m, 1)).getTime());
      months = months.map((x,m) => getTime(m) >= startTime && getTime(m) <= endTime);
    }
    return months;
  }

  public getIsActiveQuarter(q:number) {
    return this.getAreValidDates() &&
      q >= moment(this.commitmentSpec.startDate).quarter() &&
      q <= moment(this.commitmentSpec.endDate).quarter();
  }

  constructor(
    private appUxService: AppUxService,
    private store: Store,
    commitmentFormBaseService: CommitmentFormBaseService
  ) {

    super();

    this.form = commitmentFormBaseService.form;
    this.phasingFormArray = this.form?.controls.commitmentPhases as UntypedFormArray;

    commitmentFormBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitmentSpec = {
        commitmentID: x.commitmentID != null ? x.commitmentID : undefined,
        startDate: x.startDate ?? undefined,
        endDate: x.endDate ?? undefined,
        amount: x.amount ?? 0
      };
      this.phaseYearDisplayed = this.commitmentSpec.startDate?.getFullYear();
    })

    this.onFormChanges(null);
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onFormChanges);
  }

  public getMonthName(monthNum:number) {
    return moment.monthsShort(monthNum);
  }

  public getQuarters() {
    return []
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected onFormChanges = ((event:any) => {

    const phaseForms = this.phasingFormArray?.controls as UntypedFormGroup[];
    if (!phaseForms?.length)
      return;

    const amount = phaseForms
      .map(x => (((x as UntypedFormGroup)?.controls?.amounts as UntypedFormArray)?.value as number[] ?? []).reduce((a, v) => a + v))
      .reduce((a,v) => a + v)
      ?? 0;
    this.assignedAmount = Math.round((amount * 100) + Number.EPSILON) / 100;

    if (!this.phaseYearDisplayed || !this.commitmentSpec.startDate?.getFullYear)
      return;

    const yearIncrement = this.phaseYearDisplayed - this.commitmentSpec.startDate?.getFullYear();
    const currentAmounts = ((phaseForms.find(x => x.controls?.yearIncrement?.value === yearIncrement)?.controls?.amounts as UntypedFormArray)?.value as number[] ?? []);

    if (currentAmounts?.length < 12) {
      this.quarterAmounts.fill(0);
    } else {
      this.quarterAmounts[0] = currentAmounts.slice(0,3).reduce((a,v) => a + v);
      this.quarterAmounts[1] = currentAmounts.slice(3,6).reduce((a,v) => a + v);
      this.quarterAmounts[2] = currentAmounts.slice(6,9).reduce((a,v) => a + v);
      this.quarterAmounts[3] = currentAmounts.slice(9,12).reduce((a,v) => a + v);
    }
  }).bind(this);

  public onReset = (() => {
    this.appUxService.openConfirmation('Reset?', "Are you sure you want to reset phasing to $0?", undefined, 190, 'alcon-background-orange', 'acb-section-09').then((confirm) => {
      if (!confirm)
        return;

      const phaseForms = this.phasingFormArray?.controls as UntypedFormGroup[];
      if (!phaseForms?.length)
        return;

      phaseForms.forEach(x => (((x as UntypedFormGroup)?.controls?.amounts as UntypedFormArray)?.setValue((new Array(12)).fill(0))));
      this.phasingFormArray?.updateValueAndValidity();

    });
  }).bind(this);

  public onDistribute = (() => {

  }).bind(this);


  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

