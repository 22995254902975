import { CustomerWithDetailsModel } from '@alcon-db-models/CustomerWithDetailsModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class CustomerWithDetailsService extends EntityCollectionServiceBase<CustomerWithDetailsModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomerWithDetails', serviceElementsFactory);
  }
}

