import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppSessionEffects } from './app-session.effects'
import { appSessionReducer } from './app-session.reducer'
import { appSessionFeatureKey } from './app-session.state'


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(appSessionFeatureKey, appSessionReducer),
    EffectsModule.forFeature([AppSessionEffects])
  ],
  providers: [AppSessionEffects]
})
export class AppSessionModule {}
