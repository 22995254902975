<div fxLayout="column" *ngIf="!(loading | async)">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start end" fxLayoutAlign.lt-md class="acb-details-wrapper" [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'">
    <acb-alcon-view-commitment-details [commitment]="commitment" fxFlex>
    </acb-alcon-view-commitment-details>
    <div class="acb-edit-button-wrapper">
      <button
        kendoButton
        class="acb-edit-button"
        [ngClass] = "{ 'acb-error': this.fundValid && !this.detailsValid }"
        (click)="onEditDetails()"
        [disabled]="!canEditDetails"
        >
      <span class="k-icon k-i-edit"></span> Edit</button>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">
    <div
      class="acb-customer acb-view-mode"
      fxLayout="row"
      fxFlex="1 0 16em"
      fxFlex.lt-sm="grow"
      [ngClass]="{ 'acb-expanded': customerDisplay.isExpanded }"
      [ngClass.gt-xs]="{ 'acb-expanded': customerDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
      >
      <div class="acb-details-layout-section acb-section2" fxFlex fxLayout="column">
        <acb-alcon-display-address
          #customerDisplay
          [label]="'Customer'"
          [code]="commitment?.customerCode"
          [name]="commitment?.customer"
          [location]="commitment?.customerLocation"
          [isExpandedOnly]="false"
          fxFlex
          ></acb-alcon-display-address>
        <div class="acb-edit-button-wrapper" [hidden]="!customerDisplay.isExpanded">
          <button kendoButton class="acb-edit-button" (click)="onEditCustomer()"><span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </div>

    <div
      class="acb-payee acb-view-mode"
      fxLayout="row"
      fxFlex="1 0 16em"
      fxFlex.lt-sm="grow"
      [ngClass]="{ 'acb-expanded': payeeDisplay.isExpanded }"
      [ngClass.gt-xs]="{ 'acb-expanded': payeeDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
      >
      <div class="acb-details-layout-section acb-section3" fxFlex fxLayout="column">
        <acb-alcon-display-address
          #payeeDisplay
          [label]="'Payee'"
          [code]="commitment?.payeeCustomerCode"
          [name]="commitment?.payee"
          [location]="commitment?.payeeLocation"
          [isExpandedOnly]="false"
          fxFlex
          ></acb-alcon-display-address>
        <div class="acb-edit-button-wrapper" [hidden]="!payeeDisplay.isExpanded">
          <button kendoButton class="acb-edit-button" (click)="onEditPayee()"><span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </div>

    <div
      class="acb-fund acb-view-mode"
      fxLayout="row"
      fxFlex="1 0 16em"
      fxFlex.lt-sm="grow"
      [ngClass]="{ 'acb-expanded': fundDisplay.isExpanded, 'acb-has-value': commitment?.fund }"
      [ngClass.gt-xs]="{ 'acb-expanded': fundDisplay.isExpanded, 'acb-has-value': commitment?.fund, 'acb-fx-intra-form-margin-controlled-container': true }"
      >
      <div class="acb-details-layout-section acb-section4" fxFlex fxLayout="column">
        <acb-alcon-display-info-card
          #fundDisplay
          [label]="'Fund'"
          [headerValue]="commitment?.fund"
          [nameAndValues] = "[
            ['ID', commitment?.fundCode, false],
            ['Name', commitment?.fund, true]
          ]"
          [isExpandedOnly]="!fundValid"
          fxFlex
          ></acb-alcon-display-info-card>
        <div class="acb-edit-button-wrapper" [hidden]="!fundDisplay.isExpanded">
          <button
            kendoButton
            class="acb-edit-button"
            [ngClass] = "{ 'acb-error': !fundValid }"
            (click)="onEditFund()"
            >
          <span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch" style="margin-bottom: -1em;">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" [ngClass.gt-xs]="'acb-intra-form-margin-correction'" fxFlex="1 1 100%">
      <acb-alcon-section-with-legend
        *ngIf="doesUseCommitmentProducts"
        #productsSection
        [isCollapsible]="false"
        [sectionTitle]="'Products'"
        class="acb-section-11 acb-products-section"
        fxFlex="calc(33.333% - 1em)"
        [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
        >
        <div fxLayout="column" style="height: 100%;">
          <acb-alcon-view-commitment-products [commitment]="commitment" [viewMode]="'edit'" fxFlex>
          </acb-alcon-view-commitment-products>
          <div class="acb-edit-button-wrapper">
            <button
              kendoButton
              class="acb-edit-button"
              [ngClass] = "{ 'acb-error': !productsValid }"
              (click)="onEditProducts()"
              >
            <span class="k-icon k-i-edit"></span> Edit</button>
          </div>
        </div>
      </acb-alcon-section-with-legend>


      <acb-alcon-section-with-legend
        [isCollapsible]="false"
        [sectionTitle]="'Phasing'"
        class="acb-section-10 acb-phasing-section"
        fxFlex="calc(22% - 1em)"
        [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
        >
        <div fxLayout="column" style="height: 100%;">
          <acb-alcon-view-commitment-phasing  [viewMode]="'edit'" fxFlex>
          </acb-alcon-view-commitment-phasing>
          <div class="acb-edit-button-wrapper">
            <button
              kendoButton
              class="acb-edit-button"
              [ngClass] = "{ 'acb-error': !phasingValid }"
              (click)="onEditPhasing()"
              >
            <span class="k-icon k-i-edit"></span> Edit</button>
          </div>
        </div>
      </acb-alcon-section-with-legend>
      <acb-alcon-section-with-legend
        #attachmentsSection
        [isCollapsible]="false"
        [sectionTitle]="'Attachments'"
        class="acb-section-06 acb-attachments-section"
        fxFlex="calc(44.666% - 1em)"
        [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
        >
        <div fxLayout="column" style="height: 100%;">
          <acb-alcon-view-commitment-attachments [viewMode]="'view-edit'" [commitment]="commitment" [doShowFieldLabel]="false" >
          </acb-alcon-view-commitment-attachments>
          <div class="acb-edit-button-wrapper">
            <button kendoButton class="acb-edit-button" (click)="onEditAttachments()"><span class="k-icon k-i-edit"></span> Edit</button>
          </div>
        </div>
      </acb-alcon-section-with-legend>
    </div>
  </div>
</div>

<kendo-dialog class="acb-error-dialog" title="Action required" *ngIf="errorDialogVisible" (close)="errorDialogVisible = false">
  <p style="margin: 30px; text-align: center;">Error saving commitment</p>
  <br>
  <p>{{ errorMessage }}</p>
  <kendo-dialog-actions>
      <button kendoButton (click)="errorDialogVisible = false">Ok</button>
  </kendo-dialog-actions>
</kendo-dialog>



