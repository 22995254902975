import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { FundGroupForDropdownModel } from '@alcon-db-models/FundGroupForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class FundGroupForDropdownService extends EntityCollectionServiceBase<FundGroupForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('FundGroupForDropdown', serviceElementsFactory);
  }
}
