import { ChangeDetectorRef, Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivityForDropdownService } from './activity-for-dropdown.service';
import { ClaimAttachmentsService } from './claim-attachments.service';
import { ClaimFormBaseService } from './claim-form-base.service';
import { ClaimUpsertServiceService } from './claim-upsert-service.service';
import { ClaimWithDetailsService } from './claim-with-details.service';
import { CommitmentWithDetailsService } from './commitment-with-details.service';
import { DraftWithDetailsService } from './draft-with-details.service';
import { ProductForDropdownService } from './product-for-dropdown.service';
import { WizardFeatureService } from './wizard-feature.service';

@Injectable()
export class ClaimEditService extends ClaimFormBaseService {

  constructor(
    store: Store,
    claimWithDetailsService: ClaimWithDetailsService,
    claimUpsertService : ClaimUpsertServiceService,
    activityForDropdownService: ActivityForDropdownService,
    commitmentWithDetailsService: CommitmentWithDetailsService,
    productForDropdownService: ProductForDropdownService,
    claimAttachmentsService: ClaimAttachmentsService,
    changeDetectorRef: ChangeDetectorRef,
    draftWithDetailsService: DraftWithDetailsService,
    @Optional() wizardFeatureService?: WizardFeatureService
  ) {
    super(store, claimWithDetailsService, draftWithDetailsService, claimUpsertService, activityForDropdownService, commitmentWithDetailsService, productForDropdownService, claimAttachmentsService, changeDetectorRef, wizardFeatureService);
    //TODO: this is a hack so validation knows if we're in edit (ClaimedAmount is conditionally disabled)
    this.isEdit = true;
  }
}

