import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({ template: '' })
export class LogoutPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // We're not actually doing anything in this component.  Log out is ond by store effect
    // TODO: Is there a better way? ^
    this.router.navigate(['/login']);
  }
}
