import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { ClaimUpsertRequestModel } from '@alcon-db-models/ClaimUpsertRequestModel'
import { Observable } from 'rxjs';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class ClaimUpsertServiceService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public upsertClaim(claim: ClaimUpsertRequestModel, doShowOverlay: boolean = true ): Observable<ServiceResponse<number>> {
    return claim.claimID ? this.saveClaim(claim, doShowOverlay) : this.createClaim(claim, doShowOverlay);
  }

  public createClaim(claim: ClaimUpsertRequestModel, doShowOverlay: boolean = true ): Observable<ServiceResponse<number>> {
    return this.post<number>('ClaimUpsert', claim).pipe(
      firstWithLoadingOverlayAndErrorHandling<number>(doShowOverlay)
    );
  }

  public saveClaim(claim: ClaimUpsertRequestModel, doShowOverlay: boolean = true ): Observable<ServiceResponse<number>> {
    return this.put<number>('ClaimUpsert/' + claim?.claimID, claim).pipe(
      firstWithLoadingOverlayAndErrorHandling<number>(doShowOverlay)
    );
  }

}
