import { Injectable } from '@angular/core';
import { updateDashboardPreferences, updateGridPreferences } from '@app-store/user-preferences/user-preferences.actions';
import { selectDashboardPreferences, selectGridPreferences } from '@app-store/user-preferences/user-preferences.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { DashboardSettings, GridSettings } from '../components/core/core.module';
import { JsonUtilities } from '../shared/json-utilities';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(private store: Store) { }

  public getGridSettings(key: string) {
    let gridPreferences: { [name: string]: GridSettings } = {};
    this.store.select(selectGridPreferences).pipe(first()).subscribe(x => {
      gridPreferences = x;
    });
    const gridSetting = gridPreferences ? gridPreferences[key] : undefined;
    return gridSetting ? JsonUtilities.convertDatesAndCopy(gridSetting) as GridSettings : undefined;
  }

  public setGridSettings(key: string, settings: GridSettings ): void {
    let gridPreferences: { [name: string]: GridSettings } = {};
    this.store.select(selectGridPreferences).pipe(first()).subscribe(x => {
      gridPreferences = {...x};
    });
    gridPreferences ??= (gridPreferences ?? {});
    gridPreferences[key] = settings
    this.store.dispatch(updateGridPreferences({ data: gridPreferences }));
  }

  public getDashboardSettings() {
    let dashboardSettings: DashboardSettings | null = null;
    this.store.select(selectDashboardPreferences).pipe(first()).subscribe(x => {
      dashboardSettings = x;
    });
    return dashboardSettings ? JsonUtilities.convertDatesAndCopy(dashboardSettings) as DashboardSettings : null;
  }

  public setDashboardSettings(dashboardSettings: DashboardSettings): void {
    this.store.dispatch(updateDashboardPreferences({ data: dashboardSettings }));
  }

}
