import { createReducer, on, Action } from '@ngrx/store';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { Utilities } from 'src/app/shared/utilities';
import * as AppActions from './app-session.actions';
import { initialState, AppSessionState } from './app-session.state'


export const appSessionReducer = createReducer(
  initialState,

  on(AppActions.updateIsReady,  (state, action) => {
    const newState = {
      ...state,
      isReady: action?.payload
    };
    return newState;
  }),

  on(AppActions.updateAppSession, (state, action) => ({
    ...state,
    ...Utilities.getShallowObjectCopyWithoutNullProperties(action?.payload)
  })),

  on(AppActions.logInUser, (state) => {
    return {
    ...state,
  }}),

  on(AppActions.userLoggedOut, (state) => ({
    ...state,
    memoryOnlyRefreshToken: null,
    accessPerson: null,
    currentPerson: null,
    doKeepMeLoggedIn: false,
    isWaitingForPasswordReset: false,
    userName: null
  })),


  on(AppActions.userLoginSuccess, (state, action) => ({
    ...state,
    memoryOnlyRefreshToken: action?.payload.memoryOnlyRefreshToken ?? null,
  })),

  on(AppActions.userLoginFailure, (state, action) => ({
    ...state,
    userName: null,
    accessPerson: null,
    currentPerson: null,
    lastError: action.payload,
  })),

   on(AppActions.tokenRefreshSuccess, (state, action) => ({
    ...state,
    memoryOnlyRefreshToken: action?.payload.memoryOnlyRefreshToken ?? null,

  })),

  on(AppActions.updateMemoryOnlyRefreshToken, (state, action) => ({
    ...state,
    memoryOnlyRefreshToken: action?.payload
  })),

  on(AppActions.updateAccessPerson, (state, action) => ({
    ...state,
    accessPerson: { ...action?.payload }
  })),

  on(AppActions.updateAccessPerson, (state, action) => {
    return {
      ...state,
      accessPerson: { ...action?.payload }
    };
  }),

  on(AppActions.updateCurrentPerson, (state, action) => { 
    return {
      ...state,
      currentPerson: action?.payload ? JsonUtilities.convertDatesAndCopy(action?.payload) : null
    };
    //state = JsonUtilities.convertDatesAndCopy(state);
    // state.currentPerson = action?.payload ? JsonUtilities.convertDatesAndCopy(action?.payload) : null;
    // return state;
  }),

  on(AppActions.updateDoKeepMeLoggedIn, (state, action) => ({
    ...state,
    doKeepMeLoggedIn: action?.payload
  })),

  on(AppActions.updateFeatureMap, (state, action) => ({
    ...state,
    featureMap: action.payload
  })),

  on(AppActions.updateThemes, (state, action) => ({
    ...state,
    themes: action.payload
  })),

);

export function reducer(state: AppSessionState | undefined, action: Action) {
  return appSessionReducer(state, action);
}
