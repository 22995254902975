import { AppSessionState } from './app-session'
import { AppPersistedSettingsState } from './app-persisted-settings'
import { UserPreferencesState } from './user-preferences'
import { ClientStorageService } from '@services/client-storage.service';
import { AppConfigService } from '@services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfigurationModel } from '@alcon-db-models/ApplicationConfigurationModel';
import { first } from 'rxjs/operators';

export interface RootState {
  isReady: boolean;
  AppSession: AppSessionState.AppSessionState | null;
  AppPersistedSettings: AppPersistedSettingsState.AppPersistedSettingsState | null;
  UserPreferencesState: UserPreferencesState.UserPreferencesState | null;
}

export const initialState: RootState = {
  isReady: false,
  AppSession: null,
  AppPersistedSettings: null,
  UserPreferencesState: null
};

export function appStateFactory(clientStorageService: ClientStorageService) {
  return {
    ...AppPersistedSettingsState.appPersistedSettingsIntialStateFactory(clientStorageService),
    ...AppSessionState.appIntialIntialStateFactory(clientStorageService)
  }
}