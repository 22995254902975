import { CommitmentOrganizationsModel } from '@alcon-db-models/CommitmentOrganizationsModel';
import { CustomerEmailModel } from '@alcon-db-models/CustomerEmailModel';
import { FeatureMandateType, FeatureType } from '@alcon-db-models/Enums';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { Injectable } from '@angular/core';
import { DialogRef, DialogService, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { BehaviorSubject, Observable, of, race, timer } from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';
import { BatchAllocationStepDialogComponent } from '../components/batch-allocation-step/batch-allocation-step-dialog.component';
import { EditFeatureType, ViewMode } from '../components/components.module';
import { FundAdjustment } from '../components/core/core.module';
import { EditAuditInstructionComponent } from '../components/edit-audit-instruction/edit-audit-instruction.component';
import { EditClaimDetailsDialogComponent } from '../components/edit-claim-details/edit-claim-details-dialog.component';
import { EditClaimProductsDialogComponent } from '../components/edit-claim-products/edit-claim-products-dialog.component';
import { EditClaimDialogComponent } from '../components/edit-claim/edit-claim-dialog.component';
import { EditCommitmentApprovalRuleComponent } from '../components/edit-commitment-approval-rule/edit-commitment-approval-rule.component';
import { EditCommitmentDetailsDialogComponent } from '../components/edit-commitment-details/edit-commitment-details-dialog.component';
import { EditCommitmentPhasingsDialogComponent } from '../components/edit-commitment-phasing/edit-commitment-phasings-dialog.component';
import { EditCommitmentProductsDialogComponent } from '../components/edit-commitment-products/edit-commitment-products-dialog.component';
import { EditCommitmentDialogComponent } from '../components/edit-commitment/edit-commitment-dialog.component';
import { EditCustomerComponent } from '../components/edit-customer/edit-customer.component';
import { EditEmailComponent } from '../components/edit-email/edit-email.component';
import { EditFeatureComponent } from '../components/edit-feature/edit-feature.component';
import { EditFundComponent } from '../components/edit-fund/edit-fund.component';
import { CreateUserDialogComponent } from '../components/edit-user/create-user-dialog.component';
import { EditUserDialogComponent } from '../components/edit-user/edit-user-dialog.component';
import { RequestClaimVoidComponent } from '../components/request-claim-void/request-claim-void.component';
import { RequestCommentComponent } from '../components/request-comment/request-comment.component';
import { RequestConfirmationComponent } from '../components/request-confirmation/request-confirmation.component';
import { RequestNameComponent } from '../components/request-name/request-name.component';
import { RequestReviewCommentComponent } from '../components/request-review-comment/request-review-comment.component';
import { SelectAttachmentsDialogComponent } from '../components/select-attachments/select-attachments-dialog.component';
import { SelectCommitmentDialogComponent } from '../components/select-commitment/select-commitment-dialog.component';
import { SelectCustomerComponent } from '../components/select-customer/select-customer.component';
import { SelectFundComponent } from '../components/select-fund/select-fund.component';
import { SelectPayeeCustomerDialogComponent } from '../components/select-payee/select-payee-customer-dialog.component';
import { SelectPayeeDialogComponent } from '../components/select-payee/select-payee-dialog.component';
import { ViewErrorResultComponent } from '../components/view-error-result/view-error-result.component';
import { ClaimReviewMode, ReviewClaimsWindowComponent } from '../containers/claims/review-claims-window/review-claims-window.component';
import { ViewClaimWindowComponent } from '../containers/claims/view-claim-window/view-claim-window.component';
import { ReviewCommitmentsWindowComponent, ReviewMode } from '../containers/commitments/review-commitments-window/review-commitments-window.component';
import { ViewCommitmentWindowComponent } from '../containers/commitments/view-commitment-window/view-commitment-window.component';
import { CustomerViewMode } from '../shared/static';
import { AppWindowServiceBase } from './app-window-service.base';
import { AppWindowSubject } from './app-window-subject';
import { WindowComService } from './window-com.service';
import { RequestDateComponent } from '../components/request-date/request-date.component';

@Injectable({
  providedIn: 'root'
})
export class AppWindowService extends AppWindowServiceBase {

  constructor(private windowService:WindowService, private dialogService: DialogService, private windowComService: WindowComService) {
    super();
  }


  // --------------------------------------------------------------------------
  public openViewClaim(claimID: number): { windowRef: WindowRef, component: ViewClaimWindowComponent, windowSubject: AppWindowSubject<ViewClaimWindowComponent> } | null {

    const desiredWidth = 1100;
    const desiredPadding = 15;
    const forceMaximizeThreshold = 600;

    const windowSubject = new AppWindowSubject<ViewClaimWindowComponent>(this.windowService,desiredWidth,desiredPadding,forceMaximizeThreshold,this.windowComService);
    const spec = windowSubject.openSubject({ content: ViewClaimWindowComponent, title: 'Claim #' + claimID})

    if (spec?.component)
      spec.component.claimID = claimID;

    return spec ? { windowRef: spec.windowRef, component: spec.component, windowSubject } : null;
  }

  // --------------------------------------------------------------------------
  public openEditClaim(claimID:number): { dialogRef: DialogRef, component: EditClaimDialogComponent } | null {

    this.windowComService.closeAllWindows();

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditClaimDialogComponent,
      width: 'min(1180px, calc(100vw - var(--acb-dialog-margin)))',
      minWidth: 320,
      minHeight: 200,
      maxWidth: 320,
      maxHeight: 'calc(100vh - var(--acb-dialog-margin))',
    });

    const component = (dialogRef.content.instance as EditClaimDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.claimID = claimID;

    component.loading$.subscribe((x: boolean) => {
      if (x) {
        dialogRef.dialog.instance.maxWidth = 320;
        dialogRef.dialog.instance.height = 200;
      } else {
        dialogRef.dialog.instance.maxWidth = "unset";
        dialogRef.dialog.instance.height = "unset";
      }
    })

    component.saving$.subscribe((x: boolean) => {
      if (x) {
        dialogRef.dialog.instance.maxWidth = 320;
        dialogRef.dialog.instance.height = 200;
      } else {
        dialogRef.dialog.instance.maxWidth = "unset";
        dialogRef.dialog.instance.height = "unset";
      }
    })

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openReviewClaim(claimIDs: number[], claimReviewMode: ClaimReviewMode, comment?:string): { dialogRef: DialogRef, component: ReviewClaimsWindowComponent } | null {

    let title: string = 'review';
    const plural: string = claimIDs?.length > 1 ? 'S' : '';
    switch (claimReviewMode) {
      case 'audit':
        title = "AUDIT CLAIM" + plural;
        break;
      case 'check':
        title = "CHECK CLAIM" + plural;
        break;
      default:
        title = "REVIEW CLAIM" + plural;
    }

    const height = 820;
    const dialogRef: DialogRef = this.dialogService.open({
      content: ReviewClaimsWindowComponent,
      title: title,
      width: 'min(1428px, calc(100vw - var(--acb-dialog-margin)))',
      height: height,
      minWidth: 320,
      minHeight: 200,
      maxHeight: 'calc(100vh - var(--acb-dialog-margin))',
    });

    const component = (dialogRef.content.instance as ReviewClaimsWindowComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.comment = comment ?? '';
    component.claimReviewMode = claimReviewMode;
    component.claimIDs = claimIDs;

    component.closed.pipe(take(1)).subscribe(() => {
        dialogRef.close();
    });

    const nativeWindow: HTMLElement = (dialogRef.dialog?.instance as any)?.el?.nativeElement;
    nativeWindow?.classList.add('acb-review-window');

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditClaimDetails(): { dialogRef: DialogRef, component: EditClaimDetailsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditClaimDetailsDialogComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditClaimDetailsDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    const sub = component.viewCommitment.subscribe((x: number) => {
      this.openViewCommitment(x);
    })

    const sub2 = component.viewClaim.subscribe((x: number) => {
      this.openViewClaim(x);
    })

    dialogRef.dialog.instance.close.pipe(first()).subscribe(x => {
      sub.unsubscribe();
    })


    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openViewCommitment(commitmentID: number): { windowRef: WindowRef, component: ViewCommitmentWindowComponent, windowSubject: AppWindowSubject<ViewCommitmentWindowComponent> } | null {

    const desiredWidth = 1100;
    const desiredPadding = 15;
    const forceMaximizeThreshold = 600;

    const windowSubject = new AppWindowSubject<ViewCommitmentWindowComponent>(this.windowService,desiredWidth,desiredPadding,forceMaximizeThreshold,this.windowComService);
    const spec = windowSubject.openSubject({ content: ViewCommitmentWindowComponent, title: 'COMMITMENT #' + commitmentID})

    if (spec?.component)
      spec.component.commitmentID = commitmentID;

    return spec ? { windowRef: spec.windowRef, component: spec.component, windowSubject } : null;
  }

  // --------------------------------------------------------------------------
  public openRequestName(title: string, nameLabel?: string, descriptionLabel?: string, nameValue?: string | null, descriptionValue?: string | null, width: number = 480, height: number = 300): Observable<{ name: string, description: string } | null> {

    title = title ?? 'Enter Name';

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestNameComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
      autoFocusedElement: '[formcontrolname="name"]'
    });

    const requestNameComponent = (dialogRef?.content?.instance as RequestNameComponent);
    if (!requestNameComponent) {
      dialogRef.close();
      return of(null);
    }

    if (title)
      requestNameComponent.title = title;
    if (nameLabel)
      requestNameComponent.nameLabel = nameLabel;
    if (descriptionLabel)
      requestNameComponent.descriptionLabel = descriptionLabel;
    if (nameValue)
      requestNameComponent.nameValue = nameValue;
    if (descriptionValue)
      requestNameComponent.descriptionValue = descriptionValue;

    return race(
      requestNameComponent.save.pipe(take(1), map(x => {
        dialogRef.close();
        return { name: x?.name, description: x?.description };
      })),
      requestNameComponent.cancel.pipe(take(1),map(() => {
        dialogRef.close();
        return null;
      }))

    );
  }

  // --------------------------------------------------------------------------
  public openRequestDate(title: string, dateLabel?: string, dateValue?: Date | null, width: number = 480, height: number = 300): Observable<{ date: Date } | null> {

    title = title ?? 'Enter Date';

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestDateComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
      autoFocusedElement: '[formcontrolname="date"]'
    });

    const requestDateComponent = (dialogRef?.content?.instance as RequestDateComponent);
    if (!requestDateComponent) {
      dialogRef.close();
      return of(null);
    }

    if (title)
      requestDateComponent.title = title;
    if (dateLabel)
      requestDateComponent.dateLabel = dateLabel;
    if (dateValue)
      requestDateComponent.dateValue = dateValue;

    return race(
      requestDateComponent.save.pipe(take(1), map(x => {
        dialogRef.close();
        return { date: x?.date };
      })),
      requestDateComponent.cancel.pipe(take(1),map(() => {
        dialogRef.close();
        return null;
      }))

    );
  }

  // --------------------------------------------------------------------------
  public openVoidClaimPrompt(claimCount: number): Observable<{ comment: string, doReissueClaim: boolean } | null>  {

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestClaimVoidComponent,
      width: 400,
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as RequestClaimVoidComponent);
    if (!component) {
      dialogRef.close();
      return of(null);
    }

    component.claimCount = claimCount;

    return race(
      component.save.pipe(take(1), map(x => {
        dialogRef.close();
        return { comment: x.comment, doReissueClaim: x.doReissueClaim };
      }))
      ,
      component.cancel.pipe(take(1),map(() => {
        dialogRef.close();
        return null;
      }))
    );


  }

  // --------------------------------------------------------------------------
  public openRequestComment(title: string, commentLabel?: string, commentValue?: string | null, width: number = 480, height: number | undefined = undefined): Observable<{ comment: string } | null> {

    title = title ?? 'Enter Comment';

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestCommentComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
      autoFocusedElement: '[formcontrolname="comment"]'
    });

    const requestCommentComponent = (dialogRef?.content?.instance as RequestCommentComponent);
    if (!requestCommentComponent) {
      dialogRef.close();
      return of(null);
    }

    if (title)
    requestCommentComponent.title = title;
    if (commentLabel)
      requestCommentComponent.commentLabel = commentLabel;
    if (commentValue)
      requestCommentComponent.commentValue = commentValue;

    return race(
      requestCommentComponent.save.pipe(take(1), map(x => {
        dialogRef.close();
        return { comment: x?.comment };
      })),
      requestCommentComponent.cancel.pipe(take(1),map(() => {
        dialogRef.close();
        return null;
      }))
    );

  }

  // --------------------------------------------------------------------------
  public openRequestReviewComment(
    commitmentIDs: number[],
    action: 'approve' | 'deny',
    title?: string,
    titleBarStyle?: string,
    bodyStyle?: string,
    width = 540,
    height?: number
    ): Observable<{ action: 'save' | 'cancel' | 'review', comment: string | undefined } | undefined> {

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestReviewCommentComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
      autoFocusedElement: '[formcontrolname="comment"]'
    });

    const requestReviewCommentComponent = (dialogRef?.content?.instance as RequestReviewCommentComponent);
    if (!requestReviewCommentComponent) {
      dialogRef.close();
      return of({action: 'cancel', comment: undefined});
    }

    requestReviewCommentComponent.commitmentIDs = commitmentIDs;
    requestReviewCommentComponent.action = action;

    if (title)
      requestReviewCommentComponent.title = title;
    if (titleBarStyle)
      requestReviewCommentComponent.titleBarStyle = titleBarStyle;
    if (bodyStyle)
      requestReviewCommentComponent.bodyStyle = bodyStyle;

    return race(
      requestReviewCommentComponent.save.pipe(take(1), map(x => {
        dialogRef.close();
        return { action: 'save' as 'save' | 'cancel' | 'review', comment: x };
      })),
      requestReviewCommentComponent.cancel.pipe(take(1),map(() => {
        dialogRef.close();
        return { action: 'cancel' as 'save' | 'cancel' | 'review', comment: undefined};
      })),
      requestReviewCommentComponent.review.pipe(take(1),map(x => {
        setTimeout(dialogRef.close, 250);
        return { action: 'review' as 'save' | 'cancel' | 'review', comment: x};
      }))
    );
  }

  // --------------------------------------------------------------------------
  public openConfirmation(
    title: string = "Confirmation",
    body: string = "Are you sure?",
    width: number = 480,
    height: number = 300,
    titleClass?: string,
    sectionWithLegendClass?: string
    ): Promise<boolean|undefined> {

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestConfirmationComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
    });

    const requestConfirmationComponent = (dialogRef?.content?.instance as RequestConfirmationComponent);
    if (!requestConfirmationComponent) {
      dialogRef.close();
      return Promise.resolve(false)
    }

    Object.assign(requestConfirmationComponent, { title, body, titleClass, sectionWithLegendClass })

    return race(
      requestConfirmationComponent.yes.pipe(take(1), map(x => {
        dialogRef.close();
        return true;
      })),
      requestConfirmationComponent.no.pipe(take(1),map(() => {
        dialogRef.close();
        return false;
      }))
    ).toPromise();
  }

  // --------------------------------------------------------------------------
  public openSelectCommitment(commitmentID?: number | null | undefined): { dialogRef: DialogRef, component: SelectCommitmentDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectCommitmentDialogComponent,
      width: 'min(1020px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      maxHeight: '100vh',
    });

    const component = (dialogRef.content.instance as SelectCommitmentDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.commitmentID = commitmentID ?? undefined;

    return { dialogRef: dialogRef, component: component };
  }



  // --------------------------------------------------------------------------
  public openSelectCustomer(customerID?: number | null | undefined): { dialogRef: DialogRef, component: SelectCustomerComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectCustomerComponent,
      width: 'min(920px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      maxHeight: '100vh',

      autoFocusedElement: '[formcontrolname="customerCodeOrName"]',
    });

    const component = (dialogRef.content.instance as SelectCustomerComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    // HACK: fix this, quick way to get around binding directive late assignment
    timer(10).pipe(first()).subscribe(() => component.customerID = customerID);

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openSelectPayee(commitmentOrganizations: CommitmentOrganizationsModel): { dialogRef: DialogRef, component: SelectPayeeDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectPayeeDialogComponent,
      width: 'min(920px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      maxHeight: '100vh'
    });

    const component = (dialogRef.content.instance as SelectPayeeDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.commitmentOrganizations =  commitmentOrganizations;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openSelectPayeeCustomer(payeeSpec: { customerID?: number | null | undefined, payeeCustomerID?: number | null | undefined } | null): { dialogRef: DialogRef, component: SelectPayeeCustomerDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectPayeeCustomerDialogComponent,
      width: 'min(920px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      maxHeight: '100vh',
      autoFocusedElement: '[formcontrolname="customerCodeOrName"]',
    });

    const component = (dialogRef.content.instance as SelectPayeeCustomerDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.payeeSpec =  payeeSpec;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openSelectFund(customerID?: number, fundID?: number | null | undefined, adjustments?: FundAdjustment[], fundYears?: number[], doShowHidden?: boolean): { dialogRef: DialogRef, component: SelectFundComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectFundComponent,
      width: adjustments?.length ? 'min(920px, 100vw)' : 'min(820px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      autoFocusedElement: '[formcontrolname="fundNameOrYear"]',
    });

    const component = (dialogRef.content.instance as SelectFundComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.fundAdjustments = adjustments ?? [];

    // HACK: fix this, quick way to get around binding directive late assignment
    timer(10).pipe(first()).subscribe(() => component.fundSpec = {  customerID: customerID, fundID: fundID ?? undefined, fundYears: fundYears, doShowHidden: doShowHidden });

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openCreatePayee(customerViewMode:CustomerViewMode): { dialogRef: DialogRef, component: EditCustomerComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCustomerComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      autoFocusedElement: '[formcontrolname="displayName"]',
    });

    const component = (dialogRef.content.instance as EditCustomerComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.customerViewMode = customerViewMode;
    component.viewMode = "new";


    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditPayee(customerViewMode:CustomerViewMode, customerID: number): { dialogRef: DialogRef, component: EditCustomerComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCustomerComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200,
      autoFocusedElement: '[formcontrolname="displayName"]',
    });

    const component = (dialogRef.content.instance as EditCustomerComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.customerViewMode = customerViewMode;
    component.viewMode = "edit";
    component.customerID = customerID;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditCommitmentDetails(): { dialogRef: DialogRef, component: EditCommitmentDetailsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentDetailsDialogComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditCommitmentDetailsDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditCommitmentProducts(): { dialogRef: DialogRef, component: EditCommitmentProductsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentProductsDialogComponent,
      width: 'min(920px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as EditCommitmentProductsDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditClaimProducts(): { dialogRef: DialogRef, component: EditClaimProductsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditClaimProductsDialogComponent,
      width: 'min(920px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as EditClaimProductsDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditCommitmentPhasing(): { dialogRef: DialogRef, component: EditCommitmentPhasingsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentPhasingsDialogComponent,
      width: 'min(760px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as EditCommitmentPhasingsDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openSelectCommitmentAttachments(): { dialogRef: DialogRef, component: SelectAttachmentsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectAttachmentsDialogComponent,
      width: 'min(760px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as SelectAttachmentsDialogComponent);

    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openSelectClaimAttachments(): { dialogRef: DialogRef, component: SelectAttachmentsDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: SelectAttachmentsDialogComponent,
      width: 'min(760px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as SelectAttachmentsDialogComponent);

    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }


  // --------------------------------------------------------------------------
  public openEditCommitment(commitmentID:number): { dialogRef: DialogRef, component: EditCommitmentDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentDialogComponent,
      width: 'min(1180px, calc(100vw - var(--acb-dialog-margin)))',
      minWidth: 320,
      minHeight: 200,
      maxWidth: 320,
      maxHeight: 'calc(100vh - var(--acb-dialog-margin))',
    });

    const component = (dialogRef.content.instance as EditCommitmentDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.commitmentID = commitmentID;

    component.loading$.subscribe((x: boolean) => {
      if (x) {
        dialogRef.dialog.instance.maxWidth = 320;
        dialogRef.dialog.instance.height = 200;
      } else {
        dialogRef.dialog.instance.maxWidth = "unset";
        dialogRef.dialog.instance.height = "unset";
      }
    })

    component.saving$.subscribe((x: boolean) => {
      if (x) {
        dialogRef.dialog.instance.maxWidth = 320;
        dialogRef.dialog.instance.height = 200;
      } else {
        dialogRef.dialog.instance.maxWidth = "unset";
        dialogRef.dialog.instance.height = "unset";
      }
    })

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openReviewCommitment(commitmentIDs: number[], reviewMode: ReviewMode, comment?:string): { dialogRef: DialogRef, component: ReviewCommitmentsWindowComponent } | null {

    let title: string = 'review';
    const plural: string = commitmentIDs?.length > 1 ? 'S' : '';
    switch (reviewMode) {
      case 'approve':
        title = "APPROVE COMMITMENT" + plural;
        break;
      case 'deny':
        title = "DENY COMMITMENT" + plural;
        break;
      default:
        title = "REVIEW COMMITMENT" + plural;
    }

    const dialogRef: DialogRef = this.dialogService.open({
      content: ReviewCommitmentsWindowComponent,
      title: title,
      width: 'min(1140px, calc(100vw - var(--acb-dialog-margin)))',
      minWidth: 320,
      minHeight: 200,
      maxHeight: 'calc(100vh - var(--acb-dialog-margin))',
    });

    const component = (dialogRef.content.instance as ReviewCommitmentsWindowComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.comment = comment ?? '';
    component.reviewMode = reviewMode;
    component.commitmentIDs = commitmentIDs;

    component.loading$.pipe(first(x => x)).subscribe((x: boolean) => {
        dialogRef.dialog.instance.maxWidth = 320;
        dialogRef.dialog.instance.height = 200;
    })

    component.loading$.pipe(first(x => !x)).subscribe((x: boolean) => {
      dialogRef.dialog.instance.maxWidth = "unset";
      dialogRef.dialog.instance.height = "unset";
    })

    component.closed.pipe(take(1)).subscribe(() => {
        dialogRef.close();
    });


    const nativeWindow: HTMLElement = (dialogRef.dialog?.instance as any)?.el?.nativeElement;
    nativeWindow?.classList.add('acb-review-window');

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openErrorDialog(message: string = "Unknown error"): { dialogRef: DialogRef, component: ViewErrorResultComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: ViewErrorResultComponent,
      width: 320,
      height: 200,
    });

    const component = (dialogRef.content.instance as ViewErrorResultComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.message = [message];
    component.close.pipe(first()).subscribe(() => {
      dialogRef.close();
    })

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openBatchAllocationStepDialog(): { dialogRef: DialogRef, component: BatchAllocationStepDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: BatchAllocationStepDialogComponent,
      width: 'min(720px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as BatchAllocationStepDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditUserDialog(): { dialogRef: DialogRef, component: EditUserDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditUserDialogComponent,
      width: 'min(720px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as EditUserDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openCreateUserDialog(): { dialogRef: DialogRef, component: CreateUserDialogComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: CreateUserDialogComponent,
      width: 'min(720px, 100vw)',
      minWidth: 200,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as CreateUserDialogComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openCreateCommitmentApprovalRule(): { dialogRef: DialogRef, component: EditCommitmentApprovalRuleComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentApprovalRuleComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditCommitmentApprovalRuleComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.viewMode = "new";

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditCommitmentApprovalRule(localCommitmentApprovalRuleID: number): { dialogRef: DialogRef, component: EditCommitmentApprovalRuleComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditCommitmentApprovalRuleComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditCommitmentApprovalRuleComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.viewMode = "edit";
    component.localCommitmentApprovalRuleID = localCommitmentApprovalRuleID;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openCreateFund(cloneFundID?: number ): { dialogRef: DialogRef, component: EditFundComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditFundComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditFundComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.viewMode = "new";
    if (cloneFundID) component.cloneFundID = cloneFundID;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditFund(fundID: number): { dialogRef: DialogRef, component: EditFundComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditFundComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditFundComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.viewMode = "edit";
    component.fundID = fundID;

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openCreateFeature(feature: FeatureModel, featuresForSelected: FeatureModel[], featureMandateTypeWhiteList?: FeatureMandateType[], featureTypeWhiteList?: FeatureType[], editFeatureType?: EditFeatureType): { dialogRef: DialogRef, component: EditFeatureComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditFeatureComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditFeatureComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    if (editFeatureType) component.editFeatureType = editFeatureType;
    component.featuresForSelected = featuresForSelected;
    if (featureMandateTypeWhiteList) component.featureMandateTypeWhiteList = featureMandateTypeWhiteList;
    if (featureTypeWhiteList) component.featureTypeWhiteList = featureTypeWhiteList;
    component.viewMode = "new";

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openEditFeature(feature: FeatureModel, featuresForSelected: FeatureModel[], featureMandateTypeWhiteList?: FeatureMandateType[], featureTypeWhiteList?: FeatureType[], editFeatureType?: EditFeatureType): { dialogRef: DialogRef, component: EditFeatureComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      content: EditFeatureComponent,
      width: 'min(900px, 100vw)',
      minWidth: 200,
      minHeight: 200
    });

    const component = (dialogRef.content.instance as EditFeatureComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    if (editFeatureType) component.editFeatureType = editFeatureType;
    component.feature = feature;
    component.featuresForSelected = featuresForSelected;
    if (featureMandateTypeWhiteList) component.featureMandateTypeWhiteList = featureMandateTypeWhiteList;
    if (featureTypeWhiteList) component.featureTypeWhiteList = featureTypeWhiteList;
    component.viewMode = "edit";

    return { dialogRef: dialogRef, component: component };
  }

    // --------------------------------------------------------------------------
    public openCreateAuditInstruction(cloneFundID?: number ): { dialogRef: DialogRef, component: EditAuditInstructionComponent } | null {

      const dialogRef: DialogRef = this.dialogService.open({
        content: EditAuditInstructionComponent,
        width: 'min(900px, 100vw)',
        minWidth: 200,
        minHeight: 200
      });

      const component = (dialogRef.content.instance as EditAuditInstructionComponent);
      if (!component) {
        dialogRef.close();
        return null;
      }

      component.viewMode = "new";

      return { dialogRef: dialogRef, component: component };
    }

    // --------------------------------------------------------------------------
    public openEditAuditInstruction(auditInstructionID: number): { dialogRef: DialogRef, component: EditAuditInstructionComponent } | null {

      const dialogRef: DialogRef = this.dialogService.open({
        content: EditAuditInstructionComponent,
        width: 'min(900px, 100vw)',
        minWidth: 200,
        minHeight: 200
      });

      const component = (dialogRef.content.instance as EditAuditInstructionComponent);
      if (!component) {
        dialogRef.close();
        return null;
      }

      component.viewMode = "edit";
      component.auditInstructionID = auditInstructionID;

      return { dialogRef: dialogRef, component: component };
    }


}
