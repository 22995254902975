<div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">
  <div class="acb-details-layout" fxFlex="60%" gdColumns="repeat(auto-fill, minmax(8em, max-content))">
    <div class="acb-view-field">
      <kendo-label text="Activity Description"></kendo-label>
      <div>{{ claim?.activityDescription }}</div>
    </div>
    <div class="acb-view-field">
      <kendo-label text="# Ads"></kendo-label>
      <div>{{ claim?.numberOfAds }}</div>
    </div>
    <div class="acb-view-field">
      <kendo-label text="Received Date"></kendo-label>
      <div>{{ claim?.receivedDate | date: 'M/d/yyyy' }}</div>
    </div>
    <div class="acb-view-field">
      <kendo-label text="Start Date"></kendo-label>
      <div>{{ claim?.startDate | date: 'M/d/yyyy' }}</div>
    </div>
    <div class="acb-view-field">
      <kendo-label text="End Date"></kendo-label>
      <div>{{ claim?.endDate | date: 'M/d/yyyy' }}</div>
    </div>
    <div class="acb-view-comment-wrapper" fxFlex fxLayout="column" fxLayoutGap="1em">
      <div class="acb-view-comment" fxFlex>
        <kendo-label text="Review Comment"></kendo-label>
        <ng-container *ngIf="getLastHistoryComment() as comment">
          <div>{{ comment }}</div>
        </ng-container>
      </div>
    </div>
    <div class="acb-view-field">
      <kendo-label text="Audit Codes"></kendo-label>
      <div [innerHTML]="getLastHistoryAuditCodes()"></div>
    </div>
  </div>
</div>
