<button class="k-button" (click)="pdf.saveAs('test.pdf')">
  Save As PDF...
</button>

<button class="k-button" (click)="onPrint()">
  Print
</button>

 <kendo-pdf-export
 #pdf
 paperSize="letter"
 margin=".25in"
 [scale]=".705"
 [keepTogether]="'.no-splt'"
>

  <ng-container *ngIf="claim$ | async; let claimData">
    <div [hidden]="claimStatus != 'ready'" style="padding: 1em;">
      <acb-alcon-print-claim-details [claim]="claimData"></acb-alcon-print-claim-details>
      <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">


        <div *ngIf="commitment$ | async; let commitmentData" class="acb-details-layout-section acb-section2">
          <acb-alcon-display-address
            #customer
            [label]="'Customer'"
            [code]="commitmentData?.customerCode"
            [name]="commitmentData?.customer"
            [location]="commitmentData?.customerLocation"
            [isExpandedOnly]="true"
            ></acb-alcon-display-address>
        </div>

        <div class="acb-details-layout-section acb-section3">
          <acb-alcon-display-address
            #payee
            [label]="'Payee'"
            [code]="claimData?.payeeCustomerCode"
            [name]="claimData?.payee"
            [location]="claimData?.payeeLocation"
            [isExpandedOnly]="true"
            ></acb-alcon-display-address>
        </div>

        <div *ngIf="commitment$ | async; let commitmentData" class="acb-details-layout-section acb-section5">
          <acb-alcon-display-info-card
            #fund
            [label]="'Fund'"
            [headerValue]="commitmentData?.fund"
            [nameAndValues] = "[
              ['ID', commitmentData?.fundCode, false],
              ['Name', commitmentData?.fund, true]
            ]"
            [isExpandedOnly]="true"
            ></acb-alcon-display-info-card>
        </div>

      </div>

      <acb-alcon-view-commitment-phasing
        *ngIf="commitment$ | async; let commitmentData"
        [viewMode]="'print'"
        [commitment]="commitmentData"
        class="no-split"
        >
      </acb-alcon-view-commitment-phasing>


      <acb-alcon-section-with-legend
        class="acb-attachments-section acb-section-11 no-splt"
        #section
        [sectionTitle]="'Products'"
        style="width: 100%; height: 100%;"
        [isCollapsible]="false"
        >
        <acb-alcon-view-claim-products [claim]="claimData">
        </acb-alcon-view-claim-products>
      </acb-alcon-section-with-legend>

      <acb-alcon-section-with-legend
        class="acb-attachments-section acb-section-06"
        #section
        [sectionTitle]="'Attachments'"
        style="width: 100%; height: 100%;"
        [isCollapsible]="false"
        >
        <acb-alcon-view-claim-attachments [claim]="claimData" [doShowFieldLabel]="false"></acb-alcon-view-claim-attachments>
      </acb-alcon-section-with-legend>

      <div class="acb-outer-section">
        <acb-alcon-view-claim-review-history [claim]="claimData"></acb-alcon-view-claim-review-history>
      </div>
    </div>
  </ng-container>
</kendo-pdf-export>
