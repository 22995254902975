



export class UserInfoModel  {
    applicationUserID?: number | null;
    applicationUserPersonID?: number | null;
    userName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    emailAddress?: string | null;
    isActive?: boolean | null;
    mustUpdatePassword?: boolean | null;
    password?: string | null;
    accessRoleIDs?: number[] | null;
    doResetPassword?: boolean | null;
    doEmailUser?: boolean | null;
}

 
     
         
