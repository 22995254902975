import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';

import * as UserPpreferencesActions from './user-preferences/user-preferences.actions';
import * as AppPersistedSettingsActions from './app-persisted-settings/app-persisted-settings.actions';
import * as AppSessionActions from './app-session/app-session.actions';
import { selectDoKeepMeLoggedIn } from './app-session/app-session.selectors';
import { appPersistedSettingsLocalStorageKey } from './app-persisted-settings/app-persisted-settings.state';
import { ClientStorageType, ClientStorageService } from '@data-services/client-storage.service';
import { RootState } from './root.state'
import * as RootActions from './root.actions'
import { appSessionLocalStorageKey } from './app-session/app-session.state';
import { AppSessionSelectors } from './app-session';
import { UserPreferencesState, UserPreferencesActions, UserPreferencesSelectors } from './user-preferences';
import { ApplicationService } from '@services/application.service';


@Injectable()
export class RootEffects {

  persistedSettingsUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppPersistedSettingsActions.persistedSettingsUpdated),
      withLatestFrom(this.store.select(selectDoKeepMeLoggedIn)),
      map(([action,doKeepMeLoggedIn]) => {
        this.clientStorageService.mergeAndSetItem(appPersistedSettingsLocalStorageKey, action.data, doKeepMeLoggedIn ? ClientStorageType.storeLocal : ClientStorageType.sessionOnly);
        this.clientStorageService.setItem(appPersistedSettingsLocalStorageKey, {  }, doKeepMeLoggedIn ? ClientStorageType.sessionOnly : ClientStorageType.storeLocal);
        return RootActions.noopAction();
        })
      )
    }
  );

  userPreferencesUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPpreferencesActions.userPreferencesUpdated),
      withLatestFrom(this.store.select(AppSessionSelectors.selectAccessPerson)),
      map(([action, accessPerson]) => {
        if (!accessPerson?.personID) {
          return RootActions.noopAction();
        }

        let scopedID = UserPreferencesState.getScopedUserPreferencesFeatureKey(accessPerson?.personID?.toString());

        this.clientStorageService.mergeAndSetItem(scopedID, action.data, ClientStorageType.storeLocal);
        return RootActions.noopAction();
        })
      )
    }
  );

  appSessionUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.appSessionUpdated),
      withLatestFrom(this.store.select(UserPreferencesSelectors.selectThemeId),this.store.select(selectDoKeepMeLoggedIn)),
      switchMap(([action, themeId, doKeepMeLoggedIn]) => {
        this.clientStorageService.mergeAndSetItem(appSessionLocalStorageKey, { doKeepMeLoggedIn: action?.payload?.doKeepMeLoggedIn ?? doKeepMeLoggedIn } , ClientStorageType.storeLocal);
        this.clientStorageService.mergeAndSetItem(appSessionLocalStorageKey, action.payload, ClientStorageType.sessionOnly);
        return [RootActions.rootStoreIntilized()];
        })
      )
    }
  );


// TODO: We're intializing UserPreferencesState scoped to personID, pulling from local storage.  There must be a better place to do this and/or a more approriate event...
onAccessPersonUpdated$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(AppSessionActions.accessPersonUpdated),
    map(action => {
      if (!action?.payload?.personID) {
        return RootActions.noopAction();
      }
      let localValues = this.clientStorageService.getItem(UserPreferencesState.getScopedUserPreferencesFeatureKey(action?.payload?.personID?.toString()));
      let newState = {...localValues};
      return UserPreferencesActions.updateUserPreferences({ data: newState })
    })

  );
});


userLoginSuccessForRoot$  = createEffect(() => {
  return this.actions$.pipe(
    ofType(AppSessionActions.userLoginSuccessForRoot),
    switchMap(action => [
      AppSessionActions.updateMemoryOnlyRefreshToken({ payload: action?.payload?.memoryOnlyRefreshToken ?? ""}),
      AppPersistedSettingsActions.updateAccessTokenInfo({ data: action?.payload.tokenInfoModel ?? { } })
    ])
  );
});

onTokenRefreshSuccessForRoot$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(AppSessionActions.tokenRefreshSuccess),
    switchMap(action => [
      AppSessionActions.updateMemoryOnlyRefreshToken({ payload: action?.payload?.memoryOnlyRefreshToken ?? ""}),
      AppPersistedSettingsActions.updateAccessTokenInfo({ data: action?.payload.tokenInfoModel ?? { } })
    ])
  );
 });


userLoggedOut$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(AppSessionActions.userLoggedOut),
    map(() => {
      this.clientStorageService.setItem(appPersistedSettingsLocalStorageKey, { }, ClientStorageType.sessionOnly);
      this.clientStorageService.setItem(appPersistedSettingsLocalStorageKey, { }, ClientStorageType.storeLocal);
      this.clientStorageService.setItem(appSessionLocalStorageKey, { }, ClientStorageType.sessionOnly);
      this.clientStorageService.setItem(appSessionLocalStorageKey, { }, ClientStorageType.storeLocal);
      return RootActions.noopAction();
      })
    )
  }
);

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private logger: NGXLogger,
    private clientStorageService: ClientStorageService,
    private applicationService: ApplicationService,
    ) {}

}
