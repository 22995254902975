import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from, defer } from 'rxjs';
import { catchError, withLatestFrom, first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { Store } from '@ngrx/store';
import { tokenRefreshSuccess } from '@app-store/app-session/app-session.actions';
import { selectDoKeepMeLoggedIn, selectMemoryOnlyRefreshToken } from '@app-store/app-session/app-session.selectors';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error, caught) => {
      if (error instanceof HttpErrorResponse && error.status === 401 && request.url.toLowerCase().indexOf("spa-refresh") === -1) {
        return this.handle401Error(request, next);
      }
      return throwError(error);
    }))
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    const result = this.store.select(selectDoKeepMeLoggedIn).pipe(
      withLatestFrom(this.store.select(selectMemoryOnlyRefreshToken)),
      first(),
      switchMap(([doKeepMeLoggedIn, memoryOnlyRefreshToken]) =>
        this.authenticationService.refreshToken({ doPersistAuthorization: doKeepMeLoggedIn, refreshToken: memoryOnlyRefreshToken })
      ),
      switchMap(result => {
        if (result.tokenInfoModel?.token) {
          this.store.dispatch(tokenRefreshSuccess({ payload: result  }));
          return next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${result.tokenInfoModel.token}` }}));
        }
        return next.handle(request);
      })
    );
    return result;
  }
}
