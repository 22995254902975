import { createReducer, on } from '@ngrx/store';
import * as UserPreferencesActions from './user-preferences.actions';
import { initialState } from './user-preferences.state';

export const userPreferencesReducer = createReducer(
  initialState,

  on(UserPreferencesActions.updateUserPreferences, (state, action) => ({...state, ...action.data})),
  on(UserPreferencesActions.updateThemeId, (state, action) => ({ ...state, themeId: action?.data?.themeId })),

  on(UserPreferencesActions.updateManageFeaturesPage_showAllAccessRoles, (state, action) => ({
    ...state,
    container: {
      ...state.container,
      manageFeaturesPage: {
        ...state.container.manageFeaturesPage,
        showAllAccessRoles: action.data
      }
    }
  })),

  on(UserPreferencesActions.updateGridPreferences, (state, action) => {
    return ({ ...state, gridPreferences: action?.data })
  }),

  on(UserPreferencesActions.updateDashboardPreferences, (state, action) => {
    return ({ ...state, dashboardPreferences: action?.data })
  }),


);





