import { createAction, props } from '@ngrx/store';
import { DashboardSettings, GridSettings } from 'src/app/components/core/core.module';
import { UserPreferencesState } from './user-preferences.state'

export enum ActionTypes {
  UpdateUserPreferences = '[User Preferences Command] Update : User Preferences',
  UpdateThemeId = '[User Preferences Command] Update Theme Id',
  UserPreferencesUpdated = '[User Preferences Event] User Preferences Updated',
  UpdateManageFeaturesPage_showAllAccessRoles = '[User Preferences Command] Update : Show All Access Roles',
  UpdateGridPreferences = '[User Preferences Command] Update : Grid Preferences',
  UpdateDashboardPreferences = '[User Preferences Command] Update : Dashboard Preferences',
  NoOp = '[User Preferences] No Op'
}

export const updateUserPreferences = createAction(ActionTypes.UpdateUserPreferences, props<{ data: UserPreferencesState }>());

export const updateThemeId = createAction(ActionTypes.UpdateThemeId, props<{ data: { themeId: number } }>());

export const userPreferencesUpdated = createAction(ActionTypes.UserPreferencesUpdated, props<{ data: UserPreferencesState }>());

export const updateManageFeaturesPage_showAllAccessRoles = createAction(ActionTypes.UpdateManageFeaturesPage_showAllAccessRoles, props<{ data: boolean }>());

export const updateGridPreferences = createAction(ActionTypes.UpdateGridPreferences, props<{ data: { [name: string]: GridSettings } }>());

export const updateDashboardPreferences = createAction(ActionTypes.UpdateDashboardPreferences, props<{ data: DashboardSettings }>());



