

import { CommitmentCommentModel } from './CommitmentCommentModel';//2
import { CommitmentDetailStatusWithLedgerModel } from './CommitmentDetailStatusWithLedgerModel';//2
import { CommitmentDetailAttachmentModel } from './CommitmentDetailAttachmentModel';//2
import { CommitmentDetailPhaseModel } from './CommitmentDetailPhaseModel';//2
import { CommitmentDetailProductModel } from './CommitmentDetailProductModel';//2
import { CommitmentDetailActualModel } from './CommitmentDetailActualModel';//2
import { CommitmentExceptionModel } from './CommitmentExceptionModel';//2
import { CommitmentInfoModel } from './CommitmentInfoModel';//3

export class CommitmentWithDetailsModel extends CommitmentInfoModel {
    comments?: CommitmentCommentModel[] | null;
    history?: CommitmentDetailStatusWithLedgerModel[] | null;
    attachments?: CommitmentDetailAttachmentModel[] | null;
    phases?: CommitmentDetailPhaseModel[] | null;
    products?: CommitmentDetailProductModel[] | null;
    actuals?: CommitmentDetailActualModel[] | null;
    exceptions?: CommitmentExceptionModel[] | null;
}

 
     
         
