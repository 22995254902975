import { ExcelExportEvent } from "@progress/kendo-angular-grid";
import { ExcelComponent } from "@progress/kendo-angular-grid";
import { Utilities } from "./utilities";

export class ComponentUtilities {

  public static handleExcelExportWithTimestampAndPreventEmpty(excelExportEvent:ExcelExportEvent, orginalExportFileName?:string, gridExcelComponent?:ExcelComponent) {

    // export nothing if none of the sheets have non-header rows
    if (!excelExportEvent.workbook?.sheets?.length || !excelExportEvent.workbook.sheets.some((s:any) => s.rows.some((r:any) => r.type != 'header'))) {
      excelExportEvent.preventDefault();
      return;
    }

    // add timestamp to filename
    if (orginalExportFileName && gridExcelComponent) {
      const idx = orginalExportFileName.lastIndexOf('.');
      const front = idx ? orginalExportFileName.substring(0, idx) : orginalExportFileName;
      gridExcelComponent.fileName = `${front}_${Utilities.getConcatenatedPaddedDate()}`;
    }
  }

}
