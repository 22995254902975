import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CommitmentEditService } from '@services/commitment-edit.service';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditCommitmentPhasingComponent } from './edit-commitment-phasing.component';

@Component({
  selector: 'acb-alcon-edit-commitment-phasings-dialog',
  template: `
   <kendo-dialog-titlebar class="acb-edit-commitment-phasing-titlebar" (close)="onClose()">
      Edit Phasing
    </kendo-dialog-titlebar>
    <ng-container #editCommitmentPhasingContainer></ng-container>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onApply()" [disabled]="!valid" ><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-commitment-phasings-dialog.component.scss']
})
export class EditCommitmentPhasingsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('editCommitmentPhasingContainer', { read:ViewContainerRef }) editCommitmentPhasingContainer?: ViewContainerRef;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() apply: EventEmitter<any> = new EventEmitter();

  private _commitmentEditServiceSubscription?:Subscription;
  private _commitmentEditService?: CommitmentEditService;
  public set commitmentEditService(value:CommitmentEditService) {
    this._commitmentEditService = value;
    this._commitmentEditServiceSubscription?.unsubscribe();
    this._commitmentEditServiceSubscription = this._commitmentEditService.validityChange$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this.valid = x.phases;
      this.changeDetectionRef.detectChanges();
    });
  }

  public valid: boolean = true;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetectionRef : ChangeDetectorRef
    ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Need to pass service to edit details component, provider context is lost going through window service...
    // TODO: Clean up by moving to feature NGRX?
    if (this.editCommitmentPhasingContainer && this._commitmentEditService) {
      const factory = this.resolver.resolveComponentFactory(EditCommitmentPhasingComponent);

      const injector: Injector = Injector.create({ providers: [{ provide: CommitmentFormBaseService, useValue: this._commitmentEditService }]});
      injector.get(CommitmentFormBaseService);

      const ref: ComponentRef<EditCommitmentPhasingComponent> =  this.editCommitmentPhasingContainer.createComponent(factory, undefined, injector);
      ref.instance.doShowLegend = false;

      this.changeDetectionRef.detectChanges();
    }
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApply() {
    if (this.valid)
      this.apply.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
