import { ClaimDetailProductModel } from '@alcon-db-models/ClaimDetailProductModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { formatPercent } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CompactMode, ViewMode } from '../components.module';

@Component({
  selector: 'acb-alcon-view-claim-products',
  template: `
    <kendo-grid *ngIf="claim?.products; let productsData"
      class="acb-results-grid acb-display-grid"
      [data]= "productsData"
      [pageable]="false"
      [sortable]="false"
      [reorderable]="false"
      [selectable] = "false"
      [resizable]="true"
      >
      <kendo-grid-column title="Product" field="product"></kendo-grid-column>
      <kendo-grid-column title="Amount" field="amount" class="acb-grid-column-currency" [width]="100" [hidden]="compactMode == 'compact'">
        <ng-template kendoGridCellTemplate let-dataItem >
            {{ getAmount(dataItem) | currency }}
          </ng-template>
        </kendo-grid-column>
      <kendo-grid-column title="%" class="acb-grid-column-currency" [width]="60">
        <ng-template kendoGridCellTemplate let-dataItem >
          {{ getPrecent(dataItem) }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-claim-products.component.scss']
})
export class ViewClaimProductsComponent implements OnInit {

  constructor() { }

  @Input() viewMode:ViewMode = 'view';
  @Input() claim?:ClaimWithDetailsModel;
  @Input() compactMode: CompactMode = 'normal';

  public getPrecent(product:ClaimDetailProductModel) {
    return formatPercent(product.percentage ?? 0, "en-US", '1.0-0');
  }

  public getAmount(product:ClaimDetailProductModel) {
    return (this.claim?.claimedAmount ?? 0) * (product?.percentage ?? 0);
  }

  ngOnInit(): void {
  }
}
