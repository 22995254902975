import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms";

export class AcbValidators {


  public static requireSameYear(...keys:string[]) {

    const errorKey: string =  "notSameYear";

    return (control: AbstractControl):ValidationErrors | null => {

      const group = control as UntypedFormGroup;
      if (!group?.controls)
        throw new Error('FormGroup expected');

      for (let i = 1; i < keys.length; i++) {
        const curField = group.controls[keys[i]];
        const curVal: Date | undefined = curField?.value;
        const prevField = group.controls[keys[i-1]];
        const prevVal: Date | undefined = prevField?.value;
        const curErrors: ValidationErrors = {...curField.errors};
        if (curVal?.getFullYear && prevVal?.getFullYear && curVal?.getFullYear() != prevVal?.getFullYear()) {
          curErrors[errorKey] = true;
          curField.setErrors(curErrors);
        } else {
          delete curErrors[errorKey];
          curField.setErrors(Object.keys(curErrors).length ? curErrors : null);
        }
      }
      return null;
    }

  }

  public static requireSuccessiveDates(...keys:string[]) {
    return this.requireSuccessive('datesNotConsecutive', ...keys);
  }

  public static requireSuccessive(errorKey: string =  "notConsecutive", ...keys:string[]) {

    return (control: AbstractControl):ValidationErrors | null => {
      const group = control as UntypedFormGroup;
      if (!group?.controls)
        throw new Error('FormGroup expected');

      for (let i = 1; i < keys.length; i++) {
        const curField = group.controls[keys[i]];
        const curVal: any = curField?.value;
        const prevField = group.controls[keys[i-1]];
        const prevVal: any = prevField?.value;
        const curErrors: ValidationErrors = {...curField.errors};
        if (curVal != null && prevVal != null && curVal.constructor === prevVal.constructor && curVal < prevVal) {
          curErrors[errorKey] = true;
          curField.setErrors(curErrors);
        } else {
          delete curErrors[errorKey];
          curField.setErrors(Object.keys(curErrors).length ? curErrors : null);
        }
      }
      return null;
    }
  }

}
