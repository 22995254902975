import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { WindowComponent } from '@progress/kendo-angular-dialog';
import { ViewSubjectWindow } from 'src/app/components/components.module';


@Component({
  selector: 'acb-alcon-claim-Claim-window',
  template: `
      <div [hidden]="loading$ | async">
        <acb-alcon-view-claim
          [claimID]="claimID"
          (viewCommitment)="onViewCommitment($event)"
          (viewClaim) ="onViewClaim($event)"
          (loading)="onLoading($event)">
        </acb-alcon-view-claim>
    </div>
    <div [hidden]="!(loading$ | async)" class="acb-local-loading-indicator">
      <span class="k-icon k-i-loading"></span>
    </div>
  `,
  styleUrls: ['./view-claim-window.component.scss']
})
export class ViewClaimWindowComponent implements OnInit,  AfterViewInit, ViewSubjectWindow  {

  @Input() windowComponent?: WindowComponent;
  @Input() isOpen: boolean = false;

  @Input() public claimID?: number;

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<number|undefined> = new EventEmitter();
  @Output() viewClaim: EventEmitter<number|undefined> = new EventEmitter();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private changeDetectorRef: ChangeDetectorRef
    ) {
  }

  ngOnInit(): void {
  }

  public onLoading(isLoading: boolean) {

    this.loading$.next(isLoading);
    this.changeDetectorRef.detectChanges();
  }

  public onViewCommitment(commitmentID:number | undefined) {
    this.viewCommitment.emit(commitmentID);
  }

  public onViewClaim(claimID:number | undefined) {
    this.viewClaim.emit(claimID);
  }

  public ngAfterViewInit() {
  }

  public closeWindow() {
    this.isOpen = false;
    this.closed.emit(null);
  }

  public openWindow() {
    this.isOpen = true;
  }
}
