import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, zip } from 'rxjs';
import { debounceTime, first, map, takeUntil } from 'rxjs/operators';
import { BusinessRole } from '@alcon-db-models/Enums';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { environment } from '@environments/environment';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { Store } from '@ngrx/store';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { FeatureModel } from '@alcon-db-models/FeatureModel';

import { selectBusinessRules, selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { Console } from 'console';
import { TerritoryForCommitmentWithBalanceModel } from '@alcon-db-models/TerritoryForCommitmentWithBalanceModel';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'acb-alcon-edit-commitment-details',
  template: `
    <form [formGroup]="detailForm" style="width: 100%; height: 100%;" class="k-form">
      <acb-alcon-section-with-legend [sectionTitle]="'Details'" class="acb-section-04" style="width: 100%; height: 100%;" [doShowLegend]="doShowLegend" >
        <div [fxLayout]="getTopLayout()" fxLayoutAlign="start start" class="acb-field-row" style="height: 100%;">
          <div fxLayout="row wrap inline" fxLayoutAlign="start start" style="width: 100%;">

          <div fxFlex="0 1 8em" fxFlex.lt-sm="auto" class="acb-edit-detail-available">
              <kendo-label text="Available"></kendo-label>
              <div [class]="getAvailableClass()">
                {{ getAvailableAmount() | currency }}
              </div>
            </div>

            <kendo-formfield *appFieldExtentions fxFlex="1 0 8.5em" fxFlex.lt-sm="0 1 100%">
              <kendo-label [for]="amount" text="Commitment Cost"></kendo-label>
              <kendo-numerictextbox
                [decimals]="2"
                [format]="'c2'"
                [min]="0"
                [max]="fieldMaxDollar"
                #amount
                [formControlName]="'amount'"
                required
                autocomplete="off"
                [autoCorrect]="true"
                [spinners]="false"
                ></kendo-numerictextbox>
              <kendo-formhint>Commited amount</kendo-formhint>
              <kendo-formerror>Error: {{ getError('amount') }}</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield *appFieldExtentions fxFlex="1 0 8.5em" fxFlex.lt-sm="0 1 100%">
              <kendo-label [for]="startDate" text="Start Date"></kendo-label>
              <kendo-datepicker
                formControlName="startDate"
                #startDate
                required
                autocomplete="off"
                [min]="$any(minDate)"
                [max]="$any(maxDate)"
                [rangeValidation]="false"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                ></kendo-datepicker>
              <kendo-formhint>Activity start date</kendo-formhint>
              <kendo-formerror>Error: {{ getError('startDate') }}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield *appFieldExtentions fxFlex="1 0 8em" fxFlex.lt-sm="0 1 100%">
              <kendo-label [for]="endDate" text="End Date"></kendo-label>
              <kendo-datepicker
                formControlName="endDate"
                #endDate
                required
                autocomplete="off"
                [rangeValidation]="false"
                [min]="$any(minDate)"
                [max]="$any(maxDate)"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                >
              </kendo-datepicker>
              <kendo-formhint>Activity end date</kendo-formhint>
              <kendo-formerror>Error: {{ getError('endDate') }}</kendo-formerror>
            </kendo-formfield>

            <div fxHide.fxHide.gt-sm class="acb-flex-break"></div>

            <ng-container *ngIf="showVenue" >

              <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
                <kendo-label [for]="event" text="Event"></kendo-label>
                <input formControlName="event" kendoTextBox #event autocomplete="off"/>
                <kendo-formhint>Event at Venue</kendo-formhint>
                <kendo-formerror>Error: Event required</kendo-formerror>
              </kendo-formfield>

              <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
                <kendo-label [for]="estimatedAttendeeCount" text="Est. # of Attendees"></kendo-label>
                <kendo-numerictextbox
                  style="width: 100%"
                  [spinners]="true"
                  [decimals]="0"
                  [min]="0"
                  #estimatedAttendeeCount
                  [format]="'#'"
                  [formControlName]="'estimatedAttendeeCount'"
                  autocomplete="off">
                </kendo-numerictextbox>
                <kendo-formhint>Estimated Number of Attendees</kendo-formhint>
                <kendo-formerror>Error: Number of Attendees required</kendo-formerror>
              </kendo-formfield>

              <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
                <kendo-label [for]="venue" text="Venue"></kendo-label>
                <input formControlName="venue" kendoTextBox #venue autocomplete="off"/>
                <kendo-formhint>Venue Name</kendo-formhint>
                <kendo-formerror>Error: Venue required</kendo-formerror>
              </kendo-formfield>

              <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
                <kendo-label [for]="venueCity" text="Venue City"></kendo-label>
                <input formControlName="venueCity" kendoTextBox #venueCity autocomplete="off"/>
                <kendo-formhint>City Name</kendo-formhint>
                <kendo-formerror>Error: City required</kendo-formerror>
              </kendo-formfield>

              <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
                <kendo-label [for]="venueStateProvinceCodeID" text="Venue State"></kendo-label>
                <kendo-combobox
                [data]="this.stateProvinceCodes$ | async"
                [textField]="'code'"
                [valueField]="'id'"
                [filterable]="true"
                [formControlName]="'venueStateProvinceCodeID'"
                (filterChange)="onStateDropdownFilter($event)"
                [valuePrimitive]="true"
                #venueStateProvinceCodeID
                autocomplete="off"
                >
                </kendo-combobox>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror>Error: state required</kendo-formerror>
              </kendo-formfield>


            </ng-container>

            <div fxHide.fxHide.gt-sm class="acb-flex-break"></div>

          </div>

          <div fxLayout="row" >

            <!-- we want this visble if the user can change the value *and* if it is empty (to call attention to issues elsewhere preventing a territory selection) -->
            <div *ngIf="!territories.length || canChangeTerritory" >
              <kendo-formfield *appFieldExtentions [fxFlex]="getTerritoryFlex()" fxFlexAlign="stretch">
                <kendo-label [for]="territoryID" text="Territory"></kendo-label>
                <kendo-combobox
                  [data]="territories"
                  [textField]="'displayName'"
                  [valueField]="'territoryID'"
                  [formControlName]="'territoryID'"
                  #territoryID
                  [valuePrimitive]="true"
                  [disabled]="!canChangeTerritory"
                  >
                </kendo-combobox>
                <kendo-formhint>Commitment's territory</kendo-formhint>
                <kendo-formerror>Error: {{ getError('territoryID') }}</kendo-formerror>
              </kendo-formfield>
            </div>

            <ng-container *ngIf="doesUseCommitmentActivities">
              <kendo-formfield *appFieldExtentions fxLayout="column"  fxFlex="1 0 12em" fxFlex.lt-sm="0 1 100%">
                <kendo-label [for]="activity" text="Activity"></kendo-label>
                <kendo-combobox
                  [data]="activitiesForDropdown$ | async"
                  [textField]="'displayName'"
                  [valueField]="'activityID'"
                  [virtual]="{ itemHeight: 28 }"
                  [filterable]="true"
                  [formControlName]="'activityID'"
                  (filterChange)="onActivityDropdownFilter($event)"
                  [valuePrimitive]="true"
                  required
                  #activity
                  >
                </kendo-combobox>
                <kendo-formhint>Advertisement type/media</kendo-formhint>
                <kendo-formerror>Error: activity required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

          </div>

          <kendo-formfield *appFieldExtentions class="acb-comment-field" fxFlexAlign="stretch" [fxFlex]="getCommentFlex()">
            <kendo-label [for]="comment" text="Comment"></kendo-label>
            <textarea formControlName="comment" kendoTextArea #comment  style="resize: vertical; height:100%;"></textarea>
            <kendo-formhint>Notes or instructions</kendo-formhint>
            <kendo-formerror>Error: {{ getError('comment') }}</kendo-formerror>
          </kendo-formfield>
        </div>
      </acb-alcon-section-with-legend>
    </form>
  `,
  styleUrls: ['./edit-commitment-details.component.scss']
})
export class EditCommitmentDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('startDate') private startDate?: DatePickerComponent;

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public destroy$: Subject<void> = new Subject<void>();
  public editCommitmentModes = EditCommitmentMode;
  public fieldMaxDollar = environment.fieldMaxDollar;

  @Input() doShowLegend: boolean = true;
  @Input() editMode: EditCommitmentMode = EditCommitmentMode.Edit;
  @Input() form?: UntypedFormGroup;

  public activitiesForDropdownSource$: Observable<ActivityForDropdownModel[]>;
  public activitiesForDropdown$: Observable<ActivityForDropdownModel[]>;

  public staticTypes$: Observable<StaticTypeModel[]>;
  public stateProvinceCodes$: Observable<StaticTypeModel[]>;

  public showVenue: boolean = false;

  private _limitToYear: number | null = null;
  public get limitToYear(): number | null { return this._limitToYear };
  public set limitToYear(value: number | null) {
    //if (this._limitToYear == value) return;
    this._limitToYear = value;
    this._minDate = this.limitToYear ? new Date(this.limitToYear, 0, 1) : null;
    this._maxDate = this.limitToYear ? new Date((new Date(this.limitToYear+1,0,1)).getTime() - 1) : null;
  };

  private _minDate: Date | null = null;
  private _maxDate: Date | null = null;
  public get minDate() { return this._minDate; }
  public get maxDate() { return this._maxDate; }

  public elementWidth: number = 0;

  private widthThreshold = 600;

  public getTopLayout() {
    return this.elementWidth < this.widthThreshold ? 'column' : 'row wrap';
  }

  public getTerritoryFlex() {
    return this.elementWidth < this.widthThreshold ? '0 0 auto' : '1 0 18em';
  }

  public getCommentFlex() {
    return this.elementWidth < this.widthThreshold ? '1 0 auto' : '1 0 18em';
  }

  public detailForm: UntypedFormGroup;
  public territories$: Observable<TerritoryForCommitmentWithBalanceModel[]>;
  public canChangeTerritory: boolean = false;

  private _territories: TerritoryForCommitmentWithBalanceModel[] = [];
  public get territories() { return this._territories }

  private _balance: number = 0;
  private _ready: boolean = false;

  constructor(
    element: ElementRef,
    store: Store,
    private commitmentFormService: CommitmentFormBaseService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() wizardFeatureService?: WizardFeatureService
  ) {


    this.staticTypes$ = store.select(selectStaticTypes);
    this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })

    store.select(selectCurrentPersonFeatures).pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.canChangeTerritory = x.some((x:FeatureModel) => x.code == 'can_change_territory');
    });

    this.detailForm = this.commitmentFormService.form.controls.commitmentDetails as UntypedFormGroup;
    this.territories$ = this.commitmentFormService.territories$;
    this.territories$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let t1 = [...new Set(x
        .filter(y => y.primaryBusinessRoleID != BusinessRole.Asd && !x.some(z => (z.territoryID == y.territoryID && z.primaryBusinessRoleID != y.primaryBusinessRoleID && z.primaryBusinessRoleID == BusinessRole.Am)))
        .map(y => ({...y, displayName: y.primaryBusinessRoleID == BusinessRole.Am ? y.displayName : y.displayName + ' (! ' + y.businessRole + ')'}))
        )];
      let t2: TerritoryForCommitmentWithBalanceModel[] = [];
      for (let i = 0; i < t1.length; i++) {
        if (t2.findIndex(y => y.territoryID == t1[i].territoryID) == -1)
          t2.push(t1[i]);
      }
      this._territories = t2;
    });

    const getBalance = (): number => {
      let balance = 0;
      const details = (this.commitmentFormService.form.controls?.commitmentDetails as UntypedFormGroup);
      const territoryID = details?.controls?.territoryID.value as number;
      const cost = details?.controls?.amount.value as number ?? 0;
      if (territoryID && this._territories?.length) {
        const territory = this._territories?.find(x => x.territoryID == territoryID);
        balance = territory?.balance ?? 0;
        if (balance <= 0)  {
          // If AM, check if RSD has money
          if (territory?.primaryBusinessRoleID == BusinessRole.Am) {
            balance = Math.max.apply(0, this._territories?.filter(x => x.primaryBusinessRoleID == BusinessRole.Rsd).map(x => x.balance ?? 0) ?? []);
          }
          // If RSD, check if AM (same owner) has money
          else if (territory?.primaryBusinessRoleID == BusinessRole.Rsd) {
            balance = Math.max.apply(0, this._territories?.filter(x => x.primaryBusinessRoleID == BusinessRole.Am && x.primarySalesPersonID == territory.primarySalesPersonID).map(x => x.balance ?? 0) ?? []);
          }
        }
      }
      return balance - cost;
    }

    commitmentFormService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.limitToYear = (x.fundIsPostAudit || !x.fundYear) ? null : x.fundYear;
      let balance = getBalance();
      if (balance != this._balance) {
        this._balance = balance;
      }
      this.showVenue = x.fundIsVenueAware ?? false;
      if (this.areCommitmentActivityRulesDeterminedByFund) {
        this.doesUseCommitmentActivities = x.fundDoesUseCommitmentActivities ?? false;
        this.commitmentActivitiesRequired = (x.fundDoesUseCommitmentActivities ?? false) && (x.fundCommitmentActivitiesRequired ?? false);
      }
      if (this._ready) this.changeDetectorRef.detectChanges();
    });


    commitmentFormService.form.valueChanges.pipe(takeUntil(this.destroy$),debounceTime(10)).subscribe(x => {
      let balance = getBalance();
      if (balance != this._balance) {
        this._balance = balance;
        if (this._ready) this.changeDetectorRef.detectChanges();
      }
    });

    if (element) {
      const resizeObserver = new ResizeObserver(() => {
        this.elementWidth = (element.nativeElement as HTMLElement).offsetWidth;
      });
      resizeObserver.observe(element.nativeElement);
    }

    wizardFeatureService?.clear.subscribe((x: boolean) => {
      if (x) {
        this.detailForm.reset();
      }
    })

    this.activitiesForDropdownSource$ = commitmentFormService.activities$;
    this.activitiesForDropdown$ = this.activitiesForDropdownSource$;
  }

  ngOnInit(): void {
    this._ready = true;
  }


  //TODO: Refactor, merge with kendo-formerror.  String resource?
  private errorLookup: {[key: string]: string } = {
    'startDate.required': "Start Date is required",
    'startDate.datesNotConsecutive': "Start is after End",
    'startDate.maxError': "Outside fund year",
    'startDate.minError': "Outside fund year",
    'startDate.notSameYear': "Years must match",
    'endDate.required': "End Date is required",
    'endDate.datesNotConsecutive': "End is before Start",
    'endDate.maxError': "Outside fund year",
    'endDate.minError': "Outside fund year",
    'endDate.notSameYear': "Years must match",
    'amount.required': "Cost is required",
    'amount.min': "Cost must exceed $0",
    'amount.balanceNegative': "Balance negative",
    'territoryID.required': "Territory is required"
  };

  public getError(formControlName: string): string | null {
    const control = this.detailForm.get(formControlName);
    return control?.errors ? this.errorLookup[formControlName + '.' + Object.keys(control.errors)[0]] :  null;
  }

  public getErrors(): string[] {
    const errors: string[] = [];
    for (const key in this.detailForm.controls) {
      const err = this.getError(key);
      if (err) errors.push(err);
    }
    return errors;
  }

  public getAvailableClass() {
    const val = this.getAvailableAmount();
    return val == 0 ? '' : val > 0 ? 'acb-good' : 'acb-danger';
  }

  public getAvailableAmount() {
    return this._balance;
  }

  public onActivityDropdownFilter(value: string) {
    if (!value) {
      this.activitiesForDropdown$ = this.activitiesForDropdownSource$;
      return;
    }
    this.activitiesForDropdown$ = this.activitiesForDropdownSource$.pipe(
      map(x => x
        .filter(y => y.displayName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onStateDropdownFilter(value: string) {
    if (!value) {
      this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
      return;
    }
    this.stateProvinceCodes$ = this.staticTypes$.pipe(map(x => x.filter(y =>
      y.tableName == 'StateProvinceCode' && y.code!.toLowerCase().startsWith(value.toLowerCase())
      )));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export enum EditCommitmentMode { Create, Edit, Audit }
