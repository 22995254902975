import { Directive, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';

//TODO: Use kendo label "optional" option instead of rolling our own

@Directive({ selector: '[appFieldExtentions]'})
export class FieldExtentionsDirective {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

  ngAfterViewInit() {
    let formfield = this.elementRef?.nativeElement.previousSibling;
    let isRequired = formfield?.querySelector('[required]') ?? false;

    let formInputControl = formfield?.querySelector('k-combobox .k-input, .k-dropdown, .k-input, .k-textbox, .k-textarea, .k-radio, .k-switch-container');

    let formHint = formfield?.querySelector('kendo-formhint');
    let label = formfield?.querySelector('kendo-label');

    let kendoNumericTextBox = formfield?.querySelector('kendo-numerictextbox ');
    if (kendoNumericTextBox) {
      let input = kendoNumericTextBox.querySelector('input');
      input.setAttribute("inputmode", "numeric");
    }

    if (formHint && formInputControl && formInputControl.addEventListener) {

      formfield?.querySelector('kendo-label')?.className

      if (!formInputControl.disabled)
        formHint.style.visibility = "hidden"

        formInputControl.addEventListener('focus', () => { formHint.style.visibility = "visible"});
        formInputControl.addEventListener('blur', () => { if (!formInputControl.disabled) formHint.style.visibility = "hidden" });
    }
  }
}
