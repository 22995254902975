import { CommitmentOrganizationsModel } from '@alcon-db-models/CommitmentOrganizationsModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-view-commitment-organizations',
  template: `
    <div class="acb-details-layout-row" [gdColumns]="'repeat(auto-fit, minmax(18em, 1fr))'" [ngClass.gt-xs]="{'acb-intra-form-margin-correction':true}" [ngClass.xs]="'acb-tiny'">
      <div>
        <div class="acb-details-layout-section acb-section2" [ngClass]="{ 'acb-expanded': customer.isExpanded }"  (click)="customer.toggle()">
          <acb-alcon-display-address
            #customer
            [label]="'Customer'"
            [code]="commitmentOrganizations?.customerCode"
            [name]="commitmentOrganizations?.customer"
            [location]="commitmentOrganizations?.customerLocation"

            ></acb-alcon-display-address>
        </div>
      </div>
      <div>
        <div class="acb-details-layout-section acb-section3" [ngClass]="{ 'acb-expanded': payee.isExpanded }"  (click)="payee.toggle()" >
          <acb-alcon-display-address
            #payee
            [label]="'Payee'"
            [code]="commitmentOrganizations?.payeeCustomerCode"
            [name]="commitmentOrganizations?.payee"
            [location]="commitmentOrganizations?.payeeLocation"
            ></acb-alcon-display-address>
        </div>
      </div>
      <div>
        <div class="acb-details-layout-section acb-section5" [ngClass]="{ 'acb-expanded': fund.isExpanded }"  (click)="fund.toggle()">
          <acb-alcon-display-info-card
            #fund
            [label]="'Fund'"
            [headerValue]="commitmentOrganizations?.fund"
            [nameAndValues] = "[
              ['ID', commitmentOrganizations?.fundCode, false],
              ['Name', commitmentOrganizations?.fund, true]
            ]"
            ></acb-alcon-display-info-card>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./view-commitment-organizations.component.scss']
})
export class ViewCommitmentOrganizationsComponent implements OnInit {

  @Input() public commitmentOrganizations?: CommitmentOrganizationsModel | null;

  constructor() { }

  public getTerritoryHeaderValue() {
    let headerValue: string | undefined;
    if (this.commitmentOrganizations?.territoryCode) {
      headerValue = this.commitmentOrganizations.territoryCode;
      if (this.commitmentOrganizations.territoryPerson) {
        headerValue += " - " + this.commitmentOrganizations.territoryPerson;
      }
      if (this.commitmentOrganizations.fundingTerritoryID && this.commitmentOrganizations.fundingTerritoryID != this.commitmentOrganizations.territoryID) {
        headerValue += " / " + this.commitmentOrganizations.fundingTerritoryCode;
      }
    }
    return headerValue;
  }

  public getTerritoryValues() {
    const values: [string | null | undefined, string | null | undefined, boolean][] = [
      ['Code', this.commitmentOrganizations?.territoryCode, true],
      ['Rep', this.commitmentOrganizations?.territoryPerson, false],
      ['Name', this.commitmentOrganizations?.territory, false]
    ]
    if (this.commitmentOrganizations?.fundingTerritoryCode && this.commitmentOrganizations.fundingTerritoryID != this.commitmentOrganizations.territoryID) {
      values.push(['Funded By', this.commitmentOrganizations.fundingTerritoryCode, true]);
    }
    return values;
  }

  ngOnInit(): void {
  }
}
