import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { CommitmentApprovalTokenApplyTokenRequest } from '@alcon-db-models/CommitmentApprovalTokenApplyTokenRequest'
import { CommitmentApprovalTokenApplyTokenModel } from '@alcon-db-models/CommitmentApprovalTokenApplyTokenModel'
import { Observable } from 'rxjs';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class ApplyCommitmentReviewTokenService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public applyToken(request: CommitmentApprovalTokenApplyTokenRequest, doShowOverlay: boolean = true ): Observable<ServiceResponse<CommitmentApprovalTokenApplyTokenModel>> {
    return this.post<CommitmentApprovalTokenApplyTokenModel>('ApplyCommitmentReviewToken', request).pipe(
      firstWithLoadingOverlayAndErrorHandling<CommitmentApprovalTokenApplyTokenModel>(doShowOverlay)
    );
  }
}
