import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UserCreateService } from '@services/user-create.service';
import { UserFormBaseService } from '@services/user-form-base.service';
import { UserSubjectBaseService } from '@services/user-subject-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditUserComponent } from './edit-user.component';

@Component({
  selector: 'acb-alcon-create-user-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" (close)="onClose()">
      Create User
    </kendo-dialog-titlebar>
    <ng-container #editUserContainer></ng-container>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!isFormValid" ><span class="k-icon k-i-checkmark"></span>Save</button>
    </kendo-dialog-actions>
  `,
  styles: [
  ],
  providers: [
    UserCreateService,
    {
      provide: UserFormBaseService,
      useExisting: UserCreateService
    },
    {
      provide: UserSubjectBaseService,
      useExisting: UserFormBaseService
    }
  ]
})
export class CreateUserDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('editUserContainer', { read:ViewContainerRef }) editUserContainer?: ViewContainerRef;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  private _valid: boolean = true;
  public get isFormValid():boolean {
    return this._valid;
  }

  private _userCreateService?: UserCreateService;
  public set userCreateService(value:UserCreateService) {
    this._userCreateService = value;
    this._userCreateService.validityChange.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._valid = x;
    })
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetectionRef : ChangeDetectorRef
  ) { }

  public destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {
    if (this.isFormValid)
      this.save.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  ngAfterViewInit(): void {

    // Need to pass service to edit details component, provider context is lost going through window service...
    // TODO: Clean up by moving to feature NGRX?
    if (this.editUserContainer && this._userCreateService) {

      const factory = this.resolver.resolveComponentFactory(EditUserComponent);
      const injector: Injector = Injector.create({ providers: [
        {
          provide: UserCreateService, useValue: this._userCreateService
        },
        {
          provide: UserFormBaseService, useExisting: UserCreateService
        }
      ]});
      injector.get(UserCreateService);

      const ref: ComponentRef<EditUserComponent> =  this.editUserContainer.createComponent(factory, undefined, injector);
      ref.instance.editUserMode = 'create';

      this.changeDetectionRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
