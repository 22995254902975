import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserPreferences from './user-preferences.state';

export const selectUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(
  fromUserPreferences.userPreferencesFeatureKey
);

export const selectThemeId = createSelector(
  selectUserPreferencesState,
  (state: fromUserPreferences.UserPreferencesState) => (state ? state.themeId : null)
);

export const selectManageFeaturesPage_showAllAccessRoles = createSelector(
  selectUserPreferencesState,
  (state: fromUserPreferences.UserPreferencesState) => ((state ? state.container.manageFeaturesPage.showAllAccessRoles : false) ?? false)
);

export const selectGridPreferences = createSelector(
  selectUserPreferencesState,
  (state: fromUserPreferences.UserPreferencesState) => ((state ? state.gridPreferences : {}) ?? {})
);

export const selectDashboardPreferences = createSelector(
  selectUserPreferencesState,
  (state: fromUserPreferences.UserPreferencesState) => ((state ? state.dashboardPreferences : null) ?? null)
);
