import { InjectionToken } from "@angular/core";
import { SelectSearchBindingBaseDirective } from "../directives/select-search-binding.base.directive";

export type SearchFlavor = 'search' | 'manage' | 'review' | 'close' | 'exception';
export type CustomerViewMode = 'customer' | 'thirdparty' | 'payee';
export type EmailRequestMode = 'add' | 'edit';
export type ByMeOptions =  'created' | 'impersonated' | 'both';
export const SELECT_SEARCH_BINDING = new InjectionToken<SelectSearchBindingBaseDirective>('SelectSearchBindingBaseDirective');



