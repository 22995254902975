import { FundSearchWithDefaultModel } from '@alcon-db-models/FundSearchWithDefaultModel';
import { Component, HostListener, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Subject, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { WindowMode } from '../components.module';
import { FundAdjustment } from '../core/core.module';
import { SelectComponentBase } from '../select.component.base';
import { FundSelectSearchBindingDirective } from './../../directives/fund-select-search-binding.directive';

@Component({
  selector: 'acb-alcon-select-fund',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" (close)="onClose()" *ngIf="this.windowMode == 'dialog'">
    Select Fund
    </kendo-dialog-titlebar>
    <div fxLayout="column" style="height: 100%;">
      <form [formGroup]="searchForm" style="width: 100%;" class="k-form">
        <acb-alcon-section-with-legend [doShowLegend]="windowMode == 'page'" class="acb-section-03" [sectionTitle]="'Select Fund'">
          <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start start">
            <kendo-formfield fxFlex >
              <kendo-label [for]="fundNameOrYear" text="Fund Name or Year"></kendo-label>
              <input formControlName="fundNameOrYear" kendoTextBox #fundNameOrYear autocomplete="off" />
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
            <div fxFlex='0 0 6em' style="text-align: right; padding: 36px 0 0 0;" class="acb-search-button-wrapper">
              <button type="submit" kendoButton (click)="onSearch()" style="width:100%">Search</button>
            </div>
          </div>
        </acb-alcon-section-with-legend>
      </form>
      <kendo-grid
        fundSelectSearchBinding
        [doInitResults]= "true"
        [selectable] = "{
          enabled: true,
          mode: 'single'
        }"
        [pageSize]="windowMode == 'dialog' ? windowHeigth > 800 ? 10 : 5 : 5"
        [pageable]="{
          buttonCount: 3,
          pageSizes: true,
          responsive: false,
          info: false
        }"
        [sortable]="true"
        [resizable]="true"
        (beforeBind)="updateFilter()"
        kendoGridSelectBy="fundID"
        [(selectedKeys)] = "selectedKeys"
        (selectionChange)="onSelectionChange($event)"
        #resultsGrid
        fxFlex
        [ngClass]="{
          'acb-grid-has-selection': !!getSelectedItem(),
          'acb-grid-window-mode-page': windowMode == 'page',
          'acb-grid-window-mode-dialog': windowMode == 'dialog',
          'acb-grid-page-size-5': resultsGrid.pageSize == 5,
          'acb-grid-page-size-10': resultsGrid.pageSize == 10,
          'acb-grid-page-size-15': resultsGrid.pageSize == 15
        }"
      >
        <kendo-grid-command-column [width]="32" [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Name" [field]="'displayName'" [width]="364" >
        </kendo-grid-column>
        <kendo-grid-column title="Year" [field]="'year'" [width]="76">
        </kendo-grid-column>
        <kendo-grid-column title="Budget" field="budget" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="showAdjustments">
        </kendo-grid-column>
        <kendo-grid-column title="Allocations" field="allocations" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="showAdjustments">
        </kendo-grid-column>
        <kendo-grid-column title="Adjustments" class="acb-grid-column-currency" [width]="110" *ngIf="fundAdjustments?.length && showAdjustments">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ getFundAdjustment(dataItem) | currency }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Balance" class="acb-grid-column-currency" [width]="110" *ngIf="showAdjustments">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ getFundAdjustedBalance(dataItem) | currency }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages
          [pagerItemsPerPage]="'per page'"
          [pagerItems]="'funds'"
          [pagerOf]="'of'"
          [pagerPage]="''"
          >
        </kendo-grid-messages>
        <ng-template #pagerTemplate kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage" let-total="total" let-pageSize="pageSize">
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[5,10,15]" fxHide.xs="true"></kendo-pager-page-sizes>
            <div fxFlex></div>
            <div style="font-size: smaller">{{total}} funds</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
    <kendo-dialog-actions *ngIf="this.windowMode == 'dialog'">
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!isSelected" (click)="onSelect(getSelectedItem())" ><span class="k-icon k-i-checkmark"></span>Select</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-fund.component.scss']
})
export class SelectFundComponent extends SelectComponentBase<FundSearchWithDefaultModel> implements OnInit, OnDestroy {

  @Input() windowMode: WindowMode = 'dialog';
  @Input() fundAdjustments: FundAdjustment[] = [];
  @Input() showAdjustments: boolean = false;

  @ViewChild(FundSelectSearchBindingDirective) public set fundSelectSearchBinding(value:FundSelectSearchBindingDirective) {
    this.bindingDirective = value;
    this.rebind();
  }

  get isSelected():boolean {
    return Boolean(this._selectedFund?.fundID);
  }

  private _bindingDirectiveDataChangeSubscription?: Subscription
  @Input() public set fundSpec(value: { fundID?:number | null | undefined, customerID:number | null | undefined, fundYears?: number[], doShowHidden?:boolean }) {

    this._bindingDirectiveDataChangeSubscription?.unsubscribe();
    if (!value) return;

    if (value.fundID)
      this.selectedKeys[0] = value.fundID;

    this.searchForm.patchValue({ prependFundID: value.fundID, customerID: value.customerID, fundYears: value.fundYears?.join(','), doShowHidden: value.doShowHidden });

    const data: FundSearchWithDefaultModel[] = (this.resultsGrid?.data as GridDataResult)?.data;
    let fund = value.fundID && data?.length ? data.find(x => x.fundID == value.fundID) : null;
    // make selection now if fund is naturally on first page
    if (fund) {
      this.doSelectionChange(fund);
    // else wait until data page is refreshed with prepend fund
    } else {
      this._bindingDirectiveDataChangeSubscription = this.bindingDirective?.dataChange.pipe(
        map((x:GridDataResult) => x?.data?.find((y:any) => y?.fundID == value.fundID) as FundSearchWithDefaultModel ?? null),
        filter(x => Boolean(x)),
        first()
      ).subscribe(x => this.doSelectionChange(x));
      super.onSearch();
    }
  }

  @HostListener('window:keyup.enter', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  protected formFieldsToClearOnSelect:string[] = ['prependFundID'];

  private _selectedFund:FundSearchWithDefaultModel | null = null;

  public destroy$: Subject<void> = new Subject<void>();

  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    fundYears: new UntypedFormControl(),
    fundNameOrYear: new UntypedFormControl(),
    customerID: new UntypedFormControl(),
    prependFundID: new UntypedFormControl(),
    doShowHidden: new UntypedFormControl(),
  });

  constructor(
    @Optional() wizardFeatureService?: WizardFeatureService,
    @Optional() commitmentFormBaseService?: CommitmentFormBaseService
    ) {
    super(wizardFeatureService);
  }

  public getSelectedItem():FundSearchWithDefaultModel | null {
    return this._selectedFund;
  }

  public getFundAdjustment(fund: FundSearchWithDefaultModel): number {
    if (!this.fundAdjustments?.length)
      return 0;
    return this.fundAdjustments.find(x => x.fundID && x.fundID == fund.fundID)?.adjustmentAmount ?? 0;
  }

  public getFundAdjustedBalance(fund: FundSearchWithDefaultModel): number {
    const adjustment = this.fundAdjustments?.length ?
      (this.fundAdjustments.find(x => x.fundID && x.fundID == fund.fundID)?.adjustmentAmount ?? 0) : 0;
    return (fund.balance ?? 0) + adjustment;
  }

  protected doSelectionChange(fund: FundSearchWithDefaultModel | null) {
    if (fund?.fundID) this.selectedKeys[0] = fund?.fundID;
    this._selectedFund = fund;
    this.searchForm.patchValue({ prependFundID: this._selectedFund?.fundID ?? null });
    this.selectedChange.emit(this._selectedFund);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

