import { Component, Input } from '@angular/core';

@Component({
  selector: 'acb-alcon-display-item',
  template: `
    <label class='acb-alcon-display-item-label'>{{ label }}</label>
    <label class='acb-alcon-display-item-value' [ngClass]="{'acb-large': isLarge}">{{ value }}</label>
  `,
  styleUrls: ['./display-item.component.scss']
})
export class DisplayItemComponent {

  @Input() label: string = "";
  @Input() isLarge: boolean = false;
  @Input() formatType: string = "";
  @Input() value?: string | null | number = "";

  constructor() { }
}
