import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-create-commitment-review',
  template: `
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start end" fxLayoutAlign.lt-md class="acb-details-wrapper" [ngClass.xs]="'acb-details-wrapper-xs'"  [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'">
        <acb-alcon-view-commitment-details-compact>
        </acb-alcon-view-commitment-details-compact>
        <div class="acb-edit-button-wrapper">
          <button
            kendoButton
            class="acb-edit-button"
            [ngClass] = "{ 'acb-error': !this.detailsValid }"
            (click)="onEditDetails()"
            >
          <span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">
        <div
          class="acb-customer acb-view-mode"
          fxLayout="row"
          fxFlex="1 0 16em"
          fxFlex.lt-sm="grow"
          [ngClass]="{ 'acb-expanded': customerDisplay.isExpanded }"
          [ngClass.gt-xs]="{ 'acb-expanded': customerDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
          >
          <div class="acb-details-layout-section acb-section2" fxFlex fxLayout="column">
            <acb-alcon-display-address
              #customerDisplay
              [label]="'Customer'"
              [code]="commitment?.customerCode"
              [name]="commitment?.customer"
              [location]="commitment?.customerLocation"
              [isExpandedOnly]="false"
              fxFlex
              ></acb-alcon-display-address>
            <div class="acb-edit-button-wrapper" [hidden]="!customerDisplay.isExpanded">
              <button kendoButton class="acb-edit-button" (click)="onEditCustomer()"><span class="k-icon k-i-edit"></span> Edit</button>
            </div>
          </div>
        </div>

        <div
          class="acb-payee acb-view-mode"
          fxLayout="row"
          fxFlex="1 0 16em"
          fxFlex.lt-sm="grow"
          [ngClass]="{ 'acb-expanded': payeeDisplay.isExpanded }"
          [ngClass.gt-xs]="{ 'acb-expanded': payeeDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
          >
          <div class="acb-details-layout-section acb-section3" fxFlex fxLayout="column">
            <acb-alcon-display-address
              #payeeDisplay
              [label]="'Payee'"
              [code]="commitment?.payeeCustomerCode"
              [name]="commitment?.payee"
              [location]="commitment?.payeeLocation"
              [isExpandedOnly]="false"
              fxFlex
              ></acb-alcon-display-address>
            <div class="acb-edit-button-wrapper" [hidden]="!payeeDisplay.isExpanded">
              <button kendoButton class="acb-edit-button" (click)="onEditPayee()"><span class="k-icon k-i-edit"></span> Edit</button>
            </div>
          </div>
        </div>

        <div
          class="acb-fund acb-view-mode"
          fxLayout="row"
          fxFlex="1 0 16em"
          fxFlex.lt-sm="grow"
          [ngClass]="{ 'acb-expanded': fundDisplay.isExpanded }"
          [ngClass.gt-xs]="{ 'acb-expanded': fundDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
          >
          <div class="acb-details-layout-section acb-section4" fxFlex fxLayout="column">
            <acb-alcon-display-info-card
              #fundDisplay
              [label]="'Fund'"
              [headerValue]="commitment?.fund"
              [nameAndValues] = "[
                ['ID', commitment?.fundCode, false],
                ['Name', commitment?.fund, true]
              ]"
              [isExpandedOnly]="false"
              fxFlex
              ></acb-alcon-display-info-card>
            <div class="acb-edit-button-wrapper" [hidden]="!fundDisplay.isExpanded">
              <button kendoButton class="acb-edit-button" (click)="onEditFund()"><span class="k-icon k-i-edit"></span> Edit</button>
            </div>
          </div>
        </div>
      </div>

      <div class="acb-later-steps" fxLayout="column" fxLayoutAlign="start stretch">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" [ngClass.gt-xs]="'acb-intra-form-margin-correction'" fxFlex="1 1 100%">
          <acb-alcon-section-with-legend
            *ngIf="doesUseCommitmentProducts"
            #productsSection
            [isCollapsible]="false"
            [sectionTitle]="'Products'"
            class="acb-section-11 acb-products-section"
            fxFlex="calc(33.333% - 2em)"
            [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
            >
            <div fxLayout="column" style="height: 100%;">
              <acb-alcon-view-commitment-products [commitment]="commitment" [viewMode]="'edit'"  [compactMode]="'compact'" fxFlex>
              </acb-alcon-view-commitment-products>
              <div class="acb-edit-button-wrapper">
                <button
                  kendoButton
                  class="acb-edit-button"
                  [ngClass] = "{ 'acb-error': !this.productsValid }"
                  (click)="onEditProducts()"
                >
                <span class="k-icon k-i-edit"></span> Edit</button>
              </div>
            </div>
          </acb-alcon-section-with-legend>
          <acb-alcon-section-with-legend
            [isCollapsible]="false"
            [sectionTitle]="'Phasing'"
            class="acb-section-10 acb-phasing-section"
            fxFlex="calc(33.333% - 2em)"
            [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
            >
            <div fxLayout="column" style="height: 100%;">
              <acb-alcon-view-commitment-phasing [commitment]="commitment" [viewMode]="'edit'" fxFlex>
              </acb-alcon-view-commitment-phasing>
              <div class="acb-edit-button-wrapper">
                <button
                  kendoButton
                  class="acb-edit-button"
                  [ngClass] = "{ 'acb-error': !this.phasingValid }"
                  (click)="onEditPhasing()"
                  >
                <span class="k-icon k-i-edit"></span> Edit</button>
              </div>
            </div>
          </acb-alcon-section-with-legend>
          <acb-alcon-section-with-legend
            #attachmentsSection
            [isCollapsible]="false"
            [sectionTitle]="'Attachments'"
            class="acb-section-06 acb-attachments-section"
            fxFlex="calc(33.333% - 2em)"
            [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
            [ngClass.xs]="'acb-attachments-section-small'"
            >
            <div fxLayout="column" style="height: 100%;">
              <acb-alcon-view-commitment-attachments [viewMode]="'view-edit'" [compactMode]="'compact'" [commitment]="commitment" [doShowFieldLabel]="false" >
              </acb-alcon-view-commitment-attachments>
              <div class="acb-edit-button-wrapper">
                <button kendoButton class="acb-edit-button" (click)="onEditAttachments()"><span class="k-icon k-i-edit"></span> Edit</button>
              </div>
            </div>
          </acb-alcon-section-with-legend>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./create-commitment-review.component.scss']
})
export class CreateCommitmentReviewComponent implements OnInit, OnDestroy {

  @Output() selectCustomer: EventEmitter<any> = new EventEmitter();
  @Output() selectPayee: EventEmitter<any> = new EventEmitter();
  @Output() selectFund: EventEmitter<any> = new EventEmitter();
  @Output() newPayee: EventEmitter<any> = new EventEmitter();
  @Output() editDetails: EventEmitter<any> = new EventEmitter();
  @Output() editProducts: EventEmitter<any> = new EventEmitter();
  @Output() editPhasing: EventEmitter<any> = new EventEmitter();
  @Output() editAttachments: EventEmitter<any> = new EventEmitter();

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  doesUseCommitmentProducts: boolean = false;
  commitmentProductsRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public detailsValid: boolean = false;
  public productsValid: boolean = false;
  public phasingValid: boolean = false;
  public filesValid: boolean = false;
  public commitment?: CommitmentWithDetailsModel;
  public destroy$: Subject<void> = new Subject<void>();

  constructor (
    store: Store,
    private commitmentFormBaseService: CommitmentFormBaseService
  ) {
    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.doesUseCommitmentProducts = x.DoesUseCommitmentProducts;
      this.commitmentProductsRequired = x.DoesUseCommitmentProducts && x.CommitmentProductsRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })
    this.commitmentFormBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitment = x;
      if (this.areCommitmentActivityRulesDeterminedByFund) {
        this.doesUseCommitmentActivities = x.fundDoesUseCommitmentActivities ?? false;
        this.commitmentActivitiesRequired = (x.fundDoesUseCommitmentActivities ?? false) && (x.fundCommitmentActivitiesRequired ?? false);
      }

    });
    this.commitmentFormBaseService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.detailsValid = x.details;
      this.productsValid = x.products;
      this.phasingValid = x.phases;
    });
  }

  ngOnInit(): void {
  }

  public onEditCustomer() {
    this.selectCustomer.emit();
  }

  public onEditPayee() {
    this.selectPayee.emit();
  }

  public onNewPayee() {
    this.newPayee.emit();
  }

  public onEditFund() {
    this.selectFund.emit();
  }

  public onEditDetails() {
    this.editDetails.emit();
  }

  public onEditProducts() {
    this.editProducts.emit();
  }

  public onEditPhasing() {
    this.editPhasing.emit();
  }

  public onEditAttachments() {
    this.editAttachments.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
