import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SuccessEvent, UploadComponent, UploadEvent, ErrorEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { ResourceModel } from '@alcon-db-models/ResourceModel'
import { AttachmentRole, SourceType, StatusCode } from '@alcon-db-models/Enums';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Observable, Subject } from 'rxjs';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentDetailAttachmentModel } from '@alcon-db-models/CommitmentDetailAttachmentModel';
import { RemoveEvent } from '@progress/kendo-angular-grid';
import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectApplicationInfo, selectResourceTypes } from '@app-store/app-session/app-session.selectors';
import { ClaimDetailAttachmentModel } from '@alcon-db-models/ClaimDetailAttachmentModel';
import { FileUploadService } from '@services/file-upload.service';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';
import { Utilities } from '../shared/utilities';

@Component({template:''})
export abstract class UploadFileBase implements OnInit, OnDestroy {

  protected _files: any[] = [];
  @Input() set files(value: any[]) {
    this._files = value ?? [];
    const resourcesFromFiles = this._files.filter(x => x.resource).map(x => x.resource);
    this.stagedResources = [...this.stagedResources, ...resourcesFromFiles];
  }
  get files():any[] {
    return this._files;
  }

  public extensions: string[] = [];
  public stagedResources: ResourceModel[] = [];
  public applicationInfo: ApplicationInfoModel = new ApplicationInfoModel();
  public fileUploadForm = new UntypedFormGroup({ files: new UntypedFormControl(null) });

  public valid: boolean = false;

  protected destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected appUxService: AppUxService,
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef
  ) {

    store.select(selectResourceTypes).pipe(first()).subscribe(x => {
      const extensions: string[] = [];
      x.forEach(y => {
        if (y.fileExtension?.startsWith('.')) extensions.push(y.fileExtension);
        if (y.altFileExtension?.startsWith('.')) extensions.push(y.altFileExtension);
      })
      this.extensions = extensions;
    })

    store.select(selectApplicationInfo).pipe(first()).subscribe(x => {
      this.applicationInfo = JsonUtilities.convertDatesAndCopy(x);
    });

    this.fileUploadForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.valid = this.fileUploadForm.valid;
      this.changeDetectorRef.detectChanges();
    })

  }

  protected getValidationMessage(key:string):string {
    switch(key) {
      case "invalidFileExtension":
        return "Type not supported.";
      case "invalidFiles":
        return "Not supported.";
      case "invalidMaxFileSize":
        return "Too large.";
      case "invalidMinFileSize":
        return "Too small.";
    }
    return "";
  }

  public validateForm(): boolean {
    this.fileUploadForm.markAllAsTouched();
    return this.fileUploadForm.valid;
  }

  public getErrors(): string[] {
    const errors: string[] = [];
    if (this.fileUploadForm.controls.files.errors)
      errors.push("File is required");
    return errors;
  }

  ngOnInit(): void {
  }

  public onSuccess(event:SuccessEvent) {
    if (event.operation == 'upload') {
      if (!Array.isArray(event.response.body))
        return;
      const r = [...event.response.body, ...this.stagedResources];
      // Remove duplicates...
      this.stagedResources = r.filter((n, i) => r.indexOf(n) === i);
    }
  }

  public onUpload (event:UploadEvent) {
    if (event.files?.length != 1)
      return;
    event.data = {
      uid: event.files[0].uid
    };
  }

  public onError(event:ErrorEvent) {
    if (event.operation == 'upload')
      this.appUxService.openErrorDialog(['Error uploading file']);
  }

  public onSelect(event:SelectEvent) {
    const invalidFiles = event.files.filter(x => x.validationErrors?.length);
    if (invalidFiles.length) {
      const messages = [...['Invalid File(s)'],...new Set(([] as string[]).concat.apply([], invalidFiles.map(x => x.name + " - " + (x.validationErrors ?? []).map(y => this.getValidationMessage(y)).reduce((a,v) => a + " " + v)    )))];
      this.appUxService.openErrorDialog(messages);
    }
  }





  public onRemove(event: any) {

    if (event.files?.length != 1)
      return;

    const matchedResource = this.stagedResources.find(x => x.code == Utilities.stripUid(event.files[0].uid));
    if (matchedResource) {
      this.stagedResources = this.stagedResources.filter(x => x != matchedResource);
      if (matchedResource?.resourceID)
        event.data = {
          resourceID: matchedResource.resourceID,
          uid: event.files[0].uid
        };
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
