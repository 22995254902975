import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { selectIsReady } from "@app-store/root.selectors";
import { Store } from "@ngrx/store";
import { Observable, first, tap } from "rxjs";

export const userPreferencesResolver: ResolveFn<any> = 
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> =>
    {
        const store = inject(Store);
        return store.select(selectIsReady).pipe(first(x => x));
    }