import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserPreferencesEffects } from './user-preferences.effects'
import { userPreferencesReducer } from './user-preferences.reducer'
import { userPreferencesFeatureKey } from './user-preferences.state'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(userPreferencesFeatureKey, userPreferencesReducer),
    EffectsModule.forFeature([UserPreferencesEffects])
  ],
  providers: [UserPreferencesEffects]
})
export class UserPreferencesModule {}
