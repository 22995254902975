import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TerritoryWithBalanceModel } from '@alcon-db-models/TerritoryWithBalanceModel'

@Injectable({
  providedIn: 'root'
})
export class TerritoryWithBalanceService extends EntityCollectionServiceBase<TerritoryWithBalanceModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('TerritoryWithBalance', serviceElementsFactory);
  }
}
