import { Component, OnInit, EventEmitter, Output, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataBindingDirective, ExcelComponent, ExcelExportEvent, GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { CommitmentSearchModel } from '@alcon-db-models/CommitmentSearchModel';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { from, Observable } from 'rxjs';
import { TerritoryForDropdownWithCompositeNameModel } from './core/core.module';
import { PageExpansionService } from '@services/page-expansion.service';
import { Store } from '@ngrx/store';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { formatDate } from '@angular/common';
import { SearchBaseComponent } from './search.base.component'
import { SearchFilterService } from '@services/search-filter.service';
import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { selectBusinessRules, selectImpersonationStatus } from '@app-store/app-session/app-session.selectors';
import { first, switchMap, take } from 'rxjs/operators';
import { ByMeOptions } from 'src/app/shared/static';
import { Utilities } from '../shared/utilities';
import { ComponentUtilities } from '../shared/component-utilities';

@Component({
  selector: 'acb-alcon-search-base',
  template: '',
  styles: [ ]
})
export abstract class GridBoundSearchBaseComponent extends SearchBaseComponent implements OnInit, AfterViewInit  {

  @Output() viewEntity: EventEmitter<any> = new EventEmitter();
  @Output() editEntity: EventEmitter<any> = new EventEmitter();
  @Output() printEntity: EventEmitter<any> = new EventEmitter();

  @ViewChild(ExcelComponent) public gridExcelComponent?:ExcelComponent;

  public selectedKeys: number[] = [];
  public isEventAndVenueAware: boolean = false;

  public get hasSelected(): boolean { return this.selectedKeys && !!this.selectedKeys.length };

  private _isGridIdentifierAndActionColumnsLocked: boolean = false;
  public get isGridIdentifierAndActionColumnsLocked(): boolean { return this._isGridIdentifierAndActionColumnsLocked; };
  public set isGridIdentifierAndActionColumnsLocked(value: boolean) {
    this._isGridIdentifierAndActionColumnsLocked = value;
    this.gridIdentifierAndActionColumnsLockedIcon = value ? 'lock' : 'unlock';
  }
  public gridIdentifierAndActionColumnsLockedIcon: string = 'unlock';

  public abstract resultsGrid?: GridComponent;
  public abstract bindingDirective?: DataBindingDirective;
  public searchResults$: Observable<CommitmentSearchModel> = from([]);

  private personID: number | undefined;
  private impersonatorPersonID: number | undefined;

  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    searchFilterService: SearchFilterService,
    private changeDetectorRef: ChangeDetectorRef
    ) {

    super(store, pageExpansionService, searchFilterService);

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.isEventAndVenueAware = x.EventAndVenueAware;
    })

    this.store.select(selectImpersonationStatus).pipe(take(1)).subscribe((x) => {
      this.personID = x.currentPerson.personID ?? undefined;
      this.impersonatorPersonID = x.isImpersonating ? x.accessPerson.personID ?? undefined : undefined;
    });

    this.updateFilter();
    this.allData = this.allData.bind(this);
  }

  public getTimestamp() {
    return Utilities.getConcatenatedPaddedDate();
  }


  ngOnInit(): void {
  }

  private _orginalExportFileName?: string = "";
  ngAfterViewInit() {
    this._orginalExportFileName = this.gridExcelComponent?.fileName ?? 'Export';
    this.onSearch();
    this.changeDetectorRef.detectChanges();

    this.resultsGrid?.excelExport.subscribe((x:ExcelExportEvent ) => {
      ComponentUtilities.handleExcelExportWithTimestampAndPreventEmpty(x, this._orginalExportFileName, this.gridExcelComponent);
    })

    // this.resultsGrid?.excelExport.subscribe((x:ExcelExportEvent ) => {

    //   // add timestamp to filename
    //   if (this._orginalExportFileName && this.gridExcelComponent) {
    //     const idx = this._orginalExportFileName.lastIndexOf('.');
    //     const front = idx ? this._orginalExportFileName.substring(0, idx) : this._orginalExportFileName;
    //     this.gridExcelComponent.fileName = `${front}_${Utilities.getConcatenatedPaddedDate()}`;
    //   }

    //   // export nothing if none of the sheets have non-header rows
    //   if (!x.workbook?.sheets?.length || !x.workbook.sheets.some((s:any) => s.rows.some((r:any) => r.type != 'header')))
    //     x.preventDefault();
    // })
  }

  public onSearch() {

    this.selectedKeys.length = 0;

    if (!this.resultsGrid || !this.bindingDirective)
      return;

    this.updateFilter();
    this.bindingDirective.skip = 0;
    this.rebind();
  }


  public updateFilter() {

    if (!this.resultsGrid || !this.bindingDirective)
      return;

    const form = this.searchForm;
    let filters:FilterDescriptor[] = [];

    const setValue = function(fieldName: string) {
      const field = form.get(fieldName);
      if (field) {

        let val = field.value;
        switch(fieldName) {

          case 'claimIDs':
          case 'commitmentIDs':
          case 'customerIDs':
          case 'fundingBatchIDs':
            break;
          case 'statusCode':
            val = field.value?.id ?? field.value?.statusCodeID;
            fieldName = 'statusCodeID';
            break;
          case 'statusCodes':
            val = (val ?? []).map((x: any) => x.id ?? x.statusCodeID).join();
            fieldName = 'statusCodeIDs';
            break;
          case 'fund':
            val = field.value?.fundID;
            fieldName = 'fundID';
            break;
          case 'funds':
            val = Array.isArray(field.value) ? (field.value as FundForDropdownModel[]).map(x => x.fundID).join(',') : null;
            fieldName = 'fundIDs';
            break;
          case 'activities':
            val = Array.isArray(field.value) ? (field.value as ActivityForDropdownModel[]).map(x => x.activityID).join(',') : null;
            fieldName = 'activityIDs';
            break;
          case 'fundYears':
            val = Array.isArray(field.value) ? (field.value as number[]).join(',') : null;
            break;
          case 'territory':
            val = field.value?.territoryID;
            fieldName = 'territoryID';
            break;
          case 'territoryIDs':
          case 'territories':
            val = (val ?? []).map((x: TerritoryForDropdownWithCompositeNameModel) => x.territoryID).join();
            fieldName = 'territoryIDs';
            break;
          case 'accessRoles':
            val = (val ?? []).map((x: StaticTypeModel) => x.id).join();
            fieldName = 'accessRoleIDs';
            break;
          case 'businessRoles':
            val = (val ?? []).map((x: StaticTypeModel) => x.id).join();
            fieldName = 'businessRoleIDs';
            break;
          default:
            if (typeof field.value === 'string')
              // FIX ME: Escape strings better?  We us a TSQL LIKE comparison...
              val = field.value ? '%' + field.value.replace('%','[%]').replace('_','[_]').replace('[','[[') + '%' : undefined;
            else if (field.value instanceof Date)
              val = formatDate(field.value, 'shortDate', 'en-us');
            else if (field.value instanceof Boolean)
              val = Boolean(val)
        }
        filters.push({ field: fieldName, operator: 'eq', value: val })
      }
    }

    //HACK: handle ByMe
    if (Object.keys(form.controls).includes('byMe')) {
      const byMeValue = form.get('byMe')?.value;
      Object.keys(form.controls).filter(x => x != 'byMe').forEach(setValue);
      if (byMeValue) {
        switch(byMeValue as ByMeOptions) {
          case 'created':
            filters.push({ field: 'createdByPersonID', operator: 'eq', value: this.personID })
            break;
          case 'impersonated':
            filters.push({ field: 'impersonatedByPersonID', operator: 'eq', value: this.personID })
            break;
          case 'both':
            filters.push({ field: 'createdByPersonID', operator: 'eq', value: this.personID })
            filters.push({ field: 'impersonatedByPersonID', operator: 'eq', value: this.personID })
            break;
        }
      }
    } else {
      Object.keys(form.controls).forEach(setValue);
    }

    this.bindingDirective.filter = { filters: filters, logic: 'and' };
  }

  public rebind() {
    this.selectedKeys.length = 0;
    this.bindingDirective?.rebind();
  }

  public onToggleGridIdentifierAndActionColumnsLocked() {
    this.isGridIdentifierAndActionColumnsLocked = !this._isGridIdentifierAndActionColumnsLocked;
  }


  public abstract allData(): Observable<ExcelExportData>;
}


