


import { CommitmentOrganizationsModel } from './CommitmentOrganizationsModel';//3

export class CommitmentInfoModel extends CommitmentOrganizationsModel {
    status?: string | null;
    statusCodeID?: number | null;
    approvalResult?: string | null;
    approvalResultTypeID?: number | null;
    commitmentDetailStatusID?: number | null;
    amount?: number | null;
    claimed?: number | null;
    approved?: number | null;
    unused?: number | null;
    returned?: number | null;
    adjusted?: number | null;
    available?: number | null;
    startDate?: Date | null;
    endDate?: Date | null;
    expirationDate?: Date | null;
    event?: string | null;
    venue?: string | null;
    venueCity?: string | null;
    venueStateProvinceCodeID?: number | null;
    venueStateProvinceCode?: string | null;
    estimatedAttendeeCount?: number | null;
    activityID?: number | null;
    activity?: string | null;
    notificationRecipientEmailAddress?: string | null;
    hasException?: boolean | null;
    exception?: string | null;
    commitmentComment?: string | null;
}

 
     
         
