import { NgModule, Injector, forwardRef, InjectionToken, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

// Kendo charts depend on this
import 'hammerjs';

import { StoreModule, INITIAL_STATE } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MenuModule } from '@progress/kendo-angular-menu';

import { environment } from '@environments/environment';
import * as fromRoot from './store'
import { RootModule } from '@app-store/root.module'
import { RootEffects } from '@app-store/root.effects';

import { ComponentsModule } from './components/components.module';

import { AuthenticationService } from '@data-services/authentication.service';
import { ClientStorageService } from '@data-services/client-storage.service';
import { ApplicationService } from '@data-services/application.service';
import { AppConfigService } from '@data-services/app-config.service';
import { CommitmentSearchService } from '@data-services/commitment-search-service'
import { CustomerSelectSearchService } from '@data-services/customer-select-search-service'
import { FundSelectSearchService } from '@data-services/fund-select-search.service'
import { PayeeSelectSearchService } from '@data-services/payee-select-search.service'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSessionEffects } from '@app-store/app-session/app-session.effects';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LoginPageModule } from './containers/login-page/login-page.module';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { IconsModule } from '@progress/kendo-angular-icons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DefaultFieldDirective } from './directives/default-field.directive';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { NgResizeObserverPonyfillModule } from 'ng-resize-observer';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PageReuseStrategy } from './page-resuse-strategy'

import { SELECT_SEARCH_BINDING } from './shared/static';
import { CustomerSelectSearchBindingDirective } from './directives/customer-select-search-binding.directive';
import {NoopAnimationsModule, BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { JsdateInterceptor } from './interceptors/jsdate.interceptor';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { GlobalErrorHandler } from './global-error-handler';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AnonymousCommitmentReviewPageComponent } from './containers/external/anonymous-commitment-review-page/anonymous-commitment-review-page.component';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ICON_SETTINGS } from "@progress/kendo-angular-icons";


export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    DefaultFieldDirective,
    AnonymousCommitmentReviewPageComponent
  ],
  imports: [
    NoopAnimationsModule,
    NgResizeObserverPonyfillModule,
    RootModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    StoreModule.forRoot(fromRoot.RootReducers.rootReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        // TODO:  Fix this hack.  We still have a problem with errors returned by API enity fetch
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([RootEffects, AppSessionEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false,
      serverLogLevel: NgxLoggerLevel.OFF,
      serverLoggingUrl: '',
      httpResponseType: 'json',
      enableSourceMaps: true,
      timestampFormat: 'HH:mm:ss.SS',
    }),
    MenuModule,
    TreeListModule,
    LayoutModule,
    FlexLayoutModule,
    ComponentsModule,
    DialogsModule,
    LoginPageModule,
    EntityDataModule.forRoot(entityConfig),
    IconsModule,
    GridModule,
    DateInputsModule,
    TooltipModule,
    UploadModule,
    ToolBarModule,
    FontAwesomeModule,
    SortableModule,
    ChartsModule,
    ProgressBarModule,
    BrowserAnimationsModule,
    NotificationModule,
    PDFExportModule,
  ],
  providers: [
    { provide: ICON_SETTINGS, useValue: { type: "font" } },
    {provide: SELECT_SEARCH_BINDING, useClass: forwardRef(() => CustomerSelectSearchBindingDirective)},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    AuthenticationService,
    ClientStorageService,
    ApplicationService,
    AppConfigService,
    CommitmentSearchService,
    CustomerSelectSearchService,
    FundSelectSearchService,
    PayeeSelectSearchService,
    // {
    //   provide: INITIAL_STATE,
    //   deps: [ClientStorageService],
    //   useFactory: (css: ClientStorageService) => fromRoot.AppPersistedSettingsState.appPersistedSettingsIntialStateFactory(css)
    // },
    // {
    //   provide: INITIAL_STATE,
    //   deps: [ClientStorageService],
    //   useFactory: (css: ClientStorageService) => fromRoot.AppSessionState.appIntialIntialStateFactory(css)
    // },
    {
      provide: INITIAL_STATE,
      deps: [ClientStorageService],
      useFactory: (css: ClientStorageService) => fromRoot.RootState.appStateFactory(css)
    },
    {
      provide: DefaultDataServiceConfig,
      useValue: {
        root: environment.baseApiUrl,
        timeout: 30000, // request timeout
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsdateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: PageReuseStrategy,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}


