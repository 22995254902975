export class Utilities {

  public static stripUid (uid?:string | null): string | null {
    return uid?.replace(/\W/g, '') ?? null;
  }

  public static unstripUid (uid:string): string {
    return uid?.length >= 32 ? uid.slice(0, 8) + "-" + uid.slice(8, 12) + "-" + uid.slice(12, 16) + "-" + uid.slice(16, 20) + "-" + uid.slice(20, 36) : uid;
  }

  public static spreadYears(years:any[] | Date | null | undefined): number[] {

    let yrs:any[];
    const getInt = (x:any): number | null => Number.isInteger(x) ? x : x?.getFullYear ? x.getFullYear() ?? null : null;

    if (Array.isArray(years)) {
      yrs = years.map(getInt).filter(Number.isInteger);
    } else {
      const y = getInt(years);
      yrs = y ? [y] : [];
    }

    if (!yrs?.length)
      return <number[]>[];
    const y1 = Math.min.apply(null, yrs);
    const y2 = Math.max.apply(null, yrs);
    return Array(y2 - y1 + 1).fill(y1).map((y,i) => y + i);
  }

  public static deepCopyObject(object: any) {
    return Object.assign({}, object, JSON.parse(JSON.stringify(object)));
  }

  // https://github.com/jfromaniello/url-join
  public static normalizeUrl (strArray: string[]) {
    var resultArray = [];
    if (strArray.length === 0) { return ''; }

    if (typeof strArray[0] !== 'string') {
      throw new TypeError('Url must be a string. Received ' + strArray[0]);
    }

    // If the first part is a plain protocol, we combine it with the next part.
    if (strArray[0].match(/^[^/:]+:\/*$/) && strArray.length > 1) {
      var first = strArray.shift();
      strArray[0] = first + strArray[0];
    }

    // There must be two or three slashes in the file protocol, two slashes in anything else.
    if (strArray[0].match(/^file:\/\/\//)) {
      strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1:///');
    } else {
      strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1://');
    }

    for (var i = 0; i < strArray.length; i++) {
      var component = strArray[i];

      if (typeof component !== 'string') {
        throw new TypeError('Url must be a string. Received ' + component);
      }

      if (component === '') { continue; }

      if (i > 0) {
        // Removing the starting slashes for each component but the first.
        component = component.replace(/^[\/]+/, '');
      }
      if (i < strArray.length - 1) {
        // Removing the ending slashes for each component but the last.
        component = component.replace(/[\/]+$/, '');
      } else {
        // For the last component we will combine multiple slashes to a single one.
        component = component.replace(/[\/]+$/, '/');
      }

      resultArray.push(component);

    }

    var str = resultArray.join('/');
    // Each input component is now separated by a single slash except the possible first plain protocol part.

    // remove trailing slash before parameters or hash
    str = str.replace(/\/(\?|&|#[^!])/g, '$1');

    // replace ? in parameters with &
    var parts = str.split('?');
    str = parts.shift() + (parts.length > 0 ? '?': '') + parts.join('&');

    return str;
  }

  public static areEqualShallow(a: any, b: any) {

    if (a == null || b == null)
      return a == b;

    for (let key in a) {
      if (!(key in b) || a[key] !== b[key]) {
        return false;
      }
    }
    for (let key in b) {
      if (!(key in a)) {
        return false;
      }
    }
    return true;
  }

  public static getConcatenatedPaddedDate() {
    const now = new Date();
    return now.getFullYear() +
      (now.getMonth() + 1).toString().padStart(2, '0') +
      now.getDate().toString().padStart(2, '0') +
      now.getHours().toString().padStart(2, '0') +
      now.getMinutes().toString().padStart(2, '0') +
      now.getSeconds().toString().padStart(2, '0');
  }

  public static getShallowObjectCopyWithoutNullProperties(obj: any) {
    const newObj: { [name: string]: any } = {};
    if (obj && typeof obj === 'object') { Object.keys(obj).forEach((p) => { if (obj[p] != null) newObj[p] = obj[p]; }); }
    return newObj;
  }

  public static roundDollar(num:number) {
    return Math.round((num * 100) + Number.EPSILON) / 100;
  }




}

