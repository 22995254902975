import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';

@Component({
  selector: 'acb-alcon-edit-claim-product',
  template: `
    <ng-container *ngIf="form; let form" [formGroup]="form">
      <div fxLayout="row" fxLayoutGap=".5em" fxLayoutAlign="start center">
        <kendo-dropdownlist *ngIf="(productsForDropdown$ | async); let productsForDropdownData"
          formControlName="productID"
          [data]="productsForDropdownData"
          [textField]="'displayName'"
          [valueField]="'productID'"
          [valuePrimitive]="true"
          #productDropDown
          required
          fxFlex
        >
        </kendo-dropdownlist>
        <div class="acb-product-amount" fxFlex="6em">
          <kendo-numerictextbox
            [decimals]="2"
            [format]="'c'"
            [value]="0"
            [spinners]="false"
            formControlName="amount"
            [autoCorrect]="true"
            [step]="100"
            (valueChange)="onAmountValueChange($event)"
            >
          </kendo-numerictextbox>
        </div>
        <div class="acb-product-amount" fxFlex="4.75em">
          <kendo-numerictextbox
            [decimals]="8"
            [format]="percentFormat"
            [step]="1"
            [min]="0.0000000"
            [max]="100"
            [value]="0"
            [spinners]="false"
            formControlName="percent"
            [autoCorrect]="true"
            (valueChange)="onPercentValueChange($event)"
            >
          </kendo-numerictextbox>
        </div>
        <div>
          <button kendoButton [look]="'clear'" [icon]="'delete'" (click)="onDeleteClicked()">
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./edit-claim-product.component.scss']
})
export class EditClaimProductComponent implements OnInit,  AfterViewInit {

  @ViewChild('productDropDown') productDropDown: DropDownListComponent | undefined;

  @Input() form?: UntypedFormGroup;
  @Input() claimedAmount: number = 0;
  @Input() productsForDropdown$?: Observable<ProductForDropdownModel[]>;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  public percentFormat = "##0.00'%'";

  constructor(private store:Store, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  public onPercentValueChange(percent:number) {
    if (!this?.form?.controls) return;
    const controlValue = percent ? Math.floor((this.claimedAmount * percent) + Number.EPSILON) / 100 : 0;
    this.form.patchValue({ amount: controlValue });
  }

  public onAmountValueChange(amount:number) {
    if (!this?.form?.controls) return;
    const controlValue = ((amount ?? 0.00) / this.claimedAmount) * 10000;
    this.form.patchValue({ percent: controlValue / 100 });
  }

  public onDeleteClicked() {
    this.delete.emit(this.form);
  }
}
