import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Observable, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { DisplayAddressComponent } from '../display-address/display-address.component';
import { DisplayInfoCardComponent } from '../display-info-card/display-info-card.component';

@Component({
  selector: 'acb-alcon-edit-commitment',
  templateUrl: './edit-commitment.component.html',
  styleUrls: ['./edit-commitment.component.scss'],
})
export class EditCommitmentComponent implements OnDestroy, OnInit {

  @ViewChild("customerDisplay") customerDisplay?: DisplayAddressComponent;
  @ViewChild("payeeDisplay") payeeDisplay?: DisplayAddressComponent;
  @ViewChild("fundDisplay") fundDisplay?: DisplayInfoCardComponent;

  @Output() selectCustomer: EventEmitter<any> = new EventEmitter();
  @Output() selectPayee: EventEmitter<any> = new EventEmitter();
  @Output() selectFund: EventEmitter<any> = new EventEmitter();
  @Output() newPayee: EventEmitter<any> = new EventEmitter();
  @Output() editDetails: EventEmitter<any> = new EventEmitter();
  @Output() editProducts: EventEmitter<any> = new EventEmitter();
  @Output() editPhasing: EventEmitter<any> = new EventEmitter();
  @Output() editAttachments: EventEmitter<any> = new EventEmitter();

  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  doesUseCommitmentProducts: boolean = false;
  commitmentProductsRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public commitmentSource$: Observable<CommitmentWithDetailsModel> = new Observable;
  public commitment?: CommitmentWithDetailsModel;
  public destroy$: Subject<void> = new Subject<void>();

  public errorDialogVisible: boolean = false;
  public errorMessage: string = "";
  public valid: boolean = true;
  public canEditDetails: boolean = true;

  public detailsValid: boolean = true;
  public productsValid: boolean = true;
  public phasingValid: boolean = true;
  public fundValid: boolean = true;

  constructor(
    store: Store,
    private dialogService: DialogService,
    private commitmentFormBaseService: CommitmentFormBaseService,
    private changeDetectionRef: ChangeDetectorRef  ) {

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.doesUseCommitmentProducts = x.DoesUseCommitmentProducts;
      this.commitmentProductsRequired = x.DoesUseCommitmentProducts && x.CommitmentProductsRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })

    this.commitmentFormBaseService.loading$.subscribe(x => this.loading.emit(x));
    this.commitmentFormBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitment = x;
      if (this.areCommitmentActivityRulesDeterminedByFund) {
        this.doesUseCommitmentActivities = x.fundDoesUseCommitmentActivities ?? false;
        this.commitmentActivitiesRequired = (x.fundDoesUseCommitmentActivities ?? false) && (x.fundCommitmentActivitiesRequired ?? false);
      }
    })
    this.commitmentFormBaseService.customerUpdated.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x && this.customerDisplay)
        this.customerDisplay.isExpanded = false;
    })
    this.commitmentFormBaseService.payeeUpdated.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x && this.payeeDisplay)
        this.payeeDisplay.isExpanded = false;
    })
    this.commitmentFormBaseService.fundUpdated.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x && this.fundDisplay)
        this.fundDisplay.isExpanded = false;
    })
  }

  public showError(errorMessage: string) {

    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Error',
      content: 'Error saving commitment: ' + errorMessage,
      actions: [
          { text: 'OK', primary: true }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialogRef.result.pipe(take(1)).subscribe(() => {
    })
  }

  ngOnInit(): void {
    this.commitmentFormBaseService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.valid = x.form;
      this.detailsValid = x.details;
      this.productsValid = x.products;
      this.phasingValid = x.phases;
      this.fundValid = x.fund;
      // can't edit details if there are no territories...
      this.canEditDetails = x.customer && x.fund;
      this.changeDetectionRef.detectChanges();
    });
  }

  public onEditCustomer() {
    this.selectCustomer.emit();
  }

  public onEditPayee() {
    this.selectPayee.emit();
  }

  public onNewPayee() {
    this.newPayee.emit();
  }


  public onEditFund() {
    this.selectFund.emit();
  }

  public onEditDetails() {
    this.editDetails.emit();
  }

  public onEditProducts() {
    this.editProducts.emit();
  }

  public onEditPhasing() {
    this.editPhasing.emit();
  }

  public onEditAttachments() {
    this.editAttachments.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
