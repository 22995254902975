import { Component, TemplateRef } from "@angular/core";
import { WindowComponent, WindowRef, WindowService, WindowSettings, WindowState } from "@progress/kendo-angular-dialog";
import { first, takeUntil } from "rxjs/operators";
import { ViewSubjectWindow } from "../components/components.module";
import { WindowComService } from "./window-com.service";

export class AppWindowSubject<T extends Component & ViewSubjectWindow>  {

  private isLoading: boolean = false;
  private readonly minWidth = 200;
  private readonly minHeight = 200;

  private lastState: WindowState = 'default';
  private currentState: WindowState = 'default';
  private lastWidth: number = 0;
  private lastHeight: number = 0;
  private lastTop: number = 0;
  private lastLeft: number = 0;
  private _checkWindowDebounceTimeout: number = 0;

  private windowSpec?: {
    windowRef: WindowRef,
    windowComponent: WindowComponent,
    nativeWindow: HTMLElement
  }

  // --------------------------------------------------------------------------
  constructor(
    private windowService: WindowService,
    private desiredWidth: number,
    private desiredPadding: number,
    private forceMaximizeThreshold: number,
    private windowComService: WindowComService
  ) {
    // detect when page resizes
    const bodyResizeObserver = new ResizeObserver(() => {
      clearTimeout(this._checkWindowDebounceTimeout);
      this._checkWindowDebounceTimeout = window.setTimeout(this.checkWindow, 200);
    });
    bodyResizeObserver.observe(window.document.body, { });

    windowComService.close$.pipe(first(x => x)).subscribe(() => {
      this.windowSpec?.windowRef.close();
    })
  }

  // --------------------------------------------------------------------------
  public openSubject(settings: { content?: string | Function | TemplateRef<any>, title?: string }): { windowRef: WindowRef, component: T } | null  {

    const windowRef = this.windowService.open({
      ...settings,
      width: this.desiredWidth,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
    });

    const component = windowRef.content?.instance as T;
    if (!component) {
      windowRef.close();
      return null;
    }

    const windowComponent = component.windowComponent = windowRef.window.instance;
    const nativeWindow: HTMLElement | undefined = (windowComponent as any)?.el?.nativeElement;

    if (!nativeWindow) {
      windowRef.close();
      return null;
    }

    this.windowSpec = {
      windowRef,
      windowComponent,
      nativeWindow
    }

    // detect when this popup window resizes
    const windoResizeObserver = new ResizeObserver(() => {
      clearTimeout(this._checkWindowDebounceTimeout);
      this._checkWindowDebounceTimeout = window.setTimeout(this.checkWindow, 200);
    });
    windoResizeObserver.observe(nativeWindow);

    // detect when a popup window style changes. Mostly concerned with top and left
    const observer = new MutationObserver((mutationsList) => {
      if (mutationsList.some(x => x.type === 'attributes' && x.attributeName === 'style')) {
        this.checkWindow();
      }
    });
    observer.observe(nativeWindow, { attributes: true, childList: false, subtree: false });

    let vsto: number;
    this.isLoading = true;
    component.loading$.subscribe((x: boolean) => {
      const maximizeButton = nativeWindow.querySelector('button[kendowindowmaximizeaction]') as HTMLElement;
      const restoreButton = nativeWindow.querySelector('button[kendowindowrestoreaction]') as HTMLElement;
      const minimizeButton = nativeWindow.querySelector('button[kendowindowminimizeaction]') as HTMLElement;
      if (this.isLoading = x) {
        // simulating a dedicated loading popup.  This, along with visibility and timeout, is partly to hide content "pop in"
        windowComponent.width = 320;
      } else {
        const startingWidth = Math.min(window.outerWidth, window.innerWidth, this.desiredWidth);
        nativeWindow.style["visibility"] = "hidden";
        if (minimizeButton) minimizeButton.style["visibility"] = "visible";
        if (maximizeButton) maximizeButton.style["visibility"] = "visible";
        windowComponent.setDimension('width', startingWidth);
        window.clearTimeout(vsto);
        vsto = window.setTimeout(() => {
          // if we're forcing maximized (when the view/device is narrow), we should disallow retore
          if (window.innerWidth < this.forceMaximizeThreshold) {
            if (maximizeButton?.click) {
              maximizeButton.click();
              restoreButton.remove();
            }
          } else {
            this.centerWindow(windowRef);
          }
          nativeWindow.style["visibility"] = "visible";
          nativeWindow.focus();
        }, 20);
      }
    })

    /*
    We want to keep the window inside the broswer at all times, position and size.
    This is where we begin to fight, and hopefully win, with kendo's window positioning logic.
    TODO: Long term we should find a better way.  Create our own window control?  Inherit from kendo's?
    */
    this.storeNativePos();

    windowComponent.stateChange.subscribe((x: WindowState) => {
      this.lastState = this.currentState;
      this.currentState = x;
      switch(x) {
        case 'default':
          nativeWindow.style.removeProperty('min-width');
          windowComponent.setDimension('width', this.lastWidth);
          windowComponent.setDimension('height', this.lastHeight);
          if (this.lastState != 'minimized') {
            windowComponent.setOffset('top', this.lastTop);
            windowComponent.setOffset('left', this.lastLeft);
          }
          break;
        case 'maximized':
          // Must fight to force full width.  Kendo trys to keep a margin for the underlying page's scrollbar
          nativeWindow.style.setProperty('min-width', Math.min(window.outerWidth, window.innerWidth) + 'px', "important");
          break;
        default:
          nativeWindow.style.removeProperty('min-width');
          break;
      }
    });

    return { windowRef, component };
  }

  // --------------------------------------------------------------------------
  private centerWindow(windowRef: WindowRef) {
    const nativeWindow: HTMLElement = (windowRef.window?.instance as any)?.el?.nativeElement;
    if (nativeWindow) {
      const top = Math.floor(Math.max((window.innerHeight - nativeWindow.offsetHeight) / 2.0, 0));
      const left = Math.floor(Math.max((window.innerWidth - nativeWindow.offsetWidth) / 2.0, 0));
      windowRef.window.instance.setOffset("top", top);
      windowRef.window.instance.setOffset("left", left);
    }
  }

  // --------------------------------------------------------------------------
  private storeNativePos() {
    if (!this.windowSpec) return;
    this.lastWidth = this.windowSpec.nativeWindow.offsetWidth;
    this.lastHeight = this.windowSpec.nativeWindow.offsetHeight;
    this.lastTop = this.windowSpec.nativeWindow.offsetTop;
    this.lastLeft = this.windowSpec.nativeWindow.offsetLeft;
  }

  // --------------------------------------------------------------------------
  private checkWindow() {

    clearTimeout(this._checkWindowDebounceTimeout);

    if (!this.windowSpec || this.isLoading || this.currentState === 'maximized')
      return;

    const viewWidth = Math.min(window.outerWidth, window.innerWidth);
    const viewHeight = Math.min(window.outerHeight, window.innerHeight);

    const isOverWidth: boolean = viewWidth <= this.desiredWidth + (this.desiredPadding * 2);
    const padding = isOverWidth ? 0 : this.desiredPadding;

    if (this.windowSpec.windowComponent.minWidth > viewWidth + padding)
      return;

    if (this.windowSpec.windowComponent.minHeight > viewHeight + padding)
      return;

    const maxTop = Math.max(padding, viewHeight - this.windowSpec.nativeWindow.offsetHeight - padding);
    const maxLeft = Math.max(padding, viewWidth - this.windowSpec.nativeWindow.offsetWidth - padding);

    this.windowSpec.nativeWindow.style.maxHeight = (viewHeight + maxTop - padding) + "px";
    this.windowSpec.nativeWindow.style.maxWidth = (viewWidth + maxLeft - padding) + "px";

    if (this.windowSpec.nativeWindow.offsetTop < padding ) {
      this.windowSpec.windowComponent.setOffset("top", padding);
    } else if (this.windowSpec.nativeWindow.offsetTop > maxTop) {
      this.windowSpec.windowComponent.setOffset("top", maxTop);
    }

    if (this.windowSpec.nativeWindow.offsetLeft < padding ) {
      this.windowSpec.windowComponent.setOffset("left", padding);
    } else if (this.windowSpec.nativeWindow.offsetLeft > maxLeft ) {
      this.windowSpec.windowComponent.setOffset("left", maxLeft);
    }

    if (this.currentState === 'default') {
      this.storeNativePos();
    }
  }

}
