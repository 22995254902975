import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { ClaimUpsertRequestModel } from '@alcon-db-models/ClaimUpsertRequestModel'
import { Observable } from 'rxjs';
import { ResourceModel } from '@alcon-db-models/ResourceModel';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public cloneFilesForCommitmentCreate(commitmentID: number ): Observable<ResourceModel[]> {
    return this.post<ResourceModel[]>('CloneFilesForCommitmentCreate',  commitmentID );
  }

  public cloneFilesForClaimCreate(claimID: number ): Observable<ResourceModel[]> {
    return this.post<ResourceModel[]>('CloneFilesForClaimCreate',  claimID );
  }
}
