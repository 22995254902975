import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class FundForDropdownService extends EntityCollectionServiceBase<FundForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('FundForDropdown', serviceElementsFactory);
  }
}
