import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FundForDropdownService } from '@services/fund-for-dropdown.service';
import { Observable, of, throwError } from 'rxjs';
import { debounceTime, first, map, startWith, takeUntil } from 'rxjs/operators';
import { CommitmentSearchService } from '@services/commitment-search-service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CommitmentSearchBindingDirective} from './../../directives/commitment-search-binding.directive'
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { TerritoryForDropdownService } from './../../services/territory-for-dropdown.service';
import { PageExpansionService } from '@services/page-expansion.service';
import { ByMeOptions, SearchFlavor } from 'src/app/shared/static';
import { CommitmentSearchModel } from '@alcon-db-models/CommitmentSearchModel';
import { Router } from '@angular/router';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { AuthenticationService } from '@services/authentication.service';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { StatusCode, StatusCodeGroup } from '@alcon-db-models/Enums';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { SearchFilterService } from '@services/search-filter.service';
import { UserPreferenceEnabledGridBaseComponent } from '../user-preference-enabled-grid.base.component';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';


@Component({
  selector: 'acb-alcon-commitment-search',
  templateUrl: './commitment-search.component.html',
  styleUrls: ['./commitment-search.component.scss'],
  providers: [
    SearchFilterService
  ]
})
export class CommitmentSearchComponent extends UserPreferenceEnabledGridBaseComponent implements OnInit {

  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;

  private _bindingDirective?: CommitmentSearchBindingDirective;
  @ViewChild(CommitmentSearchBindingDirective) public set bindingDirective(value: CommitmentSearchBindingDirective | undefined) {
    this._bindingDirective = value;
    if (this._bindingDirective) this._bindingDirective.preferenceKey = "CommitmentSearch_" + this.searchFlavor;
  }
  public get bindingDirective() { return this._bindingDirective; }

  @Input() searchFlavor: SearchFlavor = 'search';

  @Output() createClaim: EventEmitter<CommitmentSearchModel> = new EventEmitter();
  @Output() reverseClaim: EventEmitter<CommitmentSearchModel> = new EventEmitter();
  @Output() copyCommitment: EventEmitter<CommitmentSearchModel> = new EventEmitter();
  @Output() approveCommitment: EventEmitter<CommitmentSearchModel> = new EventEmitter();
  @Output() denyCommitment: EventEmitter<CommitmentSearchModel> = new EventEmitter();
  @Output() reviewCommitments: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() reviewApproveCommitments: EventEmitter<number[]> = new EventEmitter();
  @Output() reviewDenyCommitments: EventEmitter<number[]> = new EventEmitter();
  @Output() cancelCommitments: EventEmitter<number[]> = new EventEmitter();
  @Output() closeCommitments: EventEmitter<number[]> = new EventEmitter();

  public commitmentStatusCodes$: Observable<StatusCodeWithGroupsModel[]>;

  public reviewDropDownButtonItems: Array<any> = [{
      text: 'Approve',
      icon: 'check-outline',
      click: () => this.onReviewApprove
  }, {
      text: 'Deny',
      icon: 'close-outline',
      className: 'acb-good',
      click: () => this.onReviewDeny
  }, {
      text: 'By Item',
      icon: 'more-horizontal',
      click: () => this.onReview
  }];

  public faEye = faEye;

  public currentUserFeatures$ = this.store.select(selectCurrentPersonFeatures);

  public canEditOwnCommitments: boolean = false;
  public canEditCommitments: boolean = false;
  public canCloneCommitments: boolean = false;
  public canClaimCommitments: boolean = false;
  public canReverseCommitments: boolean = false;
  public canCreateCommitments: boolean = false;

  public selectedItems: CommitmentSearchModel[] = [];
  public closableSelectedKeys: number[] = [];
  public cancellableSelectedKeys: number[] = [];

  public doShowEpansionAlert: boolean = false;

  public get commandColumnIsLocked(): boolean {
    return this._commandColumnMM.matches;
  }
  private _commandColumnMM = window.matchMedia ? window.matchMedia("(min-width: 720px)") : { matches: false };

  public doesUseCommitmentActivities: boolean = false;

  public byMeList: { name: string, value: ByMeOptions | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Created', value: 'created' }
  , { name: 'Impersonated', value: 'impersonated' }
  , { name: 'Both', value: 'both' }
  ];

  private _lastStatusCodeValue?: StaticTypeModel | null;
  private _lastStatusCodesValue?: StaticTypeModel[] | null;

  private _defaultFormValues = { excludeCanceledAndDenied: true };

  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    searchFilterService: SearchFilterService,
    private commitmentSearchService: CommitmentSearchService,
    router: Router,
    changeDetectorRef: ChangeDetectorRef
    ) {
      super(store, pageExpansionService, changeDetectorRef, searchFilterService, router);

      this.searchForm = new UntypedFormGroup({
        name: new UntypedFormControl(),
        commitmentIDs: new UntypedFormControl(),
        statusCodes: new UntypedFormControl(),
        _statusCode: new UntypedFormControl(),
        territories: new UntypedFormControl(),
        customerCode: new UntypedFormControl(),
        customerName: new UntypedFormControl(),
        payeeCode: new UntypedFormControl(),
        payeeName: new UntypedFormControl(),
        startDate: new UntypedFormControl(),
        endDate: new UntypedFormControl(),
        fundYears: new UntypedFormControl(),
        funds: new UntypedFormControl(),
        minAmount: new UntypedFormControl(),
        maxAmount: new UntypedFormControl(),
        activities: new UntypedFormControl(),
        byMe: new UntypedFormControl(),
        excludeCanceledAndDenied: new UntypedFormControl(),
      });

      this.searchForm.reset(this._defaultFormValues);

      this.commitmentStatusCodes$ = this.statusCodesWithGroups$.pipe(map(x => x.filter(y => y.statusCodeGroups?.some(z => z == StatusCodeGroup.Commitment))));

      store.select(selectBusinessRules).pipe(first()).subscribe(x => {
        this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      })

      this.currentUserFeatures$.pipe(first(x => x?.length > 0)).subscribe(x => {
        this.canEditOwnCommitments = x.some((x:FeatureModel) => x.code == 'can_edit_own_commitments');
        this.canEditCommitments = x.some((x:FeatureModel) => x.code == 'can_edit_commitments');
        this.canCloneCommitments = x.some((x:FeatureModel) => x.code == 'can_clone_commitments');
        this.canClaimCommitments = x.some((x:FeatureModel) => x.code == 'can_claim_commitments');
        this.canReverseCommitments = x.some((x:FeatureModel) => x.code == 'can_reverse_commitments');
        this.canCreateCommitments = x.some((x:FeatureModel) => x.code == 'mc_can_create_commitment');
      });

      this.pageExpansionService.pageExpanded$.pipe(takeUntil(this.destroy$), startWith(false)).subscribe(x => {
        this.searchForm.reset(this._defaultFormValues);
      });

      this.searchForm.valueChanges.pipe(debounceTime(100)).subscribe((x:any) => {

        const excludeCanceledAndDenied = this.searchForm.get('excludeCanceledAndDenied')?.value ?? false;
        this.commitmentStatusCodes$ = this.statusCodesWithGroups$.pipe(map(x =>
          x
            .filter(y => y.statusCodeGroups?.some(z => z == StatusCodeGroup.Commitment))
            .filter(y => !excludeCanceledAndDenied || (y.statusCodeID != StatusCode.Canceled && y.statusCodeID != StatusCode.Denied))
          ));

        this.doShowEpansionAlert =
          this.searchForm.get('payeeName')?.value ||
          this.searchForm.get('territories')?.value ||
          this.searchForm.get('customerCode')?.value ||
          this.searchForm.get('customerName')?.value ||
          this.searchForm.get('startDate')?.value ||
          this.searchForm.get('endDate')?.value ||
          this.searchForm.get('fundYears')?.value ||
          this.searchForm.get('funds')?.value ||
          this.searchForm.get('activities')?.value ||
          (this.searchForm.get('minAmount')?.value ?? 0) != 0 ||
          (this.searchForm.get('maxAmount')?.value ?? 0) != 0 ||
          false;

        //Sync status code dropdowns.
        //TODO: Is there a better way?

        const statusCodeFC = this.searchForm.get('_statusCode');
        const statusCodesFC = this.searchForm.get('statusCodes');
        if (!statusCodeFC || !statusCodesFC) return;

        if (this._lastStatusCodeValue != statusCodeFC.value) {
          if (!statusCodesFC.value?.length || !statusCodesFC.value.some((x: StatusCodeWithGroupsModel) => x.statusCodeID == statusCodeFC.value?.statusCodeID)) {
            if (!statusCodeFC.value) {
              statusCodesFC.setValue(null)
            } else if (statusCodesFC.value?.length) {
              statusCodesFC.value.push(statusCodeFC.value);
            } else {
              statusCodesFC.setValue([statusCodeFC.value]);
            }
          }
        } else if (this._lastStatusCodesValue != statusCodesFC.value) {
          if (!statusCodesFC.value?.length) {
            statusCodeFC.setValue(null);
          } else if (!statusCodesFC.value.some((x: StatusCodeWithGroupsModel) => x.statusCodeID == statusCodeFC.value?.statusCodeID)) {
            statusCodeFC.setValue(statusCodesFC.value[0]);
          }
        }
        //Ewww
        this._lastStatusCodeValue = statusCodeFC.value;
        this._lastStatusCodesValue = statusCodesFC.value;
      });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onViewCommitment(event: any, commitment: CommitmentSearchModel) {
    this.viewEntity.emit(commitment?.commitmentID);
  }

  public onEditCommitment(event: any, commitment: CommitmentSearchModel) {
    this.editEntity.emit(commitment?.commitmentID);
  }

  public onClaim(event: any, commitment: CommitmentSearchModel) {
    this.createClaim.emit(commitment);
  }

  public onReverse(event: any, commitment: CommitmentSearchModel) {
    this.reverseClaim.emit(commitment);
  }

  public onCopy(event: any, commitment: CommitmentSearchModel) {
    this.copyCommitment.emit(commitment);
  }

  public onApprove(event: any, commitment: CommitmentSearchModel) {
    this.approveCommitment.emit(commitment);
  }

  public onDeny(event: any, commitment: CommitmentSearchModel) {
    this.denyCommitment.emit(commitment);
  }

  public onCancelButtonClick() {
    if (this.cancellableSelectedKeys?.length)
      return this.cancelCommitments.emit([...this.cancellableSelectedKeys]);
  }

  public onCloseButtonClick() {
    if (this.closableSelectedKeys?.length)
      return this.closeCommitments.emit([...this.closableSelectedKeys]);
  }

  public onReviewApprove() {
    if (this.selectedKeys?.length)
      return this.reviewApproveCommitments.emit([...this.selectedKeys]);
  }

  public onReviewDeny() {
    if (this.selectedKeys?.length)
      return this.reviewDenyCommitments.emit([...this.selectedKeys]);
  }

  public onReview() {
    if (this.selectedKeys?.length)
      return this.reviewCommitments.emit([...this.selectedKeys]);
  }

  public onClearButtonClick() {
    this.selectedKeys = [];
  }

  public onCreateNewCommitment() {
    this.router.navigate(['./commitments/create']);
  }

  public getVisibility(visible: boolean = false) {
    return visible ? 'visible' : 'hidden';
  }

  public onSelectAllChange(event: any) {
    // Ugh, can't do this.  We can not select all possible rows since we never have a full list
    // and relying on a prefetched list of IDs is problematic, will fall out of sync
    //
    // if (event=="checked") {
    //   this.selectedKeys
    // }
  }

  public onResultsGridSelectedKeysChange(event: any[]) {
    const boundItems: CommitmentSearchModel[] = this.bindingDirective?.commitmentSearchService?.value?.data ?? [];
    this.selectedItems = [
      ...this.selectedItems.filter(x => x.commitmentID ? this.selectedKeys.includes(x.commitmentID) : false),
      ...this.selectedKeys.filter(x => !this.selectedItems.some(y => y.commitmentID == x)).map(x => boundItems.find(y => y.commitmentID == x) ?? {}).filter(x => x != null)
    ]
    this.closableSelectedKeys = this.selectedItems.filter(x => x.canClose && x.commitmentID).map(x => x.commitmentID ?? 0);
    this.cancellableSelectedKeys = this.selectedItems.filter(x => x.canCancel && x.commitmentID).map(x => x!.commitmentID ?? 0);
  }

  public onFiltersExpandChanged(isExpanded: boolean) {
    this.searchForm.reset(this._defaultFormValues);
  }

  public allData(): Observable<ExcelExportData> {
    return this.bindingDirective ?
      this.commitmentSearchService.searchForExcelExport({...(this.bindingDirective.getSearchRequest(true) ?? {}), isReview: this.searchFlavor === 'review' }) :
      of({});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
