import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AppUxService } from '@services/app-ux.service';
import { ClaimEditService } from '@services/claim-edit.service';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { ClaimSubjectBaseService } from '@services/claim-subject-base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { NotificationType } from '../edit-commitment/edit-commitment-dialog.component';

@Component({
  selector: 'acb-alcon-edit-claim-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" #dialogTitleBar (close)="onClose()">
      Edit Claim
    </kendo-dialog-titlebar>
    <acb-alcon-edit-claim-host
      #editClaim
      [style.visibility]="(loading$ | async) || (saving$ | async) ? 'hidden' : 'visible'"
      (viewCommitment)="viewCommitment.emit($event)"
      (viewClaim)="viewClaim.emit($event)"
      (loading)="onLoading($event)"
      >
    </acb-alcon-edit-claim-host>
    <kendo-dialog-actions [fxHide]="(loading$ | async) || (saving$ | async)">
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!valid"><span class="k-icon k-i-save" ></span>Save</button>
    </kendo-dialog-actions>
    <div class="acb-local-loading-indicator acb-opaque-loading-indicator" *ngIf="(loading$ | async) || (saving$ | async)"><span class="k-icon k-i-loading"></span></div>
  `,
  styleUrls: ['./edit-claim-dialog.component.scss'],
  providers: [
    ClaimEditService,
    {
      provide: ClaimFormBaseService,
      useExisting: ClaimEditService
    },
    {
      provide: ClaimSubjectBaseService,
      useExisting: ClaimEditService
    }
  ]
})
export class EditClaimDialogComponent  implements OnInit, OnDestroy {

  private _claimID?: number;
  @Input() public set claimID(value: number | undefined) {
    this._claimID = value;
  };
  public get claimID() { return this._claimID; };

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() viewClaim: EventEmitter<any> = new EventEmitter();
  @Output() notification: EventEmitter<NotificationType> = new EventEmitter();

  public valid: boolean = true;
  public loading$: Subject<boolean> = new BehaviorSubject<boolean>(true);
  public saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private claimEditService: ClaimEditService,
    private dialog: DialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private appUxService: AppUxService
  ) {

    //claimEditService.loading$.pipe(takeUntil(this.destroy$)).subscribe(this.loading$);
    claimEditService.saving$.pipe(takeUntil(this.destroy$)).subscribe(this.saving$);
  }

  ngOnInit(): void {
    this.claimEditService.claimID = this.claimID;
    this.claimEditService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {

      const elm = (this.dialog.dialog?.instance as any)?._elRef?.nativeElement;
      if (elm) {
        const classes:DOMTokenList = elm.classList;
        if (classes && classes.add) {
          classes.add('acb-dialog-with-notifications');
        }
      }
      this.valid = x.form;
      this.changeDetectorRef.detectChanges();
    });

  }

  public onLoading(isLoading: boolean) {
    this.loading$.next(isLoading);
  }

  public onCancel() {
    this.close.emit(null);
  }

  public onClose() {
    this.close.emit(null);
  }

  public onSave() {
    this.claimEditService.SaveClaim(undefined, false).pipe(first()).subscribe(x => {
      if (x.hasError) {
        this.appUxService.openErrorDialog(x.errorMessage)
      } else {
        this.saved.emit(null);
        this.close.emit(null);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
