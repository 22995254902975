
import { SearchServiceBase } from './search-service-base';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUxService } from './app-ux.service';

export abstract class SelectSearchBaseService extends SearchServiceBase<any> {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected store: Store,
    protected localPath: string,
    appUxService: AppUxService
  ) {
    super(http, router, store, localPath, appUxService);;
  }
}
