import { PersonProfilePreferenceInfoModel } from '@alcon-db-models/PersonProfilePreferenceInfoModel';
import { DashboardSetting, DashboardSettings, GridSettings } from 'src/app/components/core/core.module';

export const userPreferencesFeatureKey = 'UserPreferences';
const userPreferencesLocalStorageKey = userPreferencesFeatureKey;

export interface UserPreferencesState {
  themeId: number | null;
  container: {
    manageFeaturesPage: {
      showAllAccessRoles: boolean | null
    }
  };
  gridPreferences:  { [name: string]: GridSettings };
  profileID: string | null;
  profilePreferences: PersonProfilePreferenceInfoModel[];
  dashboardPreferences: DashboardSettings | null;
}

export const initialState: UserPreferencesState = {
  themeId: null,
  container: {
    manageFeaturesPage: {
      showAllAccessRoles: true
    }
  },
  gridPreferences:  {},
  profileID: null,
  profilePreferences: [],
  dashboardPreferences: null
};

export function getScopedUserPreferencesFeatureKey(userIdentifier: string) {
  return userIdentifier + '_' + userPreferencesLocalStorageKey;
}

