import { CommitmentDetailProductModel } from '@alcon-db-models/CommitmentDetailProductModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { formatPercent } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CompactMode, ViewMode } from '../components.module';

@Component({
  selector: 'acb-alcon-view-commitment-products',
  template: `
    <kendo-grid *ngIf="commitment?.products; let productsData"
      class="acb-results-grid acb-display-grid"
      [data]= "productsData"
      [pageable]="false"
      [sortable]="false"
      [reorderable]="false"
      [selectable] = "false"
      [resizable]="true"
      >
      <kendo-grid-column title="Product" field="product"></kendo-grid-column>
      <kendo-grid-column title="Amount" field="amount" format="{0:c}" class="acb-grid-column-currency" [width]="90" [hidden]="compactMode == 'compact'"></kendo-grid-column>
      <kendo-grid-column title="%" class="acb-grid-column-currency" [width]="60">
        <ng-template kendoGridCellTemplate let-dataItem >
          {{ getPrecent(dataItem) }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-commitment-products.component.scss']
})
export class ViewCommitmentProductsComponent implements OnInit {

  constructor() { }

  @Input() viewMode:ViewMode = 'view';
  @Input() commitment?:CommitmentWithDetailsModel;
  @Input() compactMode: CompactMode = 'normal';

  public getPrecent(product:CommitmentDetailProductModel) {
    const commitmentAmount = this.commitment?.amount ?? 0;
    return formatPercent(product.amount && commitmentAmount ? product.amount / commitmentAmount : 0, "en-US", '1.0-0');
  }

  ngOnInit(): void {
  }
}
