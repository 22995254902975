import { UserInfoModel } from '@alcon-db-models/UserInfoModel';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ServiceResponse } from '../shared/acb-stream';
import { UserInfoService } from './user-info.service';
import { UserSubjectBaseService } from './user-subject-base.service';

@Injectable()
export class UserFormBaseService extends UserSubjectBaseService {

  @Output() validityChange: EventEmitter<boolean> = new EventEmitter();

  public form: UntypedFormGroup;

  constructor (
    store: Store,
    userInfoService: UserInfoService
  ) {

    super(store, userInfoService);

    this.form = new UntypedFormGroup({
      userName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      emailAddress: new UntypedFormControl(null, [Validators.required]),
      isActive: new UntypedFormControl(true, [Validators.required]),
      mustUpdatePassword: new UntypedFormControl(false, [Validators.required]),
      doResetPassword: new UntypedFormControl(false),
      doEmailUser: new UntypedFormControl(true),
      accessRoleIDs: new UntypedFormControl(null, [Validators.required])
    });

    this.form.statusChanges.pipe(takeUntil(this._destroy$),debounceTime(250)).subscribe(x => {
      this.validityChange.emit(this.form.valid);
    });

    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(x => {
      // if (this.form.controls.doResetPassword.value && !this.form.controls.mustUpdatePassword.value) {
      //   this.form.patchValue ({ doResetPassword: true, mustUpdatePassword: true});
      //   this.form.updateValueAndValidity();
      // }
      //this.updateUserFromForm();
    });
  }

  public SaveUser(): Observable<ServiceResponse<UserInfoModel>> {

    const value = this.form.value;
    // TODO: we're fixing up an issue where the form shows the deisabled control as selected but passes the value as false.  There must be a better way...
    value.mustUpdatePassword = value.doResetPassword || value.mustUpdatePassword;

    return super.SaveUser({
      ...this._user,
      ...this.form.value,
      // HACK: there must be a better way.  Using this to catch a null as close to the service call as possible, for now. 
      ...(this._user?.doResetPassword == null || this.form.value?.doResetPassword == null) ? { doResetPassword: false } : {}
    });

  }

  // --------------------------------------------------------------------------
  public reset() {
    this.user$.next({});
    this.updateForm(this._user, false);
    this.form.reset();
  }

  // --------------------------------------------------------------------------
  public resetForm() {
    this.updateForm(this._user);
  }

  // --------------------------------------------------------------------------
  protected updateForm(user?:UserInfoModel, doValidate:boolean = true) {
    this.form.patchValue({
      userName: user?.userName,
      firstName: user?.firstName,
      lastName: user?.lastName,
      emailAddress: user?.emailAddress,
      isActive: user?.isActive ?? false,
      mustUpdatePassword: user?.mustUpdatePassword ?? false,
      doResetPassword: user?.doResetPassword ??  false,
      doEmailUser: user?.doEmailUser ?? false,
      accessRoleIDs: user?.accessRoleIDs
    });
    if (doValidate) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

}
