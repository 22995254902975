import { Component } from '@angular/core';
import { selectAccessPerson, selectCurrentPerson } from '@app-store/app-session/app-session.selectors';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { PageExpansionService } from '@services/page-expansion.service';
import { Console } from 'console';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-content-layout',
  template: `
    <div class="acb-impersonation-label" *ngIf="impersonatedPerson">
      <a href="./revert">
        <fa-icon [icon]="faMask"></fa-icon>
        {{ impersonatedPerson }}
      </a>
    </div>
    <acb-alcon-header-main [ngClass]="{ 'acb-impersonating': impersonatedPerson  }">
      <acb-alcon-menu-main></acb-alcon-menu-main>
    </acb-alcon-header-main>
    <div class="acb-page" [ngClass]="{ 'acb-impersonating': impersonatedPerson  }">
      <div class="acb-header-shadow"></div>
      <div class="acb-main-content" [ngClass]="{'acb-page-expanded': this.pageExpanded$ | async}">
        <router-outlet></router-outlet>
      </div>
      <acb-alcon-footer-main></acb-alcon-footer-main>
    </div>
    `,
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent {

  public impersonatedPerson: string | null= null;
  public destroy$: Subject<void> = new Subject<void>();
  public pageExpanded$ = this.pageExpansionService.pageExpanded$;

  faMask = faMask;

  constructor(
    store: Store,
    private pageExpansionService: PageExpansionService
  ) {
    combineLatest([store.select(selectCurrentPerson), store.select(selectAccessPerson)]).pipe(takeUntil(this.destroy$)).subscribe(([currentPerson, accessPerson]) => {
      this.impersonatedPerson = (currentPerson?.personID === accessPerson?.personID ? null : currentPerson?.displayName ?? null);
    })
  }

}
