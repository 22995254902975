import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'acb-alcon-edit-commitment-product',
  template: `
    <ng-container *ngIf="form; let form" [formGroup]="form">
      <div  fxLayout="row" fxLayoutGap=".5em" fxLayoutAlign="start center">
        <kendo-dropdownlist *ngIf="(productsForDropdown$ | async); let productsForDropdownData"
          formControlName="productID"
          [data]="productsForDropdownData"
          [textField]="'displayName'"
          [valueField]="'productID'"
          [valuePrimitive]="true"
          required
          fxFlex
          style="overflow:hidden"
        >
        </kendo-dropdownlist>
        <acb-alcon-value-with-percent
          [contextAmount]="commitmentAmount"
          [maxValue]="commitmentAmount"
          [form]="form"
          fxFlex="12em"
          >
        </acb-alcon-value-with-percent>
        <div>
          <button kendoButton [look]="'clear'" [icon]="'delete'" (click)="onDeleteClicked()">
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./edit-commitment-product.component.scss']
})
export class EditCommitmentProductComponent implements OnInit {

  @Input() form?: UntypedFormGroup;
  @Input() commitmentAmount: number = 0;
  @Input() productsForDropdown$?: Observable<ProductForDropdownModel[]>;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {  }

  public onDeleteClicked() {
    this.delete.emit(this.form);
  }
}
