import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginPageComponent } from './login-page.component';

import { ButtonsModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
  ]
})
export class LoginPageModule { }
