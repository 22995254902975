import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'acb-alcon-phasing-page',
  template: `
    <div *ngIf="form; let form;" [formGroup]="form" gdColumns="repeat(auto-fit, minmax(10em, 1fr))">
      <ng-container *ngIf="getFormPhaseAmounts(); let amounts; ">
        <acb-alcon-phasing-month *ngFor="let x of amounts; let i=index;"
          [form]="form"
          arrayName="amounts"
          [controlName]="i"
          [month]="i"
          [isActivityMonth]="getIsActivityMonth(i)"
          >
        </acb-alcon-phasing-month>
      </ng-container>
    </div>
  `,
  styleUrls: ['./phasing-page.component.scss']
})
export class PhasingPageComponent implements OnInit {

  @Input() form?: UntypedFormGroup;
  @Input() groupName: string | number | null = null;
  @Input() activityMonths: boolean[] | null | undefined = null;

  constructor() { }

  public getFormPhaseAmounts() {
    return (this.form?.get('amounts') as UntypedFormArray)?.controls || false;
  }

  public getIsActivityMonth(month: number): boolean {
    return this.activityMonths?.some && month >= 0 && month < this.activityMonths?.length ? this.activityMonths[month] : false;
  }

  ngOnInit(): void {
  }
}
