import { LocationModel } from '@alcon-db-models/LocationModel';
import { Component, Input, OnInit } from '@angular/core';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';

@Component({
  selector: 'acb-alcon-select-payee-address',
  template: `
    <div>
      <table>
        <tbody>
          <tr><td>ID:</td><td>{{ this.code }}</td></tr>
          <tr><td>Name:</td><td>{{ this.name }}</td></tr>
          <tr><td>Address:</td><td *ngIf="this.lines.length" >{{ this.lines[0] }}</td></tr>
          <tr *ngIf="this.lines.length > 1"><td></td><td>{{ this.lines[1] }}</td></tr>
          <tr *ngIf="this.lines.length > 2"><td></td><td>{{ this.lines[2] }}</td></tr>
          <tr *ngIf="this.cityStateZip" ><td></td><td>{{ this.cityStateZip }}</td></tr>
          <tr *ngIf="countryValue"><td></td><td>{{ countryValue }}</td></tr>

        </tbody>
      </table>
    </div>
  `,
  styles: [
    `
    div { padding: 0 2em 0 1em; }
    table { margin: auto; }
    td:first-child { text-align: right; padding-right: .5em; white-space: nowrap; font-weight: 200; font-size: smaller; }
    td:last-child {  }
    `
  ]
})
export class SelectPayeeAddressComponent implements OnInit {

  public code?: string;
  public name?: string;
  public lines: string[] = [];
  public cityStateZip?: string;

  constructor(private store: Store) { }

  @Input() set spec(value: { code?: string | null, name?: string | null, location?: LocationModel | null }) {

    this.code = value?.code ?? undefined;
    this.name = value?.name ?? undefined;
    this.lines.length = 0;
    this.cityStateZip = undefined;
    if (value?.location) {

      if (value.location?.stateProvinceCode) {
        this.stateProvinceCodes$.pipe(first()).subscribe(x => {
          this.stateValue = x.find(y => y.id == value.location?.stateProvinceCode)?.displayName ?? null;
        });
      }

      this.cityStateZip = value.location.city + ", " + this.stateValue + ' ' + value.location.postalCodeValue;

      if (value.location.locationLine1)
        this.lines.push(value.location.locationLine1);
      if (value.location.locationLine2)
        this.lines.push(value.location.locationLine2);
      if (value.location.locationLine3)
        this.lines.push(value.location.locationLine3);

      if (value.location?.countryCode) {
        this.countryCodes$.pipe(first()).subscribe(x => {
          this.countryValue = x.find(y => y.id == value.location?.countryCode)?.displayName ?? null;
        });
      }

    }
  }

  public countryValue: string | null = null;
  public stateValue: string | null = null;

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public stateProvinceCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
  public countryCodes$: Observable<StaticTypeModel[]> =  this.staticTypes$.pipe(map(x =>  x.filter(y => y.tableName == 'CountryCode')));

  ngOnInit(): void {
  }

}
