import { formatPercent } from '@angular/common';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { LabelFn } from '@progress/kendo-angular-progressbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class EditWithProgressBaseComponent implements OnInit, OnDestroy {

  protected isValid: boolean = false;
  public destroy$: Subject<void> = new Subject<void>();

  abstract doShowLegend: boolean;

  public abstract form: UntypedFormGroup;
  public abstract amount: number;

  public assignedAmount: number = 0;

  constructor() {}

  public getProgressBarFormat: LabelFn = (x: number):string => {

    const amount = Math.abs(this.amount ?? 0);
    const value = Math.abs(this.assignedAmount ?? 0);

    if (amount === value)
      return 'All applied';
    if (value > amount)
      return 'Exceeds Cost!'
    else {
      let percent = value / amount * 100;
      percent = ((percent > 100 || percent < 1) ? Math.ceil(percent) : Math.floor(percent)) / 100;
      return formatPercent(percent, "en-US", '1.0-0');
    }
  }

  public getRemaining() {
    return (this.amount ?? 0) - (this.assignedAmount ?? 0);
  }

  public validateForm() {
    return this.isValid;
  }

  ngOnInit(): void {
    this.onFormChanges(null);
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onFormChanges);
  }

  protected abstract onFormChanges(event:any): void;

  public abstract onReset():void;
  public abstract onDistribute():void;

  protected calcDistributed(zeroAmountControls:AbstractControl[]) {

    const len = zeroAmountControls.length;
    const remainingAmount = (this.amount - this.assignedAmount) ?? 0;
    const toDistributeAmount = zeroAmountControls.length ? Math.floor((remainingAmount / zeroAmountControls.length * 100) + Number.EPSILON) / 100 : 0;
    const lastAmount = Math.round((remainingAmount - (toDistributeAmount * (len - 1))) * 100 + Number.EPSILON) / 100;

    zeroAmountControls.forEach((x, i) => {
      const fg = x as UntypedFormGroup;
      const applyingAmount = (i < len - 1) ? toDistributeAmount : lastAmount;
      fg.patchValue({ amount: applyingAmount, percent: Math.round(((applyingAmount / this.amount) * 10000) + Number.EPSILON) / 100 }, { onlySelf: true, emitEvent: false });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
