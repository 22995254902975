import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-view-phasing-details',
  template: `
    <kendo-grid
      [data]="phasePeriods"
      kendoGridFocusable
      [scrollable]="'none'"
      >
      <kendo-grid-column field="name" title="Month">
      </kendo-grid-column>
      <kendo-grid-column field="phaseAmount" title="Phased" format="{0:c}">
      </kendo-grid-column>
      <kendo-grid-column field="UnitPrice" title="Actual" format="{0:c}">
      </kendo-grid-column>
      </kendo-grid>
  `,
  styleUrls: ['./view-phasing-details.component.scss']
})
export class ViewPhasingDetailsComponent implements OnInit {

  @Input() phasePeriods:PhasePeriod[] = []

  constructor() { }

  ngOnInit(): void {
  }
}

export class PhasePeriod {
  constructor(
    public month: number,
    public name: string,
    public phaseAmount: number,
    public actualAmount: number
  ) {

  }
}

