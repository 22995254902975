import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { DashboardForDropdownModel } from '@alcon-db-models/DashboardForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class DashboardForDropdownService extends EntityCollectionServiceBase<DashboardForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('DashboardForDropdown', serviceElementsFactory);
  }
}
