import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { JsonUtilities } from '../shared/json-utilities';

@Injectable()
export class JsdateInterceptor implements HttpInterceptor {
  // Hack: instead of taking control of JSON serlization of dates in request data, we're "promoting" local to UTC
  // TODO: Maybe we can switch to UTC some day?
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.baseApiUrl) && request.body) {
      JsonUtilities.promoteLocalDateToUTC(request.body);
    }
    return next.handle(request);
  }
}
