import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { ClaimDetailAttachmentModel } from '@alcon-db-models/ClaimDetailAttachmentModel';
import { ClaimDetailProductModel } from '@alcon-db-models/ClaimDetailProductModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { DraftModel } from '@alcon-db-models/DraftModel';
import { DraftWithDetailsModel } from '@alcon-db-models/DraftWithDetailsModel';
import { ClaimClaimRelationshipType, PayType, SourceType, SpecialClaimType, StatusCode, VisibilityType } from '@alcon-db-models/Enums';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { ChangeDetectorRef, Injectable, Optional } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { productsForDropdown, selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, first, takeUntil, tap } from 'rxjs/operators';
import { JsonUtilities } from '../shared/json-utilities';
import { Utilities } from '../shared/utilities';
import { ActivityForDropdownService } from './activity-for-dropdown.service';
import { ClaimAttachmentsService } from './claim-attachments.service';
import { ClaimSubjectBaseService } from './claim-subject-base.service';
import { ClaimUpsertServiceService } from './claim-upsert-service.service';
import { ClaimWithDetailsService } from './claim-with-details.service';
import { CommitmentWithDetailsService } from './commitment-with-details.service';
import { DraftWithDetailsService } from './draft-with-details.service';
import { ProductForDropdownService } from './product-for-dropdown.service';
import { WizardFeatureService } from './wizard-feature.service';

class ValidityChange {
  constructor(
    public form: boolean,
    public payee: boolean,
    public details: boolean,
    public products: boolean
  ) {}
}

@Injectable()
export class ClaimFormBaseService extends ClaimSubjectBaseService {

  doesUseClaimDoctors: boolean = false;
  claimDoctorsRequired: boolean = false;
  allowClaimsToExceedAvailableAmount: boolean = false;

  public form: UntypedFormGroup;
  public readonly validityChange$: BehaviorSubject<ValidityChange> = new BehaviorSubject<ValidityChange>({ form: true, payee: true, details: true, products: true });

  public products$: BehaviorSubject<ProductForDropdownModel[]> = new BehaviorSubject([] as ProductForDropdownModel[]);
  public activities: ActivityForDropdownModel[] = [];

  private fb: UntypedFormBuilder = new UntypedFormBuilder();

  public payeeUpdated: Subject<boolean> = new Subject();

  private _updateClaimDebounce = new Subject<ClaimWithDetailsModel | undefined>();

  constructor(
    store: Store,
    protected ClaimWithDetailsService: ClaimWithDetailsService,
    draftWithDetailsService: DraftWithDetailsService,
    ClaimUpsertService : ClaimUpsertServiceService,
    activityForDropdownService: ActivityForDropdownService,
    commitmentWithDetailsService: CommitmentWithDetailsService,
    productForDropdownService: ProductForDropdownService,
    private claimAttachmentsService: ClaimAttachmentsService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() wizardFeatureService?: WizardFeatureService
  ) {

    super(ClaimWithDetailsService,store,ClaimUpsertService,commitmentWithDetailsService,draftWithDetailsService);

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseClaimDoctors = Boolean(x.DoesUseClaimDoctors);
      this.claimDoctorsRequired = Boolean(x.DoesUseClaimDoctors && x.ClaimDoctorsRequired);
      this.allowClaimsToExceedAvailableAmount = Boolean(x.AllowClaimsToExceedAvailableAmount);
    })

    //this._updateClaimDebounce.pipe(debounceTime(500)).subscribe((x) => { this.updateClaimInternal(x); })
    this._updateClaimDebounce.subscribe((x) => { this.updateClaimInternal(x); })

    activityForDropdownService.getAll().pipe(first()).subscribe(x => {
      this.activities = x;
      // TODO: Fix this hack
      if (this._claim?.activityID) this.updateFormDetails(this._claim, false);
    });

    wizardFeatureService?.clear.subscribe((x: boolean) => {
      if (x) {
        const claim = new ClaimWithDetailsModel();
        this.claim$.next(claim);
        this.updateForm(claim);
        this.form.reset();
      }
    })

    const claimedAmountValidators = [this.claimAmountValidator];//[Validators.required, this.claimAmountValidator];
    if (!this.allowClaimsToExceedAvailableAmount) claimedAmountValidators.push(this.availableAmountValidator);

    const ClaimDetailsForm = new UntypedFormGroup({
        claimedAmount: new UntypedFormControl(null, claimedAmountValidators),
        payType: new UntypedFormControl(null, [Validators.required]),
        wereBenefitsReceived: new UntypedFormControl(null),
        deduction: new UntypedFormControl(null, [this.deductionRequiredValidator]),
        activity: new UntypedFormControl(null, [Validators.required]),
        receivedDate: new UntypedFormControl(null, [Validators.required]),
        numberOfAds: new UntypedFormControl(null),
        invoiceNumbers: new UntypedFormControl(null, [Validators.required, this.invoiceNumberValidator]),
        comment: new UntypedFormControl('', [Validators.maxLength(2000)]),
        doctors: this.claimDoctorsRequired ? new UntypedFormArray([], [Validators.required]) : new UntypedFormArray([]),
        currentAuditCodeIDs: new UntypedFormControl(null),
        startDate: new UntypedFormControl(null, [Validators.required]),
        endDate: new UntypedFormControl(null, [Validators.required]),
        activityDescription: new UntypedFormControl(null),
        currentStatusComment: new UntypedFormControl(null, [Validators.maxLength(2000)]),
        specialClaimTypeID: new UntypedFormControl(null),
        reversalRelatedClaims: new UntypedFormControl(null, [this.reversalRelatedClaimValidator]),
      }
    );

    const ClaimProducts = new UntypedFormArray([], this.validateProductSums);

    this.form = new UntypedFormGroup({
      claimDetails: ClaimDetailsForm,
      claimProducts: ClaimProducts
    });

    this.form.statusChanges.pipe(takeUntil(this._destroy$),debounceTime(250)).subscribe(x => {
      this.onValidityChange();
    });

    ClaimDetailsForm.get("specialClaimTypeID")?.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(x => {
      ClaimDetailsForm.get("claimedAmount")?.updateValueAndValidity();
    })

    this._claimIDAssigned$.pipe(takeUntil(this._destroy$), filter(x => x)).subscribe(() => {
      if (this._claim)
        this.updateForm(this._claim, false);
    })
  }

  // --------------------------------------------------------------------------
  public updateCommitmentByID(commitmentID: number | undefined | null) {
    this.updateCommitmentByIDObservable(commitmentID, false).pipe(first()).subscribe();
    // if (!commitmentID) {
    //   this.updateCommitment(undefined);
    // } else if(!this._claim?.commitmentID || forceUpdate || this._claim.commitmentID != commitmentID) {
    //   let query: any = { 'commitmentID': String(commitmentID) }
    //   if (this._claim?.claimID) query = { ...query, 'excludeClaimID' : String(this._claim?.claimID) };
    //   return this.commitmentWithDetailsService.getWithQuery(query).pipe(
    //     first(),
    //     catchError(() => of([])),
    //     tap(x => this.updateCommitment(x?.length ? x[0] : undefined))
    //   );
    // }
    // return of([]);
  }

  public updateCommitmentByIDObservable(commitmentID: number | undefined | null, doForceUpdateAndDoMinimalClaimClear: boolean = false): Observable<CommitmentWithDetailsModel[]> {
    if (!commitmentID) {
      this.updateCommitment(undefined);
    } else if(!this._claim?.commitmentID || doForceUpdateAndDoMinimalClaimClear || this._claim.commitmentID != commitmentID) {
      let query: any = { 'commitmentID': String(commitmentID) }
      if (this._claim?.claimID) query = { ...query, 'excludeClaimID' : String(this._claim?.claimID) };
      return this.commitmentWithDetailsService.getWithQuery(query).pipe(
        first(),
        catchError(() => of([])),
        tap(x => this.updateCommitment(x?.length ? x[0] : undefined, doForceUpdateAndDoMinimalClaimClear))
      );
    }
    return of([]);
  }


  public updateCommitment(commitment?: CommitmentWithDetailsModel, doForceUpdateAndDoMinimalClaimClear: boolean = false) {

    // if commitment is new, re-init claim with new commitment data/defaults
    if(doForceUpdateAndDoMinimalClaimClear || commitment?.commitmentID != this._claim?.commitmentID)
    {

      const claimProducts = commitment?.products?.length ? commitment.products.map(x => ({
          productID: x.productID,
          productCode: x.productCode,
          product: x.product,
          productTypeID: x.productTypeID,
          percentage: commitment.amount ? Math.round((((x.amount ?? 0) / commitment.amount) * 100) + Number.EPSILON) / 100 : 0
        })) :
        [];

      const claim = {
        ...this._claim,
        commitmentID: commitment?.commitmentID,
        commitmentCode: commitment?.code,
        customerID: commitment?.customerID,
        customerCode: commitment?.customerCode,
        customer: commitment?.customer,
        customerLocation: commitment?.customerLocation,
        payeeCustomerID: commitment?.payeeCustomerID,
        payeeCustomerCode: commitment?.payeeCustomerCode,
        payee: commitment?.payee,
        payeeLocation: commitment?.payeeLocation,
        territoryID: commitment?.territoryID,
        territoryCode: commitment?.territoryCode,
        territory: commitment?.territory,
        territoryPersonID: commitment?.territoryPersonID,
        territoryPerson: commitment?.territoryPerson,
        fundID: commitment?.fundID,
        fundCode: commitment?.fundCode,
        fund: commitment?.fund,
        fundYear: commitment?.fundYear,
        fundAllowsInternalDeduction: commitment?.fundAllowsInternalDeduction,
        fundIsPostAudit: commitment?.fundIsPostAudit,
        fundIsVenueAware: commitment?.fundIsVenueAware,
        commitmentAmount: commitment?.amount,
        products: claimProducts,
        commitmentProducts: commitment?.products ?? [],
        commitmentAvailableAmount: commitment?.available,
        //attachments: [],
      } as ClaimWithDetailsModel;

      if (!doForceUpdateAndDoMinimalClaimClear) {
        claim.doctors = [];
        claim.activity = commitment?.activity;
        claim.activityID = commitment?.activityID;
        claim.startDate = commitment?.startDate;
        claim.endDate = commitment?.endDate;
      }

      // remove attachments brough over from previous selected commitment
      claim.attachments = claim.attachments?.filter(x => x.sourceType != SourceType.Commitment) ?? [];

      if (commitment?.commitmentID) {
        this.claimAttachmentsService.getWithQuery({ commitmentID: commitment?.commitmentID?.toString() ?? "" }).pipe(first()).subscribe(y => {
          claim.attachments = (y ?? []).concat(claim.attachments ?? []);
          this.updateClaim(claim);
          this.updateAvailableProducts();
        });
      } else {
        this.updateClaim(claim);
        this.updateAvailableProducts();
      }



    } else {
      this.updateAvailableProducts();
    }
  }


  // --------------------------------------------------------------------------
  private deductionRequiredValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const details = this.form?.controls?.claimDetails as UntypedFormGroup;
    const payTypeID = details?.controls.payType?.value?.payTypeID;
    if (payTypeID == PayType.Deduction && !details?.controls.deduction?.value) {
      return {'deductionrequired': 'Deduction required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private availableAmountValidator = ((control: AbstractControl): { [key: string]: any } | null => {

    const details = this.form?.controls?.claimDetails as UntypedFormGroup;
    const specialClaimTypeID = details?.controls.specialClaimTypeID?.value as number | null | undefined;
    if (specialClaimTypeID == SpecialClaimType.Reversal) return null;

    const available = this._claim?.commitmentAvailableAmount ?? 0;
    const value = control?.value as number ?? 0;
    return (available - value) < 0 ? {'Insufficent funds': 'Insufficent funds'} : null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private invoiceNumberValidator(control: AbstractControl): { [key: string]: any } | null {
    const maxLength = 64;
    const invalidValue = (control?.value as string[])?.find(x => x?.length > maxLength);
    return invalidValue ? { segMaxLength: {value: invalidValue} } : null;
  }

  // --------------------------------------------------------------------------
  private claimAmountValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const amount = (control?.value ?? 0) as number;
    const details = this.form?.controls?.claimDetails as UntypedFormGroup;
    const specialClaimTypeID = details?.controls.specialClaimTypeID?.value as number | null | undefined;

    if (specialClaimTypeID == SpecialClaimType.Reversal && amount > 0) {
      return {'max': 'Reversal requires negative amount' }
    } else if (specialClaimTypeID != SpecialClaimType.Reversal && amount < 0) {
      return {'min': 'Negative amount requires reversal' }
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private reversalRelatedClaimValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const value = (control?.value ?? []) as number[];
    const details = this.form?.controls?.claimDetails as UntypedFormGroup;
    const specialClaimTypeID = details?.controls.specialClaimTypeID?.value as number | null | undefined;
    if (specialClaimTypeID == SpecialClaimType.Reversal && !value.length) {
      return {'required': 'Related claim required' }
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  public validateDetails(): boolean {
    const ClaimDetailsForm =  this.form.controls.claimDetails;
    ClaimDetailsForm.markAllAsTouched();
    ClaimDetailsForm.updateValueAndValidity();
    return ClaimDetailsForm?.valid ?? false;
  }

  // --------------------------------------------------------------------------
  public validateProducts(): boolean {
    const ClaimProductsArray = this.form.controls.claimProducts as UntypedFormArray;
    ClaimProductsArray?.markAllAsTouched();
    ClaimProductsArray?.updateValueAndValidity();
    return ClaimProductsArray?.valid ?? false;
  }

  // --------------------------------------------------------------------------
  private recalcProducts(previousClaimAmount: number, nextClaimAmount: number) {

    const claimProductsForm = this.form.controls.claimProducts as UntypedFormArray;
    if (!claimProductsForm?.controls?.length) return;

    const productControls = claimProductsForm?.controls;
    if (!productControls?.length) return;

    let sum = 0;
    productControls.forEach((x,i) => {
      let val = 0;
      if (i < productControls!.length - 1) {
        sum += val = Math.ceil((previousClaimAmount ? nextClaimAmount * ((x.value.amount ?? 0) / previousClaimAmount) : 0) * 100) / 100;
      } else {
        val = Math.round(((nextClaimAmount - sum) * 100) + Number.EPSILON) / 100;
      }
      x.patchValue( { amount: val });
    });

    this.applyProductsFromForm();
    this.validateProducts();
  }

  // --------------------------------------------------------------------------
  public applyDetailsFromForm() {

    const detailsFormControls = (this.form.controls.claimDetails as UntypedFormGroup)?.controls;
    const doctorFormArray = detailsFormControls.doctors as UntypedFormArray;

    if (this._claim && detailsFormControls) {

      let doRecalcProducts: boolean = false;
      let products = this._claim?.products;

      const previousCommitmentAmount = this._claim?.claimedAmount ?? 0;
      const nextCommitmentAmount = detailsFormControls?.claimedAmount?.value ?? 0;

      if (previousCommitmentAmount != nextCommitmentAmount) {
        doRecalcProducts = true;
      }

      this.claim$.next({
        ...JsonUtilities.convertDatesAndCopy(this._claim),
        claimedAmount: nextCommitmentAmount,
        payTypeID: detailsFormControls.payType.value?.payTypeID,
        payType: detailsFormControls.payType.value?.displayName,
        wereBenefitsReceived: detailsFormControls.wereBenefitsReceived.value,
        deduction: detailsFormControls.payType.value?.payTypeID == PayType.Deduction || (PayType.Internal && this._claim?.fundAllowsInternalDeduction) ? detailsFormControls.deduction.value : null,
        activityID: (detailsFormControls.activity.value as ActivityForDropdownModel)?.activityID,
        activity: (detailsFormControls.activity.value as ActivityForDropdownModel)?.displayName,
        receivedDate: (detailsFormControls.receivedDate.value),
        numberOfAds: (detailsFormControls.numberOfAds.value),
        invoice: detailsFormControls.invoiceNumbers.value ? (detailsFormControls.invoiceNumbers.value as string[]).join(',') : undefined,
        comments:  [{ commentBody: detailsFormControls.comment.value }],
        doctors: doctorFormArray.value,
        relatedClaims: (detailsFormControls.reversalRelatedClaims.value as number[])?.map(x => ({ claimID: this._claim?.claimID, relatedClaimID: x, claimClaimRelationshipTypeID: ClaimClaimRelationshipType.Reversal })) ?? [],
        startDate: detailsFormControls.startDate.value,
        endDate: detailsFormControls.endDate.value,
        activityDescription: detailsFormControls.activityDescription.value,
        currentStatusComment: detailsFormControls.currentStatusComment.value,
        currentAuditCodeIDs: detailsFormControls.currentAuditCodeIDs.value,
        specialClaimTypeID: detailsFormControls.specialClaimTypeID.value,
        products: products
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  // --------------------------------------------------------------------------
  public applyProductsFromForm() {

    const claim: ClaimWithDetailsModel | undefined = this._claim ? JsonUtilities.convertDatesAndCopy(this._claim) : undefined;
    if (!claim)
      return;

    const productArray = this.form.controls.claimProducts as UntypedFormArray  ;
    const productArrayControls: UntypedFormGroup[] | undefined = productArray?.controls as any[];
    if (!productArray || !productArrayControls)
      return;

    let products: ProductForDropdownModel[];
    this.products$.pipe(first()).subscribe(x => products = x );

    const newList: ClaimDetailProductModel[] = [];
    let percentageSum = 0;
    const claimedAmount = claim.claimedAmount ?? 0;

    if (claimedAmount) {

      productArrayControls.filter(x => x.controls?.productID?.value).forEach((x,i,a) => {
        const productID = x.controls?.productID?.value;
        const product = products.find(y => y.productID == productID);
        let amount = 0;
        let percentage = 0;
        amount = x.controls?.amount?.value ?? 0;
        // Amount is the validated value so we'll recalc percentage (the saved field) from amount (display/validation only).
        percentage = amount / claimedAmount;

        if (i < a.length - 1) {
          percentageSum += percentage;
        // Last product?  Lets make sure final percentage sum = 1 (100%) and rely on later penny fix ups
        } else {
          percentage = 1 - percentageSum;
        }

        if (amount) {
          newList.push({
            ...(claim.products?.find(y => y.productID == productID) ?? {}),
            productID: productID,
            product: product?.displayName,
            productCode: product?.code,
            percentage: percentage,
            amount: amount
          })
        }
      });
      claim.products = newList;
      this.claim$.next(claim);
    }

  }

  // --------------------------------------------------------------------------
  public updateAvailableProducts(products?: ProductForDropdownModel[]) {

    if (!products) {

      if (this.businessRules.AreClaimProductsFilteredByCommitment) {
        products = (this._claim?.commitmentProducts ?? [])?.map(y => ({
          code: y.productCode,
          displayName: y.product,
          productID: y.productID,
          productType: y.productTypeD,
          productTypeID: y.productTypeID
        }));
      } else {
        this.store.select(productsForDropdown).pipe(first()).subscribe(x => {
          products = [...x].sort((a, b) => (a?.displayName ?? "").localeCompare(b?.displayName ?? ""));
        });
      }

      // ...make sure the claim's product is in the list
      if(this._claim?.products?.length) {
        products = (products ?? []).concat(
          this._claim.products
            .filter(y =>!products?.some(z => z.productID == y.productID))
            .map(y => ({
              code: y.productCode,
              displayName: y.product,
              productID: y.productID,
              productType: y.productTypeD,
              productTypeID: y.productTypeID
            }))
        );
      }
    }
    this.products$.next([...products ?? []]);
  }


  // --------------------------------------------------------------------------
  public updateClaim(claim:ClaimWithDetailsModel | undefined) {
    this._updateClaimDebounce.next(claim);
  }

  private updateClaimInternal(claim:ClaimWithDetailsModel | undefined) {
    let c:ClaimWithDetailsModel  = claim ? JsonUtilities.convertDatesAndCopy(claim) : new ClaimWithDetailsModel();
    if (!c) return;
    this.claim$.next(c);
    this.updateForm(c, true);
  }

  // --------------------------------------------------------------------------
  public updateAttachments(attachments:ClaimDetailAttachmentModel[]) {
    this.claim$.next({
      ...this._claim,
      attachments: attachments
    });
    this.changeDetectorRef.detectChanges();
  }

  // --------------------------------------------------------------------------
  public updatePayee(payee:any) {
    this.claim$.next({
      ...this._claim,
      ...{
        payeeCustomerID: payee?.customerID,
        payeeCustomerCode: payee?.customerCode ?? payee?.code,
        payee: payee?.customer ?? payee?.displayName,
        payeeLocation: {
          locationLine1: payee?.locationLine1,
          locationLine2: payee?.locationLine2,
          city: payee?.city,
          postalCodeValue: payee?.postalCodeValue,
          stateProvinceCode: payee?.stateProvinceCodeID ?? payee?.stateProvinceCode,
          countryCode: payee?.countryCodeID ?? payee?.countryCode,
        }
      }
    });
    this.payeeUpdated.next(true);
  }

  // --------------------------------------------------------------------------
  public resetForm() {
    if (this._claim)
      this.updateForm(this._claim);
  }

  // --------------------------------------------------------------------------
  public addProduct() {

    const productArray: UntypedFormArray | undefined = this.form.controls.claimProducts as UntypedFormArray;
    if (!productArray?.controls)
      return;

    if (!this._claim)
      return;

    const fg = this.fb.group({
      productID: new UntypedFormControl(null),
      amount: new UntypedFormControl(0),//, Validators.min(.01)),
      percent: new UntypedFormControl(0, Validators.min(.0000000001)),
    })
    productArray.push(fg);
    productArray.markAllAsTouched();
    productArray.updateValueAndValidity();
  }

  // --------------------------------------------------------------------------
  public removeProduct(productID?:number) {
    const productArray: UntypedFormArray | undefined = this.form.controls.claimProducts as UntypedFormArray;
    if (!productArray?.controls)
      return;

    const index = productArray.controls.findIndex(x => (x as UntypedFormGroup)?.controls?.productID?.value == productID);
    if (index >= 0) productArray.removeAt(index);
    productArray.markAllAsTouched();
    productArray.updateValueAndValidity();
  }

  // --------------------------------------------------------------------------
  private validateProductSums = ((control: AbstractControl):ValidationErrors | null => {
    const productValues = (this.form?.controls?.claimProducts as UntypedFormArray)?.controls?.map((x:any) => x?.controls?.amount?.value ?? 0) ?? [0];
    const sum: number = productValues?.length ? productValues.reduce((a,v) => a + v) : 0;
    return sum || this.businessRules.ClaimProductsRequired ? this.validateSums(Math.round((sum * 100) + Number.EPSILON) / 100, control.errors) : null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private validateSums(sum:number,currentError:ValidationErrors | null):ValidationErrors | null {

    const errorKey: string =  "sumDoesNotMatchTotal";
    let err: ValidationErrors | null = null;

    const ClaimAmount: number = (this.form?.controls?.claimDetails as UntypedFormGroup)?.controls?.claimedAmount?.value ?? 0;

    if (ClaimAmount != sum)  {
      err = {};
      err[errorKey] = true;
    } else if (currentError?.length) {
      const clearedErr = {...currentError};
      delete clearedErr[errorKey];
      err = clearedErr;
    }

    return err;
  }

  // --------------------------------------------------------------------------
  public updateForm(claim?:ClaimWithDetailsModel, doValidate:boolean = true) {

    claim = claim ? claim : this._claim;
    if (!claim) return;

    this.updateFormDetails(claim, false);
    this.updateFormProducts(claim, false);
    this.updateFormAttachments(claim, false);
    this.updateAvailableProducts();

    if (doValidate) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }

    this.onValidityChange();
  }

  // --------------------------------------------------------------------------
  protected updateFormDetails(claim:ClaimWithDetailsModel, doValidate:boolean = true) {

    const activity = this.activities.find(x => x.activityID == claim.activityID);

    this.form.controls.claimDetails.patchValue({
      claimedAmount: claim.claimedAmount,
      payType: claim.payTypeID ? {
          payTypeID: claim.payTypeID,
          displayName: claim.payType,
          visibilityTypeID: VisibilityType.Visible
        } : undefined,
      wereBenefitsReceived: claim.wereBenefitsReceived,
      deduction: claim.deduction,
      activity: activity,
      receivedDate: claim.receivedDate ?? new Date(),
      numberOfAds: claim.numberOfAds,
      invoiceNumbers: claim.invoice?.split(',') ?? undefined,
      comment: claim.comments?.length ? claim.comments[0].commentBody : undefined,
      doctors: [],
      reversalRelatedClaims: claim.relatedClaims?.filter(x => x.claimClaimRelationshipTypeID == ClaimClaimRelationshipType.Reversal).map(x => x.relatedClaimID),
      currentAuditCodeIDs: claim.currentAuditCodeIDs,
      startDate: claim.startDate,
      endDate: claim.endDate,
      activityDescription: claim.activityDescription,
      currentStatusComment: claim.currentStatusComment,
      specialClaimTypeID: claim.specialClaimTypeID,
    });

    // TODO: Clean this up
    let doDisableDoctor: boolean = false;
    const claimDetails = this.form.controls.claimDetails as UntypedFormGroup;
    if (this._claim?.isEditRestrictedToAttachments) {
      claimDetails.controls.claimedAmount.disable({emitEvent: false})
      claimDetails.controls.payType.disable({emitEvent: false});
      claimDetails.controls.deduction.disable({emitEvent: false});
      claimDetails.controls.activity.disable({emitEvent: false});
      claimDetails.controls.receivedDate.disable({emitEvent: false});
      claimDetails.controls.numberOfAds.disable({emitEvent: false});
      claimDetails.controls.invoiceNumbers.disable({emitEvent: false});
      claimDetails.controls.doctors.disable({emitEvent: false});
      claimDetails.controls.reversalRelatedClaims.disable({emitEvent: false});
      claimDetails.controls.wereBenefitsReceived.disable({emitEvent: false});
      doDisableDoctor = true;
    } else {
      claimDetails.controls.claimedAmount.enable({emitEvent: false});
      claimDetails.controls.payType.enable({emitEvent: false});
      claimDetails.controls.deduction.enable({emitEvent: false});
      claimDetails.controls.activity.enable({emitEvent: false});
      claimDetails.controls.receivedDate.enable({emitEvent: false});
      claimDetails.controls.numberOfAds.enable({emitEvent: false});
      claimDetails.controls.invoiceNumbers.enable({emitEvent: false});
      claimDetails.controls.doctors.enable({emitEvent: false});
      claimDetails.controls.reversalRelatedClaims.enable({emitEvent: false});
      claimDetails.controls.wereBenefitsReceived.enable({emitEvent: false});
    }

    if (this._claim?.statusCodeID == StatusCode.Paid
      || this._claim?.statusCodeID == StatusCode.Partialpaid
      || this._claim?.statusCodeID == StatusCode.Denied
      || this._claim?.statusCodeID == StatusCode.Canceled
      || this._claim?.statusCodeID == StatusCode.Void) {
      claimDetails.controls.claimedAmount.disable();
    }

    let formArray = claimDetails?.controls.doctors as UntypedFormArray;
    formArray.clear();

    if (claim.doctors?.length) {
      claim.doctors?.forEach(doctor => {
        const doctorForm = new UntypedFormGroup({
          doctor: this.claimDoctorsRequired ? new UntypedFormControl({ value: doctor.doctor, disabled: doDisableDoctor}, Validators.required) : new UntypedFormControl({ value: doctor.doctor, disabled: doDisableDoctor}),
          doctorCode: this.claimDoctorsRequired ? new UntypedFormControl({ value: doctor.doctorCode, disabled: doDisableDoctor}, Validators.required) : new UntypedFormControl({ value: doctor.doctorCode, disabled: doDisableDoctor}),
        });
        if (doDisableDoctor) doctorForm.disable();
        formArray?.push(doctorForm);
      });
    }


    if (doValidate) {
      this.form.controls.claimDetails.markAllAsTouched();
      this.form.controls.claimDetails.updateValueAndValidity();
    }
  }

  // --------------------------------------------------------------------------
  protected updateFormProducts(claim:ClaimWithDetailsModel, doValidate:boolean = true) {

    const ClaimProductsFormArray = this.form.get('claimProducts') as UntypedFormArray;
    if (!ClaimProductsFormArray)
      return;

    if (!claim) {
      ClaimProductsFormArray.reset();
      while(ClaimProductsFormArray.length !== 0) {
        ClaimProductsFormArray.removeAt(0);
      }
      return;
    }

    const products: ClaimDetailProductModel[] = claim?.products ?? [];

    let i = ClaimProductsFormArray.length;
    while (i--) {
      const productGroup = ClaimProductsFormArray.controls[i] as UntypedFormGroup;
      if (!products.some(x => x.productID == productGroup.controls?.productID?.value)) {
        ClaimProductsFormArray.removeAt(i);
      }
    }

    let sum = 0;
    const claimedAmount = this._claim?.claimedAmount ?? 0;
    products.forEach((x, i) => {

      const percentage = (x.percentage ?? 0);
      let displayPercent = percentage * 100;
      let amount = Utilities.roundDollar(claimedAmount * percentage);

      if (i < products.length - 1) {
        sum += amount;
      // Fix up last product amount if balance is 1 penny
      } else if (Utilities.roundDollar(Math.abs(claimedAmount - sum - amount)) == .01) {
        amount = claimedAmount - sum;
      }

      // Fix up percent.  Note, new claims may not have claimed amount
      if (claimedAmount) {
        displayPercent = amount / claimedAmount * 100;
      }

      const productGroup = ClaimProductsFormArray.controls.find((y:any) => y.controls?.productID?.value == x.productID) as UntypedFormGroup;
      if (productGroup) {
        productGroup.patchValue({
          productID: x.productID,
          percent: displayPercent,
          amount: amount
        });
      } else if (x.productID != null) {

        const fg = this.fb.group({
          productID: new UntypedFormControl(x.productID, [Validators.required]),
          percent: new UntypedFormControl(displayPercent, Validators.min(.0000000001)),
          amount: new UntypedFormControl(amount)//, Validators.min(.01))
        });
        ClaimProductsFormArray.push(fg);
        doValidate = true;
      }
    });

    if(doValidate) {
      ClaimProductsFormArray.markAllAsTouched();
      ClaimProductsFormArray.updateValueAndValidity();
    }

  }

  // --------------------------------------------------------------------------
  protected updateFormAttachments(Claim:ClaimWithDetailsModel, doValidate:boolean = true) {

    const ClaimAttachmentsFormArray: UntypedFormArray | undefined = this.form.controls.ClaimAttachments as UntypedFormArray;
    if (!ClaimAttachmentsFormArray) return;

    ClaimAttachmentsFormArray.reset();
    while(ClaimAttachmentsFormArray.length !== 0) {
      ClaimAttachmentsFormArray.removeAt(0);
    }

    const attachments: ClaimDetailAttachmentModel[] = Claim?.attachments ?? [];
    if (!Claim || !attachments)
      return;

    attachments.forEach(x => {

      if (x.resourceID) {
        const fg = this.fb.group({
          resourceID: new UntypedFormControl(x.resourceID),
          displayName: new UntypedFormControl(x.displayName),
          createdByPerson: new UntypedFormControl(x.createdByPerson),
          createdByPersonID: new UntypedFormControl(x.createdByPersonID),
        });
        ClaimAttachmentsFormArray.push(fg);
      }
    });

    if (doValidate) {
      ClaimAttachmentsFormArray.markAllAsTouched();
      ClaimAttachmentsFormArray.updateValueAndValidity();
    }
  }

  // --------------------------------------------------------------------------
  public addDoctor() {
    const formArray = (this.form.controls.claimDetails as UntypedFormGroup)?.controls.doctors as UntypedFormArray;
    const doctorForm = new UntypedFormGroup({
      doctor: new UntypedFormControl(null, Validators.required),
      doctorCode: new UntypedFormControl(null, Validators.required)
    });
    formArray?.push(doctorForm);
  }

  // --------------------------------------------------------------------------
  public removeDoctor(doctor:any) {
    const formArray = (this.form.controls.claimDetails as UntypedFormGroup)?.controls.doctors as UntypedFormArray;
    if (!formArray)
      return;
    const index = formArray.controls.findIndex(x => x == doctor);
    if (index > -1) formArray.removeAt(index)
  }

    // --------------------------------------------------------------------------
    public createDraftClaimFromCurrent(name?: string, description?: string, draftID?: string) : Promise<DraftWithDetailsModel|null|undefined> {
      return this._claim ? this.createDraftClaim(this._claim, name, description, draftID) : Promise.resolve(null);
    }


  // --------------------------------------------------------------------------
  private onValidityChange() {
    const validity = {
      form: this.form.valid,
      details: this.form.controls.claimDetails.valid,
      products: this.form.controls.claimProducts.valid,
      payee: Boolean(this._claim?.payeeCustomerID),
    };
    this.validityChange$.next(validity);
  }


}
