import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { DashboardItemForDashboardModel } from '@alcon-db-models/DashboardItemForDashboardModel'
import { DashboardItemForDashboardSaveRequestModel } from '@alcon-db-models/DashboardItemForDashboardSaveRequestModel'
import { ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardItemForDashboardService extends EntityCollectionServiceBase<DashboardItemForDashboardModel> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, protected http: HttpClient) {
    super('DashboardItemForDashboard', serviceElementsFactory);
  }

  public save(request: DashboardItemForDashboardSaveRequestModel): Observable<ServiceResponse<boolean>> {
    return this.http.post<boolean>(Location.joinWithSlash(environment.baseApiUrl, 'DashboardItemForDashboard/save'), request).pipe(
      firstWithLoadingOverlayAndErrorHandling<boolean>()
    );
  }

}
