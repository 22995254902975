<div
fxLayout="column"
style="height: 100%"
class="acb-wrapper"
[ngClass]="{
  'acb-approving':  form?.value && form!.value.reviewStatus == 'approve',
  'acb-denying':  form?.value && form!.value.reviewStatus == 'deny',
  'acb-pending':  form?.value && form!.value.reviewStatus == 'pend',
  'acb-closing':  form?.value && form!.value.reviewStatus == 'close',
  'acb-ignoring':  form?.value && form!.value.reviewStatus == 'ignore'
}"
>
  <div
    #wrapper
    class="acb-wrapper"
    fxLayout="row"
    fxLayout.lt-md="column"
    >
    <div class="acb-preview" fxLayout="column" fxFlex.gt-sm >

      <acb-alcon-window-notifications
        *ngIf="(notifications$ | async); let notificationsData"
        [windowNotifications]="notificationsData"
        >
      </acb-alcon-window-notifications>
      <div class="acb-inner">

        <div [hidden]="!(loading$ | async)" class="acb-local-loading-indicator">
          <span class="k-icon k-i-loading"></span>
        </div>

        <div fxLayout="column" *ngIf="(claim$ | async) as claimData" [fxHide]="(loading$ | async)">
          <acb-alcon-edit-claim-host
            [claim]="claimData"
            [viewMode]="'review'"
            (viewCommitment)="viewCommitment.emit($event)"
            (viewClaim)="viewClaim.emit($event)"
            (claimChanged)="onClaimChanged($event)"
            (valid)="onClaimValidChange($event)"
            [validateClaimOnLoad]="true"
            >
          </acb-alcon-edit-claim-host>
        </div>
      </div>
    </div>

    <div [class]="sectionClass + ' acb-controls acb-left-seperated-container'" fxFlex.gt-sm="34em" fxLayout="column">

      <div fxLayout="column" class="acb-fields" fxFlex>
        <div class="acb-section-alt-title" fxLayout="row" fxLayoutGap=".75em">
          <span
            class="acb-indicator"
            [ngClass] = "{
              'acb-indicator-audit-valid': form?.status == 'VALID',
              'acb-indicator-audit-invalid': form?.status == 'INVALID',
              'acb-indicator-claim-valid': form?.controls?.claimIsValid?.status == 'VALID',
              'acb-indicator-claim-invalid': form?.controls?.claimIsValid?.status == 'INVALID'
            }"></span>
          <span class="acb-section-alt-title-label">{{ sectionTitle.label }}</span>
          <span class="acb-section-alt-title-entity">{{ sectionTitle.entity }}</span>
          <span fxFlex></span>
          <span class="acb-section-alt-title-info">{{ sectionTitle.info }}</span>
        </div>
        <kendo-datapager
          *ngIf="count > 1"
          style="white-space: nowrap;"
          [pageSize]="1"
          [skip]="index"
          [total]="count"
          (pageChange)="onPageChange($event)"
          >
          <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <div class="acb-data-pager-wrapper" style="width: 100%">
              <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
              <kendo-slider
                  [showButtons]="false"
                  [tickPlacement]="'after'"
                  [max]="totalPages"
                  [min]="1"
                  (valueChange)="sliderChange($event)"
                  [value]="currentPage"
                  >
              </kendo-slider>
              <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
            </div>
          </ng-template>
        </kendo-datapager>

        <hr class="acb-seperator" *ngIf="count > 1"/>
        <div class="acb-audit-instructions" *ngIf="auditInstructions?.length">
          <div  class="acb-audit-instructions-label">Audit Instructions:</div>
          <ul>
            <li *ngFor="let ai of auditInstructions">
              <span>{{ai.instructionBody}}</span>
            </li>
          </ul>
        </div>

        <form class="acb-audit-fields" *ngIf="form" [formGroup]="form" fxLayout="column" fxLayoutGap="1em">
          <acb-alcon-form-button-group
            [formControlName]="'reviewStatus'"
            [buttonSpecs] = "[
              { text: 'Approve', icon: 'check-outline', key: 'approve', buttonClass: 'acb-button-approve', enabledGroups: ['audit'] },
              { text: 'Deny', icon: 'close-outline', key: 'deny', buttonClass: 'acb-button-deny', enabledGroups: ['audit', 'duplicate'] },
              { text: 'Pend', icon: 'clock', key: 'pend', buttonClass: 'acb-button-pend', enabledGroups: ['audit','duplicate'] },
              { text: 'Close', key: 'close', buttonClass: 'acb-button-close', enabledGroups: ['audit','duplicate'] },
              { text: 'Ignore', key: 'ignore', buttonClass: 'acb-button-ignore' }
            ]"
            [enableGroup] = "this.reviewStatusButtonEnableGroup"
            [hidden]="(loading$ | async)"
            >
          </acb-alcon-form-button-group>
          <acb-alcon-form-button-group
            *ngIf="form.value.reviewStatus == 'close'"
            [formControlName]="'closeStatus'"
            [buttonSpecs] = "[
              { text: 'Void', icon: 'cancel', key: 'void', buttonClass: 'acb-button-close' },
              { text: 'Cancel', icon: 'cancel-outline', key: 'cancel', buttonClass: 'acb-button-close' }
            ]"
            >
          </acb-alcon-form-button-group>

          <div fxFlex="auto" style="height: .5em;" *ngIf="form.value.reviewStatus && form.value.reviewStatus != 'ignore'"></div>

          <div fxLayout="row" fxLayoutGap="1em" *ngIf="form.value.reviewStatus == 'approve' || form.value.reviewStatus == 'deny'">

            <ng-container *ngIf="form.value.reviewStatus == 'approve'">
              <kendo-formfield *appFieldExtentions fxFlex="10em">
                <kendo-label [for]="approvedAmount" text="Approved Amount"></kendo-label>
                <kendo-numerictextbox
                  [spinners]="false"
                  [decimals]="2"
                  [format]="'c2'"
                  #approvedAmount
                  [formControlName]="'approvedAmount'"
                  autocomplete="off"
                  style="width: 100%"
                  ></kendo-numerictextbox>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror>Error: invalid amount</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <kendo-formfield *appFieldExtentions fxFlex>
              <kendo-label [for]="activityDescription" text="Activity Description"></kendo-label>
              <kendo-textbox
                #activityDescription
                [formControlName]="'activityDescription'"
                style="width: 100%"
                ></kendo-textbox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: description required</kendo-formerror>
            </kendo-formfield>
          </div>

          <div *ngIf="form.value.reviewStatus == 'approve' || form.value.reviewStatus == 'deny'" fxLayout="row" fxLayoutGap="1em">

            <kendo-formfield *appFieldExtentions fxFlex="4em">
              <kendo-label [for]="numberOfAds" text="# Ads"></kendo-label>
              <kendo-numerictextbox
                style="width: 100%"
                [spinners]="true"
                [decimals]="0"
                [min]="0"
                #numberOfAds
                [format]="'#'"
                [formControlName]="'numberOfAds'"
                autocomplete="off">
              </kendo-numerictextbox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>&nbsp;</kendo-formerror>
            </kendo-formfield>

            <ng-container *ngIf="form.value.reviewStatus == 'approve' && (this.approveAuditCodes$ | async) as auditCodesData">
              <kendo-formfield *appFieldExtentions fxFlex>
                <kendo-label [for]="approveAuditCodes" text="Audit Codes"></kendo-label>
                <kendo-multiselect
                  formControlName="approveAuditCodes"
                  [data]="auditCodesData"
                  [textField]="'compositeName'"
                  [valueField]="'auditCodeID'"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="onApproveAuditCodeFilter($event)"
                  #approveAuditCodes
                  >
                </kendo-multiselect>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror *ngIf="form.controls.approveAuditCodes.errors?.auditCodeMismatch; else standardError">Error: code requires full amount</kendo-formerror>
                <ng-template #standardError><kendo-formerror>Error: code(s) required</kendo-formerror></ng-template>
              </kendo-formfield>
            </ng-container>

            <ng-container *ngIf="form.value.reviewStatus == 'deny' && (this.denyAuditCodes$ | async) as auditCodesData">
              <kendo-formfield *appFieldExtentions fxFlex>
                <kendo-label [for]="denyAuditCodes" text="Audit Codes"></kendo-label>
                <kendo-multiselect
                  formControlName="denyAuditCodes"
                  [data]="auditCodesData"
                  [textField]="'compositeName'"
                  [valueField]="'auditCodeID'"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="onDenyAuditCodeFilter($event)"
                  #denyAuditCodes
                  >
                </kendo-multiselect>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror>Error: code(s) required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

          </div>

          <div *ngIf="form.value.reviewStatus == 'approve' || form.value.reviewStatus == 'deny'" fxLayout="row" fxLayoutGap="1em">

            <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
              <kendo-label [for]="receivedDate" text="Received Date"></kendo-label>
              <kendo-datepicker
                formControlName="receivedDate"
                #receivedDate
                autocomplete="off"
                [rangeValidation]="true"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                >
              </kendo-datepicker>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: date required</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
              <kendo-label [for]="startDate" text="Start Date"></kendo-label>
              <kendo-datepicker
                formControlName="startDate"
                #startDate
                autocomplete="off"
                [rangeValidation]="true"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                >
              </kendo-datepicker>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: date required</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
              <kendo-label [for]="endDate" text="End Date"></kendo-label>
              <kendo-datepicker
                formControlName="endDate"
                #endDate
                autocomplete="off"
                [rangeValidation]="true"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                >
              </kendo-datepicker>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: date required</kendo-formerror>
            </kendo-formfield>

          </div>

          <ng-container *ngIf="form.value.reviewStatus && form.value.reviewStatus != 'ignore' ">
            <kendo-formfield *appFieldExtentions>
              <kendo-label [for]="comment" text="Comment" [optional]="false"></kendo-label>
              <textarea
                formControlName="comment"
                kendoTextArea
                #comment
                rows="3"
                style="resize: vertical; width: 100%"
                ></textarea>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror>Error: comment required</kendo-formerror>
            </kendo-formfield>
          </ng-container>
        </form>
        <hr class="acb-seperator">
        <div fxFlex></div>
      </div>
      <div fxLayout="row" class="acb-review-button-wrapper">
        <button class="acb-cancel"  kendoButton (click)="onCancel()" [look]="'clear'" fxFlex class="acb-cancel">Cancel</button>
        <button kendoButton (click)="onSubmit()" [primary]="true" [look]="'clear'" fxFlex [primary]="true" [disabled]="!isValid"  >Post{{count > 1 ? ' All' : ''}}</button>
      </div>
    </div>
  </div>
</div>
