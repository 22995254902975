
 
 
export enum FilterSpecFieldType {  
    Text = 1,  
    Numeric = 2,  
    Currency = 3,  
    Date = 4,  
    Boolean = 5,  
    FundYear = 100,  
    FundYears = 101,  
    FundID = 102,  
    FundIDs = 103,  
    TerritoryID = 104,  
    TerritoryIDs = 105,  
    ProcessedDateMin = 106,  
    ProcessedDateMax = 107,  
    PayTypeID = 108,  
    ExpenseTypeID = 109,  
    FundingDocumentTypeIDs = 110,  
    StatusCodeIDs = 111,  
    ProductIDs = 112,  
    ProductTypeIDs = 113,  
    Custom = 255  
}  
     
         
