import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { StatusCode } from '@alcon-db-models/Enums';
import { CommitmentSubjectBaseService } from '@services/commitment-subject-base.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';

@Component({
  selector: 'acb-alcon-view-commitment-details-compact',
  template: `
    <div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">
      <div class="acb-details-layout"  fxLayout="row wrap">
        <div class="acb-view-field">
          <kendo-label text="Amount"></kendo-label>
          <div style="font-weight:bold">{{ commitment?.amount | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Start Date"></kendo-label>
          <div>{{ commitment?.startDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="End Date"></kendo-label>
          <div>{{ commitment?.endDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field" style="white-space: nowrap; overflow: visible" *ngIf="doesUseCommitmentActivities">
          <kendo-label text="Activity"></kendo-label>
          <div>{{ commitment?.activity }}</div>
        </div>
        <div class="acb-view-field" style="white-space: nowrap; overflow: visible">
          <kendo-label text="Territory"></kendo-label>
          <div>{{ commitment?.territorySuggestedName }}</div>
        </div>
      </div>
      <div class="acb-view-comment" fxFlex>
        <kendo-label text="Comment"></kendo-label>
        <ng-container *ngIf="commitment?.comments?.length">
          <div>{{ commitment!.comments![commitment!.comments!.length - 1].commentBody }}</div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./view-commitment-details-compact.component.scss']
})
export class ViewCommitmentDetailsCompactComponent implements OnInit, OnDestroy {

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public commitment?: CommitmentWithDetailsModel | null;
  @Input() public doShowLessForReview: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    store: Store,
    @Optional() commitmentSubjectBaseService?: CommitmentSubjectBaseService
  ) {
    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })
    if (commitmentSubjectBaseService) {
      commitmentSubjectBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.commitment = x;
        if (this.areCommitmentActivityRulesDeterminedByFund) {
          this.doesUseCommitmentActivities = x.fundDoesUseCommitmentActivities ?? false;
          this.commitmentActivitiesRequired = (x.fundDoesUseCommitmentActivities ?? false) && (x.fundCommitmentActivitiesRequired ?? false);
        }
      });
    }
  }

  getStatusColor(commitment?: CommitmentWithDetailsModel | null) {
    switch (commitment?.statusCodeID as StatusCode) {
      case StatusCode.Approved:
        return '#00AE44';
      case StatusCode.Denied:
        return '#9B1A2F';
      case StatusCode.Requested:
        return '#0063b7';
      case StatusCode.Closed:
        return '#FF6B12';
      case StatusCode.Canceled:
        return '#9B1A2F';
      default:
        return '#484844';
    }
  }

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
