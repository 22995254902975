import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { ChangeDetectorRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AppUxService } from '@services/app-ux.service';
import { BehaviorSubject } from 'rxjs';
import { EditWithProgressBaseComponent } from './edit-with-progress.base.component';

@Injectable()
export abstract class EditProductsBaseComponent extends EditWithProgressBaseComponent implements OnInit, OnDestroy {

  public productsFormArray?: UntypedFormArray;
  public remainingProductsByProduct: BehaviorSubject<ProductForDropdownModel[]>[] = [];
  protected productsForDropdown: ProductForDropdownModel[] = [];

  constructor(private appUxService: AppUxService,private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public abstract onAdd():void;
  public abstract onDelete(productForm:UntypedFormGroup):void;

  protected onFormChanges = ((event:any) => {

    let amount = 0;
    let selectedProductIDs: number[] = [];
    this.productsFormArray?.controls?.forEach((x,i) => {
      const fg = x as UntypedFormGroup;
      if (this.remainingProductsByProduct.length <= i)
        this.remainingProductsByProduct.push(new BehaviorSubject<ProductForDropdownModel[]>(this.productsForDropdown));
      if (fg?.controls?.productID?.value) {
        selectedProductIDs.push(fg.controls.productID.value);
        amount += fg.controls.amount?.value ?? 0;
      }
    });

    let valid: boolean = true;
    this.productsFormArray?.controls?.forEach((x,i) => {
      const fg = x as UntypedFormGroup;
      const value = fg?.controls?.productID?.value;
      this.remainingProductsByProduct[i].next (
        this.productsForDropdown.filter(y => y.productID == value || !selectedProductIDs.some(z => z == y.productID))
      );
      valid = valid && fg.valid;
    });

    this.assignedAmount = Math.round((amount * 100) + Number.EPSILON) / 100;
    this.isValid = valid;

    if (this.productsFormArray?.controls.length)
      this.changeDetectorRef.detectChanges();

  }).bind(this);

  public onReset = (() => {
    this.appUxService.openConfirmation(
      "Reset?",
      "Are you sure you want to reset products to $0?",
      undefined,
      190,
      'alcon-background-orange',
      'acb-section-09').then((confirm) => {

      if (!confirm || !this.productsFormArray?.controls?.length || !this.productsFormArray)
        return;

      this.productsFormArray?.controls.forEach(x => {
        const fg = x as UntypedFormGroup;
        if (fg?.patchValue) fg.patchValue({ amount: 0, percent: 0 })
      });

      this.productsFormArray.updateValueAndValidity();
    });
  }).bind(this);


  public onDistribute = (() => {
    this.appUxService.openConfirmation(
      "Distribute Remaining?",
      "Are you sure you want to distribute the remaining amount evening across $0 products?",
      undefined,
      190,
      'alcon-background-blue',
      'acb-section-05').then((confirm) => {

      if (!confirm || !this.productsFormArray?.controls?.length || !this.productsFormArray)
        return;

      const zeroAmountControls = this.productsFormArray.controls.filter(x => x?.value && x.value?.amount == 0);
      const len = zeroAmountControls.length;
      const remainingAmount = (this.amount - this.assignedAmount) ?? 0;
      const toDistributeAmount = zeroAmountControls.length ? Math.floor((remainingAmount / zeroAmountControls.length * 100) + Number.EPSILON) / 100 : 0;
      const lastAmount = Math.round((remainingAmount - (toDistributeAmount * (len - 1))) * 100 + Number.EPSILON) / 100;

      zeroAmountControls.forEach((x, i) => {
        const fg = x as UntypedFormGroup;
        const applyingAmount = (i < len - 1) ? toDistributeAmount : lastAmount;
        fg.patchValue({ amount: applyingAmount, percent: Math.round(((applyingAmount / this.amount) * 10000) + Number.EPSILON) / 100 }, { onlySelf: true, emitEvent: false });
      });

      this.productsFormArray.updateValueAndValidity();
    });
  }).bind(this);

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
