import { HttpErrorResponse } from "@angular/common/http";
import { AppUxService } from "@services/app-ux.service";
import { AppWindowServiceBase } from "@services/app-window-service.base";
import { concat, Observable, of, OperatorFunction, Subscription, TimeoutError } from "rxjs";
import { catchError, finalize, first, map, publish, switchMap, tap, timeout } from "rxjs/operators";

export const TIMEOUT_DUE = 60000;

export function delayUntil<T>(
  notifier: Observable<any>
): OperatorFunction<T, T> {
  return source =>
    source.pipe(
      publish(published => {
        const delayed = new Observable<T>(subscriber => {
          let buffering = true;
          const buffer: T[] = [];
          const subscription = new Subscription();
          subscription.add(
            notifier.subscribe(
              () => {
                buffer.forEach(value => subscriber.next(value));
                subscriber.complete();
              },
              error => subscriber.error(error),
              () => {
                buffering = false;
                buffer.length = 0;
              }
            )
          );
          subscription.add(
            published.subscribe(
              value => buffering && buffer.push(value),
              error => subscriber.error(error)
            )
          );
          subscription.add(() => {
            buffer.length = 0;
          });
          return subscription;
        });
        return concat(delayed, published);
      })
    );
}


// export function firstWithTimeoutAndLoadingOverlay<T>(): OperatorFunction<T, { source: T | null | undefined, hasError: boolean, errorMessage: string[] }> {
//   return (source: Observable<T>) => {
//     return source.pipe(
//       tap(() => AppWindowServiceBase.showLoadingOverlay()),
//       finalize(() => AppWindowServiceBase.hideLoadingOverlay()),
//       first(),
//       timeout(60000),
//       switchMap(x => of({ source: x, hasError: false, errorMessage: [] })),
//       catchError(error => of({ source: null, hasError: true, errorMessage: ['Request timed out. Please verify action completed and try again if it has not.'] }))
//     );
//   }
// }

export function firstWithTimeout<T>(due: number = TIMEOUT_DUE): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      timeout(due),
      first()
    )
  }
}

export function firstWithLoadingOverlayAndErrorHandling<T>(doShowOverlay: boolean = true, due: number = TIMEOUT_DUE): OperatorFunction<T, ServiceResponse<T>> {
  return (source: Observable<T>) => {
    if (doShowOverlay) {
      AppWindowServiceBase.showModalOverlay();
      AppWindowServiceBase.showLoadingOverlay();
    }
    return source.pipe(
      finalize(() => {
        if (doShowOverlay) {
          AppWindowServiceBase.hideModalOverlay();
          AppWindowServiceBase.hideLoadingOverlay();
        }
      }),
      firstWithTimeout(due),
      map(x => ({ hasError: false, errorMessage: [], response: x })),
      catchError(err => {
        const errorMessage = getErrorMessage(err);
        return of({ hasError: true, errorMessage: errorMessage, response: null});
      })
    )
  }
}

export function getErrorMessage(err: any) {

  const msg: string[] = [];
  // rxjs Timeout
  if (err instanceof TimeoutError) {
    msg.push('Request timed out. Please verify action completed and try again if it has not.');
  // angular HttpErrorResponse
  } else if (err instanceof HttpErrorResponse) {
    let her = err as HttpErrorResponse;
    if (her?.error?.title)
      msg.push(her.error.title);
    else 
      msg.push('Error contacting server: ' + err.message);
  // plain string error
  } else if (typeof err === 'string') {
    msg.push(err);
  // look for error in known places
  } else {
    msg.push(err?.error?.detail ?? err?.message ?? err?.error?.error?.detail ?? 'Unknown Error');
  }

  return msg;
}

export class ServiceResponse<T> {
  hasError: boolean = false;
  errorMessage: string[] = [];
  response: T | null = null;
}



