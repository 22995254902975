import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { CommitmentUpsertRequestModel } from '@alcon-db-models/CommitmentUpsertRequestModel'
import { Observable } from 'rxjs';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class CommitmentUpsertServiceService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public upsertCommitment(commitment: CommitmentUpsertRequestModel, doShowOverlay: boolean = true ):  Observable<ServiceResponse<number>> {
    return commitment.commitmentID ? this.saveCommitment(commitment, doShowOverlay) : this.createCommitment(commitment, doShowOverlay);
  }

  public createCommitment(commitment: CommitmentUpsertRequestModel, doShowOverlay: boolean = true ): Observable<ServiceResponse<number>> {
    return this.post<number>('CommitmentUpsert', commitment).pipe(
      firstWithLoadingOverlayAndErrorHandling<number>(doShowOverlay)
    );
  }

  public saveCommitment(commitment: CommitmentUpsertRequestModel, doShowOverlay: boolean = true ):  Observable<ServiceResponse<number>> {
    //TODO:  Ugh, use PUT function correctly
    return this.put<number>('CommitmentUpsert/' + commitment?.commitmentID, commitment).pipe(
      firstWithLoadingOverlayAndErrorHandling<number>(doShowOverlay)
    );
  }
}
