import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuModule, ContextMenuModule  } from '@progress/kendo-angular-menu';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { HeaderMainComponent } from './header-main/header-main.component';
import { FooterMainComponent } from './footer-main/footer-main.component';
import { MenuMainComponent } from './menu-main/menu-main.component';
import { DropDownTreeComponent } from './drop-down-tree/drop-down-tree.component';
import { CommitmentSearchComponent } from './commitment-search/commitment-search.component';

import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ReactiveFormsModule  } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule, TextBoxModule  } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputModule } from '@progress/kendo-angular-dateinputs';
import { UploadsModule  } from '@progress/kendo-angular-upload';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ListViewModule } from '@progress/kendo-angular-listview';

import { WindowComponent, WindowModule } from '@progress/kendo-angular-dialog';

import { LOCALE_ID } from "@angular/core"

import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { CoreModule } from './core/core.module'

import { ExcelModule as ExcelModule2  } from '@progress/kendo-angular-treelist';
import { GridModule, ExcelModule, PDFModule  } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { Observable } from 'rxjs';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { EditCommitmentDetailsComponent } from './edit-commitment-details/edit-commitment-details.component';
import { ViewCommitmentDetailsComponent } from './view-commitment-details/view-commitment-details.component';
import { DisplayItemComponent } from './display-item/display-item.component';
import { DisplayAddressComponent } from './display-address/display-address.component';
import { DisplayInfoCardComponent } from './display-info-card/display-info-card.component';
import { ViewCommitmentReviewHistoryComponent } from './view-commitment-review-history/view-commitment-review-history.component';
import { ViewCommitmentAttachmentsComponent } from './view-commitment-attachments/view-commitment-attachments.component';
import { SelectCustomerComponent } from './select-customer/select-customer.component';
import { SelectPayeeComponent } from './select-payee/select-payee.component';
import { SelectFundComponent } from './select-fund/select-fund.component';
import { SelectTerritoryComponent } from './select-territory/select-territory.component';
import { SelectAttachmentsComponent } from './select-attachments/select-attachments.component';
import { SelectBaseComponent } from './select.base.component';
import { ClaimSearchComponent } from './claim-search/claim-search.component';
import { ViewClaimDetailsComponent } from './view-claim-details/view-claim-details.component';
import { ViewClaimReviewHistoryComponent } from './view-claim-review-history/view-claim-review-history.component';
import { ViewClaimAttachmentsComponent } from './view-claim-attachments/view-claim-attachments.component';
import { EditClaimDetailsComponent } from './edit-claim-details/edit-claim-details.component';
import { ViewCommitmentOrganizationsComponent } from './view-commitment-organizations/view-commitment-organizations.component';
import { SelectCommitmentComponent } from './select-commitment/select-commitment.component';
import { SectionWithLegendComponent } from './section-with-legend/section-with-legend.component';
import { CreateClaimReviewComponent } from './create-claim-review/create-claim-review.component';
import { DisplayErrorsComponent } from './display-errors/display-errors.component';
import { CreateCommitmentReviewComponent } from './create-commitment-review/create-commitment-review.component';
import { RequestNameComponent } from './request-name/request-name.component';
import { RequestReviewCommentComponent } from './request-review-comment/request-review-comment.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { RevertPageComponent } from './revert-page.component'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardMultiselectDropdownComponent } from './dashboard-multiselect-dropdown/dashboard-multiselect-dropdown.component';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';

import { ChartModule } from '@progress/kendo-angular-charts';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { ColumnChooserComponent } from './column-chooser/column-chooser.component';
import { FilterChooserComponent } from './filter-chooser/filter-chooser.component';
import { FilterChooserFieldComponent } from './filter-chooser-field/filter-chooser-field.component';
import { EditCommitmentComponent } from './edit-commitment/edit-commitment.component';
import { RequestConfirmationComponent } from './request-confirmation/request-confirmation.component';
import { EditCommitmentPhasingComponent } from './edit-commitment-phasing/edit-commitment-phasing.component';
import { CreateCommitmentSuccessComponent } from './create-commitment-success/create-commitment-success.component';
import { ViewErrorResultComponent } from './view-error-result/view-error-result.component';
import { PhasingPageComponent } from './edit-commitment-phasing/phasing-page.component';
import { PhasingMonthComponent } from './edit-commitment-phasing/phasing-month.component';
import { ViewCommitmentPhasingComponent } from './view-commitment-phasing/view-commitment-phasing.component';
import { ViewPhasingDetailsComponent } from './view-commitment-phasing/view-phasing-details.component';
import { ViewPhasingYearComponent } from './view-commitment-phasing/view-phasing-year.component';
import { EditCommitmentProductsComponent } from './edit-commitment-products/edit-commitment-products.component';
import { EditCommitmentProductComponent } from './edit-commitment-products/edit-commitment-product.component';
import { ViewCommitmentProductsComponent } from './view-commitment-products/view-commitment-products.component';
import { EditCommitmentDetailsDialogComponent } from './edit-commitment-details/edit-commitment-details-dialog.component';
import { EditCommitmentProductsDialogComponent } from './edit-commitment-products/edit-commitment-products-dialog.component';
import { EditCommitmentPhasingsDialogComponent } from './edit-commitment-phasing/edit-commitment-phasings-dialog.component';
import { SelectAttachmentsDialogComponent } from './select-attachments/select-attachments-dialog.component';
import { AttachmentComponent } from './select-attachments/attachment.component';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';
import { EditCommitmentDialogComponent } from './edit-commitment/edit-commitment-dialog.component';
import { EditCommitmentHostComponent } from './edit-commitment/edit-commitment-host.component';
import { ViewCommitmentDetailsCompactComponent } from './view-commitment-details/view-commitment-details-compact.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { EditCommitmentDialogTitlebarComponent } from './edit-commitment/edit-commitment-dialog-titlebar.component';
import { WindowNotificationsComponent } from './window-notifications/window-notifications.component';
import { WindowNotificationComponent } from './window-notifications/window-notification.component';
import { BatchAllocationStepComponent } from './batch-allocation-step/batch-allocation-step.component'
import { TerritoryWithBalanceFormfieldComponent } from './batch-allocation-step/territory-with-balance-formfield.component';
import { BatchAllocationStepDialogComponent } from './batch-allocation-step/batch-allocation-step-dialog.component';
import { ViewClaimProductsComponent } from './view-claim-products/view-claim-products.component';
import { EditClaimProductsComponent } from './edit-claim-products/edit-claim-products.component';
import { EditClaimProductComponent } from './edit-claim-products/edit-claim-product.component';
import { ViewClaimDetailsCompactComponent } from './view-claim-details/view-claim-details-compact.component';
import { CreateClaimSuccessComponent } from './create-claim-success/create-claim-success.component';
import { SelectPayeeDialogComponent } from './select-payee/select-payee-dialog.component';
import { SelectPayeeCustomerComponent } from './select-payee/select-payee-customer.component';
import { SelectPayeeCustomerDialogComponent } from './select-payee/select-payee-customer-dialog.component';
import { SelectPayeeAddressComponent } from './select-payee/select-payee-address.component';
import { EditUserDialogComponent } from './edit-user/edit-user-dialog.component';
import { CreateUserDialogComponent } from './edit-user/create-user-dialog.component';
import { EditClaimComponent } from './edit-claim/edit-claim.component';
import { EditClaimHostComponent } from './edit-claim/edit-claim-host.component';
import { EditClaimDetailsDialogComponent } from './edit-claim-details/edit-claim-details-dialog.component';
import { SelectCommitmentDialogComponent } from './select-commitment/select-commitment-dialog.component';
import { EditClaimProductsDialogComponent } from './edit-claim-products/edit-claim-products-dialog.component';
import { PrintClaimDetailsComponent } from './view-claim-details/print-claim-details.component';
import { ViewClaimComponent } from './view-claim/view-claim.component';
import { EditClaimDetailsDoctorComponent } from './edit-claim-details/edit-claim-details-doctor.component';
import { ViewClaimDoctorsComponent } from './view-claim-doctors/view-claim-doctors.component';
import { ValueWithPercentComponent } from './value-with-percent/value-with-percent.component';
import { FormButtonGroupComponent } from './form-button-group/form-button-group.component';
import { EditClaimDialogComponent } from './edit-claim/edit-claim-dialog.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { DisplayNotificationComponent } from './display-notifications/display-notification.component';
import { DisplayNotificationsComponent } from './display-notifications/display-notifications.component';
import { EditCommitmentApprovalRuleComponent } from './edit-commitment-approval-rule/edit-commitment-approval-rule.component';
import { HierarchyCompareComponent } from './hierarchy-compare/hierarchy-compare.component';
import { HierarchyTreeComponent } from './hierarchy-compare/hierarchy-tree.component';
import { RequestCommentComponent } from './request-comment/request-comment.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { EditFundComponent } from './edit-fund/edit-fund.component';
import { EditClaimReviewComponent } from './edit-claim-review/edit-claim-review.component';
import { ViewClaimReviewComponent } from './view-claim-review/view-claim-review.component';
import { RequestClaimVoidComponent } from './request-claim-void/request-claim-void.component';
import { FeatureTreeComponent } from './feature-tree/feature-tree.component';
import { EditFeatureComponent } from './edit-feature/edit-feature.component';
import { EditAuditInstructionComponent } from './edit-audit-instruction/edit-audit-instruction.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { RequestDateComponent } from './request-date/request-date.component';
import { SandboxFileUploadSearchComponent } from './sandbox-file-upload/sandbox-file-upload-search.component';
import { SandboxFileUploadComponent } from './sandbox-file-upload/sandbox-file-upload.component';
import { LcpComparisonComponent } from './sandbox-file-upload/lcp-comparison.component';
import { GridstackModule } from 'gridstack/dist/angular';
import { DashboardWidgetChartComponent } from './dashboard-widget/dashboard-widget-chart.component';
import { CldrIntlService, cldrServiceFactory } from '@progress/kendo-angular-intl';

@NgModule({
  declarations: [    
    HeaderMainComponent,
    FooterMainComponent,
    MenuMainComponent,
    DropDownTreeComponent,
    CommitmentSearchComponent,
    SearchFiltersComponent,
    EditCommitmentDetailsComponent,
    DisplayItemComponent,
    ViewCommitmentDetailsComponent,
    DisplayAddressComponent,
    DisplayInfoCardComponent,
    ViewCommitmentReviewHistoryComponent,
    ViewCommitmentAttachmentsComponent,
    SelectCustomerComponent,
    SelectPayeeComponent,
    SelectFundComponent,
    SelectTerritoryComponent,
    SelectAttachmentsComponent,
    SelectBaseComponent,
    ClaimSearchComponent,
    ViewClaimDetailsComponent,
    ViewClaimReviewHistoryComponent,
    ViewClaimAttachmentsComponent,
    EditClaimDetailsComponent,
    ViewCommitmentOrganizationsComponent,
    SelectCommitmentComponent,
    SectionWithLegendComponent,
    CreateClaimReviewComponent,
    DisplayErrorsComponent,
    CreateCommitmentReviewComponent,
    RequestNameComponent,
    RequestReviewCommentComponent,
    EditUserComponent,
    EditCustomerComponent,
    RevertPageComponent,
    DashboardComponent,
    DashboardMultiselectDropdownComponent,
    DashboardWidgetComponent,
    ColumnChooserComponent,
    FilterChooserComponent,
    FilterChooserFieldComponent,
    EditCommitmentComponent,
    RequestConfirmationComponent,
    EditCommitmentPhasingComponent,
    CreateCommitmentSuccessComponent,
    ViewErrorResultComponent,
    PhasingPageComponent,
    PhasingMonthComponent,
    ViewCommitmentPhasingComponent,
    ViewPhasingDetailsComponent,
    ViewPhasingYearComponent,
    EditCommitmentProductsComponent,
    EditCommitmentProductComponent,
    ViewCommitmentProductsComponent,
    EditCommitmentDetailsDialogComponent,
    EditCommitmentProductsDialogComponent,
    EditCommitmentPhasingsDialogComponent,
    SelectAttachmentsDialogComponent,
    AttachmentComponent,
    EditCommitmentDialogComponent,
    EditCommitmentHostComponent,
    ViewCommitmentDetailsCompactComponent,
    EditCommitmentDialogTitlebarComponent,
    WindowNotificationsComponent,
    WindowNotificationComponent,
    BatchAllocationStepComponent,
    TerritoryWithBalanceFormfieldComponent,
    BatchAllocationStepDialogComponent,
    ViewClaimProductsComponent,
    EditClaimProductsComponent,
    EditClaimProductComponent,
    ViewClaimDetailsCompactComponent,
    CreateClaimSuccessComponent,
    SelectPayeeDialogComponent,
    SelectPayeeCustomerComponent,
    SelectPayeeCustomerDialogComponent,
    SelectPayeeAddressComponent,
    EditUserDialogComponent,
    CreateUserDialogComponent,
    EditClaimComponent,
    EditClaimHostComponent,
    EditClaimDetailsDialogComponent,
    SelectCommitmentDialogComponent,
    EditClaimProductsDialogComponent,
    PrintClaimDetailsComponent,
    ViewClaimComponent,
    EditClaimDetailsDoctorComponent,
    ViewClaimDoctorsComponent,
    ValueWithPercentComponent,
    FormButtonGroupComponent,
    EditClaimDialogComponent,
    CustomerSearchComponent,
    EditEmailComponent,
    DisplayNotificationComponent,
    DisplayNotificationsComponent,
    EditCommitmentApprovalRuleComponent,
    HierarchyCompareComponent,
    HierarchyTreeComponent,
    RequestCommentComponent,
    UserSearchComponent,
    EditFundComponent,
    EditClaimReviewComponent,
    ViewClaimReviewComponent,
    RequestClaimVoidComponent,
    FeatureTreeComponent,
    EditFeatureComponent,
    EditAuditInstructionComponent,
    RequestDateComponent,
    SandboxFileUploadSearchComponent,
    SandboxFileUploadComponent,
    LcpComparisonComponent,
    DashboardWidgetChartComponent,
    ],
  imports: [
    IconsModule,
    ListViewModule,
    WindowModule,
    ChartModule,
    SparklineModule,
    CommonModule,
    RouterModule,
    MenuModule,
    TreeViewModule,
    PopupModule,
    TreeListModule,
    ReactiveFormsModule,
    LabelModule,
    InputsModule,
    LayoutModule,
    ButtonsModule,
    DropDownsModule,
    StoreModule,
    FormsModule,
    FlexLayoutModule,
    DialogModule,
    DateInputModule,
    DateInputsModule,
    GridModule,
    CoreModule,
    ExcelModule,
    ExcelModule2,
    PDFModule,
    ContextMenuModule,
    UploadsModule,
    TextBoxModule,
    ToolBarModule,
    FontAwesomeModule,
    SortableModule,
    ProgressBarModule,
    NotificationModule,
    GridstackModule
  ],
  exports: [
    HeaderMainComponent,
    FooterMainComponent,
    MenuMainComponent,
    DropDownTreeComponent,
    CommitmentSearchComponent,
    EditCommitmentDetailsComponent,
    ViewCommitmentDetailsComponent,
    ViewCommitmentReviewHistoryComponent,
    ViewCommitmentAttachmentsComponent,
    SelectCustomerComponent,
    DisplayAddressComponent,
    DisplayInfoCardComponent,
    SelectPayeeComponent,
    SelectFundComponent,
    SelectTerritoryComponent,
    SelectAttachmentsComponent,
    ClaimSearchComponent,
    ViewClaimDetailsComponent,
    ViewClaimReviewHistoryComponent,
    ViewClaimAttachmentsComponent,
    EditClaimDetailsComponent,
    ViewCommitmentOrganizationsComponent,
    DisplayItemComponent,
    SelectCommitmentComponent,
    SectionWithLegendComponent,
    CreateClaimReviewComponent,
    DisplayErrorsComponent,
    CreateCommitmentReviewComponent,
    SearchFiltersComponent,
    RequestReviewCommentComponent,
    EditUserComponent,
    EditCustomerComponent,
    RevertPageComponent,
    DashboardComponent,
    DashboardMultiselectDropdownComponent,
    DashboardWidgetComponent,
    ColumnChooserComponent,
    FilterChooserComponent,
    FilterChooserFieldComponent,
    EditCommitmentComponent,
    RequestConfirmationComponent,
    EditCommitmentPhasingComponent,
    CreateCommitmentSuccessComponent,
    ViewErrorResultComponent,
    ViewCommitmentPhasingComponent,
    EditCommitmentProductsComponent,
    ViewCommitmentProductsComponent,
    EditCommitmentDetailsDialogComponent,
    EditCommitmentProductsDialogComponent,
    EditCommitmentPhasingsDialogComponent,
    SelectAttachmentsDialogComponent,
    EditCommitmentDialogComponent,
    EditCommitmentHostComponent,
    ViewCommitmentDetailsCompactComponent,
    EditCommitmentDialogTitlebarComponent,
    WindowNotificationsComponent,
    WindowNotificationComponent,
    BatchAllocationStepComponent,
    BatchAllocationStepDialogComponent,
    ViewClaimProductsComponent,
    EditClaimProductsComponent,
    EditClaimProductComponent,
    ViewClaimDetailsCompactComponent,
    CreateClaimSuccessComponent,
    SelectPayeeDialogComponent,
    SelectPayeeCustomerComponent,
    SelectPayeeCustomerDialogComponent,
    SelectPayeeAddressComponent,
    EditUserDialogComponent,
    CreateUserDialogComponent,
    EditClaimComponent,
    EditClaimHostComponent,
    EditClaimDetailsDialogComponent,
    SelectCommitmentDialogComponent,
    EditClaimProductsDialogComponent,
    PrintClaimDetailsComponent,
    ViewClaimComponent,
    EditClaimDetailsDoctorComponent,
    ViewClaimDoctorsComponent,
    ValueWithPercentComponent,
    FormButtonGroupComponent,
    EditClaimDialogComponent,
    CustomerSearchComponent,
    DisplayNotificationComponent,
    DisplayNotificationsComponent,
    EditCommitmentApprovalRuleComponent,
    HierarchyCompareComponent,
    HierarchyTreeComponent,
    RequestCommentComponent,
    UserSearchComponent,
    RequestClaimVoidComponent,
    FeatureTreeComponent,
    EditFeatureComponent,
    SandboxFileUploadSearchComponent,
    SandboxFileUploadComponent,
    LcpComparisonComponent
  ],
  providers: [
    LocalizationService,
    CldrIntlService,
    {provide: L10N_PREFIX, useValue: ''},
    { provide: LOCALE_ID, useValue: "en-US" }
  ]
})
export class ComponentsModule { }

export type ViewMode =  'edit' | 'new' | 'search' | 'success' | 'view' | 'view-edit' | 'print' | 'review' | 'impersonate';

export type CompactMode = 'normal' | 'compact';

export type WindowMode = 'dialog' | 'window' | 'page';

export type EditFeatureType = 'normal' | 'resource-only';

export type AnonymousCommitmentReviewMode = 'approve' | 'deny' | 'approve-all' | 'unknown';

export type AnonymousCommitmentErrorMode = 'error' | 'success' | 'unknown';

export interface ViewSubjectWindow {
  windowComponent?: WindowComponent;
  loading$: Observable<boolean>
}
