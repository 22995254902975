import { CommitmentDetailStatusWithLedgerModel } from '@alcon-db-models/CommitmentDetailStatusWithLedgerModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { StatusCode } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Component, Input, OnInit } from '@angular/core';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-view-commitment-review-history',
  template: `
    <label class='acb-alcon-display-item-label'>Status Tracking</label>
    <kendo-grid
      *ngIf="gridView$ | async as gridViewData"
      #statusTrackingGrid
      class="acb-display-grid"
      [data]= "gridViewData"
      [pageable]="false"
      [sortable]="false"
      [reorderable]="false"
      [selectable]="false"
      [resizable]="true"
      [rowClass]="this.onRowClass.bind(this)"
      >
      <kendo-grid-column title="Date" field="eventDate" [width]="160" [style]="{'text-align': 'center'}">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{dataItem.eventDate | date: 'M/d/yyyy H:mm:ss a'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Person" field="person" [autoSize]="true">

        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ !dataItem.status && dataItem.territory ? dataItem.person + ' ('+dataItem.territory+')' : dataItem.person }}
        </ng-template>

      </kendo-grid-column>
      <kendo-grid-column title="Status" field="status" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        {{ dataItem.status ? dataItem.status : gridViewData.total == rowIndex + 1 ? 'Final Approval Pending' : 'Approval Pending' }}
        </ng-template>

      </kendo-grid-column>
      <kendo-grid-column title="Amount" field="transactionAmount" [width]="120" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{
              dataItem.statusCode != requestedStatusCode &&
              (dataItem.statusDollarAmount ? dataItem.statusDollarAmount : dataItem.transactionAmount)
              ? ((dataItem.statusDollarAmount ? dataItem.statusDollarAmount : dataItem.transactionAmount) | currency)
              : ''
          }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Comment" field="eventComment" [autoSize]="true" [style]="{'text-align': 'right'}">
      </kendo-grid-column>

    </kendo-grid>
  `,
  styleUrls: ['./view-commitment-review-history.component.scss']
})
export class ViewCommitmentReviewHistoryComponent implements OnInit {

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public statusCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StatusCode')));
  public approvalResultTypes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'ApprovalResultType')));
  public requestedStatusCode = StatusCode.Requested;

  public console = console;

  private _commitment: CommitmentWithDetailsModel | null | undefined = {};
  @Input() public set commitment(value: CommitmentWithDetailsModel | null | undefined) {
    this._commitment = value;
    this.history = this.buildHistory(value?.history ?? []);
    this.loadData()
  };
  public get commitment(): CommitmentWithDetailsModel | null | undefined {
    return this._commitment;
  }

  private buildHistory(data: CommitmentDetailStatusWithLedgerModel[]): any[] {
    let history: any[] = [];
    if (data?.length) {
      history = data.map(x => ({
        ...x,
        type: this.getType(x),
        status: this.getStatusCode(x),
        person: this.getPerson(x),
      }));
    }
    return history;
  }

  public history: any[] = [];
  public gridView$: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>({data:[], total: 0});

  private getStatusCode(model: CommitmentDetailStatusWithLedgerModel): string | null {
    let statusCode: string | null = null;
    let statusCodeID: number | null = model?.statusCode ?? null;
    if (statusCodeID) {
      this.statusCodes$.pipe(first(), map(x => x.find(y => y.id == statusCodeID))).subscribe(x => statusCode = x?.displayName ?? null);
    }
    return statusCode;
  }

  private getType(model: CommitmentDetailStatusWithLedgerModel): string | null {
    return model.statusFlavor ?? '';
  }

  private getPerson(model: CommitmentDetailStatusWithLedgerModel): string | null {
    const person: string = model.eventPerson ?? "?"
    return (model.eventImpersonator ? model.eventImpersonator + ' (for ' + person + ')' : person);
  }

  constructor(private store: Store,) {
  }

  private loadData() {
    this.gridView$.next({
      data: this.history,
      total: this.history.length
    });
  }

  public onRowClass(rowClassArgs: RowClassArgs) {

    if (!rowClassArgs?.dataItem || !this.history?.length)
      return '';
    const item = (rowClassArgs?.dataItem as CommitmentDetailStatusWithLedgerModel & { type:string, status: string, person:string });
    const index = this.history.findIndex(x => x.commitmentDetailStatusID == item.commitmentDetailStatusID)

    return index < this.history.length - 1
      && item.type == 'Credit'
      && this.history[index + 1]?.statusCode == StatusCode.Requested
      ? 'acb-strikethrough' : '';
  }

  ngOnInit(): void {
  }
}
