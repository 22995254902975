<acb-alcon-search-filters
  [title]="'Search'"
  [formGroup]="searchForm"
  [formDefaults]="formDefaults"
  (formReset)="onSearch()"
  (filterChosen)="onSearch()"
  #searchFilters
  [fxHide]="this.pageExpanded$ | async"
>
  <p featureText>
    <strong>User Search.</strong> To expand the search option for other commitments, click on the right corner box to allow you to search for additional fields.
    <br><br>
    To see additional field options on the grid below, click on the Column Lines to the left to add or remove columns.
  </p>
  <ng-container filterSectionBody>

    <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto" [fxHide]="searchFilters.isExpanded">
      <kendo-label [for]="name" text="Name"></kendo-label>
      <input formControlName="name" kendoTextBox #name autocomplete="off" />
      <kendo-formhint>Filters by User, First, and Last names</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="userName" text="UserName"></kendo-label>
      <input formControlName="userName" kendoTextBox #userName autocomplete="off" />
      <kendo-formhint>Login name</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="firstName" text="First Name"></kendo-label>
      <input formControlName="firstName" kendoTextBox #firstName autocomplete="off" />
      <kendo-formhint>Person's given name</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="lastName" text="Last Name"></kendo-label>
      <input formControlName="lastName" kendoTextBox #lastName autocomplete="off" />
      <kendo-formhint>Person's surname</kendo-formhint>
    </kendo-formfield>

    <ng-container *ngIf="viewMode == 'search'">

      <div class="acb-flex-break" [fxHide]="!searchFilters.isExpanded"></div>

      <ng-container *ngIf="accessRoles$ | async as accessRolesData">
        <kendo-formfield *appFieldExtentions fxFlex="1 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
          <kendo-label [for]="accessRoles" text="Access Roles"></kendo-label>
          <kendo-multiselect
            formControlName="accessRoles"
            [data]="accessRolesData"
            [textField]="'displayName'"
            [valueField]="'id'"
            #accessRoles
            >
          </kendo-multiselect>
          <kendo-formhint>One or more access roles</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <ng-container *ngIf="businessRoles$ | async as businessRolesDate">
        <kendo-formfield *appFieldExtentions fxFlex="1 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
          <kendo-label [for]="businessRoles" text="Business Role"></kendo-label>
          <kendo-multiselect
            formControlName="businessRoles"
            [data]="businessRolesDate"
            [textField]="'displayName'"
            [valueField]="'id'"
            #businessRoles
            >
          </kendo-multiselect>
          <kendo-formhint>One or more business roles</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <div class="acb-flex-break" [fxHide]="!searchFilters.isExpanded"></div>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="isActive" text="Is Active"></kendo-label>
        <kendo-dropdownlist
          formControlName="isActive"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          #isActive
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="isLoginable" text="Can Login"></kendo-label>
        <kendo-dropdownlist
          formControlName="isLoginable"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          #isLoginable
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="7em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="mustUpdatePassword" text="Change Pwd"></kendo-label>
        <kendo-dropdownlist
          formControlName="mustUpdatePassword"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          #mustUpdatePassword
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>
  </ng-container>
</acb-alcon-search-filters>

<kendo-grid
  #resultsGrid
  manageUserSearchBindingDirective
  [pageable]="{
    buttonCount: 4,
    pageSizes: true
  }"
  [sortable]="true"
  [reorderable]="true"
  [pageSize]="10"
  [selectable]="true"
  [resizable]="true"
  [(selectedKeys)] = "selectedKeys"
  [selectable] = "{
    enabled: true,
    mode: 'multiple'
  }"
  (selectedChange)="onSelectedChange($event)"
  (selectedKeysChange)="onResultsGridSelectedKeysChange($event)"
  kendoGridSelectBy="personID"
  class="acb-results-grid"
  [rowClass]="rowClassCallback"
  (beforeBind)="updateFilter"
  >
  <ng-template kendoGridToolbarTemplate>
    <div fxLayout="row" fxLayoutAlign=" center" >
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
        <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'" fxFlexOffset="1em" >Reset Grid</button>
        <button *ngIf="viewMode == 'search'" kendoButton type="button" [icon]="'user'" (click)="onCreateNewUser()" [className]="'acb-primary'" fxFlexOffset="1em" >New User</button>
      </div>
      <ng-container *ngIf="viewMode == 'search'">
        <div fxFlex='1.25 1 auto' fxFlex.xs='.75 1 auto'></div>
        <kendo-toolbar [overflow]="true" fxFlex="0 1 35em" fxFlex.xs="0 0 4em" [fxHide]="!hasSelected" [ngStyle.xs]="{'margin':'0'}">
          <kendo-toolbar-button [text]="'Clear (' + this.selectedKeys.length.toString() + ')'" [icon]="'apply-format'" (click)="onClearButtonClick()" [className]="'acb-toolbar-button-clear'" ></kendo-toolbar-button>
          <kendo-toolbar-button [text]="'Activate'" (click)="onActivateSelected()" [icon]="'checkmark'" [className]="'acb-good'"></kendo-toolbar-button>
          <kendo-toolbar-button [text]="'Deactivate'" (click)="onDeactivateSelected()" [icon]="'close'" [className]="'acb-warning'"></kendo-toolbar-button>
          <kendo-toolbar-button [text]="'Delete'" (click)="onDeactivateSelected()" [icon]="'delete'" [className]="'acb-danger'"></kendo-toolbar-button>
        </kendo-toolbar>
        <div fxFlex='1 1 auto'></div>
        <div fxFlexAlign="center" class="acb-toolbar-adjacent">
          <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export to Excel</button>
          <button kendoButton type="button" [icon]="expandToggleIcon" class="k-button" (click)="onToggleExpand()" fxFlexOffset="1em" ></button>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <kendo-grid-checkbox-column
    [width]="42"
    [reorderable]="false"
    [includeInChooser]="false"
    [showSelectAll]="true"
    [locked]="true"
    >
    <ng-template kendoGridHeaderTemplate>
      <input class="k-checkbox" kendoGridSelectAllCheckbox (selectAllChange)="onSelectAllChange($event)">
    </ng-template>
  </kendo-grid-checkbox-column>
  <kendo-grid-command-column
    [width]="42"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    class="acb-grid-cell-with-single-command"
    [locked]="true"
    *ngIf="viewMode == 'search'"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button
        *ngIf="dataItem?.isEditable"
        kendoGridEditCommand
        class="acb-in-grid-edit-button"
        [icon]="'edit'"
        look="clear"
        (click)="onEditUser($event, dataItem)"
        ></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column
    [width]="42"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    class="acb-grid-cell-with-single-command"
    [locked]="true"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button
        *ngIf="dataItem?.isImpersonatable"
        kendoGridEditCommand
        class="acb-in-grid-impersonate-button"
        look="clear"
        (click)="onImpersonateUser($event, dataItem)">
        <fa-icon [icon]="faMask"></fa-icon>
      </button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column title="ID" field="personID" [width]="60" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Username" field="userName" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="First Name" field="firstName" [width]="120">
  </kendo-grid-column>
  <kendo-grid-column title="Last Name" field="lastName" [width]="120">
  </kendo-grid-column>
  <kendo-grid-column title="Active" field="isActive" [width]="90" class="acb-user-grid-yesno-column" *ngIf="viewMode == 'search'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': !dataItem.isActive}">{{dataItem.isActive ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Can Login" field="isLoginable" [width]="90" class="acb-user-grid-yesno-column" *ngIf="viewMode == 'search'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': !dataItem.isLoginable}">{{dataItem.isLoginable ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Upd Pwd" field="mustUpdatePassword" [width]="110" [hidden]="true" *ngIf="viewMode == 'search'">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.mustUpdatePassword ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Last Login" field="lastLoginDate" [width]="168" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.lastLoginDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Created" field="createdDate" [width]="168" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.createdDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Modified" field="modifiedDate" [width]="168" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.modifiedDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Access Roles" field="accessRoles" [width]="200" *ngIf="viewMode == 'search'">
  </kendo-grid-column>
  <kendo-grid-column title="Business Roles" field="businessRoles" [width]="200" [hidden]="true" *ngIf="viewMode == 'search'">
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes' [pageSizes]="[5,10,20,40]" ></kendo-pager-page-sizes>
  </ng-template>
  <kendo-grid-excel fileName="Users.xlsx" [fetchData]="allData">
  </kendo-grid-excel>

</kendo-grid>

<div *ngIf="isLoading$ | async" class="acb-alcon-page-loading-indicator">
  <span class="k-icon k-i-loading"></span>
</div>
