import { CommitmentSearchWithDefaultModel } from '@alcon-db-models/CommitmentSearchWithDefaultModel';
import { Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridComponent, GridDataResult, PagerSettings, RowArgs, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Subscription } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { CommitmentSelectSearchBindingDirective } from 'src/app/directives/commitment-select-search-binding.directive';
import { WindowMode } from '../components.module';
import { SelectBaseComponent } from '../select.base.component';

@Component({
  selector: 'acb-alcon-select-commitment',
  template: `

    <div fxLayout="column" style="height: 100%;">
      <form [formGroup]="searchForm" style="width: 100%;" class="k-form acb-form-1">
        <acb-alcon-section-with-legend [sectionTitle]="'Select Commitment'" class="acb-section-01" [doShowLegend]="doShowLegend">
          <div fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="start start" style="width: 100%;">
            <kendo-formfield fxFlex>
              <kendo-label [for]="name" text="Commitment ID, Customer, or Fund"></kendo-label>
              <input formControlName="name" kendoTextBox #name autocomplete="off"/>
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
            <div fxFlex='0 0 6em' style="text-align: right; padding: 36px 0 0 0;" class="acb-search-button-wrapper">
              <button type="submit" kendoButton (click)="onSearch()" style="width:100%;">Search</button>
            </div>
          </div>
        </acb-alcon-section-with-legend>
      </form>
      <kendo-grid
        commitmentSelectSearchBinding

        [doInitResults]= "true"
        [selectable] = "{
          enabled: true,
          mode: 'single'
        }"
        [pageSize]="10"
        [pageable]="{
          buttonCount: 3,
          pageSizes: true,
          responsive: false,
          info: false
        }"
        [sortable]="true"
        [resizable]="true"
        (beforeBind)="updateFilter()"
        kendoGridSelectBy="commitmentID"
        [(selectedKeys)] = "selectedKeys"
        (selectionChange)="onSelectionChange($event)"
        #CommitmentResultsGrid
        fxFlex
        [ngClass]="{
          'acb-grid-has-selection': !!getSelectedItem(),
          'acb-grid-window-mode-page': true,
          'acb-grid-page-size-10': true
        }"
        (dataStateChange)="onDataStateChange()"
        [rowClass]="onRowClass"
        class="acb-results-grid"
      >
        <kendo-grid-command-column [width]="32">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Commitment" field="commitmentID" [width]="100" class="acb-grid-cell-with-view" [includeInChooser]="false" [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button (click)="onViewCommitment($event, dataItem)">
              {{dataItem.commitmentID}}
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Cust #" [field]="'customerCode'">
        </kendo-grid-column>
        <kendo-grid-column title="Customer" [field]="'customer'" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column title="Payee" [field]="'payeeCustomer'" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column title="Fund" field="fund" [width]="240">
        </kendo-grid-column>
        <kendo-grid-column title="Amount" field="amount" format="{0:c}" class="acb-grid-column-currency" [width]="110">
        </kendo-grid-column>
        <kendo-grid-column title="Unused" field="unused" format="{0:c}" class="acb-grid-column-currency" [width]="110">
        </kendo-grid-column>
        <kendo-grid-column title="Start" field="startDate" [width]="90" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.startDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="End" field="endDate" [width]="90" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem >
            {{dataItem.endDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages
          [pagerItemsPerPage]="'per page'"
          [pagerItems]="'commitment'"
          [pagerOf]="'of'"
          [pagerPage]="''"
          >
        </kendo-grid-messages>
        <ng-template #pagerTemplate kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage" let-total="total" let-pageSize="pageSize">
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[5,10,15]" fxHide.xs="true"></kendo-pager-page-sizes>
            <div fxFlex></div>
            <div style="font-size: smaller">{{total}} commitments</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  `,
  styleUrls: ['./select-commitment.component.scss']
})
export class SelectCommitmentComponent extends SelectBaseComponent {

  @Input() windowMode: WindowMode = 'dialog';
  @Input() doShowLegend: boolean = true;
  @Input() isReverseClaim: boolean = false;

  @ViewChild('CommitmentResultsGrid') public resultsGrid?: GridComponent;
  @ViewChild(CommitmentSelectSearchBindingDirective) public set CommitmentSelectSearchBinding(value:CommitmentSelectSearchBindingDirective) {

    this.bindingDirective = value;

    //TODO: this is a mess.  Extract common code from CommitmentSelectSearchBinding, routedCommitmentID and commitment creation's counterpart in customer selection
    if (this._routedCommitmentID) {
      this.searchForm.patchValue({ prependCommitmentID: this._routedCommitmentID });
      this.bindingDirective.dataChange.pipe(take(1)).subscribe(x => {
        const commitment = x.data.find(y => y.commitmentID == this._routedCommitmentID)
        this.selectedChange.emit(commitment ? [{index: 0, dataItem:commitment}] : []);
      });
      super.onSearch();
      this.selectedKeys[0] = this._routedCommitmentID;
    } else {
      this.rebind();
      this.selectedKeys = [];
    }
  }

  //TODO: this is a mess.  Extract common code from CommitmentSelectSearchBinding, routedCommitmentID and commitment creation's counterpart in customer selection
  private _routedCommitmentID: number | null | undefined;
  private _bindingDirectiveDataChangeSubscription?:Subscription
  @Input() public set routedCommitmentID(value: number | null | undefined) {

    this.searchForm.patchValue({ prependCommitmentID: this._routedCommitmentID });

    this._routedCommitmentID = value;

    if (!this.bindingDirective) return;

    this._bindingDirectiveDataChangeSubscription?.unsubscribe();
    if (!value) return;
    this.selectedKeys[0] = value;
    this.searchForm.patchValue({ prependCommitmentID: value });
    const data: CommitmentSearchWithDefaultModel[] = (this.resultsGrid?.data as GridDataResult)?.data ?? [];
    let commitment = data.find(x => x.commitmentID == value) ?? null;

    // make selection now if commitment is naturally on first page
    if (commitment) {
      this.doSelectionChange(commitment);
    // else wait until data page is refreshed with prepend commitment
    } else {
      this._bindingDirectiveDataChangeSubscription = this.bindingDirective?.dataChange.pipe(
        map((x:GridDataResult) => x?.data?.find((y:any) => y?.commitmentID == value) as CommitmentSearchWithDefaultModel ?? null),
        filter(x => Boolean(x)),
        first()
      ).subscribe(x => this.doSelectionChange(x));
      super.onSearch();
    }
  };

  protected doSelectionChange(commitment: CommitmentSearchWithDefaultModel | null) {
    if (commitment?.commitmentID) this.selectedKeys[0] = commitment?.commitmentID;
    this._selectedCommitment = commitment;
    this.searchForm.patchValue({ prependCommitmentID: this._selectedCommitment?.commitmentID ?? null });
    this.selectedChange.emit(commitment ? [{index: 0, dataItem:commitment}] : []);
  }


















  @Output() selectedChange: EventEmitter<RowArgs[]> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();

  public selectedKeys: number[] = [];
  public pageable:boolean | PagerSettings = false;

  private _selectedCommitment:CommitmentSearchWithDefaultModel | null = null;

  constructor(
    @Optional() wizardFeatureService?: WizardFeatureService
  ) {
    super( new UntypedFormGroup({
      name: new UntypedFormControl(),
      prependCommitmentID: new UntypedFormControl(),
    }));

    wizardFeatureService?.clear.subscribe((x: boolean) => {
      if (x) {
        this.selectedKeys = [];
      }
    })
  }

  public getSelectedItem():CommitmentSearchWithDefaultModel | null {
    return this._selectedCommitment;
  }

  public onSearch() {

    //TODO: Move this to the base class... somehow
    this.pageable = (this.searchForm?.get('commitmentID')?.value || this.searchForm?.get('customerCodeOrName')?.value) ? {
        buttonCount: 4,
        pageSizes: true
      } :
      false;

    super.onSearch();
  }

  public onSelectionChange(event:SelectionEvent) {

    // TODO: refactor this "reset"
    this.searchForm.patchValue({ prependCommitmentID: event?.selectedRows?.length ? event.selectedRows[0]?.dataItem?.commitmentID : null });
    this._selectedCommitment = event.selectedRows?.length && event.selectedRows[0]?.dataItem?.commitmentID ? event.selectedRows[0]?.dataItem as CommitmentSearchWithDefaultModel : null;
    this.selectedChange.emit(event.selectedRows);
  }

  public onDataStateChange() {
  }

  public onViewCommitment(event: any, claim: any) {
    this.viewCommitment.emit(claim?.commitmentID);
  }

  public onRowClass(rowClassArgs: RowClassArgs) {
    return ((rowClassArgs?.dataItem as CommitmentSearchWithDefaultModel)?.rowNum == 0) ?
      "acb-wizard-inserted-row" :
      ""
  }
}

