import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ButtonGroupComponent } from '@progress/kendo-angular-buttons';

export class GroupButtonSpec {
  constructor(
    public key: any,
    public text?: string,
    public icon?: string,
    public buttonClass?: string,
    public enabledGroups?: any[]
  ) {}
  public selected$?: Subject<boolean> = new Subject<boolean>()
}

@Component({
  selector: 'acb-alcon-form-button-group',
  template: `
    <kendo-buttongroup
      #buttonGroup
      selection="single"
      fxLayout="row"
      >
        <button
          fxFlex
          kendoButton
          [toggleable]="true"
          [icon]="buttonSpec.icon ? buttonSpec.icon : ''"
          (selectedChange)="onButtonSelectedChanged(i)"
          *ngFor="let buttonSpec of buttonSpecs; let i=index;"
          [selected] = 'selectedIndex == i'
          [class]='buttonSpec.buttonClass'
          [disabled]='getButtonDisabled(buttonSpec)'
          >
            {{buttonSpec.text}}
        </button>
    </kendo-buttongroup>
  `,
  styleUrls: ['./form-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormButtonGroupComponent),
      multi: true
    }
  ]
})
export class FormButtonGroupComponent implements OnInit, ControlValueAccessor {

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  @ViewChild('buttonGroup') buttonGroup?: ButtonGroupComponent

  @Input() buttonSpecs: GroupButtonSpec[] = [];
  @Input() enableGroup?: any;
  @Output() selectionChange?: EventEmitter<GroupButtonSpec>;

  public selectedIndex: number = -1;

  onChange: any = () => {};
  onTouched: any = () => {};

  private _value: any;
  set value(val: any) {
    this._value = val;
    this.selectedIndex = this.buttonSpecs?.findIndex(x => x.key === val) ?? -1;
  }
  get value(): any { return this._value; }

  getButtonDisabled(buttonSpec: GroupButtonSpec) {
    const disabled = buttonSpec.enabledGroups?.length && this.enableGroup && !buttonSpec.enabledGroups.some(x => x == this.enableGroup);
    if (disabled && this.value && this.value === buttonSpec.key) {
      this.value = null;
      this.onChange(this._value);
    }
    return disabled;
  }

  writeValue(key: any): void {
    this.value = key;
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  public onButtonSelectedChanged(buttonSpecIndex: number) {
    if ((this.buttonSpecs?.length ?? 0) <= buttonSpecIndex) return;
    this.value = this.buttonSpecs![buttonSpecIndex].key;
    this.onChange(this._value);
    this.onTouched();
    this.changeDetectorRef.detectChanges();
  }


}
