import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { selectAccessRoles } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { UserFormBaseService } from '@services/user-form-base.service';
import { AccessRoleType, VisibilityType } from '@alcon-db-models/Enums';
import { AccessRoleModel } from '@alcon-db-models/AccessRoleModel';

export type EditUserMode = 'create' | 'edit';

@Component({
  selector: 'acb-alcon-edit-user',
  template: `
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend class="acb-section-01" [doShowLegend]="false">

        <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start top">

          <div fxLayout="column" fxLayoutAlign="start top" fxFlex="35%">

            <ng-container>
              <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
                <kendo-label [for]="userName" text="User Name"></kendo-label>
                <input formControlName="userName" kendoTextBox #userName autocomplete="off" />
                <kendo-formhint>User's login name</kendo-formhint>
                <kendo-formerror>Error: User Name required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <ng-container>
              <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
                <kendo-label [for]="emailAddress" text="Email"></kendo-label>
                <input formControlName="emailAddress" kendoTextBox #emailAddress autocomplete="off" />
                <kendo-formhint>User's email address</kendo-formhint>
                <kendo-formerror>Error: Email required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <ng-container>
              <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
                <kendo-label [for]="firstName" text="First Name"></kendo-label>
                <input formControlName="firstName" kendoTextBox #firstName autocomplete="off"/>
                <kendo-formhint>User's given name</kendo-formhint>
                <kendo-formerror>Error: First Name required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <ng-container>
              <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
                <kendo-label [for]="lastName" text="Last Name"></kendo-label>
                <input formControlName="lastName" kendoTextBox #lastName autocomplete="off" />
                <kendo-formhint>User's surname</kendo-formhint>
                <kendo-formerror>Error: Last Name required</kendo-formerror>
              </kendo-formfield>
            </ng-container>

          </div>

          <div fxLayout="column" fxFlex  *ngIf="this.accessRoles$ | async as accessRolesData">
            <kendo-formfield  *appFieldExtentions>
              <kendo-label [for]="accessRoleIDs" text="Access Roles"></kendo-label>
              <kendo-multiselect
                formControlName="accessRoleIDs"
                #accessRoleIDs min="0"
                required
                [data]="accessRolesData"
                [textField]="'displayName'"
                [valueField]="'accessRoleID'"
                [valuePrimitive]="true"
                >
              </kendo-multiselect>
              <kendo-formhint>Controls feature visibility and access</kendo-formhint>
              <kendo-formerror>Error: Access Role required</kendo-formerror>
            </kendo-formfield>

            <div fxLayout="row" fxLayoutGap="2em">
              <div *ngIf="editUserMode == 'create'">
                <kendo-formfield *appFieldExtentions>
                  <kendo-label [for]="isActive" class="acb-form-label-and-checkbox" text="Active"><input formControlName="isActive" type="checkbox" kendoCheckBox #isActive /></kendo-label>
                </kendo-formfield>
              </div>
              <div fxLayout="column">
                <ng-container *ngIf="editUserMode == 'edit'">
                  <kendo-formfield *appFieldExtentions>
                    <kendo-label [for]="mustUpdatePassword" class="acb-form-label-and-checkbox" text="User Must Change Password">
                      <input
                        formControlName="mustUpdatePassword"
                        type="checkbox"
                        kendoCheckBox
                        #mustUpdatePassword />
                    </kendo-label>
                  </kendo-formfield>
                </ng-container>
                <ng-container *ngIf="editUserMode == 'edit'">
                  <kendo-formfield *appFieldExtentions>
                    <kendo-label [for]="doResetPassword" class="acb-form-label-and-checkbox" text="Reset Password"><input formControlName="doResetPassword" type="checkbox" kendoCheckBox #doResetPassword /></kendo-label>
                  </kendo-formfield>
                </ng-container>
                <div *ngIf="editUserMode == 'create'">
                  <kendo-formfield *appFieldExtentions>
                    <kendo-label [for]="doEmailUser" class="acb-form-label-and-checkbox" text="Email User"><input formControlName="doEmailUser" type="checkbox" kendoCheckBox #doEmailUser /></kendo-label>
                  </kendo-formfield>
                </div>
              </div>
            </div>
          </div>
        </div>
      </acb-alcon-section-with-legend>
    </form>
  `,
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnDestroy, OnInit {

  @Input() editUserMode: EditUserMode = 'edit';

  @Output() canceled: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() validityChange: EventEmitter<boolean> = new EventEmitter();

  public accessRoles$: Observable<AccessRoleModel[]>;

  public loading$: Observable<boolean> = this.userFormBaseService.loading$;
  public destroy$: Subject<void> = new Subject<void>();

  public get form(): UntypedFormGroup { return this.userFormBaseService.form; }

  public isReset: boolean = false;

  public onSave() {
    this.userFormBaseService.SaveUser().pipe(take(1)).subscribe(x => {
      this.saved.emit(null);
    });
  }

  public onCancel() {
    this.canceled.emit(null);
  }


  constructor(
    private store: Store,
    private userFormBaseService: UserFormBaseService,
    private changeDetectorRef: ChangeDetectorRef
    ) {

    this.accessRoles$ = this.store.select(selectAccessRoles).pipe(map(x => x.filter(y => y.visibilityTypeID == VisibilityType.Visible && y.accessRoleTypeID == AccessRoleType.Role)));

    let lastResetStatus: boolean = false;
    userFormBaseService.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {

      const ee = { emitEvent: false };

      if (this.editUserMode == 'edit') {
        if (this.form.value?.doResetPassword) {
          this.form.patchValue({
            mustUpdatePassword: true,
            doEmailUser: true
          }, { emitEvent: false });
        }

      }
      // else if (this.editUserMode == 'create') {
      //   if (this.form.value?.doEmailUser) {
      //     this.form.patchValue({
      //       isActive: true
      //     }, { emitEvent: false });
      //   } else if (!this.form.value?.isActive) {
      //     this.form.patchValue({
      //       doEmailUser: false
      //     }, { emitEvent: false });
      //   }
      // }

      changeDetectorRef.detectChanges();



      // userFormBaseService.form.controls.mustUpdatePassword.disable(ee);
      // userFormBaseService.form.controls.doResetPassword.disable(ee);
      // userFormBaseService.form.controls.doEmailUser.disable(ee);

      // const clearPasswordUpdate = () => {
      //   this.form.patchValue({
      //     doResetPassword: this.editUserMode == 'edit' ? false : undefined,
      //     mustUpdatePassword: this.editUserMode == 'edit' ? false : undefined,
      //     doEmailUser: false
      //   });
      // }

      // if (!x.isActive) {
      //   if (x.doResetPassword || x.mustUpdatePassword) clearPasswordUpdate();
      //   return;
      // }

      // if (x.doResetPassword && x.mustUpdatePassword) {
      //   clearPasswordUpdate();
      //   return;
      // }

      // if (!x.doResetPassword && !x.mustUpdatePassword && x.doEmailUser && this.editUserMode == 'edit') {
      //   clearPasswordUpdate();
      //   return;
      // }


      if (x.doResetPassword) {
        lastResetStatus = true;
        // userFormBaseService.form.controls.doEmailUser.enable(ee);
        userFormBaseService.form.controls.mustUpdatePassword.setValue(true, ee);
        // userFormBaseService.form.controls.mustUpdatePassword.disable(ee);
      }
      else {
        // userFormBaseService.form.controls.mustUpdatePassword.enable(ee);
        // TODO:  This is ugly.  Must be a better way
        if (lastResetStatus) {
          lastResetStatus = false;
          userFormBaseService.form.controls.mustUpdatePassword.setValue(false, ee);
        }
      }

      // if (!x.mustUpdatePassword)
      //   userFormBaseService.form.controls.doResetPassword.enable(ee);

    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
