import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'acb-alcon-search-filters[formGroup]',
  template: `
    <form class="k-form acb-expandable-form"
      [formGroup]="formGroup"
      [ngClass]="{ 'acb-expanded': isExpanded }"
      fxLayout="row"
      fxLayout.lt-lg="column"
      fxLayoutGap.gt-xs="2em"
      >
      <ng-template #legendTemplate>
        <div class="acb-filters-header">
          <h3 fxFlex>{{title}}</h3>
          <fa-icon [icon]="faExclamation" *ngIf="!isExpanded && doShowEpansionAlert"></fa-icon>
          <button kendoButton type="button" look="clear" [icon]="isExpanded ? 'fullscreen-exit' : 'fullscreen'" (click)="onToggleExpand()"></button>
        </div>
      </ng-template>
      <acb-alcon-section-with-legend [sectionTitle]="title" [legendTemplate]="legendTemplate" class="acb-section-01">
        <div
          class="acb-expandable-form-body"
          [fxLayout]="bodyFxLayout"
          [fxLayout.lt-lg]="isExpanded ? 'column' : 'row'"
          [fxLayoutAlign]="bodyFxLayoutAlign"
          >
          <div
            [fxFlex]="defaultContentFxFlex"
            [ngClass]="{'acb-fieldset-row':isExpanded, 'acb-expandable-fields': true }"
            fxLayout="row wrap"
            >
            <ng-content select="[filterSectionBody]"></ng-content>
          </div>
          <div
            class="acb-expandable-form-button-wrapper"
            [fxFlex]="buttonWrapperFxFlex"
            [ngClass]="{'acb-section-buttons': isExpanded, 'acb-section-buttons_collapsed': !isExpanded}"
            fxLayout="column"
            fxLayout.lt-lg="row"
            fxLayoutAlign="end end"
            fxLayoutGap.gt-md="2em"
            >
            <button type="submit" kendoButton [primary]="true" (click)="onApply()" fxFlexOrder="2">Apply</button>
            <button kendoButton (click)="onClear()" [fxHide]="!isExpanded" fxFlexOrder="1">Clear</button>
          </div>
        </div>
      </acb-alcon-section-with-legend>
      <div
        class="acb-expandable-form-info"
        [ngClass.lt-lg]="'acb-column-layout'"
        [fxHide]="isExpanded || (!featureText?.innerText?.trim() && !featureKey?.innerText?.trim())"
        fxFlex="1 1 50%"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap.gt-xs="2em"
        >
        <div fxFlex class="acb-info-feature-text" [fxHide]="!featureText?.innerText?.trim()" fxFlex.xs="auto">
          <div #featureText fxFlex class="acb-inner-wrapper">
            <ng-content select="[featureText]"></ng-content>
          </div>
        </div>
        <div fxFlex="12.5em" fxFlex.lt-lg="17.15em"  fxFlex.xs="auto" class="acb-info-feature-key" [fxHide]="!featureKey?.innerText?.trim()">
          <div class="acb-info-inner-header">
            <div fxFlex class="acb-inner-wrapper">
              <ng-content select="[featureKeyHeader]"></ng-content>
            </div>
          </div>
          <div #featureKey class="acb-info-inner-text">
            <ng-content select="[featureKey]"></ng-content>
          </div>
        </div>
      </div>
    </form>

  `,
  styleUrls: ['./search-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFiltersComponent implements OnInit {

  @Input() formGroup!: UntypedFormGroup;
  @Input() formDefaults!: any;
  @Input() title?: string;
  @Input() doShowEpansionAlert: boolean = false;

  @Output() formReset: EventEmitter<any> = new EventEmitter();
  @Output() filterChosen: EventEmitter<any> = new EventEmitter();
  @Output() filtersExpandChanged: EventEmitter<boolean> = new EventEmitter();

  public expandToggleIcon = 'fullscreen';
  public faExclamation = faExclamation;

  public bodyFxLayout!: string;
  public defaultContentFxFlex: string = "auto";
  public buttonWrapperFxFlex!: string;
  public bodyFxLayoutAlign!: string;

  private _isExpanded: boolean = false;

  public get isExpanded() {
    return this._isExpanded;
  };

  public set isExpanded(value:boolean) {
    if (value) {
      this.bodyFxLayoutAlign = "";
      this.bodyFxLayout = "";
      this.buttonWrapperFxFlex= ""
    } else {
      this.bodyFxLayoutAlign = "start center"
      this.bodyFxLayout = "row";
      this.buttonWrapperFxFlex= "none"
    }
    this._isExpanded = value;
  };

  constructor() {
    this.isExpanded = false;
  }

  public expanded() {
  }

  public contract() {
  }

  ngOnInit(): void {
    if (!this.formGroup)
      throw new TypeError("The input 'searchForm' is required");
  }

  public onClear() {
    this.formGroup.reset(this.formDefaults);
    this.formReset.emit(null);
  }

  public onApply() {
    this.filterChosen.emit(null);
  }

  public onToggleExpand() {
    this.filtersExpandChanged.emit(this.isExpanded = !this.isExpanded);
  }
}
