import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActionsSubject, Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { first, map, take, takeUntil, concatMap, scan, last, catchError, finalize, delay } from 'rxjs/operators';
import { PageExpansionService } from '@services/page-expansion.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ManageUserSearchBindingDirective } from 'src/app/directives/manage-user-search-binding.directive';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { ViewMode } from 'src/app/components/components.module';
import { faPeopleArrows, faUserFriends, faMask, faUserSecret, faMale } from '@fortawesome/free-solid-svg-icons';
import { UserInfoService } from '@services/user-info.service';
import { ManageUserSearchModel } from '@alcon-db-models/ManageUserSearchModel';
import { AppAction, impersonatePerson, updateCurrentPerson, userLoginFailure } from '@app-store/app-session/app-session.actions';
import { PersonWithRolesService } from '@services/person-with-access-roles.service'
import { ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AppWindowService } from '@services/app-window.service';
import { UserEditService } from '@services/user-edit.service';
import { UserCreateService } from '@services/user-create.service';
import { VisibilityType } from '@alcon-db-models/Enums';
import { SearchFilterService } from '@services/search-filter.service';
import { AppUxService } from '@services/app-ux.service';
import { UserPreferenceEnabledGridBaseComponent } from 'src/app/components/user-preference-enabled-grid.base.component';
import { QueryParams } from '@ngrx/data';
import { UserInfoModel } from '@alcon-db-models/UserInfoModel';
import { ServiceResponse, getErrorMessage, firstWithTimeout } from 'src/app/shared/acb-stream';
import { AppWindowServiceBase } from '@services/app-window-service.base';
import { UserActivateByUsernameService } from '@services/user-activate-by-username.service';

@Component({
  selector: 'acb-alcon-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  providers: [
    UserCreateService,
    UserEditService,
    SearchFilterService
  ]
})
export class UserSearchComponent extends UserPreferenceEnabledGridBaseComponent implements OnInit {

  faPeopleArrows = faPeopleArrows;
  faUserFriends = faUserFriends;
  faMask = faMask;
  faUserSecret = faUserSecret;
  faMale = faMale;

  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;
  @ViewChild(ManageUserSearchBindingDirective) public bindingDirective?: ManageUserSearchBindingDirective;

  @Input() viewMode: ViewMode = 'search'

  public accessRoles$: Observable<StaticTypeModel[]>;
  public businessRoles$: Observable<StaticTypeModel[]>;

  public activateableSelectedKeys: string[] = [];
  public deactivateableSelectedKeys: string[] = [];


  public selectedUserID?: number;
  public yesNoList: { name: string, value: boolean | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Yes', value: true }
  , { name: 'No', value: false }
  ];

  public isLoading$: Observable<boolean> = new Observable<boolean>();

  formDefaults = {
    name: null,
    userName: null,
    firstName: null,
    lastName: null,
    isActive: true,
    isLoginable: null,
    mustUpdatePassword: null,
    accessRoles: null,
    businessRoles: null,
  }

  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    private userInfoService: UserInfoService,
    private PersonWithRolesService: PersonWithRolesService,
    private actionsSubject$: ActionsSubject,
    router: Router,
    private appWindowService: AppWindowService,
    private userEditService: UserEditService,
    private userCreateService: UserCreateService,
    private userActivateByUsernameService: UserActivateByUsernameService,
    changeDetectorRef: ChangeDetectorRef,
    searchFilterService: SearchFilterService,
    private appUxService: AppUxService
  ) {
      super(store, pageExpansionService, changeDetectorRef, searchFilterService, router)

      this.searchForm = new UntypedFormGroup({
        name: new UntypedFormControl(),
        userName: new UntypedFormControl(),
        firstName: new UntypedFormControl(),
        lastName: new UntypedFormControl(),
        isActive: new UntypedFormControl(),
        isLoginable: new UntypedFormControl(),
        mustUpdatePassword: new UntypedFormControl(),
        accessRoles: new UntypedFormControl(),
        businessRoles: new UntypedFormControl(),
      });

      this.accessRoles$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'AccessRole')));
      this.businessRoles$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'BusinessRole' && y.visibilityTypeID == VisibilityType.Visible)));
      this.searchForm.reset(this.formDefaults);
  }

  ngOnInit(): void {
  }

  public onFilter(value: string) {
    if (!value)
      return;
  }


  public onActivateSelected = (() =>  {
    this.updateStatus(true);
    // this.selectedKeys.forEach(x => {
    //   this.userInfoService.getByKey(x).pipe(first()).subscribe(user => {
    //     user = {...user, isActive: true };
    //     this.userInfoService.upsert(user).pipe(first()).subscribe(y => {
    //       this.rebind();
    //     })
    //   });
    // });
  }).bind(this);


  public onDeactivateSelected = (() =>  {
    this.updateStatus(false);
    // this.selectedKeys.forEach(x => {
    //   this.userInfoService.getByKey(x).pipe(first()).subscribe(user => {
    //     user = {...user, isActive: false };
    //     this.userInfoService.upsert(user).pipe(first()).subscribe(y => {
    //       this.rebind();
    //     })
    //   });
    // });
  }).bind(this);

  public onResultsGridSelectedKeysChange(event: any[]) {

  }

  private updateStatus(isActive:boolean ) {
    this.appWindowService.showLoadingOverlay();
    from(this.selectedKeys).pipe(

      // merge getByKey observable
      concatMap(x => this.userInfoService.getByKey(x).pipe(firstWithTimeout())),
      // merge upsert observable into ServiceResponse<UserInfoModel>
      concatMap(x => {
        if (x?.applicationUserPersonID) {
          const user:UserInfoModel = {...x, isActive: isActive };
          return this.userInfoService.upsert(user).pipe(
            firstWithTimeout(),
            map(y => ({ hasError: false, errorMessage: [], response: y }) as ServiceResponse<UserInfoModel>)
          );
        } else {
          return of({ hasError: true, errorMessage: ['User not found'], response: null });
        }
      }),
      catchError(err => {
        const errorMessage = getErrorMessage(err);
        return of({ hasError: true, errorMessage: errorMessage, response: null});
      }),
      // accumulate into a single array
      scan((acc, value) => {
        acc.push(value);
        return acc;
      }, [] as ServiceResponse<UserInfoModel>[]),
      // we only want the final accumulated value
      last(),
      finalize(() => this.appWindowService.hideLoadingOverlay())
    ).subscribe((x) => {
      // we'll ignore errors if any succeeded
      if (x.some(y => !y.hasError)) {
        this.rebind();
        this.selectedKeys = [];
      // otherwise we'll show the distinct error messages
      } else {
        const msgs = [...new Set(x.filter(y => y.errorMessage?.length).map(y => y.errorMessage[0]))]
        this.appUxService.openErrorDialog(msgs);
      }
    })
  }

  public onClearButtonClick() {
    this.selectedKeys = [];
  }

  public onSelectedChange(event: any) {
  }

  public onEditCanceled() {
    this.viewMode = "search";
  }

  public onEditSaved() {
    this.viewMode = "search";
    this.rebind();
    this.selectedKeys = [];
  }

  public onCreateNewUser = (() =>  {

    const dialogSpec = this.appWindowService.openCreateUserDialog();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    this.userCreateService.initForm();

    if (dialogSpec?.component)
      dialogSpec.component.userCreateService = this.userCreateService;

    if (component && dialogRef) {
      component.cancel.pipe(take(1)).subscribe(x => {
        this.userCreateService.reset();
        dialogRef.close();
      })
      component.save.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.userCreateService.SaveUser().pipe(first()).subscribe((x:ServiceResponse<UserInfoModel>) => {
          if (x.hasError) {
            let isResolved = false;
            let duplicateUserNameMsg = x.errorMessage.find(str => str.includes("DuplicateUserName"));
            if (duplicateUserNameMsg) {
              let regExp = /\(([^)]+)\)/;
              let matches = regExp.exec(duplicateUserNameMsg);
              if (matches?.length) {
                let username = matches[0];
                if (username) {
                  //HACK: use a better regex
                  username = username.replace("(", "");
                  username = username.replace(")", "");
                  isResolved = true;
                  this.appUxService.openConfirmation(
                    "Duplicate Username",
                    "A user with that username already exists. Would you like to activate the existing user if it is inactive?",
                    undefined,
                    190,
                    'alcon-background-orange',
                    'acb-section-09').then((confirm) => {
                      if (confirm) this.userActivateByUsernameService.ActivateByUserName(username).pipe(first()).subscribe(x => {
                        this.rebind();
                        this.selectedKeys = [];
                        this.userCreateService.reset();
                        dialogRef.close();
                      });
                    });
                }
              }
            }
            if (!isResolved)
            {
              this.appUxService.openErrorDialog(x.errorMessage);
              return;
            }
          } else {
            this.selectedKeys = [];
            this.rebind();
            this.userCreateService.reset();
            dialogRef.close();
          }
        });
      });
    }
  }).bind(this);

  public onEditUser = ((event: any, dataItem: any ) =>  {

    this.selectedKeys = [this.selectedUserID = dataItem?.applicationUserID];
    const dialogSpec = this.appWindowService.openEditUserDialog();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (dialogSpec?.component)
      dialogSpec.component.userEditService = this.userEditService;

    this.userEditService.userSpec = { applicationUserID: dataItem?.applicationUserID };

    if (component && dialogRef) {
      component.cancel.pipe(take(1)).subscribe(x => {
        this.userEditService.reset();
        dialogRef.close();
      })
      component.save.pipe(takeUntil(this.destroy$)).subscribe(x => {

        this.userEditService.SaveUser().subscribe((x:ServiceResponse<UserInfoModel>) => {
          if (x.hasError) {
            this.appUxService.openErrorDialog(x.errorMessage);
          } else {
            this.selectedKeys = [];
            this.rebind();
            this.userEditService.reset();
            dialogRef.close();
          }
        });
      })
    }
  }).bind(this);

  public onImpersonateUser = ((event: any, dataItem: ManageUserSearchModel ) =>  {
    if (dataItem?.personID) {
      this.isLoading$ = this.PersonWithRolesService.loading$;
      this.actionsSubject$
        .pipe(
          ofType(updateCurrentPerson.type, userLoginFailure.type), 
          first()
        )
        .subscribe(
          (action: AppAction) => {
            this.isLoading$ = of(false);
            if (action?.type === updateCurrentPerson.type) {
              this.router.navigate(['/']);
            } else {
              this.appUxService.openErrorDialog("Impersonation failed");
            }
          }
        );
      // TODO: Lock this down, make sure visibility affects access
      this.store.dispatch(impersonatePerson({ payload: dataItem.personID}));
    } else {
      this.isLoading$ = of(false);
    }
  }).bind(this);

  public onSelectAllChange(event: any) {
    // Ugh, can't do this.  We can not select all possible rows since we never have a full list
    // and relying on a prefetched list of IDs is problematic, will fall out of sync
    //
    // if (event=="checked") {
    //   this.selectedKeys
    // }
  }



  public allData(): Observable<ExcelExportData> {

    return this.bindingDirective ?
      this.bindingDirective.manageUserSearchService.searchForExcelExport(this.bindingDirective.getSearchRequest(true)) :
      of({});


  }

  public rowClassCallback(context: RowClassArgs) {
    return context.dataItem?.isActive ? 'acb-active-user-row' : 'acb-inactive-user-row';
  }
}
