import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { StatusCode } from '@alcon-db-models/Enums';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'acb-alcon-print-claim-details',
  template: `
    <div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">
      <div class="acb-details-layout"  gdColumns="repeat(auto-fill, minmax(8em, max-content))">
        <div class="acb-view-field acb-view-field-claim-id">
          <div>
            <div class="acb-value">{{ claim?.claimID }}</div>
          </div>
        </div>
        <div class="acb-view-field acb-view-field-commitment-id">
          <kendo-label text="Commitment"></kendo-label>
          <div style="font-weight:bold">{{ claim?.commitmentID }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Status"></kendo-label>
          <div style="font-weight:bold" [ngStyle]="{'color': getStatusColor(claim) }">{{ claim?.displayStatus }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Claimed"></kendo-label>
          <div style="font-weight:bold">{{ claim?.claimedAmount | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Paid"></kendo-label>
          <div [ngStyle]="getPaidStyle(claim)">{{ (claim?.paidAmount ? claim?.paidAmount : 0) | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Committed"></kendo-label>
          <div>{{ claim?.commitmentAmount | currency }}</div>
        </div>
        <div class="acb-view-field acb-span-2">
          <kendo-label text="Activity"></kendo-label>
          <div>{{ claim?.activity }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Pay Type"></kendo-label>
          <div>{{ claim?.payType ? claim?.payType : 'Unknown' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Deduction"></kendo-label>
          <div>{{ claim?.deduction }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Invoice"></kendo-label>
          <div>{{ claim?.invoice }}</div>
        </div>
        <div class="acb-view-field" *ngIf="claim?.fundIsVenueAware">
          <kendo-label text="Benefits Rec."></kendo-label>
          <div>{{ claim?.wereBenefitsReceived ? 'Yes' : 'No' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Claim Date"></kendo-label>
          <div>{{ claim?.creationDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Received Date"></kendo-label>
          <div>{{ claim?.receivedDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Start Date"></kendo-label>
          <div>{{ claim?.startDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="End Date"></kendo-label>
          <div>{{ claim?.endDate | date: 'M/d/yyyy' }}</div>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['./print-claim-details.component.scss']
})
export class PrintClaimDetailsComponent implements OnInit, OnDestroy {

  @Input() public claim?: ClaimWithDetailsModel | null;
  @Input() public doShowLessForReview: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  getStatusColor(claim?: ClaimWithDetailsModel | null) {
    switch (claim?.statusCodeID as StatusCode) {
      case StatusCode.Paid:
      case StatusCode.Partialpaid:
          return '#00AE44';
      case StatusCode.Denied:
        return '#9B1A2F';
      case StatusCode.Inprocess:
        return '#0063b7';
      case StatusCode.Closed:
        return '#FF6B12';
      case StatusCode.Canceled:
      case StatusCode.Void:
        return '#9B1A2F';
      default:
        return '#484844';
    }
  }

  getPaidStyle(claim?: ClaimWithDetailsModel | null) {
    return claim?.paidAmount ? { color: '#00AE44', 'font-weight': 'bold' }  : {};
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
