<kendo-grid 
    #resultsGrid 
    sandboxFileUploadSearchBinding 
    [pageable]="{
        buttonCount: 4,
        pageSizes: true
        }" 
    [sortable]="true" 
    [reorderable]="true" 
    [pageSize]="5" 
    [selectable]="true"
    [resizable]="true"    
    [(selectedKeys)]="selectedKeys" 
    [selectable]="{
        enabled: true,
        mode: 'single'
        }" 
    (selectionChange)="onSelectedChange($event)" 
    kendoGridSelectBy="alconSandboxFileUploadID"
    class="acb-results-grid" 
    (beforeBind)="updateFilter"
    >
    <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlexAlign="center" class="acb-toolbar-adjacent">
                <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
                <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'"
                    fxFlexOffset="1em">Reset Grid</button>
            </div>
            <div fxFlex='1.25 1 auto' fxFlex.xs='.75 1 auto'></div>
            <kendo-toolbar [overflow]="true" fxFlex="0 1 35em" fxFlex.xs="0 0 4em" [fxHide]="!hasSelected"
                [ngStyle.xs]="{'margin':'0'}">
                <!-- <kendo-toolbar-button [text]="'Clear (' + this.selectedKeys?.length?.toString() + ')'" [icon]="'apply-format'" (click)="onClearButtonClick()" [className]="'acb-toolbar-button-clear'" ></kendo-toolbar-button>
                        <kendo-toolbar-button [ text]="'Activate'" (click)="onActivateSelected()" [icon]="'checkmark'" [className]="'acb-good'"></kendo-toolbar-button>
                        <kendo-toolbar-button [text]="'Deactivate'" (click)="onDeactivateSelected()" [icon]="'close'" [className]="'acb-warning'"></kendo-toolbar-button> -->
                <!-- <kendo-toolbar-button [text]="'Delete'" (click)="onDeactivateSelected()" [icon]="'delete'" [className]="'acb-danger'"></kendo-toolbar-button> -->
            </kendo-toolbar>
            <div fxFlex='1 1 auto'></div>
            <div fxFlexAlign="center" class="acb-toolbar-adjacent">
                <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export to Excel</button>
            </div>
        </div>
    </ng-template>
    <kendo-grid-command-column [width]="30" [locked]="true" [reorderable]="false" [includeInChooser]="false"
        [resizable]="false" [headerClass]="'acb-grid-command-column-header'">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox />
        </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column title="File Name" field="displayName" [width]="240">
    </kendo-grid-column>
    <kendo-grid-column title="Type" field="resourceType" [width]="80">
    </kendo-grid-column>
    <kendo-grid-column title="Status" field="statusCode" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column title="Cur. Sandbox?" field="isSandboxCurrentLoadedFile" [width]="120" class="acb-user-grid-yesno-column">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="{'acb-user-grid-value-no': !dataItem.isSandboxCurrentLoadedFile  }">{{ dataItem.isSandboxCurrentLoadedFile ? 'Yes' : 'No'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Created By" field="createdByPerson" [width]="180">
    </kendo-grid-column>    
    <kendo-grid-column title="Created" field="creationDate" [width]="168" class="acb-grid-column-date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.creationDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Sandbox By" field="LastSandboxLoadPerson" [width]="180" [hidden]="true">
    </kendo-grid-column>    
    <kendo-grid-column title="Sandbox Load" field="lastSandboxLoadDate" [width]="168" class="acb-grid-column-date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.lastSandboxLoadDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Prod By" field="LastProductionLoadPerson" [width]="180" [hidden]="true">
    </kendo-grid-column>    
    <kendo-grid-column title="Prod Load" field="lastProductionLoadDate" [width]="168" class="acb-grid-column-date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.lastProductionLoadDate | date: 'MM-dd-yyyy&nbsp;&nbsp;hh:mm:ss a'}}
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes'
            [pageSizes]="[5,10,20,40]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="Funds.xlsx" [fetchData]="allData">
    </kendo-grid-excel>

</kendo-grid>