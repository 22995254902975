import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PayTypeForDropdownModel } from '@alcon-db-models/PayTypeForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class PayTypeForDropdownService extends EntityCollectionServiceBase<PayTypeForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('PayTypeForDropdown', serviceElementsFactory);
  }
}
