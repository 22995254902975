import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SelectSearchBaseService } from './../services/select-search-base';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppUxService } from '@services/app-ux.service';
import { getErrorMessage } from '../shared/acb-stream';

@Directive({
  selector: '[selectSearchBindingBase]'
})
export abstract class SelectSearchBindingBaseDirective extends DataBindingDirective implements OnInit, OnDestroy, SelectSearchBinding {

  @Output() beforeBind: EventEmitter<any> = new EventEmitter();
  @Output() dataChange: EventEmitter<GridDataResult> = new EventEmitter();

  @Input() public doInitResults: boolean = true;

  private serviceSubscription?: Subscription;
  private initialFetchPhaseComplete = false;

  constructor(private selectSearchBaseService: SelectSearchBaseService, grid: GridComponent, private appUxService: AppUxService) {
    super(grid);
  }

  public ngOnInit(): void {
    this.serviceSubscription = this.selectSearchBaseService.pipe(catchError(err => {
      const errorMessage = getErrorMessage(err);
      return [];
    })).subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.dataChange.emit(result);
    });
    this.grid.data = [];
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    if (this.initialFetchPhaseComplete || this.doInitResults) {
      setTimeout(() => {
        this.grid.loading = true;
        this.selectSearchBaseService?.query(this.getSearchRequest());
      });
    }
    this.initialFetchPhaseComplete = true;
  }

  public abstract getSearchRequest(): any;

}

export interface SelectSearchBinding {
  doInitResults: boolean;
}

