import { FilterSpecModel } from './FilterSpecModel';//2
import { DashboardType } from './Enums';//1


export class DashboardForDropdownModel  {
    dashboardID?: number | null;
    dashboardType?: DashboardType | null;
    code?: string | null;
    displayName?: string | null;
    filterSpec?: FilterSpecModel | null;
}

 
     
         
