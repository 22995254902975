import { DashboardItemForDashboardModel } from '@alcon-db-models/DashboardItemForDashboardModel';
import { DashboardReportModel } from '@alcon-db-models/DashboardReportModel';
import { DashboardReportRequestModel } from '@alcon-db-models/DashboardReportRequestModel';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ChartComponent, LegendItemVisualArgs, RenderEvent, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { DashboardReportService } from '@services/dashboard-report.service'
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { delay, finalize, first, map, switchMap, take, takeUntil, throttle } from 'rxjs/operators';
import { Text, Group, geometry, Element, Circle as CircleShape } from '@progress/kendo-drawing';
import { ViewChild } from '@angular/core';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { GridstackItemComponent } from 'gridstack/dist/angular';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppWindowService } from '@services/app-window.service';
import { DashboardMessageModel } from '@alcon-db-models/DashboardMessageModel';
import { formatNumber } from '@progress/kendo-intl';
import * as i1$5 from '@progress/kendo-angular-intl';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';
import { CldrIntlService } from '@progress/kendo-angular-intl';
import { Store } from '@ngrx/store';
import { selectImpersonationStatus } from '@app-store/app-session/app-session.selectors';
import { Router } from '@angular/router';

const { Point, Rect, Size, Circle } = geometry;
type ColumnActionType = "view_commitment" | "view_claim";

@Component({
  selector: 'acb-alcon-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardWidgetComponent implements OnInit, OnDestroy {

  @HostBinding('class') get className() { return this.isLoading ? 'acb-dashboard-widget-is-loading' : 'acb-dashboard-widget-is-ready'; }

  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('chart') chart?: ChartComponent;
  @ViewChild('widgetInnerWrapper') widgetInnerWrapper?: ElementRef;
  @ViewChild('widgetToolPopupButton') widgetToolPopupButton?: ElementRef;
  @ViewChild('widgetToolPopupAnchor') widgetToolPopupAnchor?: ElementRef;
  @ViewChild('widgetToolPopup') widgetToolPopup?: ElementRef;

  @Output() widgetSelected: EventEmitter<DashboardWidgetComponent> = new EventEmitter();

  private _dashboardItem: DashboardItemForDashboardModel | null = null;
  @Input() public set dashboardItem(value: DashboardItemForDashboardModel | null) {
    this._dashboardItem = value;
    this.additionalParameters = this.dashboardItem?.additionalParameters ? JSON.parse(this.dashboardItem.additionalParameters) : null;
    this.additionalParametersSpec = this.dashboardItem?.additionalParametersSpec ? JSON.parse(this.dashboardItem.additionalParametersSpec) : null;
    this.rowGroup = this.dashboardItem?.rowGroup ?? undefined;
    this.rowHighlight = this.dashboardItem?.rowHighlight ? JSON.parse(this.dashboardItem?.rowHighlight) : undefined;

    var spec = this.dashboardItem!.drillThroughUrlTemplate! ? JSON.parse(this.dashboardItem!.drillThroughUrlTemplate!) ?? undefined : undefined;
    this.drillThroughSpec = (spec?.field && spec?.action) ? spec : undefined;

    const cols = this.dashboardItem?.columnsSpec ? JSON.parse(this.dashboardItem?.columnsSpec) as any[] : [];
    if (cols?.length && spec?.field) {
      const col = cols.find(x => x.field == spec.field);
      if (col) col.drillThroughSpec = spec;
    }
    this.columns = cols;
  }
  public get dashboardItem() { return this._dashboardItem; }

  private _dito: any;
  private _options$: BehaviorSubject<DashboardWidgetOptions> = new BehaviorSubject(new DashboardWidgetOptions());
  @Input() public set options$(value: BehaviorSubject<DashboardWidgetOptions>) {
    this._options$ = value;
    this.options$.pipe(takeUntil(this.destroy$), throttle(() => interval(500))).subscribe(x => {
      this.canDelete = x.canDelete;
      if (this.dashboardItem) {
        switch (this.dashboardItem.presentationType) {
          case 'messagecenter':
            this.loadMessageData();
            break;
          case 'chart':
            this.loadSeriesData();
            break;
          case 'grid':
            this.loadGridData();
            break;
        }
      }
    });
  }
  public get options$() { return this._options$; }

  // private _isLocked: boolean = true;
  // @Input() public set isLocked(value) {

  //   this._isLocked = value
  // }
  public get isLocked() { return this.dashboard.isLocked ?? true }

  public doShowTopLegend: boolean = true;
  public doShowRightLegend: boolean = true;
  public doShowAxis: boolean = true;
  public doShowWidgetToolPopup: boolean = false;
  public isSelected: boolean = false;
  public isLoading: boolean = true;
  public isChartReady: boolean = false;
  public activeToolType: WidgetToolType = 'filter'
  public canDelete: boolean = false;

  public get isToolPopupShowing(): boolean { return !!this.popupRef };

  private popupRef?: PopupRef;
  private maxLegendLabelCharLength: number = 18;
  private minLegendLabelChartWidth: number = 300;
  private minLegendLabelChartHeight: number = 300;
  private minAxisChartHeight: number = 200;
  private minAxisChartWidth: number = 300;
  public width: number = this.hostElementRef.nativeElement?.offsetWidth;
  public height: number = this.hostElementRef.nativeElement?.offsetHeight;

  private chartReportResults$: Observable<DashboardReportModel[]> = of([]);
  private gridReportResults$: Observable<any[]> = of([]);
  public messageReportResults$: Observable<DashboardMessageModel[]> = of([]);
  public series$: Observable<any> = of([]);
  public gridData$: Observable<any> = of([]);
  public destroy$: Subject<void> = new Subject<void>();

  public columns: any[] = [];
  public additionalParameters: any;
  public additionalParametersSpec: any[] = [];
  public rowGroup: string | undefined;
  public rowHighlight: { field: string, value: any } | undefined;
  public drillThroughSpec?: { field: string, action: ColumnActionType };

  private isImpersonating: boolean = false;

  constructor(
    public dashboardReportService: DashboardReportService,
    private popupService: PopupService,
    public hostElementRef: ElementRef,
    public dashboard: DashboardComponent,
    public gridstackItemComponent: GridstackItemComponent,
    private appWindowService: AppWindowService,
    public intlService: CldrIntlService,
    private store: Store,
    private router: Router
  ) {

  }

  getWidgetName(): string | null {
    let name = this.dashboardItem?.displayName ?? null;
    try {
      if (this.dashboardItem?.nameTemplate && this.additionalParameters) {
        //TODO: Must be a better way?  Seems clunky, may be a XSS risk.
        const fillTemplate = (s: string): string => new Function("return `" + s + "`;").call(this.additionalParameters);
        name = fillTemplate(this.dashboardItem.nameTemplate);
      }
    } catch (e) {
    } finally {
      //Hack: when under impersonation, replace "My" with "Their".  
      return this.isImpersonating ? name?.replace(/^My/, "Their") ?? null : name;
    }
  }

  //TODO: Clean this up
  toggleWidgetToolPopup(anchor?: ElementRef, template?: TemplateRef<any>) {
    return;
    // if (this.popupRef) {
    //   this.popupRef.close();
    //   this.popupRef = undefined;

    //   //TODO: clean this up
    //   switch (this.dashboardItem?.presentationType) {
    //     case 'messagecenter':
    //       this.loadMessageData();
    //       break;
    //     case 'chart':
    //       this.loadSeriesData();
    //       break;
    //     case 'grid':
    //       this.loadGridData();
    //       break;
    //   }

    // } else if (anchor && template) {

    //   this.activeToolType = this.additionalParametersSpec?.length ? 'filter' : this.columns?.length ? 'columns' : 'options';

    //   this.widgetInnerWrapper?.nativeElement?.scrollIntoView({ block: "nearest", inline: "nearest" });
    //   this.popupRef = this.popupService.open({
    //     anchor: anchor,
    //     content: template,
    //     anchorAlign: { horizontal: 'left', vertical: 'top' },
    //     popupAlign: { horizontal: 'left', vertical: 'top' },
    //     //collision: { horizontal: 'fit', vertical: 'fit'},
    //     popupClass: 'acb-widget-tool-popup',
    //     animate: {
    //       direction: 'up',
    //       type: 'fade',
    //       duration: 100
    //     }
    //   });
    // }
  }

  public chartLegendVisual = ((args: LegendItemVisualArgs): Element => {
    return args.createVisual();
  }).bind(this)

  public pieLegendVisual = ((args: LegendItemVisualArgs): Element => {
    const group = new Group();
    const circle: CircleShape = new CircleShape(new Circle(new Point(0, 11), 4), { stroke: {}, fill: { color: args.options.markers.border.color } });
    let labelText = args.series?.data[args.pointIndex]?.category ?? "";
    //If too long: truncate text, trim all non-alphanumeric characters from the end, and append ellipsis
    labelText = labelText.length > this.maxLegendLabelCharLength ? labelText.substring(0, this.maxLegendLabelCharLength - 2).replace(/[^a-z0-9]+$/i, '') + '...' : labelText;

    const labelFont = args.options.labels.font;
    const fontColor = args.options.labels.color;
    const textOptions = { font: labelFont, fill: { color: fontColor } };
    const text = new Text(labelText, new Point(14, 0), textOptions);

    group.append(circle, text);

    if (!args.active) {
      group.opacity(0.5);
    }
    return group;
  }).bind(this)

  loadMessageData() {
    this.isLoading = true;
    this.messageReportResults$ = this.dashboardReportService.getMessageReport().pipe(first(), finalize(() => {
      setTimeout(() => this.isLoading = false, 500)
    }));
  }

  loadGridData() {
    const o = this.options$.value;
    this.isLoading = true;
    if (this.dashboardItem) {
      const request = {
        ...new DashboardReportRequestModel(),
        fundYears: (o.fundYears.map(x => x.toString()) ?? []).join(","),
        fundIDs: (o.funds.map(x => x.fundID?.toString()) ?? []).join(","),
        territoryIDs: (o.territories.map(x => x.territoryID?.toString()) ?? []).join(","),
        dashboardItemID: this.dashboardItem.dashboardItemID,
        //TODO: Replace with user prefs when applicable
        additionalParameters: this.additionalParameters
      };
      this.gridReportResults$ = this.dashboardReportService.getGridReport(request);
    } else {
      this.gridReportResults$ = of([]);
    }

    //TODO: clean this up
    this.gridData$ = this.gridReportResults$.pipe(first(), map(x => {
      let groupName = this.dashboardItem?.rowGroup;
      let lastGroupValue: undefined | string;
      let gridData = [];
      for (let i = 0; i < x.length; i++) {
        let item = JsonUtilities.convertDatesAndCopy(x[i]);
        item.isGroupRow = false;
        if (groupName && item[groupName] && lastGroupValue != item[groupName]) {
          lastGroupValue = item[groupName];
          let groupItem = JsonUtilities.convertDatesAndCopy(item);
          // Clear all fields but the group field on the group "header"
          for (const key in groupItem) {
            if (key != groupName) {
              groupItem[key] = null;
            }
          }
          groupItem.isGroupRow = true;
          gridData.push(groupItem)
        }
        gridData.push(item);
      }
      return gridData;
    }), finalize(() => setTimeout(() => this.isLoading = false, 500)));

    //this.gridData$.pipe(first()).subscribe();


  }

  loadSeriesData() {
    if (!this.dashboardItem) return;
    let delayMS = 0;
    const o = this.options$.value;
    let reportType = 'columns';
    switch (this.dashboardItem?.dashboardItemType) {
      case 'columnchart':
        reportType = 'column';
        break;
      case 'areachart':
        reportType = 'area';
        break;
      case 'donutchart':
        delayMS = 0;//Math.random() * (1500 - 250) + 250;
        reportType = 'donut';
        break;
    }

    this.isLoading = true;
    this.isChartReady = false;

    this.series$ = this.dashboardReportService.getChartReport({
      ...new DashboardReportRequestModel(),
      fundYears: (o.fundYears.map(x => x.toString()) ?? []).join(","),
      fundIDs: (o.funds.map(x => x.fundID?.toString()) ?? []).join(","),
      territoryIDs: (o.territories.map(x => x.territoryID?.toString()) ?? []).join(","),
      dashboardItemID: this.dashboardItem.dashboardItemID
    }).pipe(
      first(),
      //HACK: This delay "fixes" donut point error. Find a better way.
      delay(delayMS),
      map(x => {
        this.isLoading = false
        const series = [...(new Set(x.map(y => y.series)))].map(y => ({
          name: y,
          data: [{}],
          type: reportType
        }));
        series.forEach(y => {
          y.data = x.filter(z => z.series === y.name).map(z => ({ ...z }));
        });
        return series;
      }),
      finalize(() => setTimeout(() => this.isLoading = false, 500)) //false)
    );

    //this.series$.pipe(first()).subscribe();
  }

  public getRowClass(args: RowClassArgs): string {
    let c =
      (args.dataItem.isGroupRow ? 'acb-dashboard-grid-group-row' : '') +
      (args.dataItem.IsHightlightRowType1 ? ' acb-dashboard-grid-highlight-row-1' : '') +
      (args.dataItem.IsHightlightRowType2 ? ' acb-dashboard-grid-highlight-row-2' : '');

    return c;
  }

  public getIsDrillthroughColumn(field: string) {
    return (field == this.drillThroughSpec?.field && this.drillThroughSpec?.action);
  }

  ngOnInit(): void {
    this.dashboardItem = { ...(this.gridstackItemComponent?.options as any)?.item };
    this.options$ = this.dashboard.dashboardWidgetOptions$;

    this.store.select(selectImpersonationStatus).pipe(take(1)).subscribe(x => {
      this.isImpersonating = x.isImpersonating;
    });

  }

  //private crTOID: any;
  onChartRender(event: RenderEvent) {
    //if (this.crTOID) clearTimeout(this.crTOID);
    //this.crTOID = setTimeout(() => this.isChartReady = !this.isLoading, 10);
    if (this.isLoading) return;
    this.isChartReady = true;
    const w: number | null = event.sender?.instance?.element?.offsetWidth ?? null;
    const h: number | null = event.sender?.instance?.element?.offsetHeight ?? null
    this.doShowRightLegend = w ? w > this.minLegendLabelChartWidth : true;
    this.doShowTopLegend = h ? h > this.minLegendLabelChartHeight : true;
    this.doShowAxis = (w ? w > this.minAxisChartWidth : true) && (h ? h > this.minAxisChartHeight : true);
  }

  public onWidgetToolSelectedChange(event: any, toolType: WidgetToolType) {
    this.activeToolType = toolType;
  }

  public onCloseWidget() {
    if (this.dashboardItem?.dashboardDashboardItemID)
      this.dashboard.removeWidgetByID(this.dashboardItem.dashboardDashboardItemID);
  }

  @HostListener("click") onClick = (() => {
    this.widgetSelected.emit(this);
  }).bind(this)

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27) {
      this.toggleWidgetToolPopup();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggleWidgetToolPopup();
    }
  }

  private contains(target: any): boolean {
    return this.widgetToolPopup?.nativeElement?.contains(target)
      || this.widgetToolPopupButton?.nativeElement?.contains(target);
    //|| this.widgetToolPopupAnchor?.nativeElement?.contains(target);
  }

  private onViewClaim(claimID: number) {
    this.appWindowService.openViewClaim(claimID);
  }

  private onViewCommitment(commitmentID: number) {
    this.appWindowService.openViewCommitment(commitmentID);
  }

  public onColumnValueClicked(col: any, dataItem: any) {
    if (this.drillThroughSpec?.field == col?.field && this.drillThroughSpec?.action) {
      switch (this.drillThroughSpec.action) {
        case 'view_claim':
          this.onViewClaim(dataItem[col.field]);
          break;
        case 'view_commitment':
          this.onViewCommitment(dataItem[col.field]);
          break;
      }
    } 


    // if (dataItem.name == this.drillThroughSpec?.column && this.drillThroughSpec?.action) {
    //   switch (this.drillThroughSpec.action) {
    //     case 'view_claim':
    //       break;
    //     case 'view_commitment':
    //       this.onViewCommitment(dataItem)
    //       break;
    //   }
    // }

  }

  public onSeriesClick(event: SeriesClickEvent) {

    if (!event.dataItem?.drillthroughSubjectID)
      return;

    const options = this.options$.value;
    console.debug('onSeriesClick', event);
    // TODO: Must support /claims/view for readonly!
    this.router.navigateByUrl('/claims/manage', {
      state: {
        seriesCategory: event.category,
        drillthroughSubjectID: event.dataItem?.drillthroughSubjectID,
        drillthroughSubject: event.dataItem?.drillthroughSubject,
        fundYears: options?.fundYears,
        funds: options?.funds,
        territories: options?.territories
      }});
  }


  ngOnDestroy() {
    if (this._dito) clearTimeout(this._dito);
    this.destroy$.next();
    this.destroy$.complete();
  }

}

export class DashboardWidgetOptions {
  fundYears: number[] = [];
  funds: FundForDropdownModel[] = [];
  territories: TerritoryForDropdownModel[] = [];
  canDelete: boolean = false;
}

export type WidgetToolType = 'filter' | 'columns' | 'options';
