import { CommitmentDetailAttachmentModel } from "@alcon-db-models/CommitmentDetailAttachmentModel";
import { CommitmentDetailPhaseModel } from "@alcon-db-models/CommitmentDetailPhaseModel";
import { CommitmentUpsertRequestModel } from "@alcon-db-models/CommitmentUpsertRequestModel";
import { CommitmentWithDetailsModel } from "@alcon-db-models/CommitmentWithDetailsModel";
import { DraftFileUploadModel } from "@alcon-db-models/DraftFileUploadModel";
import { DraftModel } from "@alcon-db-models/DraftModel";
import { DraftWithDetailsModel } from "@alcon-db-models/DraftWithDetailsModel";
import { AttachmentRole, DraftType, StatusCode, VisibilityType } from "@alcon-db-models/Enums";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { selectCurrentPerson } from "@app-store/app-session/app-session.selectors";
import { getGuid } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { from, Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, first, takeUntil, map, finalize, tap, } from "rxjs/operators";
import { ServiceResponse } from "../shared/acb-stream";
import { JsonUtilities } from "../shared/json-utilities";
import { Utilities } from "../shared/utilities";
import { CommitmentUpsertServiceService } from "./commitment-upsert-service.service";
import { CommitmentWithDetailsService } from "./commitment-with-details.service";
import { DraftWithDetailsService } from "./draft-with-details.service";

@Injectable()
export abstract class CommitmentSubjectBaseService implements OnDestroy {

  private _commitmentID: number | undefined;
  public set commitmentID(value: number | undefined) {
    this._commitmentID = value;
    if (this._commitmentID) {
      this.commitmentWithDetailsService.getByKey(this._commitmentID).pipe(first()).subscribe(x => {
        const commitment = this.createCommitment(JsonUtilities.convertDatesAndCopy(x) as CommitmentWithDetailsModel);
        this.commitment$.next(commitment);
      });
    } else {
      this.commitment$.next(this.createCommitment());
    }
  };
  public get commitmentID():number | undefined {
    return this._commitmentID;
  }

  public readonly commitment$ = new ReplaySubject<CommitmentWithDetailsModel>(1);//new BehaviorSubject(this.createCommitment());
  public loading$ = new Subject<boolean>();
  public saving$ = new Subject<boolean>();
  public error$ = new Subject<any>();

  protected _destroy$ = new Subject<void>();
  protected _commitment?: CommitmentWithDetailsModel;

  constructor(
    protected commitmentWithDetailsService: CommitmentWithDetailsService,
    private store:Store,
    private commitmentUpsertServiceService: CommitmentUpsertServiceService,
    private draftWithDetailsService: DraftWithDetailsService,
  ) {

    this.commitment$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      const commitment = JsonUtilities.convertDatesAndCopy(x) as CommitmentWithDetailsModel;

      //TODO:  Ugh, this is a hack to init phases for create commitment (and assumes 1 phase year).  Clean up
      if (commitment.startDate?.getFullYear) {
        if (!commitment.phases?.length) {
          commitment.phases = [{
            yearIncrement: 0,
            year: commitment.startDate!.getFullYear(),
            amounts: new Array(12).fill(0)
          }];

          this.commitment$.next(commitment);
        } else if (commitment.phases[0].year != commitment.startDate.getFullYear()) {
          commitment.phases[0].year = commitment.startDate.getFullYear();
          commitment.phases[0].yearIncrement = 0;

          this.commitment$.next(commitment);
        }
      }
      this._commitment = commitment;
    });

    this.commitmentWithDetailsService.loading$.pipe(takeUntil(this._destroy$)).subscribe(this.loading$);
  }

  protected createCommitment(commitment?:CommitmentWithDetailsModel): CommitmentWithDetailsModel {
    commitment = commitment ?? new CommitmentWithDetailsModel();
    commitment.code = commitment.code ?? getGuid().replace('-','');
    if (!commitment.phases?.length) {
      commitment.phases = [this.createDefaultPhase()];
    }
    return commitment;
  }

  protected createDefaultPhase(): CommitmentDetailPhaseModel {
    return {
      yearIncrement: 0,
      amounts: new Array(12).fill(0)
    }
  }

  public static createCommitmentFromDraft(draft: DraftWithDetailsModel) : CommitmentWithDetailsModel | null {
    let commitment: CommitmentWithDetailsModel = draft.draftBody ? JsonUtilities.convertDatesAndCopy(JSON.parse(draft.draftBody))?.commitment : null;

    commitment.attachments = draft.draftFileUploads?.map(x => ({
      commitmentDetailAttachmentID: null,
      commitmentDetailID: null,
      commitmentID: null,
      code: x.resourceCode,
      displayName: x.displayName,
      attachmentRole: AttachmentRole.Other,
      resourceType: x.resourceTypeID,
      fileSize: x.fileSize,
      internalFileName: x.internalFileName,
      visibilityType: VisibilityType.Visible,
      statusCode: StatusCode.Draft
    }));

    return commitment;
  }

  public static cleanCommitment(commitment: CommitmentWithDetailsModel) : CommitmentWithDetailsModel {

    commitment = JsonUtilities.convertDatesAndCopy(commitment);

    //TODO: flip this to copy only nessessary values instead of all and then clear?

    commitment.code = null;
    commitment.commitmentDetailStatusID = null;
    commitment.commitmentID = null;
    commitment.creationDate = null;
    commitment.createdByPersonID = null;
    commitment.createdByPerson = null;
    commitment.modifiedByPersonID = null;
    commitment.modifiedByPerson = null;
    commitment.modifiedDate = null;
    commitment.notificationRecipientEmailAddress = null;
    commitment.fundingTerritory = null;
    commitment.fundingTerritoryCode = null;
    commitment.fundingTerritoryID = null;
    commitment.fundingTerritoryPerson = null;
    commitment.fundingTerritoryPersonID = null;
    commitment.fundingTerritorySuggestedName = null;

    commitment.phases?.forEach(x => {
      x.commitmentID = null,
      x.commitmentDetailID = null,
      x.commitmentDetailPhaseID = null
    });
    commitment.products?.forEach(x => {
      x.commitmentID = null,
      x.commitmentDetailID = null,
      x.commitmentDetailProductID = null
    });
    commitment.attachments?.forEach(x => {
      x.commitmentID = null,
      x.commitmentDetailID = null,
      x.commitmentDetailAttachmentID = null
    });
    commitment.history = [];
    commitment.actuals = [];

    return commitment;
  }

  public createDraftCommitment(commitment: CommitmentWithDetailsModel, name?: string, description?: string, draftID?: string) : Promise<DraftWithDetailsModel|null|undefined> {

    return !draftID ? Promise.resolve(null) : this.draftWithDetailsService.getDraftWithDetail(draftID)
      .then((oldDraft: DraftWithDetailsModel | null|undefined) => {

        const draft : DraftWithDetailsModel = {
          draftID: draftID,
          draftTypeID: DraftType.Commitment,
          displayName: name ?? `Draft for ${commitment.customer ?? 'UNKNOWN'} (${(new Date()).toLocaleDateString('en-US')})`,
          displayDescription: description,
          draftBody: JSON.stringify({
            commitment: CommitmentSubjectBaseService.cleanCommitment(commitment)
          })
        };

        draft.draftFileUploads = oldDraft?.draftFileUploads?.filter(x => commitment.attachments?.some(y => y.code == x.resourceCode)) ?? [];
        draft.draftFileUploads.push(
          ...commitment.attachments?.filter(x => x.statusCode == StatusCode.Processing || !oldDraft?.draftFileUploads?.some(y => y.resourceCode = x.code)).map(x => ({
            draftID: draftID,
            code: getGuid(),
            resourceCode: x.code,
            displayName: x.displayName,
            resourceTypeID: x.resourceType,
            internalFileName: x.internalFileName,
            fileSize: x.fileSize
          }) as DraftFileUploadModel) ?? []
        );

        return draft;
      })
      .catch((x:HttpErrorResponse) => {
        return null;
      });
  }

  public SaveCommitment(statusCode?: StatusCode, draftID?: string | null | undefined, doShowOverlay: boolean = true): Observable<ServiceResponse<number>> {

    let personID: number | null = null;
    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      personID = x?.personID ?? null
    })

    this.saving$.next(true);

    if (!this._commitment) {
      const msg = "No commitment";
      this.saving$.next(false);
      this.error$.next(msg)
      return of({ hasError: true, errorMessage: [msg], response: null });
    }

    const commitment: CommitmentUpsertRequestModel = {
      personID: personID,
      commitmentID: this._commitment.commitmentID,
      customerID: this._commitment.customerID,
      payeeCustomerID: this._commitment.payeeCustomerID,
      fundID: this._commitment.fundID,
      territoryID: this._commitment.territoryID,
      activityID: this._commitment.activityID ?? null,
      startDate: this._commitment.startDate,
      endDate: this._commitment.endDate,
      event: this._commitment.event,
      venue: this._commitment.venue,
      venueCity: this._commitment.venueCity,
      venueStateProvinceCodeID: this._commitment.venueStateProvinceCodeID,
      estimatedAttendeeCount: this._commitment.estimatedAttendeeCount,
      amount: this._commitment.amount,
      attachments: this._commitment.attachments,
      phases: this._commitment.phases,
      products: this._commitment.products,
      comments: this._commitment.comments,
      draftID: draftID
    };

    if (statusCode) commitment.statusCodeID = statusCode;


    // return this.userInfoService.upsert(user).pipe(
    //   firstWithLoadingOverlayAndErrorHandling<UserInfoModel>(),
    //   map(x => {
    //     if (x.response?.applicationUserPersonID) this.user$.next(x.response);
    //     this.error$.next(x.errorMessage);
    //     return x;
    //   }),
    //   finalize(() => {
    //     this.loading$.next(false);
    //     this.saving$.next(false);
    //   })
    // );

    return this.commitmentUpsertServiceService.upsertCommitment(commitment,doShowOverlay).pipe(
      tap(x => {
        if (x.hasError) {
          this.error$.next(x.errorMessage);
        } else {
          commitment.commitmentID = commitment.commitmentID ?? x.response;
          this.commitment$.next(commitment)
          this.error$.next(null);
        }
      }),
      finalize(() => {
        this.loading$.next(false);
        this.saving$.next(false);
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
