import { ClaimSearchRequestModel } from '@alcon-db-models/ClaimSearchRequestModel';
import { CommitmentApprovalRuleSearchRequestModel } from '@alcon-db-models/CommitmentApprovalRuleSearchRequestModel';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ClaimSearchService } from '@services/claim-search.service';
import { CommitmentApprovalRuleSearchService } from '@services/commitment-approval-rule-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[commitmentApprovalRuleSearchBindingDirective]'
})
export class CommitmentApprovalRuleSearchBindingDirective extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  @Input() preferenceKey: string = "CommitmentApprovalRuleSearch";

  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public commitmentApprovalRuleSearchService: CommitmentApprovalRuleSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.commitmentApprovalRuleSearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.commitmentApprovalRuleSearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.commitmentApprovalRuleSearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): CommitmentApprovalRuleSearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      approvalRuleTypeID: getValue('approvalRuleTypeID'),
      territoryIDs: getValue('territoryIDs'),
      customerCode: getValue('customerCode'),
      customerName: getValue('customerName'),
      fundYears: getValue('fundYears'),
      fundIDs: getValue('fundIDs'),
      personName: getValue('personName'),
      statusCodeID: getValue('statusCodeID'),
    });
  }
}
