import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'acb-alcon-request-date',
  template: `
    <kendo-dialog-titlebar class="acb-request-name-titlebar" (close)="onCancel()">
      {{title}}
    </kendo-dialog-titlebar>
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <div fxLayout="column" fxLayoutGap=".5em" fxLayoutAlign="start start">
          <kendo-formfield fxFlex style="width: 20em">
            <kendo-label [for]="date" text="Date" [optional]="true"></kendo-label>
            <kendo-datepicker
              formControlName="date"
            #date
            autocomplete="off"
            [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
            >
            </kendo-datepicker>
          </kendo-formfield>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" >Save</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-date.component.scss']
})
export class RequestDateComponent extends DialogContentBase implements OnInit {

  @Input() title?: string = 'Date';
  @Input() dateLabel?: string = 'Date';
  @Input() dateValue?: Date;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<{date: Date}> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    date: new UntypedFormControl()
  });

  constructor(public dialog : DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {
    this.form.patchValue({
      date: this.dateValue,
    })
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {
    this.save.emit(this.form.value);
  }


}
