import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'acb-alcon-request-confirmation',
  template: `
    <kendo-dialog-titlebar [ngClass]="titleClass" (close)="onNo()" >
      {{ title }}
    </kendo-dialog-titlebar>
    <acb-alcon-section-with-legend [doShowLegend]="false" [ngClass]="sectionWithLegendClass" [innerHtml]="body">
    </acb-alcon-section-with-legend>
    <kendo-dialog-actions>
    <button kendoButton [primary]="makeYesTheDefault" (click)="onYes()" class="acb-cancel" >Yes</button>
    <button kendoButton [primary]="!makeYesTheDefault" (click)="onNo()" >No</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-confirmation.component.scss'],
})
export class RequestConfirmationComponent extends DialogContentBase implements OnInit {

  @Input() title?: string = 'Confirmation';
  @Input() body?: string = 'Are you sure?';
  @Input() titleClass: string = '';
  @Input() sectionWithLegendClass: string = "acb-section-05";
  @Input() makeYesTheDefault: boolean = false;

  @Output() no: EventEmitter<any> = new EventEmitter();
  @Output() yes: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    description: new UntypedFormControl(),
  });

  constructor(public dialog : DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {
  }

  public onNo() {
    this.no.emit(null);
  }

  public onYes() {
    this.yes.emit(this.form.value);
  }

}
