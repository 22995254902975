
import { TokenInfoModel } from '@alcon-db-models/TokenInfoModel';
import { ClientStorageService } from '@data-services/client-storage.service';


export const appPersistedSettingsFeatureKey = 'AppPersistedSettings';
export const appPersistedSettingsLocalStorageKey = appPersistedSettingsFeatureKey;


export interface AppPersistedSettingsState {
  accessTokenInfo?: TokenInfoModel | null;
}

export const initialState: AppPersistedSettingsState = {
  accessTokenInfo: null
};


export function appPersistedSettingsIntialStateFactory(clientStorageService: ClientStorageService) {
  var localValues = clientStorageService.getItem(appPersistedSettingsFeatureKey);
  return { [appPersistedSettingsFeatureKey]: {...initialState, ...localValues} };
};
