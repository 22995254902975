import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentEditService } from '@services/commitment-edit.service';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { CommitmentSubjectBaseService } from '@services/commitment-subject-base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { EditCommitmentHostComponent } from './edit-commitment-host.component';

export type NotificationType = 'approvedMoneyWarning' | 'yearMismatchValidationError' | 'endDateValidationError' | 'territoryValidationError' | 'productsValidationError' | 'phasingValidationError';

@Component({
  selector: 'acb-alcon-edit-commitment-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-titlebar" #dialogTitleBar (close)="onClose()">
      Edit Commitment
    </kendo-dialog-titlebar>
    <acb-alcon-edit-commitment-host #editCommitment [fxHide]="(loading$ | async) || (saving$ | async)" >
    </acb-alcon-edit-commitment-host>
    <kendo-dialog-actions [fxHide]="(loading$ | async) || (saving$ | async)">
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!valid"><span class="k-icon k-i-save" ></span>Save</button>
    </kendo-dialog-actions>
    <div class="acb-local-loading-indicator acb-opaque-loading-indicator" *ngIf="(loading$ | async) || (saving$ | async)"><span class="k-icon k-i-loading"></span></div>
  `,
  styleUrls: ['./edit-commitment-dialog.component.scss'],
  providers: [
    CommitmentEditService,
    {
      provide: CommitmentFormBaseService,
      useExisting: CommitmentEditService
    },
    {
      provide: CommitmentSubjectBaseService,
      useExisting: CommitmentEditService
    }
  ]
})
export class EditCommitmentDialogComponent extends DialogContentBase implements OnInit, OnDestroy {

  @ViewChild('productsValidationErrorTemplate', { read: TemplateRef }) public productsValidationErrorTemplate?: TemplateRef<any>;
  @ViewChild('editCommitment') public editCommitment?: EditCommitmentHostComponent;

  private _commitmentID?: number;
  @Input() public set commitmentID(value: number | undefined) {
    this._commitmentID = value;

  };
  public get commitmentID() { return this._commitmentID; };

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() notification: EventEmitter<NotificationType> = new EventEmitter();

  showCommitmentFundingChangeNotificationPopup: boolean = false;

  public valid: boolean = true;
  public loading$: Subject<boolean> = new BehaviorSubject<boolean>(true);
  public saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private commitmentEditService: CommitmentEditService,
    private changeDetectionRef: ChangeDetectorRef,
    private appUxService: AppUxService,
    public dialog: DialogRef,
    store: Store
    ) {
    super(dialog);

    store.select(selectBusinessRules).pipe(first()).subscribe(x => this.showCommitmentFundingChangeNotificationPopup = x.ShowCommitmentFundingChangeNotificationPopup);

    commitmentEditService.loading$.pipe(takeUntil(this.destroy$)).subscribe(this.loading$);
    commitmentEditService.saving$.pipe(takeUntil(this.destroy$)).subscribe(this.saving$);
  }

  ngOnInit(): void {
    this.commitmentEditService.commitmentID = this._commitmentID;
    this.commitmentEditService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {

      const elm = (this.dialog.dialog?.instance as any)?._elRef?.nativeElement;
      if (elm) {
        const classes:DOMTokenList = elm.classList;
        if (classes && classes.add) {
          classes.add('acb-dialog-with-notifications');
        }
      }
      this.valid = x.form;
      if (x.products) {
        this.notification.emit('productsValidationError');
      }
      this.changeDetectionRef.detectChanges();
    });
  }

  public onCancel() {
    this.close.emit(null);
  }

  public onClose() {
    this.close.emit(null);
  }

  public onSave() {

    const doSave = () => {
      this.commitmentEditService.SaveCommitment(undefined, undefined, false).subscribe(x => {
        if (x.hasError) {
          this.appUxService.openErrorDialog(x.errorMessage)
        } else {
          this.saved.emit(null);
          this.close.emit(null);
        }
      })
    }

    let commitment: CommitmentWithDetailsModel | undefined;
    this.commitmentEditService.commitment$.pipe(first()).subscribe(x => {
      commitment = x;
    });
    if (!commitment)
      return;

    if (
      this.showCommitmentFundingChangeNotificationPopup &&
      this.editCommitment?.isFundingChangePending &&
      commitment.fundingTerritoryID &&
      commitment.territoryID != commitment.fundingTerritoryID
      ) {

      const confirmBody = "Altering funding on this commitment will return money to " +
        '<strong>' + commitment.fundingTerritoryCode + ' - ' + commitment.fundingTerritoryPerson + '</strong>' +
        ' and new funds will come from ' +
        '<strong>' + commitment.territoryCode + ' - ' + commitment.territoryPerson  + '</strong>' +
        ' (or parent territory).  <br><br> Would you like to proceed?';

      this.appUxService.openConfirmation(
        "Funding Change",
        confirmBody,
        undefined,
        'auto',
        'alcon-background-orange',
        'acb-section-09').then((confirm) => {
          if (confirm) doSave();
        });

    } else {
      doSave();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
