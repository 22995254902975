<button #anchor (click)="show = !show" class="k-button"></button>
<kendo-popup [anchor]="$any(anchor)" (anchorViewportLeave)="show = false" *ngIf="show">
<kendo-treeview
  *ngIf="nodes && textField && childrenField"
  #treeview
  [nodes]="nodes"
  [textField]="textField"
  [childrenField]="childrenField"
  [selectBy]="'featureID'"
  [expandBy]="'featureID'"
  kendoTreeViewExpandable
  kendoTreeViewSelectable
  kendoTreeViewHierarchyBinding
  (selectionChange)="onSelection($event)"
  [(selectedKeys)]="selectedKeys"
  [(expandedKeys)]="expandedKeys"
>
</kendo-treeview>
</kendo-popup>
