import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromState from './app-session.state'
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';
import { JsonUtilities } from 'src/app/shared/json-utilities';

export const selectAppSessionState = createFeatureSelector<fromState.AppSessionState>(fromState.appSessionFeatureKey);

export const selectIsReady = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state.isReady : false )
);

export const selectMemoryOnlyRefreshToken = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state.memoryOnlyRefreshToken : null)
);

export const selectAccessPerson = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state.accessPerson : null)
);

export const selectCurrentPerson = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state.currentPerson : null)
);

export const selectLastError = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state.lastError : null)
);

export const selectDoKeepMeLoggedIn = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.doKeepMeLoggedIn ?? false : false)
);

export const selectFeatureMap = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.featureMap : [])
);

export const selectReports = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.reports : [])
);

export const selectThemes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.themes : [])
);

export const selectStaticTypes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.staticTypes : [])
);

export const selectTransferDepositTypes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.transferDepositTypes ?? [] : [])
);


export const selectPullbackDepositTypes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.pullbackDepositTypes ?? [] : [])
);

export const selectTeams = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.teams : [])
);

export const selectStatusCodesWithGroups = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.statusCodesWithGroups : [])
);

export const selectAuditCodesWithGroups = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.auditCodesWithGroups : [])
);

export const productsForDropdown = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.productsForDropdown : [])
);

export const selectResourceTypes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.resourceTypes : [])
);

export const selectAccessRoles = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.accessRoles : [])
);

export const selectBusinessRoles = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.businessRoles : [])
);

export const selectBusinessRules = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.businessRules : [])
);

export const selectExternalLinks = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.externalLinks : [])
);


export const selectEnvironmentRules = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state ? state?.environmentRules : [])
);

export const selectApplicationInfo = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state?.applicationInfo ?? new ApplicationInfoModel())
);

export const selectBannerNotificationTypes = createSelector(
  selectAppSessionState,
  (state: fromState.AppSessionState) => (state?.bannerNotificationTypes ?? [])
);

export const selectImpersonationStatus = createSelector(
  selectCurrentPerson,
  selectAccessPerson,
  (currentPerson, accessPerson) => {
    return {
      currentPerson: {...currentPerson} as PersonWithRolesModel,
      accessPerson: {...accessPerson} as PersonWithRolesModel,
      isImpersonating: (accessPerson?.personID && accessPerson?.personID != currentPerson?.personID) as boolean
    };
  }
);
