import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridBoundSearchBaseComponent } from 'src/app/components/grid-bound-search.base.component';
import { PageExpansionService } from '@services/page-expansion.service';
import { Router } from '@angular/router';
import { SearchFilterService } from '@services/search-filter.service';
import { UserPreferenceEnabledDataBindingDirective } from '../directives/user-preference-enabled-data-binding.directive';
import { FormGroup } from '@angular/forms';

@Component({template:''})
export abstract class UserPreferenceEnabledGridBaseComponent extends GridBoundSearchBaseComponent {

  protected formDefaults: {} = {};

  public abstract bindingDirective?: UserPreferenceEnabledDataBindingDirective;

  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    changeDetectorRef: ChangeDetectorRef,
    searchFilterService: SearchFilterService,
    protected router: Router
  ) {
    super(store, pageExpansionService, searchFilterService, changeDetectorRef);
  }

  public onResetGridPrefs() {
    (this.searchForm as FormGroup).reset((this as any).formDefaults);
    this.selectedKeys.length = 0;
    this.updateFilter();
    this.bindingDirective?.resetGridPreferences();
  }
} 

