

import { ClaimDetailDoctorModel } from './ClaimDetailDoctorModel';//2
import { ClaimDetailCommentModel } from './ClaimDetailCommentModel';//2
import { CommitmentCommentModel } from './CommitmentCommentModel';//2
import { ClaimDetailStatusWithLedgerModel } from './ClaimDetailStatusWithLedgerModel';//2
import { ClaimDetailProductModel } from './ClaimDetailProductModel';//2
import { CommitmentDetailProductModel } from './CommitmentDetailProductModel';//2
import { ClaimDetailAttachmentModel } from './ClaimDetailAttachmentModel';//2
import { AuditInstructionModel } from './AuditInstructionModel';//2
import { ClaimClaimModel } from './ClaimClaimModel';//2
import { ClaimInfoModel } from './ClaimInfoModel';//3

export class ClaimWithDetailsModel extends ClaimInfoModel {
    currentStatusComment?: string | null;
    currentAuditCodeIDs?: number[] | null;
    doctors?: ClaimDetailDoctorModel[] | null;
    comments?: ClaimDetailCommentModel[] | null;
    commitmentComments?: CommitmentCommentModel[] | null;
    history?: ClaimDetailStatusWithLedgerModel[] | null;
    products?: ClaimDetailProductModel[] | null;
    commitmentProducts?: CommitmentDetailProductModel[] | null;
    attachments?: ClaimDetailAttachmentModel[] | null;
    auditInstructions?: AuditInstructionModel[] | null;
    relatedClaims?: ClaimClaimModel[] | null;
}

 
     
         
