



export class DashboardReportRequestModel  {
    personID?: number | null;
    dashboardItemID?: number | null;
    fundYears?: string | null;
    fundIDs?: string | null;
    territoryIDs?: string | null;
    additionalParameters?: { [key: string]: any; } | null;
}

 
     
         
