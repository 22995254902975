import { createReducer, on } from '@ngrx/store';
import * as RootActions from './root.actions';

import { initialState } from './root.state';


export const rootReducer = createReducer(
  initialState,
  on(RootActions.noopAction, state => state),
  on(RootActions.rootStoreIntilized, (state, action) =>
  ({
    ...state,
    //isReady: true
  }))
);

