import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppPersistedSettingsEffects  } from './app-persisted-settings.effects'
import { reducer } from './app-persisted-settings.reducer'
import { appPersistedSettingsFeatureKey } from './app-persisted-settings.state'


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(appPersistedSettingsFeatureKey, reducer),
    EffectsModule.forFeature([AppPersistedSettingsEffects])
  ],
  providers: [AppPersistedSettingsEffects]
})
export class AppPersistedSettingsModule {}
