import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { CommitmentCloseRequestModel } from '@alcon-db-models/CommitmentCloseRequestModel'
import { Observable } from 'rxjs';
import { ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class UserActivateByUsernameService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public ActivateByUserName(username: string): Observable<ServiceResponse<boolean>> {
    return this.post<boolean>('UserInfo/ActivateByUserName', { UserName: username }).pipe(
      firstWithLoadingOverlayAndErrorHandling<boolean>()
    );
  }
}
