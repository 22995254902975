
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpdateDraftStatusRequestModel } from '@alcon-db-models/UpdateDraftStatusRequestModel';
import { Store } from '@ngrx/store';
import {AccessControlBaseService} from './access-controlled-base.service';
import { Router } from '@angular/router';
import { DraftWithDetailsModel } from '@alcon-db-models/DraftWithDetailsModel';
import { DraftRequestModel } from '@alcon-db-models/DraftRequestModel';

@Injectable({
  providedIn: 'root'
})
export class DraftWithDetailsService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public getDraftWithDetail(draftID:string): Promise<DraftWithDetailsModel | undefined> {
    return this.get<DraftRequestModel>('/DraftWithDetails/' + encodeURIComponent(draftID)).pipe(catchError((e) => {
        return throwError("DraftWithDetails query failed");
      }),
    ).toPromise();
  }

  public upsert(draft: DraftWithDetailsModel): Promise<DraftWithDetailsModel> {
    return draft ? this.put<any>(
        '/DraftWithDetails',
        draft
      )
      .toPromise()
      : Promise.resolve(null);
  }
}

