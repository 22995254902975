import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { TerritorySelectSearchBindingDirective } from './../../directives/territory-select-search-binding.directive';

@Component({
  selector: 'acb-alcon-select-territory',
  template: `
   <kendo-dialog-titlebar class="acb-select-titlebar" (close)="onClose()">
      Select Territory
    </kendo-dialog-titlebar>
    <div fxLayout="column" style="height: 100%;">
      <form [formGroup]="searchForm" style="width: 100%;" class="k-form">
        <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
          <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start start">
            <kendo-formfield fxFlex >
              <kendo-label [for]="territoryCodeOrName" text="Territory Code or Name"></kendo-label>
              <input formControlName="territoryCodeOrName" kendoTextBox #territoryCodeOrName autocomplete="off" />
            </kendo-formfield>
            <div fxFlex='0 0 6em' style="text-align: right; padding: 36px 0 0 0;" class="acb-search-button-wrapper">
              <button type="submit" kendoButton (click)="onSearch()" style="width:100%">Search</button>
            </div>
          </div>
        </acb-alcon-section-with-legend>
      </form>
      <kendo-grid
        territorySelectSearchBinding
        [selectable] = "{
          enabled: true,
          mode: 'single'
        }"
        [pageSize]="5"
        [pageable]="{
          buttonCount: 3
        }"
        [sortable]="true"
        kendoGridSelectBy="territoryID"
        [(selectedKeys)] = "this.selectedKeys"
        [customerID]="this.customerID"
        [fundID]="this.fundID"
        #resultsGrid
        fxFlex
      >
        <kendo-grid-command-column [width]="32">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Number" [field]="'code'" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column title="Sales Person" [field]="'salesPerson'">
        </kendo-grid-column>
        <kendo-grid-column title="Name" [field]="'displayName'">
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!selectedKeys.length" (click)="onSelect()" >Select</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-territory.component.scss']
})
export class SelectTerritoryComponent implements OnInit {

  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;
  @ViewChild(TerritorySelectSearchBindingDirective) public territorySelectSearchBindingDirective?: TerritorySelectSearchBindingDirective;

  @Input() public customerID?: number;
  @Input() public fundID?: number;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<{key:any, territory:any}> = new EventEmitter();

  public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedKeys: number[] = [];

  public searchForm = new UntypedFormGroup({
    territoryCodeOrName: new UntypedFormControl(),
  });

  constructor() { }

  ngOnInit(): void {
  }

  public onSearch() {

      if (this.resultsGrid && this.territorySelectSearchBindingDirective) {

        const form = this.searchForm;
        let filters:FilterDescriptor[] = [];

        const setValue = function(fieldName: string) {
          const field = form.get(fieldName);
          if (field) {

            let val = field.value;
            if (typeof field.value === 'string')
              // FIX ME: Escape strings better?  We us a TSQL LIKE comparison...
              val = field.value ? '%' + field.value.replace('%','[%]').replace('_','[_]').replace('[','[[') + '%' : undefined;

            filters.push({ field: fieldName, operator: 'eq', value: val })
          }
        }
        Object.keys(form.controls).forEach(setValue);

        this.territorySelectSearchBindingDirective.customerID = this.customerID;
        this.territorySelectSearchBindingDirective.fundID = this.fundID;
        this.territorySelectSearchBindingDirective.skip = 0;
        this.territorySelectSearchBindingDirective.filter = { filters: filters, logic: 'and' };
        this.rebind();
        this.selectedKeys = [];
      }

  }

  public rebind() {
    this.selectedKeys.length = 0;
    this.territorySelectSearchBindingDirective?.rebind();
    this.selectedKeys = [];
  }

  public validate(): boolean {
    return false;
  }


  public onCancel() {
    this.cancel.emit(null);
  }

  public onSelect() {

    const data:any[] = (this.resultsGrid?.data as GridDataResult)?.data;

    const key = this?.selectedKeys?.length ? this.selectedKeys[0] : null;
    const territory = key ? data?.find(x => x.territoryID == key) : null;
    this.select.emit({key, territory});
  }

  public onClose() {
    this.cancel.emit(null);
  }
}


