<ng-container *ngIf="hierarchyCompares">
  <kendo-treelist
    kendoTreeListExpandable
    [kendoTreeListFlatBinding]="hierarchyCompares"
    parentIdField="parentID"
    idField="id"
    [resizable] = "true"
    [initiallyExpanded]="true"
    [rowClass]="getRowClass"
    >

    <ng-template kendoTreeListToolbarTemplate>
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <kendo-treelist-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-treelist-column-chooser>
      </div>
      <!-- <button kendoButton (click)="onCreateNewFeatureButtonClick()">Create New Feature</button> -->
      <kendo-treelist-spacer></kendo-treelist-spacer>
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <button kendoTreeListExcelCommand type="button" icon="file-excel" class="k-button">Export<span [fxHide.xs]="true">&nbsp;to Excel</span></button>
      </div>
    </ng-template>

    <kendo-treelist-column field="name" title="" [expandable]="true" [width]="200" *ngIf="!showFlat">
      <ng-template kendoTreeListCellTemplate let-dataItem >
          <span class="acb-feature-row-icon k-treelist-ignore-click"></span>
          <span class="acb-feature-row-name">{{getName(dataItem)}}</span>
      </ng-template>
    </kendo-treelist-column>

    <kendo-treelist-column-group [title]="compareGroupTitle1" [headerClass]="'acb-column-group ' + (showFlat ? '' : 'acb-column-sep')">
      <kendo-treelist-column field="territoryCode" title="Code" [headerClass]="showFlat ? '' : 'acb-column-sep'" [class]="showFlat ? '' : 'acb-column-sep'" [width]="120"></kendo-treelist-column>
      <kendo-treelist-column field="territory" title="Name" [width]="180"></kendo-treelist-column>
      <kendo-treelist-column field="salesRep" title="Sales Rep" [width]="180"></kendo-treelist-column>
      <kendo-treelist-column field="businessRole" title="Role" [width]="60"></kendo-treelist-column>
      <kendo-treelist-column field="hasActivity" title="Activity" [width]="80" class="acb-user-grid-yesno-column">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <span *ngIf="dataItem.territoryCode" [ngClass]="{'acb-user-grid-value-no': !dataItem.hasActivity}">{{dataItem.hasActivity ? 'Yes' : 'No'}}</span>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column field="currentYearBalance" title="Yr Balance" [width]="120" format="{0:c}" class="acb-grid-column-currency" [hidden]="true"></kendo-treelist-column>
      <kendo-treelist-column field="previousYearBalance" title="Prev Yr Bal" [width]="120" format="{0:c}" class="acb-grid-column-currency" [hidden]="true"></kendo-treelist-column>
    </kendo-treelist-column-group>

    <kendo-treelist-column-group [title]="compareGroupTitle2" [headerClass]="'acb-column-group acb-column-sep'">
      <kendo-treelist-column field="territoryCode2" title="Code" [headerClass]="'acb-column-sep'" [class]="'acb-column-sep'" [width]="120"></kendo-treelist-column>
      <kendo-treelist-column field="territory2" title="Name" [width]="180"></kendo-treelist-column>
      <kendo-treelist-column field="salesRep2" title="Sales Rep" [width]="180"></kendo-treelist-column>
      <kendo-treelist-column field="businessRole2" title="Role" [width]="60"></kendo-treelist-column>
      <kendo-treelist-column field="hasActivity2" title="Activity" [width]="80" class="acb-user-grid-yesno-column">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <span *ngIf="dataItem.territoryCode2" [ngClass]="{'acb-user-grid-value-no': !dataItem.hasActivity2}">{{dataItem.hasActivity2 ? 'Yes' : 'No'}}</span>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column field="currentYearBalance2" title="Yr Balance" [width]="120" format="{0:c}" class="acb-grid-column-currency" [hidden]="true"></kendo-treelist-column>
      <kendo-treelist-column field="previousYearBalance2" title="Prev Yr Bal" [width]="120" format="{0:c}" class="acb-grid-column-currency" [hidden]="true"></kendo-treelist-column>
    </kendo-treelist-column-group>

    <!-- <kendo-treelist-column field="accessRoles" title="Roles" [class]="{'acb-feature-column-access-role-list-compact': (showAllAccessRoles$ | async) }" media="sm">
      <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem="dataItem">
        <div *ngIf="dataItem.accessRoles?.length < 2 || (showAllAccessRoles$ | async); else elseBlock" class="acb-access-role-list" >
          <span *ngFor="let accessRoleID of dataItem.accessRoles; let isLast = last">{{ this.lookUpAccessRoleName(accessRoleID, true) }}{{ isLast ? '' : ', '  }}</span>
        </div>
        <ng-template #elseBlock>
          {{ dataItem.accessRoles?.length }} Roles Assigned
          <button kendoButton [icon]="'question'" look="flat"></button>
        </ng-template>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-command-column [width]="100">
      <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem="dataItem">
          <button [kendoTreeListEditCommand]="cellContext"
                  icon="edit" title="Edit" look="flat"
                  (click)="onEditCommandButtonClick(dataItem, cellContext, isNew)"
                  >
          </button>
          <button *ngIf="dataItem.featureMandateType === FeatureMandateType.User"
            [kendoTreeListRemoveCommand]="cellContext" icon="delete" title="Remove" look="flat">
          </button>
      </ng-template>
    </kendo-treelist-command-column> -->
    <kendo-treelist-excel fileName="HierarchyComparison.xlsx" [collapsible]="true"></kendo-treelist-excel>
  </kendo-treelist>
</ng-container>
