import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-display-errors',
  template: `
    <div class="acb-error-wrapper" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1em" [ngStyle.gt-xs]="{'margin-right':'-1.5em'}">
      <div class="acb-top-error-message" fxFlex="1 0 12em" fxFlex.xs="auto" [ngClass.gt-xs]="{'acb-error-row-item': true}">
        {{ topError?.message }}
      </div>
      <div class="acb-error-item" *ngFor="let err of flatList" fxFlex="noshrink" fxFlex.xs="auto" [ngClass.gt-xs]="{'acb-error-row-item': true}">
        <div class="acb-error-group-title" *ngIf="err?.groupTitle">
          {{ err.groupTitle }}
        </div>
        <div class="acb-error-message" *ngIf="err?.message">
          {{ err.message }}
        </div>
        <div class="acb-error-list" *ngIf="err.children?.length">
          <div class="acb-error-message" *ngFor="let msg of err.children">
            {{ msg.message }}
          </div>
        </div>
      </div>
  </div>
  `,
  styleUrls: ['./display-errors.component.scss']
})
export class DisplayErrorsComponent implements OnInit {

  @Input() set displayError(value: DisplayError | null | undefined) {
    this.topError = value
    if (!value)
      return;
    this.flatList = value?.children ?? [];
  }

  public topError?: DisplayError | null;
  public flatList: DisplayError[] = [];

  constructor() { }

  ngOnInit(): void {
  }
}

export class DisplayError {
  public message?: string;
  public groupTitle?: string;
  public children?: DisplayError[] = [];
}

