import { AttachementModel } from "@alcon-db-models/AttachementModel";
import { ClaimDetailAttachmentModel } from "@alcon-db-models/ClaimDetailAttachmentModel";
import { SourceType, StatusCode, VisibilityType } from "@alcon-db-models/Enums";
import { GridComponent, GridDataResult } from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { BehaviorSubject } from "rxjs";
import { Utilities } from "../shared/utilities";

export abstract class ViewAttachmentsBaseComponent {

  protected statusTrackingGrid?: GridComponent;

  public StatusCode = StatusCode;
  public gridView$: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>({data:[], total: 0});
  public abstract sort: SortDescriptor[];

  public stagingPath?: string = './UserUploads/_in/';
  public attachmentPath: string | null = null;
  public commitmentAttachmentPath: string | null = null;

  constructor() { }

  public createUrl(attachment: AttachementModel): string | undefined {

    if (!this.stagingPath || !attachment.internalFileName || !attachment.displayName || !this.attachmentPath)
      return undefined;

    let sourcePath: string | undefined = attachment.statusCode == StatusCode.Processing ? this.stagingPath : this.attachmentPath;
    if ((attachment as any).sourceType) {
      if ((attachment as ClaimDetailAttachmentModel).sourceType == SourceType.Commitment) {
        sourcePath = this.commitmentAttachmentPath ?? undefined;
      }
    }

    const path = sourcePath ? Utilities.normalizeUrl([
      sourcePath,
      window.encodeURIComponent(attachment.internalFileName),
      window.encodeURIComponent(attachment.displayName)
    ]) : undefined;

    return path;
  }

  public getVisibilityType(visibilityType: VisibilityType) {
    return VisibilityType[visibilityType];
  }

  public getVisibilityClass(visibilityType: VisibilityType) {
    return visibilityType == VisibilityType.Hidden ? 'acb-warning' : 'acb-good-dark';
  }

  protected loadData(attachments?: AttachementModel[]) {
    this.gridView$.next({
      data: orderBy(attachments ?? [], this.sort),
      total: attachments?.length ?? 0
    });
  }

  public onViewImage(attachement: AttachementModel) {
    const url = this.createUrl(attachement);
    if (url)
      window.open(url, "_blank");
  }
}
