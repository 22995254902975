
import { VisibilityType } from './Enums';//1
import { AuditCodeGroup } from './Enums';//1


export class AuditCodeWithGroupModel  {
    auditCodeID?: number | null;
    code?: string | null;
    displayName?: string | null;
    visibilityType?: VisibilityType | null;
    auditCodeGroups?: AuditCodeGroup[] | null;
}

 
     
         
