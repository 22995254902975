import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-create-claim-review',
  template: `
   <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start end" fxLayoutAlign.lt-md class="acb-details-wrapper" [ngClass.xs]="'acb-details-wrapper-xs'"  [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'">
        <acb-alcon-view-claim-details-compact>
        </acb-alcon-view-claim-details-compact>
        <div class="acb-edit-button-wrapper">
          <button
            kendoButton
            class="acb-edit-button"
            [ngClass] = "{ 'acb-error': !this.detailsValid }"
            (click)="onEditDetails()"
            >
          <span class="k-icon k-i-edit"></span> Edit</button>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">

        <div
          class="acb-commitment acb-view-mode"
          fxLayout="row"
          fxFlex="1 0 16em"
          fxFlex.lt-sm="grow"
          [ngClass]="{ 'acb-expanded': commitmentDisplay.isExpanded }"
          [ngClass.gt-xs]="{ 'acb-expanded': commitmentDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
          >
          <div class="acb-details-layout-section acb-section2" fxFlex fxLayout="column">
            <acb-alcon-display-info-card
              #commitmentDisplay
              [label]="'Commitment'"
              [headerValue]="claim?.commitmentID ? claim?.commitmentID?.toString() : ''"
              [nameAndValues] = "[
                ['ID', claim?.commitmentID ? claim?.commitmentID?.toString() : '', true],
                ['Customer', claim?.customer, false],
                ['Fund', claim?.fund, false]
              ]"
              [isExpandedOnly]="false"
              fxFlex
              ></acb-alcon-display-info-card>
            <div class="acb-edit-button-wrapper" [hidden]="!commitmentDisplay.isExpanded">
              <button kendoButton class="acb-edit-button" (click)="onEditCommitment()"><span class="k-icon k-i-edit"></span> Edit</button>
            </div>
          </div>
        </div>

        <div
          class="acb-payee acb-view-mode"
          fxLayout="row"
          fxFlex="1 0 16em"
          fxFlex.lt-sm="grow"
          [ngClass]="{ 'acb-expanded': payeeDisplay.isExpanded }"
          [ngClass.gt-xs]="{ 'acb-expanded': payeeDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
          >
          <div class="acb-details-layout-section acb-section3" fxFlex fxLayout="column">
            <acb-alcon-display-address
              #payeeDisplay
              [label]="'Payee'"
              [code]="claim?.payeeCustomerCode"
              [name]="claim?.payee"
              [location]="claim?.payeeLocation"
              [isExpandedOnly]="false"
              fxFlex
              ></acb-alcon-display-address>
            <div class="acb-edit-button-wrapper" [hidden]="!payeeDisplay.isExpanded">
              <button kendoButton class="acb-edit-button" (click)="onEditPayee()"><span class="k-icon k-i-edit"></span> Edit</button>
            </div>
          </div>
        </div>
      </div>

      <div class="acb-later-steps" fxLayout="column" fxLayoutAlign="start stretch">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" [ngClass.gt-xs]="'acb-intra-form-margin-correction'" fxFlex="1 1 100%">
          <acb-alcon-section-with-legend
            #productsSection
            [isCollapsible]="false"
            [sectionTitle]="'Products'"
            class="acb-section-11 acb-products-section"
            fxFlex="calc(33.333% - 2em)"
            [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
            >
            <div fxLayout="column" style="height: 100%;">
              <acb-alcon-view-claim-products [claim]="claim" [viewMode]="'edit'" fxFlex>
              </acb-alcon-view-claim-products>
              <div class="acb-edit-button-wrapper">
                <button
                  kendoButton
                  class="acb-edit-button"
                  [ngClass] = "{ 'acb-error': !this.productsValid }"
                  (click)="onEditProducts()"
                >
                <span class="k-icon k-i-edit"></span> Edit</button>
              </div>
            </div>
          </acb-alcon-section-with-legend>
          <acb-alcon-section-with-legend
            #attachmentsSection
            [isCollapsible]="false"
            [sectionTitle]="'Attachments'"
            class="acb-section-06 acb-attachments-section"
            fxFlex="calc(33.333% - 2em)"
            [ngClass.gt-xs]="'acb-fx-intra-form-margin-controlled-container'"
            [ngClass.xs]="'acb-attachments-section-small'"
            >
            <div fxLayout="column" style="height: 100%;">
              <acb-alcon-view-claim-attachments [viewMode]="'view-edit'" [compactMode]="'compact'" [claim]="claim" [doShowFieldLabel]="false" >
              </acb-alcon-view-claim-attachments>
              <div class="acb-edit-button-wrapper">
                <button
                    kendoButton
                    class="acb-edit-button"
                    [ngClass] = "{ 'acb-error': !this.filesValid }"
                    (click)="onEditAttachments()"
                  >
                  <span class="k-icon k-i-edit"></span> Edit
                </button>
              </div>
            </div>
          </acb-alcon-section-with-legend>
        </div>

    </div>
  `,
  styleUrls: ['./create-claim-review.component.scss']
})
export class CreateClaimReviewComponent implements OnInit {

  @Output() selectCommitment: EventEmitter<any> = new EventEmitter();
  @Output() selectPayee: EventEmitter<any> = new EventEmitter();
  @Output() newPayee: EventEmitter<any> = new EventEmitter();
  @Output() editDetails: EventEmitter<any> = new EventEmitter();
  @Output() editProducts: EventEmitter<any> = new EventEmitter();
  @Output() editAttachments: EventEmitter<any> = new EventEmitter();

  public detailsValid: boolean = false;
  public productsValid: boolean = false;
  public filesValid: boolean = false;
  public claim?: ClaimWithDetailsModel;
  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    claimFormBaseService: ClaimFormBaseService,
  ) {
    if (claimFormBaseService) {
      claimFormBaseService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.claim = x;
        this.filesValid = Boolean(x.attachments?.length);

      });
      claimFormBaseService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.detailsValid = x.details;
        this.productsValid = x.products;
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onEditCommitment() {
    this.selectCommitment.emit();
  }

  public onEditPayee() {
    this.selectPayee.emit();
  }

  public onNewPayee() {
    this.newPayee.emit();
  }

  public onEditDetails() {
    this.editDetails.emit();
  }

  public onEditProducts() {
    this.editProducts.emit();
  }

  public onEditAttachments() {
    this.editAttachments.emit();
  }

}
