import { ClaimUpsertRequestModel } from "@alcon-db-models/ClaimUpsertRequestModel";
import { ClaimWithDetailsModel } from "@alcon-db-models/ClaimWithDetailsModel";
import { DraftFileUploadModel } from "@alcon-db-models/DraftFileUploadModel";
import { DraftModel } from "@alcon-db-models/DraftModel";
import { DraftWithDetailsModel } from "@alcon-db-models/DraftWithDetailsModel";
import { AttachmentRole, ClaimClaimRelationshipType, DraftType, SourceType, StatusCode, VisibilityType } from "@alcon-db-models/Enums";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { selectBusinessRules, selectCurrentPerson } from "@app-store/app-session/app-session.selectors";
import { getGuid } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, first, takeUntil, map, finalize, tap, } from "rxjs/operators";
import { ServiceResponse } from "../shared/acb-stream";
import { JsonUtilities } from "../shared/json-utilities";
import { ClaimUpsertServiceService } from "./claim-upsert-service.service";
import { ClaimWithDetailsService } from "./claim-with-details.service";
import { CommitmentWithDetailsService } from "./commitment-with-details.service";
import { DraftWithDetailsService } from "./draft-with-details.service";

@Injectable()
export abstract class ClaimSubjectBaseService implements OnDestroy {

  businessRules: any = {};

  private _claimID: number | undefined;
  public set claimID(value: number | undefined) {
    this._claimID = value;

    if (this._claimID) {
      this.claimWithDetailsService.getByKey(this._claimID).pipe(first()).subscribe(x => {
        const claim = this.createClaim(JsonUtilities.convertDatesAndCopy(x) as ClaimWithDetailsModel);
        this.claim$.next(claim);
        this._claimIDAssigned$.next(true);
      });
    } else {
      this.claim$.next(this.createClaim());
    }
  };
  public get claimID():number | undefined {
    return this._claimID;
  }

  public readonly claim$ = new ReplaySubject<ClaimWithDetailsModel>(1);
  public loading$ = new ReplaySubject<boolean>(1);
  public saving$ = new ReplaySubject<boolean>(1);
  public error$ = new ReplaySubject<any>(1);
  public isEdit:boolean = false;

  protected _destroy$ = new Subject<void>();
  //TODO: hack
  protected _claimIDAssigned$ = new Subject<boolean>();
  protected _claim?: ClaimWithDetailsModel;

  constructor(
    protected claimWithDetailsService: ClaimWithDetailsService,
    protected store:Store,
    private claimUpsertServiceService: ClaimUpsertServiceService,
    protected commitmentWithDetailsService: CommitmentWithDetailsService,
    private draftWithDetailsService: DraftWithDetailsService
  ) {

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.businessRules = x;
    });

    this.claim$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      const claim = JsonUtilities.convertDatesAndCopy(x) as ClaimWithDetailsModel;
      this._claim = claim;
    });

    this.claimWithDetailsService.loading$.pipe(takeUntil(this._destroy$)).subscribe(this.loading$);
  }

  protected createClaim(claim?:ClaimWithDetailsModel): ClaimWithDetailsModel {
    claim = claim ?? new ClaimWithDetailsModel();
    claim.code = claim.code ?? getGuid().replace('-','')
    return claim;
  }

  public static cleanClaim(claim: ClaimWithDetailsModel) : ClaimWithDetailsModel {
    claim = JsonUtilities.convertDatesAndCopy(claim);
    claim.claimID = null;
    claim.products?.forEach(x => {
      x.claimID = null,
      x.claimDetailID = null,
      x.claimDetailProductID = null
    });
    claim.attachments?.forEach(x => {
      x.claimID = null,
      x.claimDetailID = null,
      x.claimDetailAttachmentID = null
    });
    claim.history = [];
    return claim;
  }

  // public static createClaimFromDraft(draft: DraftModel) : ClaimWithDetailsModel | null {
  //   if (draft.draftBody) {
  //     let obj = JSON.parse(draft.draftBody ?? "")?.claim ?? null;
  //     if (obj) {
  //       return JsonUtilities.convertDatesAndCopy(obj);
  //     }
  //   }
  //   return null;
  // }

  public static createClaimFromDraft(draft: DraftWithDetailsModel) : ClaimWithDetailsModel | null {
    let claim: ClaimWithDetailsModel = draft.draftBody ? JsonUtilities.convertDatesAndCopy(JSON.parse(draft.draftBody))?.claim : null;

    claim.attachments = draft.draftFileUploads?.map(x => ({
      claimDetailAttachmentID: null,
      claimDetailID: null,
      claimID: null,
      code: x.resourceCode,
      displayName: x.displayName,
      attachmentRole: AttachmentRole.Other,
      resourceType: x.resourceTypeID,
      fileSize: x.fileSize,
      internalFileName: x.internalFileName,
      visibilityType: VisibilityType.Visible,
      statusCode: StatusCode.Draft,
      sourceType: x.sourceTypeID
    }));

    return claim;
  }

  // public static createDraftClaim(claim: ClaimWithDetailsModel, name?: string, description?: string, draftID?: string) : DraftModel {
  //   return {
  //     draftID: draftID,
  //     draftTypeID: DraftType.Claim,
  //     displayName: name ?? `Draft for ${claim.customer ?? 'UNKNOWN'} (${(new Date()).toLocaleDateString('en-US')})`,
  //     displayDescription: description,
  //     draftBody: JSON.stringify({
  //       claim: this.cleanClaim(claim)
  //     })
  //   }
  // }

  public createDraftClaim(claim: ClaimWithDetailsModel, name?: string, description?: string, draftID?: string) : Promise<DraftWithDetailsModel|null|undefined> {

    return !draftID ? Promise.resolve(null) : this.draftWithDetailsService.getDraftWithDetail(draftID)
      .then((oldDraft: DraftWithDetailsModel | null|undefined) => {

        const draft : DraftWithDetailsModel = {
          draftID: draftID,
          draftTypeID: DraftType.Claim,
          displayName: name ?? `Draft for ${claim.customer ?? 'UNKNOWN'} (${(new Date()).toLocaleDateString('en-US')})`,
          displayDescription: description,
          draftBody: JSON.stringify({
            claim: ClaimSubjectBaseService.cleanClaim(claim)
          })
        };

        draft.draftFileUploads = oldDraft?.draftFileUploads?.filter(x => claim.attachments?.some(y => y.code == x.resourceCode)) ?? [];
        draft.draftFileUploads.push(
          ...claim.attachments?.filter(x => x.statusCode == StatusCode.Processing || !oldDraft?.draftFileUploads?.some(y => y.resourceCode = x.code)).map(x => ({
            draftID: draftID,
            code: getGuid(),
            resourceCode: x.code,
            displayName: x.displayName,
            resourceTypeID: x.resourceType,
            sourceTypeID: x.sourceType,
            internalFileName: x.internalFileName,
            fileSize: x.fileSize
          }) as DraftFileUploadModel) ?? []
        );

        return draft;
      })
      .catch((x:HttpErrorResponse) => {
        return null;
      });
  }



  public SaveClaim(draftID?: string | null | undefined, doShowOverlay: boolean = true): Observable<ServiceResponse<number>> {

    let personID: number | null = null;
    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      personID = x?.personID ?? null
    })

    this.saving$.next(true);

    if (!this._claim) {
      const msg = "No commitment";
      this.saving$.next(false);
      this.error$.next(msg)
      return of({ hasError: true, errorMessage: [msg], response: null });
    }

    const claim: ClaimUpsertRequestModel = {
      personID: personID,
      claimID: this._claim.claimID,
      code: this._claim.code,
      displayName: this._claim.claimID?.toString(),
      internalNote: undefined,
      claimSetID: undefined,
      claimSetRoleID: undefined,
      commitmentID: this._claim.commitmentID,
      commitmentDetailID: undefined,
      receivedDate: this._claim.receivedDate,
      inputDate: undefined,
      processedDate: undefined,
      deductionNumber: this._claim.deduction,
      copyApprovalID: undefined,
      activityID: this._claim.activityID,
      activityDescription: this._claim.activityDescription,
      payTypeID: this._claim.payTypeID,
      fundID: this._claim.fundID,
      amount: this._claim.claimedAmount,
      statusCodeID: StatusCode.Inprocess,
      products: this._claim.products,
      attachments: this._claim.attachments?.filter(x => x.sourceType != SourceType.Commitment),
      payeeCustomerID: this._claim.payeeCustomerID,
      startDate: this._claim.startDate,
      endDate: this._claim.endDate,
      invoiceNumbers: this._claim.invoice,
      comments: this._claim.comments,
      doctors: this._claim.doctors,
      numberOfAds: this._claim.numberOfAds,
      wereBenefitsReceived: this._claim.wereBenefitsReceived,
      currentStatusComment: this._claim.currentStatusComment,
      currentAuditCodeIDs: this._claim.currentAuditCodeIDs ? (this._claim.currentAuditCodeIDs.map(x => String(x))).join(',') : undefined,
      draftID: draftID,
      specialClaimTypeID: this._claim.specialClaimTypeID,
      relatedClaims: this._claim.specialClaimTypeID ? this._claim.relatedClaims : this._claim.relatedClaims?.filter(x => x.claimClaimRelationshipTypeID != ClaimClaimRelationshipType.Reversal)
    };

    return this.claimUpsertServiceService.upsertClaim(claim,doShowOverlay).pipe(
      tap(x => {
        if (x.hasError) {
          this.error$.next(x.errorMessage);
        } else {
          this.claim$.next({
            ...this._claim,
            claimID: x.response
          })
          this.error$.next(null);
        }
      }),
      finalize(() => {
        this.loading$.next(false);
        this.saving$.next(false);
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
