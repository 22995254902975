
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-display-info-card',
  template: `
    <div class="acb-card-wrapper" (click)="onClick($event); $event.stopPropagation();" [ngClass]="{'acb-expanded':isExpanded, 'acb-expandable':!isExpandedOnly}">
      <span class="acb-expand-indicator"></span>
      <ng-container>
        <ng-container *ngIf="!isExpanded">
          <label class='acb-alcon-display-item-label'>{{ label }}</label>
          <label class='acb-value acb-top-value-label'>{{ headerValue }}&nbsp;</label>
        </ng-container>
        <ng-container *ngIf="isExpanded">
          <label class='acb-alcon-display-item-label'>{{ label }}</label>
          <table>
            <tbody>
              <tr *ngFor="let item of nameAndValues" [ngClass]="{ 'acb-emphasize': item[2] }">
                <td>{{ item[0] }}:</td>
                <td>{{ item[1] }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </div>
  `,
  styleUrls: ['./display-info-card.component.scss']
})
export class DisplayInfoCardComponent implements OnInit {

  @Input() label?: string | null;
  @Input() headerValue?: string | null;
  @Input() nameAndValues?: [string | null | undefined, string | null | undefined, boolean][] = [];

  private _isExpandedOnly: boolean = false;
  @Input() public set isExpandedOnly(value: boolean) {
    this._isExpandedOnly = value;
    this.isExpanded = value;
  };
  public get isExpandedOnly(): boolean {
    return this._isExpandedOnly;
  }

  public isExpanded: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  private toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public toggle() {
    this.toggleExpand();
  }

  public onClick(event: any) {
    if (!this.isExpandedOnly)
      this.toggleExpand();
  }
}
