import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { SpecialClaimType } from '@alcon-db-models/Enums';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AppUxService } from '@services/app-ux.service';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { takeUntil } from 'rxjs/operators';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { EditProductsBaseComponent } from '../edit-products.base.component';

@Component({
  selector: 'acb-alcon-edit-claim-products',
  template: `
    <form
      *ngIf="form"
      [formGroup]="form"
      style="width: 100%; height: 100%;"
      class="k-form"
      [ngClass]="{
        'acb-setup-complete': this.amount === assignedAmount,
        'acb-cost-exceeded': getIsCostExceeded(),
        'acb-applied-zero': !assignedAmount
      }">
    <acb-alcon-section-with-legend [sectionTitle]="'Products'" class="acb-section-11" [doShowLegend]="doShowLegend">
      <div [fxLayout]="'column'">
        <div fxLayout="row" fxLayoutGap="1em" style="margin-bottom:1em; height: 5em;">
          <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label">
            <kendo-label fxLayout="row" fxLayoutAlign="start end" class="acb-progress-top-label"><span>Setup Progress</span></kendo-label>
            <div fxFlex class="acb-progress">
              <ng-container *ngIf="this.amount || 0; let amount; else noAmount">
                <kendo-progressbar
                  #progressBar
                  [min]="progressBarMin"
                  [max]="progressBarMax"
                  [value]="getAssignedAmountForProgressBar()"
                  [label]="{ format: getProgressBarFormat, position: 'center' }"
                  emptyCssClass="acb-progressbar-empty"
                  progressCssClass="acb-progressbar-progress"
                  >
                </kendo-progressbar>
              </ng-container>
              <ng-template #noAmount>
                <div fxLayout fxLayoutAlign="center center" class="acb-no-progress">
                  <kendo-label>
                    Assign Claim Amount to view phase progress
                  </kendo-label>
                </div>
              </ng-template>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label acb-reset-button-wrapper">
            <kendo-label>&nbsp;</kendo-label>
            <button fxFlex kendoButton [icon]="'reset'" (click)="onReset()" [disabled]="!assignedAmount">
            </button>
          </div>
        </div>

        <div gdColumns="repeat(auto-fit, minmax(12em, 1fr))" fxFlex="6 0 auto" class="acb-progress-summary">
          <div class="acb-applied-row" fxLayout="row">
            <span fxFlex="0 0 6em" style="text-align:right">
              Applied:
            </span>
            <span fxFlex>
              {{ assignedAmount | currency }}
            </span>
          </div>
          <div class="acb-remaining-row" fxLayout="row">
            <span fxFlex="0 0 6em" style="text-align:right">
              Remaining:
            </span>
            <span fxFlex>
              {{ getRemaining() | currency }}
            </span>
          </div>
        </div>
        <div gdColumns="repeat(auto-fit, minmax(22em, 1fr))" gdColumns.sm="repeat(auto-fit, minmax(22em, .75fr))" gdColumns.gt-sm="repeat(auto-fit, minmax(22em, .65fr))" class="acb-edit-commitment-product-list">
          <acb-alcon-edit-claim-product
            *ngFor="let productForm of $any(productsFormArray?.controls); index as i;"
            [form]="productForm"
            [claimedAmount]="this.amount || 0"
            [productsForDropdown$]="remainingProductsByProduct[i]"
            (delete)="onDelete($event)"
            >
          </acb-alcon-edit-claim-product>
        </div>
        <div class="acb-add-product-button-wrapper">
          <button kendoButton (click)="onAdd()" [disabled]="remainingProductsByProduct.length > 0 && remainingProductsByProduct[0].value.length == 1"><span class="k-icon k-i-add"></span> Add Product</button>
        </div>
      </div>
    </acb-alcon-section-with-legend>
  `,
  styleUrls:  ['./edit-claim-products.component.scss']
})
export class EditClaimProductsComponent extends EditProductsBaseComponent implements OnInit, OnDestroy {

  @Input() doShowLegend: boolean = true;

  public claim?: ClaimWithDetailsModel;
  public form: UntypedFormGroup;
  public amount: number = 0;

  public progressBarMin: number = 0
  public progressBarMax: number = 0

  constructor(
    appUxService: AppUxService,
    private claimFormBaseService: ClaimFormBaseService,
    changeDetectorRef: ChangeDetectorRef
    ) {

    super(appUxService,changeDetectorRef);

    this.form = claimFormBaseService.form;
    this.productsFormArray = this.form?.controls.claimProducts as UntypedFormArray;

    claimFormBaseService.products$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.productsForDropdown = x;
    });

    claimFormBaseService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.claim = JsonUtilities.convertDatesAndCopy(x);
      this.amount = x.claimedAmount ?? 0;

      // if (x.specialClaimTypeID == SpecialClaimType.Reversal) {
      //   this.progressBarMin = this.amount;
      //   this.progressBarMax = 0;
      // } else {
        this.progressBarMin = 0;
        this.progressBarMax = Math.abs(this.amount);
      // }


      changeDetectorRef.markForCheck();
    })

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onFormChanges);
  }

  getAssignedAmountForProgressBar(): number {
    return Math.abs(this.assignedAmount);
  }

  getIsCostExceeded() {
    return Math.abs(this.assignedAmount) > Math.abs(this.amount || 0);
  }

  ngOnInit(): void {
    // Need to make sure any recalcs make it back to form
    this.claimFormBaseService.updateForm();
    super.ngOnInit();
  }

  public onAdd = (() => {
    this.claimFormBaseService.addProduct();
  }).bind(this);

  public onDelete = ((productForm:UntypedFormGroup) => {
    if (!productForm)
      return;
    const productID: number | undefined = productForm.controls.productID.value;
    this.claimFormBaseService.removeProduct(productID);
  }).bind(this);

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

